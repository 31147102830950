import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Box, Card, Grid, Typography } from "@mui/material"

import UserRow from "./content/UserRow";

const UserChart = ({ date, active, filter }) => {
    const {user, setMsg} = useContext(DataContext);

    const [traffic, setTraffic] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getTraffic();
    }, [date, active]);

    const getTraffic = async() => {
        const res = await fetch(apiUrl+"/traffic/daily/users", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                day: date,
                info: active,
                company: user.company.id
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTraffic(data.traffic);
    };

    return (
        <Grid item xs={12}>
            <Card
                variant="outlined"
                sx={{ p: 2 }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2.5}
                >
                    <Typography
                        fontSize={16}
                        fontWeight={500}
                        color="#000"
                    >
                        {active === 0 && "Értékesítő forgalom"}
                        {active === 1 && "Értékesítő vásárlószám"}
                        {active === 2 && "Értékesítő profit"}
                        {active === 3 && "Értékesítő árrés"}
                        {active === 4 && "Értékesítő kosárérték"}
                    </Typography>

                    {traffic.map((item, i) => {
                        if(filter.filter(item => item.active).map(item => item.id).includes(item.store)){
                            return (
                                <UserRow
                                    key={item.user}
                                    active={active}
                                    max={traffic[0].sum_traffic}
                                    name={item.last_name+" "+item.first_name}
                                    sum={item.sum_traffic}
                                />
                            );
                        }

                        return;
                    })}
                </Box>
            </Card>
        </Grid>
    );
};

export default UserChart;