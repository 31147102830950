import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Box, Card, Grid, Skeleton, Typography } from "@mui/material";

import ReactApexChart from 'react-apexcharts';
import CurrencyText from "../../../../components/currencytext/CurrencyText";

const StoreChart = ({ date, active, filter }) => {
    const {user, setMsg} = useContext(DataContext);

    const [traffic, setTraffic] = useState([]);
    const [showTraffic, setShowTraffic] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getDaily();
    }, [date, active]);

    useEffect(() => {
        const filtered = traffic.filter(item =>
            filter.filter(item => item.active)
            .map(item => item.id)
            .includes(item.store)
        );

        setShowTraffic(filtered);
    }, [traffic, filter]);

    const getDaily = async() => {
        const res = await fetch(apiUrl+"/traffic/daily/stores", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                day: date,
                info: active,
                company: user.company.id
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTraffic(data.traffic);
    };

    const calculateSum = () => {
        const sum = showTraffic.reduce((acc, obj) => {
                return acc + obj.sum_traffic;
            },0);

        return sum;
    };

    return (
        <Grid item xs={12} md={4}>
            <Card
                variant="outlined"
                sx={{ p: 2, height: 390 }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    <Typography
                        fontSize={12}
                        fontWeight={400}
                        color="#8C8C8C"
                    >
                        {active === 0 && "Napi forgalom total / telephely"}
                        {active === 1 && "Napi vásárlószám total / telephely"}
                        {active === 2 && "Napi profit total / telephely"}
                        {active === 3 && "Napi árrés total / telephely"}
                        {active === 4 && "Napi korsárérték total / telephely"}
                    </Typography>

                    {traffic.length !== 0 ?
                        <Typography
                            fontSize={24}
                            fontWeight={700}
                            color="#000"
                        >
                            {(active === 0 || active === 2) && 
                                <>
                                    <CurrencyText val={calculateSum()} /> HUF
                                </>
                            }

                            {active === 1 && 
                                <>
                                    {calculateSum()} DB
                                </>
                            }

                            {active === 3 && 
                                <>
                                    {(calculateSum()/traffic.length).toFixed(2)} %
                                </>
                            }

                            {active === 4 && 
                                <>
                                    <CurrencyText val={Math.round(calculateSum()/traffic.length)} /> HUF
                                </>
                            }
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={190} height={30} />
                    }

                    {active === 0 &&
                        <ReactApexChart
                            type="bar"
                            height={305}
                            options={{
                                chart: {
                                    height: 305,
                                    type: "bar",
                                    toolbar: {
                                        show: false
                                    }
                                },
                                tooltip: {
                                    y: {
                                        title: {
                                            formatter: (_) => "Forgalom:",
                                        },
                                        formatter: (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" HUF"
                                    }
                                },
                                grid: {
                                    show: false
                                },
                                legend: {
                                    show: false
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                xaxis: {
                                    categories: showTraffic.map(item => item.name),
                                    labels: {
                                        style: {
                                            colors: showTraffic.map((_) => "#8C8C8C")
                                        }
                                    },
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },
                                yaxis: {
                                    show: false
                                },
                                plotOptions: {
                                    bar: {
                                        distributed: true,
                                        borderRadius: 4,
                                        borderRadiusApplication: 'around',
                                        borderRadiusWhenStacked: 'all',
                                    }
                                },
                                colors: showTraffic.map(item => item.color_code_2)
                            }}
                            series={[
                                {
                                    data: showTraffic.map(item => {
                                        return item.sum_traffic;
                                    })
                                }
                            ]}
                        />
                    }

                    {active === 1 &&
                        <ReactApexChart
                            type="bar"
                            height={305}
                            options={{
                                chart: {
                                    height: 305,
                                    type: "bar",
                                    toolbar: {
                                        show: false
                                    }
                                },
                                tooltip: {
                                    y: {
                                        title: {
                                            formatter: (_) => "Vásárlószám:",
                                        },
                                        formatter: (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" DB"
                                    }
                                },
                                grid: {
                                    show: false
                                },
                                legend: {
                                    show: false
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                xaxis: {
                                    categories: showTraffic.map(item => item.name),
                                    labels: {
                                        style: {
                                            colors: showTraffic.map((_) => "#8C8C8C")
                                        }
                                    },
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },
                                yaxis: {
                                    show: false
                                },
                                plotOptions: {
                                    bar: {
                                        distributed: true,
                                        borderRadius: 4,
                                        borderRadiusApplication: 'around',
                                        borderRadiusWhenStacked: 'all',
                                    }
                                },
                                colors: showTraffic.map(item => item.color_code_2)
                            }}
                            series={[
                                {
                                    data: showTraffic.map(item => {
                                        return item.sum_traffic;
                                    })
                                }
                            ]}
                        />
                    }

                    {active === 2 &&
                        <ReactApexChart
                            type="bar"
                            height={305}
                            options={{
                                chart: {
                                    height: 305,
                                    type: "bar",
                                    toolbar: {
                                        show: false
                                    }
                                },
                                tooltip: {
                                    y: {
                                        title: {
                                            formatter: (_) => "Profit:",
                                        },
                                        formatter: (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" HUF"
                                    }
                                },
                                grid: {
                                    show: false
                                },
                                legend: {
                                    show: false
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                xaxis: {
                                    categories: showTraffic.map(item => item.name),
                                    labels: {
                                        style: {
                                            colors: showTraffic.map((_) => "#8C8C8C")
                                        }
                                    },
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },
                                yaxis: {
                                    show: false
                                },
                                plotOptions: {
                                    bar: {
                                        distributed: true,
                                        borderRadius: 4,
                                        borderRadiusApplication: 'around',
                                        borderRadiusWhenStacked: 'all',
                                    }
                                },
                                colors: showTraffic.map(item => item.color_code_2)
                            }}
                            series={[
                                {
                                    data: showTraffic.map(item => {
                                        return item.sum_traffic;
                                    })
                                }
                            ]}
                        />
                    }

                    {active === 3 &&
                        <ReactApexChart
                            type="bar"
                            height={305}
                            options={{
                                chart: {
                                    height: 305,
                                    type: "bar",
                                    toolbar: {
                                        show: false
                                    }
                                },
                                tooltip: {
                                    y: {
                                        title: {
                                            formatter: (_) => "Árrés:",
                                        },
                                        formatter: (val) => val.toFixed(2)+" %"
                                    }
                                },
                                grid: {
                                    show: false
                                },
                                legend: {
                                    show: false
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                xaxis: {
                                    categories: showTraffic.map(item => item.name),
                                    labels: {
                                        style: {
                                            colors: showTraffic.map((_) => "#8C8C8C")
                                        }
                                    },
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },
                                yaxis: {
                                    show: false
                                },
                                plotOptions: {
                                    bar: {
                                        distributed: true,
                                        borderRadius: 4,
                                        borderRadiusApplication: 'around',
                                        borderRadiusWhenStacked: 'all',
                                    }
                                },
                                colors: showTraffic.map(item => item.color_code_2)
                            }}
                            series={[
                                {
                                    data: showTraffic.map(item => {
                                        return item.sum_traffic;
                                    })
                                }
                            ]}
                        />
                    }

                    {active === 4 &&
                        <ReactApexChart
                            type="bar"
                            height={305}
                            options={{
                                chart: {
                                    height: 305,
                                    type: "bar",
                                    toolbar: {
                                        show: false
                                    }
                                },
                                tooltip: {
                                    y: {
                                        title: {
                                            formatter: (_) => "Kosárérték:",
                                        },
                                        formatter: (val) => Math.round(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" HUF"
                                    }
                                },
                                grid: {
                                    show: false
                                },
                                legend: {
                                    show: false
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                xaxis: {
                                    categories: showTraffic.map(item => item.name),
                                    labels: {
                                        style: {
                                            colors: showTraffic.map((_) => "#8C8C8C")
                                        }
                                    },
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },
                                yaxis: {
                                    show: false
                                },
                                plotOptions: {
                                    bar: {
                                        distributed: true,
                                        borderRadius: 4,
                                        borderRadiusApplication: 'around',
                                        borderRadiusWhenStacked: 'all',
                                    }
                                },
                                colors: showTraffic.map(item => item.color_code_2)
                            }}
                            series={[
                                {
                                    data: showTraffic.map(item => {
                                        return item.sum_traffic;
                                    })
                                }
                            ]}
                        />
                    }
                </Box>
            </Card>
        </Grid>
    );
};

export default StoreChart;