import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";
import useDebounce from "../../hooks/useDebounce";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Grow, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Select, Tab, Tabs, Typography } from "@mui/material";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import OpHeader from "../../components/opheader/OpHeader";
import UserTable from "./content/UserTable";

import NewUserProfile from "./newuser/NewUserProfile";
import EditUser from "./edituser/EditUser";

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: "100%" }}
        >
            {value === index && children}
        </div>
    );
};

const Users = () => {
    const {user, setLoading, setOpenOper, setMsg} = useContext(DataContext);

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("");

    const [ranks, setRanks] = useState([]);
    const [users, setUsers] = useState([]);
    const [showUsers, setShowUsers] = useState([]);

    const [tab, setTab] = useState(0);
    const [newUser, setNewUser] = useState(false);

    const [editUser, setEditUser] = useState({});
    const [open, setOpen] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        window.scrollTo(0,0);
        setLoading(true);

        getRanks();
        getUsers();

        setLoading(false);
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            setShowUsers(users.filter(item => rx.test(item.last_name+" "+item.first_name) || rx.test(item.email) || rx.test(item.rank_name)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, users], 300);

    const getRanks = async() => {
        const res = await fetch(apiUrl+"/ranks/company/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setRanks(data.ranks);
    };

    const getUsers = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/users/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setUsers(data.users);

        setLoading(false);
    };

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const renderNewUser = () => {
        if(newUser) {
            return (
                <NewUserProfile />
            );
        }
    };

    const renderEditUser = () => {
        if(JSON.stringify(editUser) !== "{}") {
            return (
                <EditUser
                    userInfo={editUser}
                    onClose={() => setEditUser({})}
                    onSave={() => {
                        setEditUser({});
                        getUsers();
                    }}
                    getUsers={getUsers}
                />
            );
        }
    };

    return (
        <>
            {renderNewUser()}
            {renderEditUser()}

            {(!newUser && JSON.stringify(editUser) === "{}") && 
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={14}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Paper
                            sx={{ p: 1 }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={8}>
                                    <Grow in={true} timeout={400}>
                                        <Tabs
                                            variant="standard"
                                            value={tab}
                                            onChange={handleChange}
                                        >
                                            <Tab
                                                label="Összes"
                                                sx={{
                                                    "&:hover": {
                                                        color: "#2278CF"
                                                    }
                                                }}
                                            />

                                            {ranks.map(item => {
                                                return (
                                                    <Tab
                                                        key={item.id}
                                                        label={item.name}
                                                        value={item.id}
                                                        sx={{
                                                            "&:hover": {
                                                                color: "#2278CF"
                                                            }
                                                        }}
                                                    />
                                                );
                                            })}

                                            <Tab
                                                label="Inaktív felhasználók"
                                                sx={{
                                                    "&:hover": {
                                                        color: "#2278CF"
                                                    }
                                                }}
                                            />
                                        </Tabs>
                                    </Grow>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Grow in={true} timeout={800}>
                                        <Button
                                            onClick={() => setOpenOper(30)}
                                            fullWidth
                                            sx={{ p: 1.4 }}
                                        >
                                            + új rang hozzáadása
                                        </Button>
                                    </Grow>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Grow in={true} timeout={1200}>
                                        <OutlinedInput
                                            type="text"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            placeholder="Felhasználó keresése"
                                            fullWidth
                                            size="small"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchOutlinedIcon style={{ width: 20 }} />
                                                </InputAdornment>
                                            }
                                            sx={{
                                                fontSize: 12,
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>
                                </Grid>

                                <Grid item xs={6} md={2} sx={{ marginLeft: "auto" }}>
                                    <Grow in={true} timeout={1600}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="sort">Rendezés</InputLabel>

                                            <Select
                                                value={sort}
                                                onChange={(e) => setSort(e.target.value)}
                                                labelId="sort"
                                                label="Rendezés"
                                            >
                                                <MenuItem value={1}>Növekvő</MenuItem>
                                                <MenuItem value={2}>Csökkenő</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grow>
                                </Grid>

                                <Grid item xs={6} md={2}>
                                    <Grow in={true} timeout={2000}>
                                        <Button
                                            onClick={() => setOpenOper(29)}
                                            variant="contained"
                                            fullWidth
                                        >
                                            + új felhasználó meghívása
                                        </Button>
                                    </Grow>
                                </Grid>

                                <Grid item container xs={12}>
                                    <TabPanel value={tab} index={0}>
                                        <UserTable
                                            users={showUsers.filter(item => item.status === 1)}
                                            onEdit={(u) => setEditUser(u)}
                                            onInactive={(u) => setOpen(u)}
                                        />
                                    </TabPanel>

                                    {ranks.map(item => {
                                        return (
                                            <TabPanel key={item.id} value={tab} index={item.id}>
                                                <UserTable
                                                    users={users.filter(u => u.rank === item.id && u.status === 1)}
                                                    onEdit={(u) => setEditUser(u)}
                                                    onInactive={(u) => setOpen(u)}
                                                />
                                            </TabPanel>
                                        );
                                    })}

                                    <TabPanel value={tab} index={ranks.length+1}>
                                        <UserTable
                                            users={users.filter(item => item.status === 0)}
                                            onEdit={(u) => setEditUser(u)}
                                            onInactive={(u) => setOpen(u)}
                                        />
                                    </TabPanel>

                                    <Dialog
                                        open={Object.keys(open).length !== 0}
                                        onClose={() => setOpen({})}
                                    >
                                        <DialogTitle>
                                            Biztosan inaktiválod a felhasználót?
                                        </DialogTitle>

                                        <DialogContent>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                            >
                                                <Typography fontSize={14} fontWeight={400}>{open.last_name+" "+open.first_name}</Typography>
                                                <Typography fontSize={12} fontWeight={400} color="#8C8C8C">{open.email}</Typography>
                                            </Box>
                                        </DialogContent>

                                        <DialogActions>
                                            <Button variant="outlined" onClick={() => setOpen({})}>
                                                Mégsem
                                            </Button>

                                            <Button variant="contained" onClick={() => {}}>
                                                Inaktiválás
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default Users;