import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";
import useDebounce from "../../../hooks/useDebounce";

import { Grid, InputAdornment, OutlinedInput } from "@mui/material";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import ModifyStore from "./ModifyStore";
import StoreItem from "./content/StoreItem";
import OpHeader from "../../../components/opheader/OpHeader";

const ManageStores = () => {
    const {user, setOpenOper, setMsg} = useContext(DataContext);

    const [stores, setStores] = useState([]);
    const [showStores, setShowStores] = useState([]);

    const [search, setSearch] = useState("");

    const [modify, setModify] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getStores();
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            setShowStores(stores.filter(item => rx.test(item.name) || rx.test(item.address) || rx.test(item.contract) || rx.test(item.license)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, stores], 500);

    const getStores = async() => {
        const res = await fetch(apiUrl+"/stores/company/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setStores(data.stores);
    };

    const renderModify = () => {
        if(JSON.stringify(modify) !== "{}") {
            return (
                <ModifyStore
                    store={modify}
                    onClose={() => setModify({})}
                    onModify={getStores}
                />
            );
        }
    };

    return (
        <>
            {renderModify()}

            {JSON.stringify(modify) === "{}" &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={24}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <OutlinedInput
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Telephelyek keresése"
                            size="small"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon style={{ width: 20 }} />
                                </InputAdornment>
                            }
                            sx={{
                                bgcolor: "#FFF",
                                width: "50%"
                            }}
                        />
                    </Grid>

                    {showStores.map(item => {
                        return (
                            <StoreItem
                                key={item.id}
                                store={item}
                                click={() => setModify(item)}
                            />
                        );
                    })}
                </Grid>
            }
        </>
    );
};

export default ManageStores;