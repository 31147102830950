import { useContext, useState } from "react";

import useDebounce from "../../../../hooks/useDebounce";
import { DataContext } from "../../../../context/DataContext";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, InputAdornment, OutlinedInput, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import Package from "./content/Package";

const PackageDialog = ({ open, onClose, packages, onChoose, onRemove, onNext }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {setMsg} = useContext(DataContext);

    const [showPackages, setShowPackages] = useState([]);

    const [search, setSearch] = useState("");

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');

            const filtered = packages.filter(item => rx.test(item.serial_num) || rx.test(item.transit_num));

            const transitGroup = filtered.filter(item => item.transit_pack).reduce((group, x) => {
                (group[x["transit_num"]] = group[x["transit_num"]] || []).push(x);
                return group;
            }, {});

            setShowPackages(transitGroup);
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, packages], 500);

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <IconButton
                    onClick={onClose}
                    size="small"
                >
                    <ArrowBackIosIcon style={{ fontSize: 16 }} />
                </IconButton>

                <IconButton
                    size="small"
                >
                    <OpenInFullIcon style={{ fontSize: 16 }} />
                </IconButton>
            </Box>

            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    <DialogContentText fontSize={14} fontWeight={500}>Befizetett értékszállítások kiválasztása</DialogContentText>
                    <DialogContentText fontSize={12} fontWeight={300}>Válaszd ki a befizetésre kerülő csomagokat</DialogContentText>
                
                    <OutlinedInput
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Keresés"
                        fullWidth
                        size="small"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchOutlinedIcon style={{ width: 20 }} />
                            </InputAdornment>
                        }
                        sx={{
                            fontSize: 12,
                            backgroundColor: "#FFFFFF"
                        }}
                    />
                    
                    {Object.keys(showPackages).map(key => {
                        return (
                            <Package
                                key={key}
                                pack={showPackages[key]}
                                onChoose={onChoose}
                                onRemove={onRemove}
                            />
                        );
                    })}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={onClose}
                >
                    Vissza
                </Button>

                <Button
                    variant="contained"
                    size="small"
                    onClick={onNext}
                >
                    Tovább
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PackageDialog;