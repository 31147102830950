import { useState } from "react";

import { getVatRateSum } from "../../services/TrafficCalc";

import { Grid, Typography } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";
import InfoRow from "../daychange/content/InfoRow";
import ModifyRow from "./content/ModifyRow";
import CloseImage from "./CloseImage";
import Buttons from "../daychange/content/Buttons";

const CloseVat = ({ traffic, fileName, round, pcs, comment, onClose}) => {
    const [checks, setChecks] = useState({
        aVat: false,
        bVat: false,
        cVat: false,
        dVat: false,
        eVat: false
    });
    const [serialNum, setSerialNum] = useState("");

    const [change, setChange] = useState(false);

    const renderCloseImage = () => {
        if(change) {
            return (
                <CloseImage
                    traffic={traffic}
                    fileName={fileName}
                    round={round}
                    pcs={pcs}
                    comment={comment}
                    serialNum={serialNum}
                    onClose={() => setChange(false)}
                />
            );
        }
    };

    return (
        <>
            {renderCloseImage()}

            {!change &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={2}
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            fontSize={12}
                            fontWeight={700}
                            color="#000"
                        >
                            Napzárás / kassza zárás
                        </Typography>
                    </Grid>

                    <ModifyRow
                        text="Add meg a zárás sorszámát"
                        val={serialNum}
                        change={(e) => setSerialNum(e.target.value)}
                    />

                    <InfoRow
                        text="A - 5%-os forgalom"
                        val={getVatRateSum(traffic, 5)}
                        checked={checks.aVat}
                        click={() => setChecks(prev => ({...prev, aVat: !prev.aVat}))}
                    />

                    <InfoRow
                        text="B - 18%-os forgalom"
                        val={getVatRateSum(traffic, 18)}
                        checked={checks.bVat}
                        click={() => setChecks(prev => ({...prev, bVat: !prev.bVat}))}
                    />

                    <InfoRow
                        text="C - 27%-os forgalom"
                        val={getVatRateSum(traffic, 27)}
                        checked={checks.cVat}
                        click={() => setChecks(prev => ({...prev, cVat: !prev.cVat}))}
                    />

                    <InfoRow
                        text="D - AJT forgalom"
                        val={getVatRateSum(traffic, 0)}
                        checked={checks.dVat}
                        click={() => setChecks(prev => ({...prev, dVat: !prev.dVat}))}
                    />

                    <InfoRow
                        text="E - AM forgalom"
                        val={0}
                        checked={checks.eVat}
                        click={() => setChecks(prev => ({...prev, eVat: !prev.eVat}))}
                    />

                    <Buttons
                        backText="Vissza"
                        nextText="Következő"
                        onBack={onClose}
                        onNext={() => setChange(true)}
                    />
                </Grid>
            }
        </>
    );
};

export default CloseVat;