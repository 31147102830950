import { useEffect, useState } from "react";

import ReactEcharts from "echarts-for-react";

import PrevTotalChartStyle from "./PrevTotalChart.module.css";

const PrevTotalChart = ({ data }) => {
    const [current, setCurrent] = useState([]);
    const [prev, setPrev] = useState([]);

    useEffect(() => {
        if(JSON.stringify(data) !== "{}") {
            calcCurrent();
            calcPrev();
        }
    }, [data?.current?.data, data?.prevYear?.data]);

    const calcCurrent = () => {
        // const currentStores = Object.groupBy(data.current.data, ({store_name}) => store_name);

        const currentStores = data.current.data.reduce((group, x) => {
            (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
            return group;
        }, {});
    
        const currentStoresSum = Object.keys(currentStores).map(key => {
            const sum = currentStores[key].reduce((acc, obj) => {
                return acc + obj.unit_value;
            }, 0);

            return {
                store: key,
                color: currentStores[key][0].color_code_2,
                sum: sum
            };
        });

        setCurrent(currentStoresSum);
    };

    const calcPrev = () => {
        // const prevStores = Object.groupBy(data.prevYear.data, ({store_name}) => store_name);
        
        const prevStores = data.prevYear.data.reduce((group, x) => {
            (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
            return group;
        }, {});

        const prevStoresSum = Object.keys(prevStores).map(key => {
            const sum = prevStores[key].reduce((acc, obj) => {
                return acc + obj.unit_value;
            }, 0);

            return {
                store: key,
                color: prevStores[key][0].color_code_2,
                sum: sum
            };
        });

        setPrev(prevStoresSum);
    };

    const calcDiff = () => {
        if(current.length === 0 || prev.length === 0) {
            return 0;
        }

        const sumCurrent = current.reduce((acc, obj) => {
            return acc + obj.sum;
        }, 0);

        const sumPrev = prev.reduce((acc, obj) => {
            return acc + obj.sum;
        }, 0);

        return (((sumCurrent/sumPrev)*100).toFixed());
    };

    return (
        <div className={PrevTotalChartStyle.container}>
            <div className={PrevTotalChartStyle.chart_container}>
                <ReactEcharts
                    option={{
                        title: {
                            text: "Forgalom boltokra lebontva",
                            left: "center"
                        },
                        series: [
                            {
                                name: "Összes forgalom az adott időszakban",
                                type: "pie",
                                radius: ["40%", "70%"],
                                data: [...current.map(item => ({
                                    name: item.store,
                                    value: item.sum,
                                    itemStyle: {color: item.color}
                                }))]
                            }
                        ],
                        tooltip: {
                            trigger: "item",
                            valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                        },
                        grid: {
                            containLabel: true
                        }
                    }}
                    style={{width: "100%", height: "100%"}}
                />
            </div>

            <div className={PrevTotalChartStyle.chart_container}>
                <ReactEcharts
                    option = {{
                        title: {
                            text: "Előző évhez képest elért forgalom",
                            left: "center"
                        },
                        series: [
                            {
                                type: 'gauge',
                                startAngle: 180,
                                endAngle: 0,
                                min: 0,
                                max: 150,
                                splitNumber: 10,
                                name: "Előző évhez képest elért forgalom",
                                detail: {
                                    formatter: (val) => val+"%"
                                },
                                progress: {
                                    show: true,
                                    roundCap: true,
                                    width: 10
                                },
                                axisTick: {
                                    splitNumber: 2,
                                    lineStyle: {
                                        width: 1
                                    }
                                },
                                axisLine: {
                                    roundCap: true,
                                    lineStyle: {
                                        width: 10
                                    }
                                },
                                axisLabel: {
                                    distance: 15,
                                    color: '#999',
                                    fontSize: 12
                                },
                                data: [{value: calcDiff()}]
                            }
                        ],
                        tooltip: {
                            trigger: "item",
                            valueFormatter: (val) => val+"%"
                        },
                    }}
                    style={{width: "100%", height: "100%"}}
                />
            </div>

            <div className={PrevTotalChartStyle.chart_container}>
                <ReactEcharts
                    option={{
                        title: {
                            text: "Előző évforgaloma az időszakban",
                            left: "center"
                        },
                        series: [
                            {
                                name: "Előző évforgaloma az időszakban",
                                type: "pie",
                                radius: ["40%", "70%"],
                                data: [...prev.map(item => ({
                                    name: item.store,
                                    value: item.sum,
                                    itemStyle: {color: item.color}
                                }))]
                            }
                        ],
                        tooltip: {
                            trigger: "item",
                            valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                        },
                        grid: {
                            containLabel: true
                        }
                    }}
                    style={{width: "100%", height: "100%"}}
                />
            </div>
        </div>
    );
};

export default PrevTotalChart;