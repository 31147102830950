import { useState } from "react";

import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

import CurrencyText from "../../../../../../../components/currencytext/CurrencyText";
import TransferDialog from "./content/TransferDialog";
import PackModify from "./content/PackModify";

const PackItem = ({ invs, onTransfer, onModify }) => {
    const [open, setOpen] = useState(false);
    const [modify, setModify] = useState(false);

    const calculateSum = () => {
        const sum = invs.reduce((acc, obj) => {
            return acc += obj.sum;
        }, 0);

        return sum;
    };

    const renderComment = () => {
        let comment = "";

        invs.map((item, i) => {
            if(i === 0) {
                return comment += item.serial_num;
            }

            return comment += ", "+item.serial_num;
        });

        return comment;
    };

    return (
        <Grid item xs={12} md={3}>
            <Box
                display="flex"
                flexDirection="column"
                border="1px solid #F0F0F0"
                gap={1}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    bgcolor="#F0F0F0"
                    padding={1}
                    color="#000"
                >
                    <Stack>
                        <Typography
                            fontSize={12}
                            fontWeight={700}
                        >
                            {invs[0].partnerName}
                        </Typography>

                        <Typography
                            fontSize={10}
                            fontWeight={300}
                        >
                            {invs.length} tétel
                        </Typography>
                    </Stack>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        alignItems="center"
                    >
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => setOpen(true)}
                            sx={{
                                bgcolor: "#1890FF",
                                maxWidth: 32,
                                minWidth: 32
                            }}
                        >
                            <SendOutlinedIcon style={{ fontSize: 22 }} />
                        </Button>

                        <TransferDialog
                            open={open}
                            setOpen={setOpen}
                            invs={invs}
                            sum={calculateSum()}
                            comment={renderComment()}
                            onTransfer={onTransfer}
                        />

                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => setModify(true)}
                            sx={{
                                bgcolor: "#FFF",
                                borderColor: "#D9D9D9",
                                maxWidth: 32,
                                minWidth: 32,
                                ":hover": {
                                    bgcolor: "#FFF",
                                    borderColor: "#D9D9D9"
                                }
                            }}
                        >
                            <BorderColorOutlinedIcon style={{ color: "#8C8C8C", fontSize: 22 }} />
                        </Button>

                        <PackModify
                            open={modify}
                            setOpen={setModify}
                            invs={invs}
                            onModify={onModify}
                        />
                    </Box>
                </Box>

                <Stack paddingInline={2}>
                    <Typography
                        fontSize={12}
                        fontWeight={700}
                        color="#000"
                    >
                        Számlaszám
                    </Typography>

                    <Typography
                        fontSize={10}
                        fontWeight={300}
                        color="#000"
                    >
                        {invs[0].bank_account ? invs[0].bank_account : "-"}
                    </Typography>
                </Stack>

                <Stack paddingInline={2}>
                    <Typography
                        fontSize={12}
                        fontWeight={700}
                        color="#000"
                    >
                        Összes utalandó
                    </Typography>

                    <Typography
                        fontSize={10}
                        fontWeight={300}
                        color="#000"
                    >
                        <CurrencyText val={calculateSum()} /> Ft
                    </Typography>
                </Stack>

                <Stack paddingInline={2} paddingBottom={2}>
                    <Typography
                        fontSize={12}
                        fontWeight={700}
                        color="#000"
                    >
                        Közlemény
                    </Typography>

                    <Typography
                        fontSize={10}
                        fontWeight={300}
                        color="#000"
                    >
                        {renderComment()}
                    </Typography>
                </Stack>
            </Box>
        </Grid>
    );
};

export default PackItem;