import { FormLabel, TextField, Tooltip } from "@mui/material";

const ProductImage = ({ productImage, onUpload }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <>
            <FormLabel
                htmlFor="upload"
                sx={{
                    cursor: 'pointer'
                }}
            >
                <Tooltip
                    arrow
                    title="Kép módosítása"
                >
                    <img
                        src={imageExists(apiUrl+"/"+productImage) ? apiUrl+"/"+productImage : null}
                        style={{ width: 150 }}
                        alt="productImage"
                    />
                </Tooltip>
            </FormLabel>

            <TextField
                type="file"
                id="upload"
                variant="outlined"
                sx={{ display: 'none' }}
                onChange={onUpload}
            />
        </>
    );
};

export default ProductImage;