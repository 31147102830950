import { useContext } from "react";

import { io } from "socket.io-client";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";
import VerifyItem from "./content/VerifyItem";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const IncomingVerify = ({ incoming, onClose }) => {
    const {user, setOpenOper, setMsg} = useContext(DataContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleNext = async() => {
        const res = await fetch(apiUrl+"/tickets/incoming/verify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                tickets: incoming
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        
        socket.emit("incoming_tickets", {id: user.company.id});
        setMsg({
            color: "success",
            message: "Sorsjegyek felvezetve!"
        });
        onClose();
        setOpenOper(0);
    };
    
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OpHeader
                    operation={11}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={{ bgcolor: "#F5F5F5" }}>
                            <TableRow>
                                <TableCell>Sorsjegyek</TableCell>
                                <TableCell>Sorozatszám-tól</TableCell>
                                <TableCell>Sorozatszám-ig</TableCell>
                                <TableCell>Mennyiség</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {incoming.map(item => {
                                return (
                                    <VerifyItem
                                        key={item.id}
                                        item={item}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        justifyContent="flex-end"
                        padding={1}
                    >
                        <Button variant="outlined" onClick={onClose}>Vissza</Button>
                        <Button variant="contained" onClick={handleNext}>Véglegesítés</Button>
                    </Box>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default IncomingVerify;