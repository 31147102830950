import { useContext, useRef, useState } from "react";

import { DataContext } from "../../context/DataContext";

import { Grid } from "@mui/material";

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import OpHeader from "../../components/opheader/OpHeader";
import TrafficMode from "../daychange/content/TrafficMode";
import IncomeTable from "./IncomeTable";

const ProductsIncome = () => {
    const {setLoading, setMsg, setOpenOper} = useContext(DataContext);

    const [jsonData, setJsonData] = useState(null);

    const inputFileRef = useRef();

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const xmlData = e.target.result;
            parseXMLtoJSON(xmlData);
        };
        reader.readAsText(file);
    };

    const parseXMLtoJSON = async(xmlString) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlString, "text/xml");

        const json = {
            packNumber: xml.getElementsByTagName("PackingslipId")[0].textContent,
            fileName: xml.documentElement.getAttribute("FileName"),
            syncNum: xml.documentElement.getAttribute("SyncNum"),
            lines: [],
            totals: {}
        };

        const lines = xml.getElementsByTagName("Line");
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];

            const lineObj = {
                itemId: line.getElementsByTagName("ItemId")[0]?.textContent,
                itemName: line.getElementsByTagName("ItemName")[0]?.textContent,
                barcode: line.getElementsByTagName("Barcode")[0]?.textContent,
                priceType: line.getElementsByTagName("PriceType")[0]?.textContent,
                salesPrice: Number(line.getElementsByTagName("SalesPrice")[0]?.textContent),
                qty: Number(line.getElementsByTagName("Qty")[0]?.textContent),
                lineAmount: Number(line.getElementsByTagName("LineAmount")[0]?.textContent),
                ok: false
            };

            json.lines.push(lineObj);
        }

        const totals = xml.getElementsByTagName("Totals")[0];
        if (totals) {
            json.totals = {
                products: Number(totals.getElementsByTagName("Products")[0]?.textContent),
                grandTotal: Number(totals.getElementsByTagName("GrandTotal")[0]?.textContent)
            };
        }

        setLoading(true);

        const res = await fetch(apiUrl+"/delivery/"+json.packNumber, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setLoading(false);

        if(data.delivery.length !== 0) {
            return setMsg({
                color: "error",
                message: "Ez a szállítólevél már be van vételezve"
            });
        }

        setJsonData(json);
    };

    const handleCheck = (barcode) => {
        const lines = jsonData.lines.map(item => {
            if(item.barcode === barcode) {
                return {
                    ...item,
                    ok: !item.ok
                }
            }

            return item;
        });

        setJsonData(prev => ({...prev, lines: lines}));
    };

    const handleCheckAll = (e) => {
        if(e.target.checked) {
            const lines = jsonData.lines.map(item => {
                return {
                    ...item,
                    ok: true
                }
            });

            setJsonData(prev => ({...prev, lines: lines}));

            return;
        }

        const lines = jsonData.lines.map(item => {
            return {
                ...item,
                ok: false
            }
        });

        setJsonData(prev => ({...prev, lines: lines}));
    };

    const renderIncome = () => {
        if(jsonData !== null) {
            return (
                <IncomeTable
                    income={jsonData}
                    onClose={() => setJsonData(null)}
                    onCheck={handleCheck}
                    onCheckAll={handleCheckAll}
                />
            );
        }
    };

    return (
        <>
            {renderIncome()}

            {jsonData === null &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={39}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        md={6}
                        spacing={2}
                        margin="auto"
                    >
                        <TrafficMode
                            text="Szállítólevél feltöltése (XML)"
                            icon={<FileUploadOutlinedIcon />}
                            click={() => inputFileRef.current.click()}
                            timeout={800}
                            active={true}
                        />

                        <input
                            type="file"
                            ref={inputFileRef}
                            style={{display: "none"}}
                            accept="text/xml"
                            onChange={handleFileUpload}
                        />
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default ProductsIncome;