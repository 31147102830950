import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Grid } from "@mui/material";

import TransferPack from "./content/transfer/TransferPack";

const TransferList = ({ onRefresh }) => {
    const {user, setLoading, setMsg} = useContext(DataContext);

    const [packs, setPacks] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getPacks();
    }, []);

    const getPacks = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/transfer/list/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        const groupPacks = data.packs.reduce((acc, row) => {
            let pack = acc.find(p => p.id === row.pack_id);

            if(!pack) {
                pack = {
                    id: row.pack_id,
                    name: row.name,
                    sum: row.sum,
                    invoices: []
                };
                acc.push(pack);
            }

            if(row.invoice_id) {
                pack.invoices.push({
                    id: row.invoice_id,
                    serial_num: row.serial_num,
                    partnerId: row.partner_id,
                    partnerName: row.partner_name,
                    partnerBank: row.bank_account,
                    record_date: row.record_date,
                    delivery_date: row.delivery_date,
                    payment_deadline: row.payment_deadline,
                    arranged: row.arranged,
                    status: row.status,
                    sum: row.invoice_sum
                });
            }

            return acc;
        }, []);

        setPacks(groupPacks.filter(item => item.invoices.length !== 0));
        
        setLoading(false);
    };

    return (
        <Grid container spacing={4}>
            {packs.map(item => {
                return (
                    <TransferPack
                        key={item.pack_id}
                        pack={item}
                        onRefresh={() => {
                            getPacks();
                            onRefresh();
                        }}
                    />
                );
            })}
        </Grid>
    );
};

export default TransferList;