import { useContext } from 'react';

import dayjs from 'dayjs';
import { motion } from 'framer-motion';

import { DataContext } from '../../../context/DataContext';

import { Box, Button, Grid, Paper, Typography } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";

const ResVerify = ({ ap, dates, personal, onClose}) => {
    const {setMsg, setLoading, setOpenOper} = useContext(DataContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleVerify = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/apartman/reservation/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ap: ap.id,
                dates: {
                    from: dayjs(dates.from, 'YYYY-MM-DD').toISOString().split("T")[0],
                    to: dayjs(dates.to, 'YYYY-MM-DD').toISOString().split("T")[0]
                },
                personal: personal
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres foglalás!"
        });

        setOpenOper(0);

        setLoading(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={35}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    component={Paper}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                    padding={2}
                >
                    <Typography fontSize={14}>Apartman: {ap.address}</Typography>

                    <Typography fontSize={14}>Időszak: {dayjs(dates.from, 'YYYY-MM-DD').toISOString().split("T")[0]} - {dayjs(dates.to, 'YYYY-MM-DD').toISOString().split("T")[0]}</Typography>

                    <Typography fontSize={14}>Teljes név: {personal.name}</Typography>

                    <Typography fontSize={14}>Email cím: {personal.email}</Typography>

                    <Typography fontSize={14}>Telefonszám: {personal.phone}</Typography>

                    <Typography fontSize={14}>Megjegyzés: {personal.comment}</Typography>
                    
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        style={{ alignSelf: "flex-end" }}
                    >
                        <Button
                            variant="contained"
                            onClick={handleVerify}
                        >
                            Véglegesítés
                        </Button>
                    </motion.div>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ResVerify;