import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, Grid, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";
import StoreIcon from "../../../layouts/stores/content/StoreIcon";

const InviteVerify = ({ email, rank, stores, onBack }) => {
    const {user, setLoading, setMsg, setOpenOper} = useContext(DataContext);

    const [userRank, setUserRank] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getRank();
    }, []);

    const getRank = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/rank/"+rank.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setUserRank(data.rank);

        setLoading(false);
    };

    const handleVerify = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/user/invite", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                company: user.company.id,
                email: email,
                rank: rank.id,
                stores: stores.map(item => item.id)
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setLoading(false);

        setMsg({
            color: "success",
            message: "A meghívó sikeresen el lett küldve a megadott email címre"
        });

        setOpenOper(0);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={29}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    component={Paper}
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    padding={2}
                >
                    <Typography fontSize={16} fontWeight={500} color="#6489E2">
                        Véglegesítés
                    </Typography>

                    <Typography fontSize={12} color="#737373">
                        Ellenőrizd le a megadott adatokat, jogosultságokat
                    </Typography>

                    <Button
                        variant="outlined"
                        onClick={handleVerify}
                        sx={{
                            color: "#6489E2",
                            alignSelf: "flex-start",
                            borderRadius: 6
                        }}
                    >
                        Véglegesítés
                    </Button>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                        marginTop={5}
                    >
                        <Typography fontSize={16} fontWeight={500} color="#6489E2">
                            Meghívott email címe
                        </Typography>

                        <Typography fontSize={12} color="#737373">
                            {email}
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                        marginBottom={5}
                    >
                        <Typography fontSize={16} fontWeight={500} color="#6489E2">
                            Meghívott rangja
                        </Typography>

                        <Typography fontSize={12} color="#737373">
                            {userRank.name}
                        </Typography>
                    </Box>

                    <Typography fontSize={16} fontWeight={500} color="#6489E2">
                        Meghívott eladótér jogosultságai
                    </Typography>

                    <Grid container>
                        {stores.map(item => {
                            return (
                                <Grid key={item.id} item xs={12}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <StoreIcon store={item} />
                                        </ListItemIcon>

                                        <ListItemText
                                            primary={
                                                <Typography variant="subtitle1" color="#6A86C4">{item.name}</Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InviteVerify;