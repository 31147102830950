import { useContext } from "react";

import axios from "axios";
import { io } from "socket.io-client";

import { calculateReceiptNumber, getCardSum, getReturnSum, getTrafficCashIn, getTrafficCashOut, getTrafficProfit, getTrafficSum, getVatProfit, getVatRateSum } from "../../services/TrafficCalc";
import { DataContext } from "../../context/DataContext";

import { Box, Button, Grid } from "@mui/material";

import ProfileItem from "./content/ProfileItem";
import OpHeader from "../../components/opheader/OpHeader";
import Buttons from "../daychange/content/Buttons";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const CloseLogout = ({ traffic, fileName, round, pcs, comment, serialNum, image, onClose }) => {
    const {user, setUser, setMsg, setOpenOper, setLoading} = useContext(DataContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleClose = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/store/daily", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                user_before: null,
                store: user.store,
                operation: 2,
                huf5: Number(pcs.huf5),
                huf10: Number(pcs.huf10),
                huf20: Number(pcs.huf20),
                huf50: Number(pcs.huf50),
                huf100: Number(pcs.huf100),
                huf200: Number(pcs.huf200),
                huf500: Number(pcs.huf500),
                huf1000: Number(pcs.huf1000),
                huf2000: Number(pcs.huf2000),
                huf5000: Number(pcs.huf5000),
                huf10000: Number(pcs.huf10000),
                huf20000: Number(pcs.huf20000),
                sum: Number(pcs.sum)
            })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        handleSaveTrafficItemized(data.id);
    };

    const handleSaveTrafficItemized = async(dailyId) => {
        const res = await fetch(apiUrl+"/store/traffic/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                traffic: traffic
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        handleSaveTraffic(dailyId);
    };

    const handleSaveTraffic = async(dailyId) => {
        const res = await fetch(apiUrl+"/store/traffic/sum/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                operation: 2,
                daily: dailyId,
                from: traffic[0].date,
                to: traffic[traffic.length-1].date,
                daily_traffic_sum: getTrafficSum(traffic),
                cash_in: getTrafficCashIn(traffic),
                cash_out: getTrafficCashOut(traffic),
                sum: (getTrafficSum(traffic) + getTrafficCashIn(traffic) - getTrafficCashOut(traffic) - getReturnSum(traffic)),
                card: getCardSum(traffic),
                round: round,
                serial_num: serialNum,
                return_sum: getReturnSum(traffic),
                profit: getTrafficProfit(traffic),
                receipt_quantity: calculateReceiptNumber(traffic),
                canceled: 0,
                a_tax: getVatRateSum(traffic, 5),
                b_tax: getVatRateSum(traffic, 18),
                c_tax: getVatRateSum(traffic, 27),
                d_tax: getVatRateSum(traffic, 0),
                e_tax: 0,
                a_tax_profit: getVatProfit(traffic, 5),
                b_tax_profit: getVatProfit(traffic, 18),
                c_tax_profit: getVatProfit(traffic, 27),
                d_tax_profit: getVatProfit(traffic, 0),
                e_tax_profit: 0,
                comment: comment
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        uploadImage(data.id);
    };

    const uploadImage = async(trafficItem) => {
        const file = new FormData();
        file.append("file", image);

        axios.post(apiUrl+"/store/traffic/image/"+trafficItem, file)
        .then(res => {
            setUser(prevUser => ({...prevUser, store: null}));

            socket.emit("store_op", {id: user.store});

            setMsg({});
            setOpenOper(0);

            setLoading(false);
        });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OpHeader
                    operation={2}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                    maxWidth={500}
                    marginLeft="auto"
                    marginRight="auto"
                >
                    <ProfileItem user={user} />

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        <Buttons
                            backText="Vissza"
                            nextText="Lezárom a napot"
                            onBack={onClose}
                            onNext={handleClose}
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CloseLogout;