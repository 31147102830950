export const getTrafficSum = (traffic) => {
    const sellArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "NY");

    const sum = sellArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return Number(sum);
};

export const getTrafficCashIn = (traffic) => {
    const cashInArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "BE");
    
    const sum = cashInArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return Number(sum);
};

export const getTrafficOnlyCashout = (traffic) => {
    const cashInArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "KI");
    
    const sum = cashInArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return Number(-sum);
};

export const getTrafficCashOut = (traffic) => {
    const cashOutArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "KI" || row.receipt_num_1.split("/")[0] === "G");
    
    const sum = cashOutArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return Number(-sum);
};

export const getReturnSum = (traffic) => {
    const returnArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "G");
    
    const sum = returnArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return Number(-sum);
};

export const getVatRateSum = (traffic, vat) => {
    const vatRateArray = traffic.filter(row => Number(row.vat_rate) === vat && row.receipt_num_1.split("/")[0] === "NY");
    
    const sum = vatRateArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return Number(sum);
};

export const getCardSum = (traffic) => {
    const sum = traffic.reduce((a, b) => a + Number(b.card), 0);

    return Number(sum);
};

export const getVatSum = (traffic) => {
    if(JSON.stringify(traffic) === "[]") {
        return {
            current: 0,
            prevYear: 0
        }
    }

    const currentUnique = traffic.current.data.sort((a, b) => new Date(b.date_from) - new Date(a.date_from)).filter((obj, index) => {
        return index === traffic.current.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
    });

    const currentVat = currentUnique.reduce((acc, obj) => {
        return acc + ((obj.a_tax*0.05) + (obj.b_tax*0.18) + (obj.c_tax*0.27)); 
    }, 0);

    const prevUnique = traffic.prevYear.data.sort((a, b) => new Date(b.date_from) - new Date(a.date_from)).filter((obj, index) => {
        return index === traffic.prevYear.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
    });

    const prevVat = prevUnique.reduce((acc, obj) => {
        return acc + ((obj.a_tax*0.05) + (obj.b_tax*0.18) + (obj.c_tax*0.27)); 
    }, 0);

    return {
        current: Number(currentVat.toFixed()),
        prevYear: Number(prevVat.toFixed())
    };
};

export const getStoreColorCode = (name, stores) => {
    return stores.filter(s => s.name === name)[0].color_code_2;
};

export const calculateReceiptNumber = (traffic) => {
    return [...new Map(traffic.map((element) => [element.receipt_num_1, element])).values()].length;
};

export const getTrafficProfit = (traffic) => {
    const sellArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "NY");

    const sumUnit = sellArray.reduce((a, b) => a + Number(b.unit_value), 0);

    const sumPurchase = sellArray.reduce((a, b) => a + Number(b.purchase_value), 0);

    return Number((sumUnit - sumPurchase).toFixed());
};

export const getVatProfit = (traffic, vat) => {
    const sellArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "NY" && Number(row.vat_rate) === vat);

    const sumUnit = sellArray.reduce((a, b) => a + Number(b.unit_value), 0);

    const sumPurchase = sellArray.reduce((a, b) => a + Number(b.purchase_value), 0);

    return Number((sumUnit - sumPurchase).toFixed());
};

export const calculateNet = (traffic) => {
    const current = traffic.current.data.map(item => ({
        ...item,
        daily_traffic_sum: Number(((item.a_tax-(item.a_tax*0.05)) + (item.b_tax-(item.b_tax*0.18)) + (item.c_tax-(item.c_tax*0.27)) + item.d_tax + item.e_tax).toFixed()),
        a_tax: Number(item.a_tax-(item.a_tax*0.05).toFixed()),
        b_tax: Number(item.b_tax-(item.b_tax*0.18).toFixed()),
        c_tax: Number(item.c_tax-(item.c_tax*0.27).toFixed()),
        d_tax: Number(item.d_tax.toFixed()),
        e_tax: Number(item.e_tax.toFixed()),
        profit: Number(((item.a_tax_profit-(item.a_tax_profit*0.05)) + (item.b_tax_profit-(item.b_tax_profit*0.18)) + (item.c_tax_profit-(item.c_tax_profit*0.27)) + item.d_tax_profit + item.e_tax_profit).toFixed()),
        a_tax_profit: Number(item.a_tax_profit-(item.a_tax_profit*0.05).toFixed()),
        b_tax_profit: Number(item.b_tax_profit-(item.b_tax_profit*0.18).toFixed()),
        c_tax_profit: Number(item.c_tax_profit-(item.c_tax_profit*0.27).toFixed()),
        d_tax_profit: Number(item.d_tax_profit.toFixed()),
        e_tax_profit: Number(item.e_tax_profit.toFixed())
    }));

    const prev = traffic.prevYear.data.map(item => ({
        ...item,
        daily_traffic_sum: Number(((item.a_tax-(item.a_tax*0.05)) + (item.b_tax-(item.b_tax*0.18)) + (item.c_tax-(item.c_tax*0.27)) + item.d_tax + item.e_tax).toFixed()),
        a_tax: Number(item.a_tax-(item.a_tax*0.05).toFixed()),
        b_tax: Number(item.b_tax-(item.b_tax*0.18).toFixed()),
        c_tax: Number(item.c_tax-(item.c_tax*0.27).toFixed()),
        d_tax: Number(item.d_tax.toFixed()),
        e_tax: Number(item.e_tax.toFixed()),
        profit: Number(((item.a_tax_profit-(item.a_tax_profit*0.05)) + (item.b_tax_profit-(item.b_tax_profit*0.18)) + (item.c_tax_profit-(item.c_tax_profit*0.27)) + item.d_tax_profit + item.e_tax_profit).toFixed()),
        a_tax_profit: Number(item.a_tax_profit-(item.a_tax_profit*0.05).toFixed()),
        b_tax_profit: Number(item.b_tax_profit-(item.b_tax_profit*0.18).toFixed()),
        c_tax_profit: Number(item.c_tax_profit-(item.c_tax_profit*0.27).toFixed()),
        d_tax_profit: Number(item.d_tax_profit.toFixed()),
        e_tax_profit: Number(item.e_tax_profit.toFixed())
    }));

    return {
        prevYear: {
            from: traffic.prevYear.from,
            to: traffic.prevYear.to,
            data: prev.sort((a, b) => new Date(b.date_from) - new Date(a.date_from))
        },
        current: {
            from: traffic.current.from,
            to: traffic.current.to,
            data: current.sort((a, b) => new Date(b.date_from) - new Date(a.date_from))
        }
    };
};