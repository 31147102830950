import { useContext, useState } from "react";

import useDebounce from "../../../../../../../hooks/useDebounce";
import { DataContext } from "../../../../../../../context/DataContext";

import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, OutlinedInput, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { DateCalendar } from '@mui/x-date-pickers';

import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import DialogHeader from "./DialogHeader";
import Option from "./content/Option";

const InputDialog = ({ type, invoice, payments, costplaces, locations, open, tags, newTag, setNewTag, onVerify, onTag, onClose }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {setMsg} = useContext(DataContext);

    const [showTags, setShowTags] = useState([]);
    const [searchTag, setSearchTag] = useState("");

    useDebounce(() => {
        if(type === "tag" || type === "newtag") {
            if(tags.length !== 0) {
                try {
                    const rx = new RegExp(`${searchTag}`,'i');
                    const filteredData = tags.filter(item => rx.test(item.name));

                    setShowTags(filteredData);
                }
                catch(err) {
                    setSearchTag("");
                    setMsg({
                        color: "error",
                        message: "Erre a karakterre nem lehet keresni"
                    });
                }
            }
        }
        else if(type === "location") {
            if(locations.length !== 0) {
                try {
                    const rx = new RegExp(`${searchTag}`,'i');
                    const filteredData = locations.filter(item => rx.test(item.name));

                    setShowTags(filteredData);
                }
                catch(err) {
                    setSearchTag("");
                    setMsg({
                        color: "error",
                        message: "Erre a karakterre nem lehet keresni"
                    });
                }
            }
        }
        else if(type === "payment") {
            if(payments.length !== 0) {
                try {
                    const rx = new RegExp(`${searchTag}`,'i');
                    const filteredData = payments.filter(item => rx.test(item.name));

                    setShowTags(filteredData);
                }
                catch(err) {
                    setSearchTag("");
                    setMsg({
                        color: "error",
                        message: "Erre a karakterre nem lehet keresni"
                    });
                }
            }
        }
        else if(type === "costplace") {
            if(costplaces.length !== 0) {
                try {
                    const rx = new RegExp(`${searchTag}`,'i');
                    const filteredData = costplaces.filter(item => rx.test(item.name));

                    setShowTags(filteredData);
                }
                catch(err) {
                    setSearchTag("");
                    setMsg({
                        color: "error",
                        message: "Erre a karakterre nem lehet keresni"
                    });
                }
            }
        }
    }, [searchTag, tags], 500);

    if(type === "deadline") {
        return (
            <Dialog fullScreen={matches} open={open} onClose={onClose} >
                <DialogTitle>
                    <DialogHeader invoice={invoice} />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Kérlek add meg fizetési határidőt.
                    </DialogContentText>

                    <DateCalendar
                        value={newTag}
                        onChange={(newValue) => onVerify(newValue)}
                        sx={{ color: "#000" }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Mégse</Button>
                </DialogActions>
            </Dialog>
        );
    }
    else if(type === "payment") {
        return (
            <Dialog fullScreen={matches} open={open} onClose={onClose} >
                <DialogTitle>
                    <DialogHeader invoice={invoice} />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Kérlek add meg a fizetési módot.
                    </DialogContentText>

                    <Stack margin={4} gap={1}>
                        {payments?.map(item => {
                            return (
                                <Option
                                    key={item.id}
                                    option={item}
                                    icon={<CreditCardOutlinedIcon />}
                                    selected={item.id === newTag}
                                    onSelect={() => onVerify(item.id)}
                                />
                            );
                        })}
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Mégse</Button>
                </DialogActions>
            </Dialog>
        );
    }
    else if(type === "costplace") {
        return (
            <Dialog
                fullScreen={matches}
                open={open}
                onClose={onClose}
                width={400}
            >
                <DialogTitle>
                    <DialogHeader invoice={invoice} />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Válaszd ki a számlához tartozó költséghelyet és költség típust. Későbbi kimutatások során rendkívül hasznos lesz.
                    </DialogContentText>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                    >
                        <OutlinedInput
                            value={searchTag}
                            onChange={(e) => setSearchTag(e.target.value)}
                            placeholder="Költséghely kersés / létrehozás"
                            size="small"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setSearchTag("");
                                            onTag(searchTag);
                                        }}
                                        disabled={!searchTag}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            sx={{ flex: 1 }}
                        />
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        gap={1}
                        marginTop={2}
                        width="100%"
                    >
                        {showTags.map(item => {
                            return (
                                <Tooltip
                                    key={item.id}
                                    onClick={() => onVerify(item.name)}
                                >
                                    <Chip
                                        size="small"
                                        icon={<PushPinOutlinedIcon />}
                                        label={item.name}
                                        clickable={true}
                                        sx={{ 
                                            backgroundColor: "#E3E3E3",
                                            borderColor: "#E3E3E3", 
                                            borderRadius: 1,
                                            fontSize: 10,
                                            fontWeight: 400
                                        }}
                                    />
                                </Tooltip>
                            );
                        })}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Mégse</Button>
                </DialogActions>
            </Dialog>
        );
    }
    else if(type === "location") {
        return (
            <Dialog fullScreen={matches} open={open} onClose={onClose} >
                <DialogTitle>
                    <DialogHeader invoice={invoice} />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Kérlek add meg a lokációt.
                    </DialogContentText>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                    >
                        <OutlinedInput
                            value={searchTag}
                            onChange={(e) => setSearchTag(e.target.value)}
                            placeholder="Új cimke"
                            size="small"
                            sx={{ flex: 1 }}
                        />

                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                                setSearchTag("");
                                onTag(searchTag);
                            }}
                            disabled={!searchTag}
                        >
                            Új cimke
                        </Button>
                    </Box>

                    <Stack spacing={1} marginTop={2}>
                        {showTags.slice(0, 5).map(item => {
                            return (
                                <Option
                                    key={item.id}
                                    option={item}
                                    icon={<PushPinOutlinedIcon />}
                                    selected={item.name === newTag}
                                    onSelect={() => onVerify(item.name)}
                                />
                            );
                        })}
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Mégse</Button>
                </DialogActions>
            </Dialog>
        );
    }
    else if(type === "tag" || type === "newtag") {
        return (
            <Dialog
                fullScreen={matches}
                open={open}
                onClose={onClose}
            >
                <DialogTitle>
                    <DialogHeader invoice={invoice} />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Add meg a cimkét
                    </DialogContentText>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                    >
                        <OutlinedInput
                            value={searchTag}
                            onChange={(e) => setSearchTag(e.target.value)}
                            placeholder="Új cimke"
                            size="small"
                            sx={{ flex: 1 }}
                        />

                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                                setSearchTag("");
                                onTag(searchTag);
                            }}
                            disabled={!searchTag}
                        >
                            Új cimke
                        </Button>
                    </Box>

                    <Stack spacing={1} marginTop={2}>
                        {showTags.slice(0, 5).map(item => {
                            return (
                                <Option
                                    key={item.id}
                                    option={item}
                                    icon={<PushPinOutlinedIcon />}
                                    selected={item.name === newTag}
                                    onSelect={() => onVerify(item.name)}
                                />
                            );
                        })}
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Mégse</Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default InputDialog;