import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import { Button } from "@mui/material";

import NewTicketPrizeStyle from "./NewTicketPrize.module.css";

import OpsHeader from "../../../../layouts/opsheader/OpsHeader";

import Feedback from "../../../../components/feedback/Feedback";
import PrizeRow from "../../../../components/prizerow/PrizeRow";

const NewTicketPrize = () => {
    const {user, msg, setMsg, homeRef, loading, setLoading} = useContext(DataContext);

    const [prizes, setPrizes] = useState([]);
    const [newPrize, setNewPrize] = useState({
        code1: "",
        code2: "",
        code3: "",
        value: ""
    });
    const prizeRef = {
        code1: useRef(),
        code2: useRef(),
        code3: useRef()
    };

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleNext = () => {
        if(prizes.length === 0) {
            return setMsg({
                color: "#F58E8E",
                message: "Legalább egy nyereményt hozzá kell adnod"
            });
        }

        navigate("/ticketinfo/new/verify/"+params.store, {state: {ticket: location.state.ticket, prizes: prizes}})
    };

    const handleAddPrize = () => {
        const newRow = {
            id: prizes.length,
            prize_code: newPrize.code1+newPrize.code2+newPrize.code3,
            prize_value: newPrize.value
        };

        setPrizes(prevPrizes => [...prevPrizes, newRow]);
        
        setNewPrize({
            code1: "",
            code2: "",
            code3: "",
            value: ""
        });
    };

    const handleModifyPrize = (id, newRow) => {
        const formatRow = {
            id: id,
            prize_code: newRow.code1+newRow.code2+newRow.code3,
            prize_value: newRow.value
        };

        setPrizes(prevPrizes => [...prevPrizes.filter(prize => prize.id !== id), formatRow]);
    };

    const handleDeletePrize = (id) => {
        setPrizes(prevPrizes => prevPrizes.filter(prize => prize.id !== id));
    };

    return (
        <div
            style={{backgroundColor: "#FFFFFF"}}
            ref={homeRef}
        >
            <div>
                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={18}
                    opsRef={homeRef}
                />
            </div>

            <div>
                <div
                    className={NewTicketPrizeStyle.ops_ticket_table}
                    style={{width: "40%"}}
                >
                    <table>
                        <thead>
                            <tr>
                                <th style={{paddingLeft: "8px"}}>Nyereménykód</th>
                                <th>Összeg</th>
                                <th>Műveletek</th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        type="text"
                                        className={NewTicketPrizeStyle.prize_code}
                                        ref={prizeRef.code1}
                                        value={newPrize.code1}
                                        onChange={(e) => {
                                            if(newPrize.code1.length === 0){
                                                setNewPrize(prevPrize => ({...prevPrize, code1: e.target.value.toUpperCase()}));
                                                prizeRef.code2.current.focus();
                                            }
                                            else if(e.target.value === ""){
                                                setNewPrize(prevPrize => ({...prevPrize, code1: e.target.value.toUpperCase()}));
                                            }
                                        }}
                                    />
                    
                                    <input
                                        type="text"
                                        className={NewTicketPrizeStyle.prize_code}
                                        ref={prizeRef.code2}
                                        value={newPrize.code2}
                                        onChange={(e) => {
                                            if(newPrize.code2.length === 0){
                                                setNewPrize(prevPrize => ({...prevPrize, code2: e.target.value.toUpperCase()}));
                                                prizeRef.code3.current.focus();
                                            }
                                            else if(e.target.value === ""){
                                                setNewPrize(prevPrize => ({...prevPrize, code2: e.target.value.toUpperCase()}));
                                                prizeRef.code1.current.focus();
                                            }
                                        }}
                                    />
                    
                                    <input
                                        type="text"
                                        className={NewTicketPrizeStyle.prize_code}
                                        ref={prizeRef.code3}
                                        value={newPrize.code3}
                                        onChange={(e) => {
                                            if(newPrize.code3.length === 0){
                                                setNewPrize(prevPrize => ({...prevPrize, code3: e.target.value.toUpperCase()}));
                                            }
                                            else if(e.target.value === ""){
                                                setNewPrize(prevPrize => ({...prevPrize, code3: e.target.value.toUpperCase()}));
                                                prizeRef.code2.current.focus();
                                            }
                                        }}
                                    />
                                </td>

                                <td>
                                    <input
                                        className={NewTicketPrizeStyle.prize_value}
                                        value={newPrize.value}
                                        onChange={(e) => {
                                            if(!isNaN(+e.target.value)) {
                                                setNewPrize(prevPrize => ({...prevPrize, value: e.target.value}))
                                            }
                                        }}
                                    />
                                </td>

                                <td>
                                    <Button onClick={handleAddPrize}>
                                        Hozzáadás
                                    </Button>
                                </td>
                            </tr>

                            {prizes.map(item => {
                                return (
                                    <PrizeRow
                                        key={item.id}
                                        prize={item}
                                        onModify={handleModifyPrize}
                                        onDelete={handleDeletePrize}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default NewTicketPrize;