import { motion } from 'framer-motion';

import { Box, Button, Card, Grid, Typography } from "@mui/material";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const InvoiceItem = ({ line, onBarcode }) => {
    return (
        <Grid
            item
            xs={12}
            md={3}
        >
            <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
            >
                <Card
                    variant="outlined"
                    sx={{
                        height: 60,
                        p: 1,
                        borderRadius: 2,
                        cursor: "pointer"
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        width="100%"
                        height="100%"
                    >
                        <Typography flex={2} fontSize={12}>{line.itemName}</Typography>

                        <Button
                            variant="contained"
                            onClick={onBarcode}
                            sx={{
                                flex: 1,
                                bgcolor: "#13C2C2",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1,
                                ":hover": {
                                    bgcolor: "#179c9c"
                                }
                            }}
                        >
                            <AddCircleOutlineIcon style={{ fontSize: 14 }} />
                            <Typography fontSize={12}>Hozzáadás</Typography>
                        </Button>
                    </Box>
                </Card>
            </motion.div>
        </Grid>
    );
};

export default InvoiceItem;