import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';

const ProfileTab = ({ onLogout }) => {
    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
            <ListItemButton>
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>

                <ListItemText secondary="Profil módosítás" />
            </ListItemButton>

            <ListItemButton onClick={onLogout}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>

                <ListItemText secondary="Kijelentkezés" />
            </ListItemButton>
        </List>
    );
};

export default ProfileTab;