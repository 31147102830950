import { useContext, useEffect, useState } from "react";

import ReactEcharts from "echarts-for-react";

import { DataContext } from "../../../context/DataContext";

import { Box, Grid, Typography } from "@mui/material";

import ChartFilter from "../../../components/charts/chartfilter/ChartFilter";

const DayAvg = ({ traffic }) => {
    const {stores} = useContext(DataContext);
    
    const [currentBar, setCurrentBar] = useState([]);
    const [prevBar, setPrevBar] = useState([]);

    const [trafficStores, setTrafficStores] = useState([]);

    const [bars, setBars] = useState([
        {
            id: 0,
            name: "Forgalom",
            color: "#5470C6",
            show: true
        },
        {
            id: 1,
            name: "Profit",
            color: "#5470C6",
            show: false
        },
        {
            id: 2,
            name: "Vásárlószám",
            color: "#5470C6",
            show: false
        }
    ]);

    const [weekDays] = useState([
        "hétfő",
        "kedd",
        "szerda",
        "csütörtök",
        "péntek",
        "szombat",
        "vasárnap"
    ]);

    useEffect(() => {
        getStores();
    }, []);

    useEffect(() => {
        if(JSON.stringify(traffic) !== "[]") {
            const current = [];
            const prevYear = [];

            trafficStores.map(item => {
                if(item.show) {
                    current.push(...traffic.current.data.filter(element => element.store === item.id));
                    prevYear.push(...traffic.prevYear.data.filter(element => element.store === item.id));
                }

                return 0;
            });

            const showData = {
                ...traffic,
                current: {
                    ...traffic.current,
                    data: current
                },
                prevYear: {
                    ...traffic.prevYear,
                    data: prevYear
                }
            };
            
            calcCurrentBar(showData);
            calcPrevBar(showData);
        }
    }, [traffic?.current?.data, traffic?.prevYear?.data, trafficStores, bars]);

    const getStores = () => {
        setTrafficStores(stores.map(item => {
            return {
                id: item.id,
                name: item.name,
                color: item.color_code_2,
                show: true
            };
        }));
    };

    const calcCurrentBar = (showData) => {
        const unique = showData.current.data.filter((obj, index) => {
            return index === showData.current.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        }).reverse();

        let dayGroup = {};

        weekDays.map(item => {
            return dayGroup[item] = [];
        });

        unique.map(item => {
            const day = new Date(item.date_from).toLocaleDateString("hu-HU", { weekday: "long" });

            return dayGroup[day].push(item);
        });

        const daysSum = [];
        
        Object.keys(dayGroup).map(key => {
            const sum = dayGroup[key].reduce((acc, obj) => {
                if(bars[0].show) return acc + obj.daily_traffic_sum;
                if(bars[1].show) return acc + obj.profit;
                if(bars[2].show) return acc + obj.receipt_quantity;
                return acc;
            }, 0);

            return daysSum.push({
                day: key,
                daysVal: dayGroup[key].length / trafficStores.filter(item => item.show).length,
                sum: Number((sum / dayGroup[key].length).toFixed())
            });
        });

        setCurrentBar(daysSum);
    };

    const calcPrevBar = (showData) => {
        const unique = showData.prevYear.data.filter((obj, index) => {
            return index === showData.prevYear.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        }).reverse();

        let dayGroup = {};

        weekDays.map(item => {
            return dayGroup[item] = [];
        });

        unique.map(item => {
            const day = new Date(item.date_from).toLocaleDateString("hu-HU", { weekday: "long" });

            return dayGroup[day].push(item);
        });

        const daysSum = [];
        
        Object.keys(dayGroup).map(key => {
            const sum = dayGroup[key].reduce((acc, obj) => {
                if(bars[0].show) return acc + obj.daily_traffic_sum;
                if(bars[1].show) return acc + obj.profit;
                if(bars[2].show) return acc + obj.receipt_quantity;
            }, 0);

            return daysSum.push({
                day: key,
                sum: Number((sum / dayGroup[key].length).toFixed())
            });
        });

        setPrevBar(daysSum);
    };

    const handleFilterStore = (store) => {
        const modifyArr = trafficStores.map(item => {
            if(item.id === store.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: !item.show
                };
            }
            
            return item;
        });

        setTrafficStores(modifyArr);
    };

    const handleFilterBar = (bar) => {
        const modifyArr = bars.map(item => {
            if(item.id === bar.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: true
                };
            }
            
            return {
                id: item.id,
                name: item.name,
                color: item.color,
                show: false
            };
        });

        setBars(modifyArr);
    };

    return (
        <Grid item xs={12}>
            <Typography marginBottom={2}>Időszakos napi átlagok</Typography>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {trafficStores.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => handleFilterStore(item)}
                            />
                        );
                    })}
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {bars.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => handleFilterBar(item)}
                            />
                        );
                    })}
                </Box>
            </Box>

            <Box
                width="100%"
                height={400}
            >
                <ReactEcharts
                    option = {{
                        tooltip: {
                            trigger: 'item',
                            valueFormatter: (val) => {
                                if(bars[2].show) {
                                    return val+" db";
                                }
                                else {
                                    return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                                }  
                            } 
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: (val) => {
                                    if(bars[2].show) {
                                        return val+" db";
                                    }
                                    else {
                                        return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                                    }  
                                } 
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: [...weekDays.map(day => {
                                if(currentBar.filter(item => item.day === day).length > 0) {
                                    return day+" ("+currentBar.filter(item => item.day === day)[0]?.daysVal+" db)";
                                }
                                return day;
                            })]
                        },
                        grid: {
                            containLabel: true,
                            left: "2%",
                            right: "2%",
                            top: "5%",
                            bottom: "5%"
                        },
                        series: [
                            {
                                name: "Jelenlegi időszak",
                                type: 'bar',
                                emphasis: {
                                    focus: 'series'
                                },
                                data: [...currentBar.map(item => ({
                                    value: item.sum,
                                    itemStyle: {color: "#5470C6"}
                                }))]
                            },
                            {
                                name: "Múlt év időszak",
                                type: 'bar',
                                emphasis: {
                                    focus: 'series'
                                },
                                data: [...prevBar.map(item => ({
                                    value: item.sum,
                                    itemStyle: {
                                        color: "#C4C4C4"
                                    }
                                }))]
                            }
                        ]
                    }}
                    style={{width: "100%", height: "100%"}}
                />
            </Box>
        </Grid>
    );
};

export default DayAvg;