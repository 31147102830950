import { useContext, useEffect, useState } from "react";

import { io } from "socket.io-client";

import { DataContext } from "../../../../context/DataContext";

import { Avatar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";

import { motion } from 'framer-motion';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import PackItem from "./content/PackItem";
import Filters from "./content/Filters";
import CurrencyText from "../../../../components/currencytext/CurrencyText";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Wait = ({ onItemized, onCredit }) => {
    const {user, setMsg, setLoading} = useContext(DataContext);

    const [packs, setPacks] = useState([]);
    const [showPacks, setShowPacks] = useState([]);
    const [logs, setLogs] = useState([]);

    const [waitLog, setWaitLog] = useState({});
    const [waitAvatar, setWaitAvatar] = useState("");

    const [deliveryLog, setDeliveryLog] = useState({});
    const [deliveryAvatar, setDeliveryAvatar] = useState("");

    const [filterOpen, setFilterOpen] = useState(false);
    const [filters, setFilters] = useState({
        profiles: [],
        dates: {
            from: null,
            to: null
        },
        values: {
            from: "",
            to: ""
        }
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        socket.emit("join", ["cid:"+user.company.id]);
        getPackages();
    }, []);

    useEffect(() => {
        socket.on("update_wait", () => {
            getPackages();
        })
    }, [socket]);

    useEffect(() => {
        let filtered = packs;

        if(filters.profiles.length !== 0) {
            filtered = filtered.filter(item => filters.profiles.includes(item.uid));
        }

        if(filters.dates.from !== null && filters.dates.to !== null) {
            const from = new Date(filters.dates.from).toLocaleString("hu-HU", {year: "numeric", month: "2-digit", day: "2-digit"});
            const to = new Date(filters.dates.to).toLocaleString("hu-HU", {year: "numeric", month: "2-digit", day: "2-digit"});

            filtered = filtered.filter(item => {
                const itemDate = new Date(item.date).toLocaleString("hu-HU", {year: "numeric", month: "2-digit", day: "2-digit"});

                if(itemDate >= from && itemDate <= to) {
                    return item;
                }
            });
        }

        if(filters.values.from !== "" && filters.values.to !== "") {
            filtered = filtered.filter(item => {
                if(Number(item.sum) >= Number(filters.values.from) && Number(item.sum) <= Number(filters.values.to)) {
                    return item;
                }
            });
        }

        setShowPacks(filtered);
    }, [packs, filters]);

    const getPackages = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/tickets/manage/packs/"+4+"/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPacks(data.packs.map(item => ({...item, selected: false})));
        setLogs(data.log);

        setLoading(false);
    };

    const handleCreditPacks = async() => {
        const res = await fetch(apiUrl+"/tickets/manage/credit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                packs: showPacks.filter(item => item.selected)
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres művelet"
        });
        
        socket.emit("credit_tickets", {id: user.company.id});

        onCredit();
    };

    const handlePackCheck = (pack, val) => {
        setShowPacks(prev => prev.map(item => {
            if(item.id === pack.id) {
                return {...item, selected: val};
            }
            return item;
        }));
    };

    const handleSelectAll = (e) => {
        if(e.target.checked) {
            setShowPacks(prev => prev.map(item => {
                return {...item, selected: true};
            }));
            return;
        }

        setShowPacks(prev => prev.map(item => {
            return {...item, selected: false};
        }));
    };

    const calculateSum = () => {
        return showPacks.reduce((acc, obj) => {
            return acc += obj.sum;
        }, 0);
    };
    const calculateTickets = () => {
        return showPacks.reduce((acc, obj) => {
            return acc += obj.quantity;
        }, 0);
    };

    const handleLog = (pack) => {
        const delivery = logs.filter(item => item.id === pack.id)[0];

        setDeliveryLog(delivery);
        setDeliveryAvatar(delivery.profile_pic ? apiUrl+"/"+delivery.profile_pic : null);

        setWaitAvatar(pack.profile_pic ? apiUrl+"/"+pack.profile_pic : null);
        setWaitLog(pack);
    };

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    }

    const handleFilter = (filter) => {
        setFilters(filter);
        setFilterOpen(false);
    };

    return (
        <Grid
            container
            spacing={3}
            p={3}
        >
            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        width="50%"
                    >
                        <OutlinedInput
                            placeholder="Felhasználók keresése"
                            size="small"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon style={{ width: 20 }} />
                                </InputAdornment>
                            }
                            sx={{
                                bgcolor: "#FFF",
                                width: "90%"
                            }}
                        />

                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <IconButton onClick={() => setFilterOpen(true)}>
                                <FilterAltOutlinedIcon />
                            </IconButton>
                        </motion.div>

                        <Filters
                            open={filterOpen}
                            onClose={handleFilter}
                            tickets={packs}
                            onFilter={handleFilter}
                            isPack={true}
                        />
                    </Box>

                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Button
                            variant="contained"
                            disabled={showPacks.filter(item => item.selected).length === 0}
                            onClick={handleCreditPacks}
                        >
                            + Befizetem
                        </Button>
                    </motion.div>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <caption>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                gap={2}
                                color="#000"
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    gap={2}
                                    color="#000"
                                >
                                    <Typography fontSize={14} fontWeight={400}>{showPacks.length} csomag</Typography>
                                    <Typography fontSize={16} fontWeight={500}>/</Typography>
                                    <Typography fontSize={14} fontWeight={400}>{calculateTickets()} db</Typography>
                                    <Typography fontSize={16} fontWeight={500}>/</Typography>
                                    <Typography fontSize={14} fontWeight={700}>
                                        <CurrencyText
                                            val={calculateSum()}
                                        /> HUF
                                    </Typography>
                                </Box>
                            </Box>
                        </caption>

                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={showPacks.length > 0 && showPacks.filter(item => item.selected).length === showPacks.length}
                                        indeterminate={showPacks.filter(item => item.selected).length > 0 && showPacks.filter(item => item.selected).length < showPacks.length}
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>

                                <TableCell>
                                    Leadta
                                </TableCell>

                                <TableCell>
                                    Darab
                                </TableCell>

                                <TableCell>
                                    Leadási érték
                                </TableCell>

                                <TableCell>
                                    Hibás kifizetés
                                </TableCell>

                                <TableCell>
                                    Átvételi érték
                                </TableCell>

                                <TableCell align="center">
                                    Műveletek
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {showPacks.map(item => {
                                return (
                                    <PackItem
                                        key={item.id}
                                        pack={item}
                                        onCheck={handlePackCheck}
                                        onItemized={onItemized}
                                        onLog={handleLog}
                                    />
                                );
                            })}

                            <Dialog
                                open={Object.keys(waitLog).length !== 0}
                                onClose={() => setWaitLog({})}
                                PaperProps={{
                                    component: 'form',
                                    onSubmit: {},
                                    sx: { width: 400 }
                                }}
                            >
                                <DialogTitle sx={{ paddingLeft: 3 }}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        gap={1}
                                        width="100%"
                                    >
                                        <ErrorOutlineOutlinedIcon style={{ color: "#1890FF" }} />
                                        <Typography fontSize={16} fontWeight={700}>Csomag előzmények</Typography>
                                    </Box>
                                </DialogTitle>

                                <DialogContent>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={3}
                                        justifyContent="center"
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography fontSize={14} fontWeight={600}>Befizetésre vár</Typography>
                                            <Typography fontSize={12} fontWeight={400}>{new Date(waitLog.wait_date).toLocaleString("hu-HU")}</Typography>
                                            <Tooltip
                                                title={waitLog.last_name+" "+waitLog.first_name}
                                                arrow
                                            >
                                                <Avatar src={imageExists(waitAvatar) ? waitAvatar : null} size="sm" />
                                            </Tooltip>
                                        </Box>

                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography fontSize={14} fontWeight={600}>Leadva SZRT</Typography>
                                            <Typography fontSize={12} fontWeight={400}>{new Date(deliveryLog.delivery_date).toLocaleString("hu-HU")}</Typography>
                                            <Tooltip
                                                title={deliveryLog.last_name+" "+deliveryLog.first_name}
                                                arrow
                                            >
                                                <Avatar src={imageExists(deliveryAvatar) ? deliveryAvatar : null} size="sm" />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        onClick={() => setWaitLog({})}
                                    >
                                        Bezárás
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default Wait;