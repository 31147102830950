import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { TableCell, TableRow, Typography } from "@mui/material";

import FiberNewIcon from '@mui/icons-material/FiberNew';

import CurrencyText from "../../../components/currencytext/CurrencyText";

const SummaryItem = ({ id, row }) => {
    const {user, setLoading, setMsg} = useContext(DataContext);

    const [newProduct, setNewProduct] = useState(false);
    const [lastPrice, setLastPrice] = useState(0);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getProduct();
    }, []);

    const getProduct = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/product/"+row.barcode, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setNewProduct(data.product.length !== 0 ? false : true);

        getPrice();
    };

    const getPrice = async() => {
        const res = await fetch(apiUrl+"/product/pricechange/last", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                barcode: row.barcode,
                company: user.company.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        if(data.priceChange.length !== 0) {
            setLastPrice(data.priceChange[0].new_price);
        }

        setLoading(false);
    };

    return (
        <TableRow hover>
            <TableCell>{id}</TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.barcode}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.itemId}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.itemName}</Typography>
                {newProduct && <FiberNewIcon />}
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.qty} db</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={row.salesPrice} /> Ft
                </Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={lastPrice} /> Ft
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default SummaryItem;