import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { jwtDecode } from "jwt-decode";

import { GoogleLogin } from '@react-oauth/google';

import { motion } from 'framer-motion';

import { DataContext } from "../../context/DataContext";
import { getUserCompanies, getUserStores, getCompanyStores, getUserOperations, getModules } from "../../services/Api";

import { Box, Button, Grow, IconButton, InputAdornment, OutlinedInput, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';

import Feedback from "../../components/feedback/Feedback";

const Login = () => {
    const {setUser, setStores, msg, setMsg, setOps, setModuleTab} = useContext(DataContext);

    const [input, setInput] = useState({
        email: "",
        pass: ""
    });

    const [showPw, setShowPw] = useState(false);

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleLogin = async(e) => {
        e.preventDefault();

        if(input.email === "" || input.pass === ""){
            return setMsg({
                color: "error",
                message: "Minden mező kitöltése kötelező"
            });
        }

        const res = await fetch(apiUrl+"/user/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: input.email,
                pass: input.pass
            })
        });

        const data = await res.json();
        
        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getUserStores(data.user.id).then(userStores => {
            getUserCompanies(data.user.id).then(userCompanies => {
                if(userCompanies.length === 0) {
                    setUser({...data.user});
                    setMsg({});

                   navigate("/register/company");
                }
                else {
                    setUser({...data.user, stores: userStores, company: userCompanies[0], companies: userCompanies});
                    setMsg({});

                    getCompanyStores(userCompanies[0].id).then(storeData => {
                        return setStores(storeData);
                    });

                    getUserOperations(data.user.id).then(opsData => {
                        setOps(opsData.filter(item => Number(item.company) === Number(userCompanies[0].id)));
                    });

                    getModules(userCompanies[0].id).then(modules => setModuleTab(modules.length+1));

                    setMsg({});
                    return navigate("/home");
                }
            });
        });
    };

    const handleGoogleLogin = async(info) => {
        const res = await fetch(apiUrl+"/user/login/google", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: info.email
            })
        });

        const data = await res.json();
        
        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getUserStores(data.user.id).then(userStores => {
            getUserCompanies(data.user.id).then(userCompanies => {
                if(userCompanies.length === 0) {
                    setUser({...data.user});
                    setMsg({});

                   navigate("/register/company");
                }
                else {
                    setUser({...data.user, stores: userStores, company: userCompanies[0], companies: userCompanies});
                    setMsg({});

                    getCompanyStores(userCompanies[0].id).then(storeData => {
                        return setStores(storeData);
                    });

                    getUserOperations(data.user.id).then(opsData => {
                        setOps(opsData.filter(item => Number(item.company) === Number(userCompanies[0].id)));
                    });

                    getModules(userCompanies[0].id).then(modules => setModuleTab(modules.length+1));

                    setMsg({});
                    return navigate("/home");
                }
            });
        });
    };

    return (
        <>
            {Object.keys(msg).length !== 0 &&
                <Feedback
                    color={msg.color}
                    message={msg.message}
                />
            }
            
            <Box
                minHeight="100vh"
                display="flex"
                flexDirection="column"
                padding={3}
                boxSizing="border-box"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                >
                    <Typography variant="subtitle1">myTRAFIX</Typography>
                </Box>

                <Box
                    flex="1"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <form
                        onSubmit={handleLogin}
                        style={isMdDown ? { width: "100%" } : { width: 400 }}
                    >
                        <Stack spacing={3}>
                            <Grow in={true} timeout={500}>
                                <Typography variant="subtitle2">Bejelentkezés</Typography>
                            </Grow>
                            
                            <Stack spacing={1}>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    style={{ width: "100%" }}
                                >
                                    <Grow in={true} timeout={1000}>
                                        <OutlinedInput
                                            type="email"
                                            placeholder="email"
                                            fullWidth
                                            value={input.email}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, email: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <MailOutlineIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disabled={input.email === ""}
                                                        onClick={() => setInput(prevInput => ({...prevInput, email: ""}))}
                                                        size="small"
                                                    >
                                                        <CloseIcon style={{ fontSize: 14 }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>
                                </motion.div>

                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    style={{ width: "100%" }}
                                >
                                    <Grow in={true} timeout={1500}>
                                        <OutlinedInput
                                            type={showPw ? "text" : "password"}
                                            placeholder="jelszó"
                                            fullWidth
                                            value={input.pass}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, pass: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <LockIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPw(prev => !prev)}
                                                        size="small"
                                                    >
                                                        {showPw ? <VisibilityIcon style={{ fontSize: 14 }} /> : <VisibilityOffIcon style={{ fontSize: 14 }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>
                                </motion.div>
                            </Stack>

                            <Stack spacing={1}>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    style={{ width: "100%" }}
                                >
                                    <Grow in={true} timeout={2000}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                        >
                                            Bejelentkezés
                                        </Button>
                                    </Grow>
                                </motion.div>

                                <Button onClick={() => navigate("/register")}>
                                    Regisztráció
                                </Button>

                                <GoogleLogin
                                    onSuccess={res => handleGoogleLogin(jwtDecode(res.credential))}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </>
    );
};

export default Login;