import { Avatar, Box, Stack, Typography } from "@mui/material";

const DialogHeader = ({ invoice }) => {
    return ( 
        <Box 
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            padding={1}
            borderRadius={1}
            sx={{ backgroundColor: "#F5F5F5"}}
        >
            <Box 
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                gap={1}
            >
                <Avatar>{invoice.partner_name.split("")[0]}</Avatar>

                <Stack>
                    <Typography
                        fontSize={16}
                        fontWeight={500}
                    >
                        {invoice.partner_name}
                    </Typography>

                    <Typography
                        fontSize={12}
                        fontWeight={300}
                    >
                        {invoice.serial_num}
                    </Typography>
                </Stack>
            </Box>

            <Stack>
                <Typography
                    fontSize={16}
                    fontWeight={500}
                >
                    {invoice.sum} HUF
                </Typography>

                <Typography
                    fontSize={12}
                    fontWeight={300}
                >
                    {invoice.sum} HUF
                </Typography>
            </Stack>
        </Box>
     );
}
 
export default DialogHeader;