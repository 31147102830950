import { useContext, useEffect, useState } from "react";

import * as XLSX from "xlsx";
import { io } from "socket.io-client";
import * as o2x from "object-to-xml";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import XLSImg from "../../../assets/invoices/export_xls.svg";
import XMLImg from "../../../assets/invoices/export_xml.svg";
import PrintImg from "../../../assets/invoices/print.svg";

import OpHeader from "../../../components/opheader/OpHeader";
import ItemizedItem from "./content/ItemizedItem";
import ItemizedTags from "./content/ItemizedTags";
import ItemizedHeader from "./content/ItemizedHeader";
import FilingDialog from "./content/content/footer/content/FilingDialog";
import IncomeStore from "./content/content/income/IncomeStore";

import CurrencyText from "../../../components/currencytext/CurrencyText";
import Stock from "../../stock/Stock";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const InvoiceItemized = ({ invoice, payments, costplaces, tags, onRefresh, onClose }) => {
    const {user, setMsg, setLoading} = useContext(DataContext);

    const [invoicePayment, setInvoicePayment] = useState({});
    const [invoiceCostplace, setInvoiceCostplace] = useState({});
    const [invoiceTags, setInvoiceTags] = useState([]);

    const [invoiceItems, setInvoiceItems] = useState([]);

    const [open, setOpen] = useState(false);

    const [income, setIncome] = useState(false);
    const [stock, setStock] = useState("");

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        socket.emit("join", ["cid:"+user.company.id]);

        setInvoiceItems([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

        getInvoice();
    }, []);

    useEffect(() => {
        getTags();
    }, [invoice]);

    useEffect(() => {
        socket.on("update_inbound", () => {
            getInvoice();
        });
    }, [socket]);

    const getTags = () => {
        getPaymentMethod();
        getInvoiceCostplace();
        getInvoiceTags();
    };

    const getPaymentMethod = async() => {
        const res = await fetch(apiUrl+"/inbound/get/payment/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoicePayment(data.payment);
    };

    const getInvoiceCostplace = async() => {
        const res = await fetch(apiUrl+"/inbound/get/costplace/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceCostplace(data.costplace);
    };

    const getInvoiceTags = async() => {
        const res = await fetch(apiUrl+"/inbound/get/tags/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceTags(data.tags);
    };

    const getInvoice = async() => {
        const res = await fetch(apiUrl+"/inbound/itemized", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: user.company.nav_token,
                invoiceNum: invoice.serial_num
            })
        });

        const data = await res.json();

        if(!data.success){
            getTags();
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceItems(data.invoice);

        if(data.fromNav) {
            saveInvoice(data.invoice);
        }
    };

    const saveInvoice = async(invoiceData) => {
        const res = await fetch(apiUrl+"/inbound/itemized/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                serialNum: invoice.serial_num,
                itemized: invoiceData
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }
    };

    const handleFiling = async() => {
        if(!invoice.payment_deadline || !invoicePayment || !invoiceCostplace) {
            return setOpen(true);
        }

        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/filing", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                serialNum: invoice.serial_num
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres iktatás"
        });

        socket.emit("inbound_invoices", {id: user.company.id});

        setOpen(false);
        onRefresh();
    };

    const handleToXLS = () => {
        const format = invoiceItems.map(item => {
            return {
                "Vonalkód": item.barcode,
                "Megnvezés": item.itemName,
                "Mennyiség": item.quantity,
                "Egységár": item.unitPrice,
                "ÁFA kör": item.vat*100
            };
        });

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(format);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, invoice.partner_name+" - "+invoice.record_date+".xlsx");
    };

    const handleToXML = () => {
        const obj = {
            Packingslips: {
                '@': {
                    SyncTime: new Date(),
                    SyncNum: "1",
                    FileName: invoice.partner_name+" - "+invoice.record_date+".xml"
                },
                Packingslip: {
                    CustAccount: "-",
                    PackingslipId: invoice.serial_num,
                    SalesId: "0000",
                    DeliveryDate: invoice.delivery_date,
                    Lines: {
                        Line: [
                            invoiceItems.map(item => {
                                return {
                                    ItemId: item.id,
                                    ItemName: item.itemName,
                                    Barcode: item.barcode,
                                    FixPrice: 0.00,
                                    SalesPrice: (Number(item.unitPrice)-(Number(item.unitPrice)*Number(item.vat))).toFixed(2),
                                    PriceType: item.vat === 0 ? "N" : "Z",
                                    Price: item.unitPrice,
                                    NetPrice: (Number(item.unitPrice)-(Number(item.unitPrice)*Number(item.vat))).toFixed(2),
                                    VatPercent: item.vat*100,
                                    Qty: item.quantity,
                                    LineAmount: Number(item.quantity)*Number(item.unitPrice)
                                };
                            })
                        ]
                    }
                },
                Totals: {
                    Products: invoice.sum,
                    GrandTotal: invoice.sum
                }
            }
        };

        const xmlData = o2x(obj);

        const blob = new Blob([xmlData], { type: 'application/xml' });

        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = invoice.partner_name+" - "+invoice.record_date+'.xml';
        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    const calcNetSum = () => {
        return Math.round(invoiceItems.reduce((acc, obj) => {
            return acc += (obj.unitPrice*obj.quantity);
        }, 0));
    };

    const calcVatSum = () => {
        return Math.round(invoiceItems.reduce((acc, obj) => {
            return acc += ((obj.unitPrice*obj.quantity)*obj.vat);
        }, 0));
    };

    const calcGrossSum = () => {
        return Math.round(invoiceItems.reduce((acc, obj) => {
            return acc += ((obj.unitPrice*obj.quantity)*(obj.vat+1));
        }, 0));
    };

    const renderIncome = () => {
        if(income) {
            return (
                <IncomeStore
                    invoice={invoice}
                    onClose={() => setIncome(false)}
                />
            );
        }
    };

    const renderStock = () => {
        if(stock !== "") {
            return (
                <Stock
                    defaultBarcode={stock}
                />
            );
        }
    };

    return (
        <>
            {renderIncome()}
            {renderStock()}

            {(!income && stock === "") &&
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <OpHeader
                                operation={22}
                                onClose={onClose}
                            />

                            <Box
                                display="flex"
                                flexDirection="row"
                                gap={1}
                            >
                                <IconButton
                                    onClick={handleToXLS}
                                    disabled={invoice.status !== 2}
                                    sx={invoice.status !== 2 && { opacity: 0.5 }}
                                >
                                    <img
                                        src={XLSImg}
                                        alt="xls"
                                        style={{ width: 24, height: 24 }}
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={handleToXML}
                                    disabled={invoice.status !== 2}
                                    sx={invoice.status !== 2 && { opacity: 0.5 }}
                                >
                                    <img
                                        src={XMLImg}
                                        alt="xml"
                                        style={{ width: 24, height: 24 }}
                                    />
                                </IconButton>

                                <IconButton>
                                    <img
                                        src={PrintImg}
                                        alt="print"
                                        style={{ width: 24, height: 24 }}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <ItemizedTags
                            invoice={({
                                ...invoice,
                                ...invoicePayment,
                                ...invoiceCostplace,
                                tags: invoiceTags
                            })}
                            payments={payments}
                            costplaces={costplaces}
                            tags={tags}
                            onRefresh={() => {
                                getTags();
                                onRefresh();
                            }}
                        />
                    </Grid>

                    <ItemizedHeader
                        invoice={invoice}
                    />

                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <caption>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        gap={2}
                                        color="#000"
                                    >
                                        <Typography fontSize={14} fontWeight={400}>
                                            Nettó összesen: <Typography fontWeight={600}><CurrencyText val={calcNetSum()} /> Ft</Typography>
                                        </Typography>

                                        <Typography fontSize={14} fontWeight={400}>
                                            Áfa összesen: <Typography fontWeight={600}><CurrencyText val={calcVatSum()} /> Ft</Typography>
                                        </Typography>

                                        <Typography fontSize={14} fontWeight={400}>
                                            Bruttó összesen: <Typography fontWeight={600}><CurrencyText val={calcGrossSum()} /> Ft</Typography>
                                        </Typography>
                                    </Box>
                                </caption>

                                <TableHead>
                                    <TableRow sx={{ bgcolor: "#FAFAFA" }}>
                                        <TableCell sx={{ color: "#434343" }}>#</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Megnevezés</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Mennyiség</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Nettó egységár</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Áfakör</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Bruttó egységár</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Áfaérték</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Nettó összesen</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Áfa összesen</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Bruttó összesen</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {invoiceItems.map((item, index) => {
                                        return (
                                            <ItemizedItem
                                                key={index}
                                                id={index+1}
                                                line={item}
                                                status={invoice.status}
                                                onStock={(barcode) => setStock(barcode)}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component={Paper}
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            gap={1}
                            padding={1}
                            boxSizing="border-box"
                            width="100%"
                            height="100%"
                        >
                            <Button variant="outlined" onClick={onClose}>Vissza</Button>
                            {!invoice.status && <Button variant="contained" onClick={handleFiling}>Iktatás</Button>}
                            {invoice.status === 1 && <Button variant="contained" onClick={() => setIncome(true)}>Bevételezés</Button>}
                        </Box>
                    </Grid>

                    <FilingDialog
                        invoice={({
                            ...invoice,
                            ...invoicePayment,
                            ...invoiceCostplace,
                            tags: invoiceTags
                        })}
                        payments={payments}
                        costplaces={costplaces}
                        open={open}
                        onClose={() => setOpen(false)}
                        onVerify={handleFiling}
                        onRefresh={onRefresh}
                    />
                </Grid>
            }
        </>
    );
};

export default InvoiceItemized;