import { useContext } from "react";
import { DataContext } from "../../../context/DataContext";

import { styled } from "@mui/material/styles";
import { AppBar, IconButton, Toolbar, useMediaQuery, useTheme } from "@mui/material";

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import Notification from "./content/Notification";
import Message from "./content/Message";
import Settings from "./content/Settings";
import Profile from "./content/Profile";

const StyledAppBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open'})
    (({ theme, open }) => ({
        width: 'calc(100% - '+theme.spacing(9)+')',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        ...(open && {
            marginLeft: 240,
            width: 'calc(100% - 240px)',
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        })
    })
);

const Header = ({ open, onNav }) => {
    const {user} = useContext(DataContext);

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    return isMdDown ? (
        <AppBar
            position="fixed"
            open={open}
            color="inherit"
            elevation={0}
        >
            <Toolbar>
                <IconButton
                    color="#9F9F9F"
                    onClick={onNav}
                >
                    {open ? <MenuOpenIcon /> : <MenuOutlinedIcon />}
                </IconButton>

                {/*<Notification />
                <Message />
                <Settings />*/}
                <Profile name={user.last_name+" "+user.first_name} />
            </Toolbar> 
        </AppBar>
    ) : (
        <StyledAppBar
            position="absolute"
            open={open}
            color="inherit"
            elevation={0}
        >
            <Toolbar>
                <IconButton
                    color="#9F9F9F"
                    onClick={onNav}
                >
                    {open ? <MenuOpenIcon /> : <MenuOutlinedIcon />}
                </IconButton>

                {/*<Notification />
                <Message />
                <Settings />*/}
                <Profile name={user.last_name+" "+user.first_name} />
            </Toolbar> 
        </StyledAppBar>
    );
};

export default Header;