import { useContext } from "react";

import { DataContext } from "../../context/DataContext";

import { Avatar, AvatarGroup, Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";
import SummaryItem from "./content/SummaryItem";
import StoreIcon from "../../layouts/stores/content/StoreIcon";

const SummaryTable = ({ income, store, onVerify, onClose }) => {
    const {user} = useContext(DataContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OpHeader
                    text={"Összegzés: "+income.packNumber}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                >
                    <AvatarGroup>
                        <Tooltip
                            arrow
                            title={user.last_name+" "+user.first_name}
                        >
                            <Avatar
                                src={imageExists(apiUrl+"/"+user?.profile_pic) ? apiUrl+"/"+user?.profile_pic : null}
                            />
                        </Tooltip>

                        <Tooltip
                            arrow
                            title={store.name}
                        >
                            <StoreIcon
                                store={store}
                            />
                        </Tooltip>
                    </AvatarGroup>

                    <Button
                        size="large"
                        variant="contained"
                        onClick={onVerify}
                    >
                        Véglegesítés
                    </Button>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#FAFAFA" }}>
                                <TableCell sx={{ color: "#434343" }}>#</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Vonalkód</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Cikkszám</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Megnevezés</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Darabszám</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Eladási ár</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Előző eladási ár</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {income.lines.map((item, i) => {
                                return (
                                    <SummaryItem
                                        key={i}
                                        id={i}
                                        row={item}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default SummaryTable;