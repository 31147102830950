import { Box, Button, Stack, Typography } from "@mui/material";

const PaymentItem = ({ payment, onSelect }) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            border="1px solid #FFF"
            borderRadius={1}
            padding={1}
            sx={{
                "&:hover": {
                    border: "1px solid #73D13D"
                },
                "&:hover button": {
                    bgcolor: "#73D13D",
                }
            }}
        >
            <Stack spacing={0.5}>
                <Typography
                    fontSize={12}
                    fontWeight={700}
                >
                    {payment.name}
                </Typography>

                <Typography
                    fontSize={10}
                    fontWeight={300}
                >
                    {payment.description}
                </Typography>
            </Stack>

            <Button
                size="small"
                onClick={() => onSelect(payment.id)}
                sx={{
                    bgcolor: "#D9D9D9",
                    fontSize: 10,
                    fontWeight: 400,
                    color: "#FFF",
                    "&:hover": {
                        bgcolor: "#5FAD32 !important"
                    }
                }}
            >
                Kiválasztás
            </Button>
        </Box>
    );
};

export default PaymentItem;