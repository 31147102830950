import { useEffect, useState } from "react";

import { Box, Button, Slider, Typography } from "@mui/material";

const MarginInput = ({ traffic, onMargin }) => {
    const [inputMargin, setInputMargin] = useState([0, 0]);

    useEffect(() => {
        if(traffic.length !== 0) {
            let min = traffic[0].margin, max = traffic[0].margin;

            traffic.map(item => {
                if(item.margin > max) max = item.margin;
                if(item.margin < min) min = item.margin;
            });

            setInputMargin([min, max]);
        }
    }, [traffic]);

    return (
        <Box
            display="flex"
            flexDirection="row"
            gap={2}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="100%"
            >
                <Typography
                    fontSize={12}
                    color="#000"
                >
                    Árrés szűrő
                </Typography>

                <Slider
                    value={inputMargin}
                    onChange={(e, newVal) => setInputMargin(newVal)}
                    valueLabelDisplay="auto"
                    getAriaValueText={(val) => val+" %"}
                    step={0.1}
                />
            </Box>

            <Button
                variant="contained"
                size="small"
                onClick={() => onMargin(inputMargin)}
            >
                Szűrés
            </Button>
        </Box>
    );
};

export default MarginInput;