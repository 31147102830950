import { Avatar, Box, Checkbox, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import StoreIcon from "../../../../../layouts/stores/content/StoreIcon";

import CurrencyText from "../../../../../components/currencytext/CurrencyText";

const WinnerItem = ({ ticket, onCheck }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = ticket.profile_pic ? apiUrl+"/"+ticket.profile_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };
    
    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    checked={ticket.selected}
                    onChange={(e) => onCheck(ticket, e.target.checked)}
                />
            </TableCell>

            <TableCell>
                <Tooltip
                    title={ticket.last_name+" "+ticket.first_name}
                    arrow
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <Avatar src={imageExists(avatar) ? avatar : null} size="sm" />
                    </Box>
                </Tooltip>
            </TableCell>

            <TableCell>
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <Typography fontSize={14} fontWeight={400}>{ticket.date}</Typography>
                    <Typography fontSize={12} fontWeight={400} color="#8C8C8C">{ticket.date}</Typography>
                </Box>
            </TableCell>

            <TableCell>
                <Tooltip
                    title={ticket.store}
                    arrow
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <StoreIcon
                            store={{
                                name: ticket.store,
                                color_code_2: ticket.store_color
                            }}
                            size="medium"
                        />
                    </Box>
                </Tooltip>
            </TableCell>

            <TableCell>
                <Typography fontSize={14} fontWeight={400}>{ticket.type}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={14} fontWeight={400}>{ticket.serial_num}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={14} fontWeight={400}>{ticket.prize_code}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={14} fontWeight={400}>
                    <CurrencyText
                        val={ticket.prize_value}
                    /> HUF
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default WinnerItem;