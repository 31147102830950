import { Avatar, AvatarGroup, Box, Checkbox, Chip, TableCell, TableRow, Tooltip } from "@mui/material";

import StoreIcon from "../../../../../layouts/stores/content/StoreIcon";

import CurrencyText from "../../../../../components/currencytext/CurrencyText";

const PackTicket = ({ ticket, onCheck, onRemove }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = ticket.profile_pic ? apiUrl+"/"+ticket.profile_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    const renderBadcashout = () => {
        if(ticket.bad_cashout) {
            if(Number(ticket.fault_type) === 0) {
                return (
                    <TableCell>
                        <Chip
                            label="Sérült sorsjegy"
                            color="warning"
                            onDelete={() => onRemove(ticket)}
                        />
                    </TableCell>
                );
            }
            else if(Number(ticket.fault_type) === 1) {
                return (
                    <TableCell>
                        <Chip
                            label="Téves kifizetés"
                            color="error"
                            onDelete={() => onRemove(ticket)}
                        />
                    </TableCell>
                );
            }
        }
    };

    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    checked={ticket.selected}
                    onChange={(e) => onCheck(ticket, e.target.checked)}
                />
            </TableCell>

            <TableCell>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <AvatarGroup>
                        <Tooltip
                            title={ticket.last_name+" "+ticket.first_name+" ("+ticket.store+")"}
                            arrow
                        >
                            <Avatar src={imageExists(avatar) ? avatar : null} sx={{ width: 32, height: 32 }} />
                        </Tooltip>

                        <StoreIcon
                            store={{
                                name: ticket.store,
                                color_code_2: ticket.store_color
                            }}
                            size="medium"
                        />
                    </AvatarGroup>
                </Box>
            </TableCell>

            <TableCell>
                {ticket.type}
            </TableCell>

            <TableCell>
                {ticket.serial_num}
            </TableCell>

            <TableCell>
                {ticket.prize_code}
            </TableCell>

            <TableCell>
                <CurrencyText
                    val={ticket.prize_value}
                />
            </TableCell>

            {renderBadcashout()}
        </TableRow>
    );
};

export default PackTicket;