import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryTransitSafeStyle from "./SummaryTransitSafe.module.css";

const SummaryTransitSafe = ({ id, log, info, serialNum }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log) {
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/safe/transit/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
        return setLogData(data.transit);
    };

    const renderTransit = () => {
        if(log) {
            return (
                <div className={SummaryTransitSafeStyle.summary_content}>
                    <span>Szállítás dátuma: <span style={{fontWeight: "600"}}>{logData[0]?.date_of_transit}</span></span>
                    <span>Értékszállító kódja: <span style={{fontWeight: "600"}}>{logData[0]?.transit_id}</span></span>
                    <table>
                        <thead>
                            <tr>
                                <th>Biztonsági tasak sorozatszáma</th>
                                <th>Rögzítés dátuma</th>
                                <th>Összeg</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logData.map(p => {
                                return (
                                    <tr key={p.id}>
                                        <td>{p.serial_num}</td>
                                        <td>{p.date_of_record}</td>
                                        <td>
                                            {p.sum} HUF
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        }
        else {
            return (
                <div className={SummaryTransitSafeStyle.summary_content}>
                    <span>Értékszállító kódja: <span style={{fontWeight: "600"}}>{serialNum}</span></span>
                    <table>
                        <thead>
                            <tr>
                                <th>Biztonsági tasak sorozatszáma</th>
                                <th>Rögzítés dátuma</th>
                                <th>Összeg</th>
                            </tr>
                        </thead>
                        <tbody>
                            {info.map(p => {
                                return (
                                    <tr key={p.id}>
                                        <td>{p.serial_num}</td>
                                        <td>{p.date_of_record.split("T")[0]}</td>
                                        <td>
                                            {p.sum} HUF
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    return renderTransit();
};

export default SummaryTransitSafe;