import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import OpsHeaderStyle from "./OpsHeader.module.css";
import HomeStyle from "../../pages/home/Home.module.css";

import ShopImg from "../../assets/body/ops_shop.svg";
import ExitImg from "../../assets/body/ops_arrow.svg";

import OpIcon from "../../components/opicon/OpIcon";
import ChartFilter from "../../components/charts/chartfilter/ChartFilter";

const OpsHeader = ({ operation, opsRef, opsClose, chart, onChartFilter, headerItem }) => {
    const {homeRef, setMsg, setOpenOper} = useContext(DataContext);

    const [op, setOp] = useState({});

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(operation !== "shop") {
            getOperationData();
        }
    }, []);

    const getOperationData = async() => {
        const res = await fetch(apiUrl+"/operation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: operation
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setOp(data.op);
    };

    const handleExitOperation = () => {
        if(operation === "shop" || op.id === 1) {
            homeRef.current.className = HomeStyle.content_body_exit;
        }
        else {
            opsRef.current.className = HomeStyle.op_content_close;

            const timer = setTimeout(() => {
                opsClose();
            }, 1000);
            return () => clearTimeout(timer);
        }
        

        const timer = setTimeout(() => {
            setMsg({});

            if(operation === "shop" || op.id === 1) return navigate("/home");

            setOpenOper(0);
        }, 1000);
        return () => clearTimeout(timer);
    };

    const renderHeaderItem = () => {
        if(headerItem) {
            return headerItem;
        }
    };

    const renderChartFilter = () => {
        if(chart) {
            return (
                <div className={OpsHeaderStyle.filter_bar}>
                    {chart.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => onChartFilter(item)}
                            />
                        );
                    })}
                </div>
            );
        }
    };

    if(operation === "shop") {
        return (
            <div className={OpsHeaderStyle.ops_card}>
                <div className={OpsHeaderStyle.ops_card_details}>
                    <div className={OpsHeaderStyle.ops_shop}>
                        <img
                            src={ShopImg}
                            alt="Icon"
                        />
                    </div>
                    
    
                    <span className={OpsHeaderStyle.ops_card_name}>ELADÓTÉR</span>
                </div>
    
                <img
                    src={ExitImg}
                    alt="Icon"
                    className={OpsHeaderStyle.back}
                    onClick={handleExitOperation}
                />
            </div>
        );
    }
    else {
        return (
            <div className={OpsHeaderStyle.ops_card}>
                <div className={OpsHeaderStyle.ops_card_details}>
                    <OpIcon
                        op={op}
                    />
    
                    <span
                        className={OpsHeaderStyle.ops_card_name}
                        style={op.id === 1 ? {color: "#FFFFFF"} : {}}
                    >
                        {op.name}
                    </span>
                </div>

                {renderChartFilter()}
                {renderHeaderItem()}
    
                <img
                    src={ExitImg}
                    alt="Icon"
                    className={OpsHeaderStyle.back}
                    onClick={handleExitOperation}
                />
            </div>
        );
    }
};

export default OpsHeader;