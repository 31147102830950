import { useContext, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { motion } from 'framer-motion';

import { Box, Card, CardActionArea, Chip, Grid, Tooltip, Typography } from "@mui/material";

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import DefaultOp from "../../../../assets/default_op.svg";

const Operation = ({ op, onRefresh }) => {
    const {user, setOpenOper, opComps} = useContext(DataContext);

    const [hover, setHover] = useState(false);
    const [fav, setFav] = useState(op.favourite);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleToOperation = () => {
        if(Object.keys(opComps).includes(op.operation.toString())) {
            setOpenOper(op.operation);
            return;
        }
    };

    const handleFavourite = async(e) => {
        e.stopPropagation();

        const res = await fetch(apiUrl+"/operation/favourite", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: user.id,
                opId: op.operation,
                val: fav === 0 ? 1 : 0
            })
        });

        const data = await res.json();

        if(!data.success) return alert(data.message);

        setFav(fav === 0 ? 1 : 0);
        onRefresh();
    };

    return (
        <Grid item xs={12} md={3}>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Card variant="outlined">
                    <CardActionArea
                        onClick={handleToOperation}
                        sx={{ height: 140, padding: 1 }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                            width="100%"
                            height="100%"
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width={60}
                                height="100%"
                            >
                                <img
                                    src={DefaultOp}
                                    alt="icon"
                                    style={{ width: 40, height: 40 }}
                                />
                            </Box>
                            

                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                alignSelf="flex-start"
                                width="100%"
                                height="100%"
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography fontSize={12}>{op.name}</Typography>

                                    <Tooltip
                                        onClick={handleFavourite}
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                        sx={{
                                            cursor: "pointer",
                                            "&:hover": { bgcolor: "transparent" }
                                        }}
                                    >
                                        <motion.div whileHover={{ scale: 1.1 }}>
                                            {fav === 0 ?
                                                (hover ?
                                                    <FavoriteIcon style={{ color: "#6E6E6E" }} />
                                                    :
                                                    <FavoriteBorderOutlinedIcon style={{ transition: "width 2s", color: "#6E6E6E" }} />
                                                )
                                                :
                                                <FavoriteIcon />
                                            }
                                        </motion.div>
                                    </Tooltip>
                                </Box>

                                <Typography fontSize={11} fontWeight={300}>{op.description}</Typography>

                                <Chip
                                    label={op.group_name}
                                    sx={{
                                        borderRadius: 1,
                                        bgcolor: "#13C2C2",
                                        color: "#FFF",
                                        fontSize: 12,
                                        alignSelf: "flex-end",
                                        marginTop: "auto",
                                        height: 25,
                                        fontWeight: 300
                                    }}
                                />
                            </Box>
                        </Box>
                    </CardActionArea>
                </Card>
            </motion.div>
        </Grid>
    );
};

export default Operation;