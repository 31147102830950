import { useEffect, useState } from "react";

import { getUserStores } from "../../../services/Api";

import { Avatar, AvatarGroup, Box, Grow, IconButton, TableCell, TableRow, Typography } from "@mui/material";

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import StoreIcon from "../../../layouts/stores/content/StoreIcon";

const UserRow = ({ userInfo, timeout, onEdit, onInactive }) => {
    const [userStores, setUserStores] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = userInfo.profile_pic ? apiUrl+"/"+userInfo.profile_pic : null;

    useEffect(() => {
        getUserStores(userInfo.id).then(data => setUserStores(data));
    }, []);

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("GET", url, true);
        http.send();
    
        return http.status !== 404;
    }

    return (
        <Grow in={true} timeout={timeout}>
            <TableRow hover>
                <TableCell>
                    <Typography fontSize={12} fontWeight={400}>{userInfo.rank_name}</Typography>
                </TableCell>

                <TableCell>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                    >
                        <Avatar src={imageExists(avatar) ? avatar : null} />
                        
                        <Box
                            display="flex"
                            flexDirection="column"
                        >
                            <Typography fontSize={14} fontWeight={400}>{userInfo.last_name+" "+userInfo.first_name}</Typography>
                            <Typography fontSize={12} fontWeight={400} color="#8C8C8C">{userInfo.email}</Typography>
                        </Box>
                    </Box>
                </TableCell>

                <TableCell>
                    <AvatarGroup
                        max={5}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        {userStores.map(item => {
                            return (
                                <Avatar
                                    key={item.id}
                                    sx={{
                                        bgcolor: "transparent",
                                        border: "none"
                                    }}
                                >
                                    <StoreIcon
                                        store={item}
                                    />
                                </Avatar>
                            );
                        })}
                    </AvatarGroup>
                </TableCell>

                <TableCell>
                    <Typography fontSize={14} fontWeight={400}>{userInfo.company_in_date?.split("T")[0]}</Typography>
                </TableCell>

                <TableCell>
                    <Typography fontSize={14} fontWeight={400}>{userInfo.company_out_date?.split("T")[0]}</Typography>
                </TableCell>

                <TableCell>
                    <IconButton onClick={onEdit}>
                        <BorderColorOutlinedIcon />
                    </IconButton>

                    <IconButton onClick={onInactive}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </Grow>
    );
};

export default UserRow;