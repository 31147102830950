import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";

const SummaryOutTicket = ({ id, log, info, prize }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log) {
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/ticket/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
        return setLogData(data.ticket);
    };

    return (
        <table className={SummaryStyle.summary_table}>
            <tbody>
                <tr>
                    <td>Típus</td>
                    <th>{log ? logData.name : info.name}</th>
                </tr>
                <tr>
                    <td>Sorozatszám</td>
                    <th>{log ? logData.serial_num : info.serial_num}</th>
                </tr>
                <tr>
                    <td>Nyereménykód</td>
                    <th>{log ? logData.prize_code : prize.code}</th>
                </tr>
                <tr>
                    <td>Kifizetett összeg</td>
                    <th>
                        {log ?
                            <>
                                {logData.prize_value} HUF
                            </>
                        :
                            <>
                                {prize.value} HUF
                            </>
                        }
                    </th>
                </tr>
            </tbody>
        </table>
    );
};

export default SummaryOutTicket;