import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, Container, Grid, Grow, InputAdornment, OutlinedInput, Paper, Toolbar, Typography } from "@mui/material";

import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import CompanyInfoSVG from "../../../assets/module/company_info.svg";

import Dashboard from "../../../layouts/dashboard/Dashboard";

const CompanyInfo = () => {
    const {user} = useContext(DataContext);

    const [input, setInput] = useState({
        name: "",
        tax: "",
        address: "",
        bank: ""
    });

    const navigate = useNavigate();

    const handleNext = async() => {
        navigate("/register/company/type", {state: input})
    };

    return (
        <Dashboard>
            <Box
                component="main"
                flexGrow={1}
                height="100vh"
                overflow="auto"
                sx={{ bgcolor: "#FFF" }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                    <Paper sx={{ p: 3 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={12} md={6}>
                                <img
                                    src={CompanyInfoSVG}
                                    alt="company"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    gap={2}
                                >
                                    <Typography color="#6A86C4" fontWeight={500}>Szervezet adatai</Typography>
                                    
                                    <Typography color="#737373" fontSize={10} fontWeight={300}>
                                        Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor. Nam risus metus, tempor ut ante viverra, blandit vulputate augue. Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor. Nam risus metus, tempor ut ante viverra, blandit vulputate augue. 
                                    </Typography>

                                    <Grow in={true} timeout={1000}>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="cégnév"
                                            fullWidth
                                            size="small"
                                            value={input.name}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, name: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <BusinessIcon />
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>

                                    <Grow in={true} timeout={1500}>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="adószám"
                                            fullWidth
                                            size="small"
                                            value={input.tax}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, tax: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <BadgeOutlinedIcon />
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>

                                    <Grow in={true} timeout={2000}>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="székhely"
                                            fullWidth
                                            size="small"
                                            value={input.address}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, address: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <HomeIcon />
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>

                                    <Grow in={true} timeout={2500}>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="bankszámla"
                                            fullWidth
                                            size="small"
                                            value={input.bank}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, bank: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <AccountBalanceIcon />
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>

                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        alignSelf="flex-end"
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={() => user.company ? navigate("/home") : navigate("/register/company") }
                                            sx={{
                                                borderRadius: 8
                                            }}
                                        >
                                            Vissza
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            disabled={Object.values(input).some(item => item === "")}
                                            sx={{
                                                alignSelf: "flex-end",
                                                borderRadius: 8,
                                                borderColor: "#6A86C4",
                                                color: "#6A86C4"
                                            }}
                                            onClick={handleNext}
                                        >
                                            Tovább
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Box>
        </Dashboard>
    );
};

export default CompanyInfo;