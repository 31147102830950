import { Chip, Tooltip, Zoom } from "@mui/material";

import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

const Tag = ({ info, click }) => {
    return (
        <Tooltip
            arrow
            TransitionComponent={Zoom}
            onClick={click}
        >        
            <Chip
                size="small" 
                icon={<LocalOfferOutlinedIcon style={{ color: "#000" }} />}
                label={info.name}
                clickable={true}
                variant='outlined'
                sx={{ 
                    backgroundColor: info.color_code,
                    borderColor: info.color_code, 
                    borderRadius: 1
                }}
            />
        </Tooltip>
    );
};

export default Tag;