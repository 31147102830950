import { useState } from "react";

import { Box, Divider, Grid, IconButton, OutlinedInput, Typography } from "@mui/material";

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

import CurrencyText from "../../../components/currencytext/CurrencyText";

const ModifyRow = ({ text, val, change }) => {
    const [modify, setModify] = useState(false);

    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Typography
                    fontSize={12}
                    fontWeight={400}
                    color="#8C8C8C"
                >
                    {text}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    justifyContent="flex-end"
                    alignItems="center"
                    width="100%"
                >
                    {modify ?
                        <OutlinedInput
                            value={val}
                            onChange={change}
                            size="small"
                            onFocus={(e) => e.target.select()}
                            onKeyDown={(e) => {
                                if(e.key === "Enter") {
                                    setModify(false);
                                }
                            }}
                        />
                    :
                        <Typography
                            fontSize={16}
                            fontWeight={500}
                            color="#262626"
                        >
                            {val}
                        </Typography>
                    }

                    <IconButton
                        size="small"
                        onClick={() => setModify(prev => !prev)}
                    >
                        <BorderColorOutlinedIcon style={{ fontSize: 32 }} />
                    </IconButton>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );
};

export default ModifyRow;