import { useContext } from "react";

import { DataContext } from "../../context/DataContext";

import SearchBarStyle from "./SearchBar.module.css";

import SearchIcon from "../../assets/body/search_icon.svg";
import DeleteImg from "../../assets/body/delete.svg";

const SearchBar = ({ type, style, val, change, ph, keyDown, focus, blur, width, clearSearch }) => {
    const {searchRef} = useContext(DataContext);

    return (
        <div
            className={SearchBarStyle.input_container}
            style={{width: width}}
        >
            <img
                src={SearchIcon}
                alt="Search"
            />
            
            <input
                id={ph}
                type={type ? type : "text"}
                style={style}
                ref={searchRef}
                value={val}
                onChange={change}
                onKeyDown={keyDown}
                onFocus={focus}
                onBlur={blur}
                required
            />
            
            <label htmlFor={ph}>
                {ph}
            </label>

            {val ?
                <img
                    src={DeleteImg}
                    alt="Clear"
                    className={SearchBarStyle.input_clear}
                    onClick={clearSearch}
                />
            :
                <></>
            }
        </div>
    );
};

export default SearchBar;