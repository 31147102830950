import { useContext, useState } from "react";

import { DataContext } from "../../../../../../../../context/DataContext";

import { Box, Button, Dialog, DialogActions, DialogContentText, IconButton, Tooltip, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CircleIcon from '@mui/icons-material/Circle';

import Tag from "./Tag";

const NewTagDialog = ({ open, setOpen, name, onNewTag }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {user, setMsg, setLoading} = useContext(DataContext);

    const [tagColor, setTagColor] = useState("#E3E3E3");

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleNewTag = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/producttags/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                colorCode: tagColor,
                company: user.company.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setLoading(false);
        onNewTag();
    };

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <IconButton
                    onClick={() => setOpen(false)}
                    size="small"
                >
                    <ArrowBackIosIcon style={{ fontSize: 16 }} />
                </IconButton>

                <IconButton
                    size="small"
                >
                    <OpenInFullIcon style={{ fontSize: 16 }} />
                </IconButton>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                alignItems="center"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    alignSelf="flex-start"
                    marginLeft={1}
                >
                    <LocalOfferOutlinedIcon />

                    <DialogContentText
                        fontSize={16}
                        paddingLeft={1}
                    >
                        Új címke színének kiválasztása
                    </DialogContentText>
                </Box>

                <DialogContentText
                    fontSize={12}
                    alignSelf="flex-start"
                    paddingLeft={1}
                >
                    A címkék között a színekkel is meg tudod különböztetni az egyes termék csoportokat kategóriákat. Bátran használd őket.
                </DialogContentText>

                <Tag
                    info={({name: name, color_code: tagColor})}
                />

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Tooltip
                        onClick={() => setTagColor("#F5F5F5")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#F5F5F5", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#F0F0F0")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#F0F0F0", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#D9D9D9")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#D9D9D9", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#BFBFBF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#BFBFBF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#8C8C8C")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#8C8C8C", fontSize: 34 }} />
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Tooltip
                        onClick={() => setTagColor("#E6F7FF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#E6F7FF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#BAE7FF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#BAE7FF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#91D5FF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#91D5FF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#69C0FF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#69C0FF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#40A9FF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#40A9FF", fontSize: 34 }} />
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Tooltip
                        onClick={() => setTagColor("#FFF1F0")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FFF1F0", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FFCCC7")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FFCCC7", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FFA39E")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FFA39E", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FF7875")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FF7875", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FF4D4F")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FF4D4F", fontSize: 34 }} />
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Tooltip
                        onClick={() => setTagColor("#FFF2E8")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FFF2E8", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FFD8BF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FFD8BF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FFBB96")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FFBB96", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FF9C6E")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FF9C6E", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FF7A45")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FF7A45", fontSize: 34 }} />
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Tooltip
                        onClick={() => setTagColor("#FCFFE6")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FCFFE6", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#F4FFB8")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#F4FFB8", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#EAFF8F")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#EAFF8F", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#D3F261")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#D3F261", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#BAE637")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#BAE637", fontSize: 34 }} />
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Tooltip
                        onClick={() => setTagColor("#F6FFED")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#F6FFED", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#D9F7BE")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#D9F7BE", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#B7EB8F")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#B7EB8F", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#95DE64")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#95DE64", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#52C41A")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#52C41A", fontSize: 34 }} />
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Tooltip
                        onClick={() => setTagColor("#E6FFFB")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#E6FFFB", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#B5F5EC")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#B5F5EC", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#87E8DE")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#87E8DE", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#5CDBD3")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#5CDBD3", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#36CFC9")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#36CFC9", fontSize: 34 }} />
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Tooltip
                        onClick={() => setTagColor("#F0F5FF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#F0F5FF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#D6E4FF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#D6E4FF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#ADC6FF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#ADC6FF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#85A5FF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#85A5FF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#597EF7")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#597EF7", fontSize: 34 }} />
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Tooltip
                        onClick={() => setTagColor("#F9F0FF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#F9F0FF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#EFDBFF")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#EFDBFF", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#D3ADF7")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#D3ADF7", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#B37FEB")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#B37FEB", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#9254DE")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#9254DE", fontSize: 34 }} />
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Tooltip
                        onClick={() => setTagColor("#FFF0F6")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FFF0F6", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FFD6E7")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FFD6E7", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FFADD2")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FFADD2", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#FF85C0")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#FF85C0", fontSize: 34 }} />
                    </Tooltip>

                    <Tooltip
                        onClick={() => setTagColor("#F759AB")}
                        sx={{ cursor: "pointer" }}
                    >
                        <CircleIcon style={{ color: "#F759AB", fontSize: 34 }} />
                    </Tooltip>
                </Box>
            </Box>

            <DialogActions>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        sx={{
                            color: "#262626",
                            borderColor: "#D9D9D9",
                            "&:hover": {
                                color: "#262626",
                                borderColor: "#D9D9D9"
                            }
                        }}
                    >
                        Vissza
                    </Button>

                    <Button
                        variant="contained"
                        onClick={handleNewTag}
                        sx={{
                            bgcolor: "#8C8C8C",
                            "&:hover": {
                                bgcolor: "#8C8C8C"
                            }
                        }}
                    >
                        Cimke létrehozása
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default NewTagDialog;