import { useContext, useRef, useState } from "react";

import * as XLSX from "xlsx";

import { DataContext } from "../../context/DataContext";

import { Grid } from "@mui/material";

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

import OpHeader from "../../components/opheader/OpHeader";
import TrafficMode from "./content/TrafficMode";
import ChangeCashin from "./ChangeCashin";

const ChangeFile = () => {
    const {setOpenOper, setMsg} = useContext(DataContext);

    const [traffic, setTraffic] = useState([]);
    const [fileName, setFileName] = useState("");
    const [change, setChange] = useState(false);

    const inputFileRef = useRef();

    const ExcelDateToJSDate = (serial) => {
        let utc_days  = Math.floor(serial - 25569);
        let utc_value = utc_days * 86400;                                        
        let date_info = new Date(utc_value * 1000);

        let fractional_day = serial - Math.floor(serial) + 0.0000001;

        let total_seconds = Math.floor(86400 * fractional_day);

        let seconds = total_seconds % 60;

        total_seconds -= seconds;

        let hours = Math.floor(total_seconds / (60 * 60));
        let minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).toLocaleString("hu-HU");
    };

    const handleFileChange = async(e) => {
        if(!e.target.files[0]) {
            return;
        }

        const file = e.target.files[0];

        if(file.name.split(".")[1] !== "XLS") {
            return setMsg({
                color: "error",
                message: "A kiválasztott fájl nem Excel fájl!"
            });
        }

        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsondata = XLSX.utils.sheet_to_json(worksheet);
        
        if(isExcelFileStructureOk(jsondata)){
            jsondata.map(row => {
                if(row.hasOwnProperty("Kassza")){
                    setTraffic(prevFile => [
                        ...prevFile,
                        {
                            date: ExcelDateToJSDate(JSON.stringify(row["Ido"])),
                            barcode: JSON.stringify(row["Vonalkód"]),
                            item_name: JSON.stringify(row["Megnevezés"]).replaceAll('"', ""),
                            receipt_num_1: JSON.stringify(row["Kassza"]).replaceAll('"', ""),
                            receipt_num_2: JSON.stringify(row["Bizonylat"])?.replaceAll('"',""),
                            quantity: JSON.stringify(row["Mennyiség"]).replaceAll('"', "").replaceAll(" ", "").replaceAll(/[^\d.-]/g, ""),
                            unit_price: JSON.stringify(row["Egységár"]),
                            unit_value: JSON.stringify(row["Érték"]),
                            purchase_price: JSON.stringify(row["Besz.ár"]),
                            purchase_value: JSON.stringify(row["Besz.Érték"]),
                            vat_rate: JSON.stringify(row["Áfakulcs"]),
                            card: row["Fizetési mód"] === "Bankkártya" || row["Fizetési mód"] === "Bankkátya" ? JSON.stringify(row["Érték"]) : 0
                        }
                    ]);

                    setMsg({});
                    setFileName(file.name);
                    return setChange(true);
                }
            });
        }
        else {
            return setMsg({
                color: "error",
                message: "A kiválasztott fájl struktúrája nem megfelelő!"
            })
        }
    };

    const isExcelFileStructureOk = (arr) => {
        let ok = true;

        arr.map((row, i) => {
            if(i !== (arr.length-1)){
                if(!row.hasOwnProperty("Ido") || !row.hasOwnProperty("Megnevezés") || 
                !row.hasOwnProperty("Kassza") || !row.hasOwnProperty("Mennyiség") || 
                !row.hasOwnProperty("Egységár") || !row.hasOwnProperty("Érték") || 
                !row.hasOwnProperty("Besz.ár") || !row.hasOwnProperty("Besz.Érték")){
                    return ok = false;
                }
            }
        });

        return ok;
    };

    const renderChangeCashin = () => {
        if(change) {
            return (
                <ChangeCashin
                    traffic={traffic}
                    fileName={fileName}
                    onClose={() => {
                        setTraffic([]);
                        setChange(false);
                    }}
                />
            );
        }
    };

    return (
        <>
            {renderChangeCashin()}

            {!change &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={3}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        md={6}
                        spacing={2}
                        margin="auto"
                    >
                        <TrafficMode
                            text="Manuális kézi műszakváltás"
                            icon={<BorderColorOutlinedIcon />}
                            timeout={400}
                            active={false}
                        />

                        <TrafficMode
                            text="Beolvasom az adatokat a forgalom táblából"
                            icon={<FileUploadOutlinedIcon />}
                            click={() => inputFileRef.current.click()}
                            timeout={800}
                            active={true}
                        />

                        <input
                            type="file"
                            ref={inputFileRef}
                            style={{display: "none"}}
                            accept="application/vnd.ms-excel"
                            onChange={handleFileChange}
                        />
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default ChangeFile;