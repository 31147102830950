import { useContext, useEffect, useState } from "react";
import axios from "axios";

import { DataContext } from "../../context/DataContext";

import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import OpHeader from "../../components/opheader/OpHeader";
import AddImage from "../../components/addimage/AddImage";
import PcsTable from "../../components/pcstable/PcsTable";

const SafeCashOut = () => {
    const {user, setOpenOper, setMsg, setLoading} = useContext(DataContext);

    const [pcs, setPcs] = useState({
        huf20000: 0,
        huf10000: 0,
        huf5000: 0,
        huf2000: 0,
        huf1000: 0,
        huf500: 0,
        huf200: 0,
        huf100: 0,
        huf50: 0,
        huf20: 0,
        huf10: 0,
        huf5: 0,
        sum: 0
    });
    const [serialNum, setSerialNum] = useState("");
    const [comment, setComment] = useState("");

    const [recordImage, setRecordImage] = useState();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        calculateSum();
    }, [pcs.huf5, pcs.huf10, pcs.huf20, pcs.huf50, pcs.huf100, pcs.huf200, pcs.huf500, pcs.huf1000,
        pcs.huf2000, pcs.huf5000, pcs.huf10000, pcs.huf20000]);

    const displayCurrency = () => {
        if(pcs.sum) return pcs.sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        else return 0;
    };

    const calculateSum = () => {
        const sum = ((20000*pcs.huf20000) + (10000*pcs.huf10000) + (5000*pcs.huf5000) + (2000*pcs.huf2000) + 
        (1000*pcs.huf1000) + (500*pcs.huf500) + (200*pcs.huf200) + (100*pcs.huf100) + 
        (50*pcs.huf50) + (20*pcs.huf20) + (10*pcs.huf10) + (5*pcs.huf5));

        setPcs(prevPcs => ({...prevPcs, sum: sum}));
    };

    const handleCashOut = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/safe/cashout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                serialNum: serialNum,
                comment: comment,
                huf5: Number(pcs.huf5),
                huf10: Number(pcs.huf10),
                huf20: Number(pcs.huf20),
                huf50: Number(pcs.huf50),
                huf100: Number(pcs.huf100),
                huf200: Number(pcs.huf200),
                huf500: Number(pcs.huf500),
                huf1000: Number(pcs.huf1000),
                huf2000: Number(pcs.huf2000),
                huf5000: Number(pcs.huf5000),
                huf10000: Number(pcs.huf10000),
                huf20000: Number(pcs.huf20000),
                sum: Number(pcs.sum)
            })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        uploadImage(data.safeItem);
    };

    const uploadImage = async(safeItem) => {
        const file = new FormData();
        file.append("file", recordImage);

        axios.post(apiUrl+"/safe/image/cashout/"+safeItem, file)
        .then(res => {
            setLoading(false);

            setMsg({
                color: "success",
                message: "Sikeres kifizetés a széfbe! (Összeg: "+pcs.sum+" HUF)"
            });

            setOpenOper(8);
        });
    };

    const handleUploadImage = (e) => {
        setRecordImage(e.target.files[0]);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={5}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <PcsTable
                pcs={pcs}
                setPcs={setPcs}
                modify={true}
            />

            <Grid item xs={12} md={4}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography fontSize={12} fontWeight={700} color="#000" marginBottom={1}>Csomagolási adatok és képek</Typography>
                
                    <TextField
                        value={serialNum}
                        onChange={(e) => setSerialNum(e.target.value)}
                        variant="filled"
                        label="Csomag menevezése"
                        size="small"
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        variant="filled"
                        label="Megjegyzés"
                        size="small"
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={displayCurrency()+" HUF"}
                        variant="filled"
                        label="Összesen"
                        size="small"
                        sx={{ "& .MuiFilledInput-root": { bgcolor: "#FFF", fontSize: 24, fontWeight: 700 } }}
                    />

                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        alignItems="center"
                        width="100%"
                    >
                        <Typography
                            fontSize={12}
                            fontWeight={300}
                            color="#000"
                        >
                            Töltsd fel a képeket a csomagolásról és a pénztárbizonylatról
                        </Typography>

                        {recordImage ?
                            <>
                                <img
                                    src={URL.createObjectURL(recordImage)}
                                    style={{ width: 200 }}
                                />

                                <IconButton onClick={() => setRecordImage()}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </>
                        :
                            <>
                                <AddImage onUpload={handleUploadImage} />

                                <Typography
                                    fontSize={12}
                                    fontWeight={300}
                                    color="#000"
                                >
                                    Kép hozzáadása
                                </Typography>
                            </>
                        }
                    </Box>

                    <Button
                        variant="contained"
                        disabled={pcs.sum === 0 || serialNum === "" || !recordImage}
                        onClick={handleCashOut}
                        sx={{ bgcolor: "#13C2C2", marginTop: 3, alignSelf: "flex-end" }}
                    >
                        <Typography fontSize={12} fontWeight={300}>Lezárom a csomagot</Typography>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default SafeCashOut;