import { useContext, useRef, useState } from "react";

import { DataContext } from "../../context/DataContext";

import { OutlinedInput } from "@mui/material";

import PrizeRowStyle from "./PrizeRow.module.css";
import TicketStyle from "../../pages/tickets/ticketinfo/ticket/Ticket.module.css";

import MoreImg from "../../assets/body/ops_more.svg";
import MinusImg from "../../assets/body/ops_minus.svg";

const PrizeRow = ({ prize, onModify, onDelete }) => {
    const {setMsg} = useContext(DataContext);

    const [edit, setEdit] = useState(false);
    const [newRow, setNewRow] = useState({
        code1: prize.prize_code[0],
        code2: prize.prize_code[1],
        code3: prize.prize_code[2],
        value: prize.prize_value
    });
    const prizeRef = {
        code1: useRef(),
        code2: useRef(),
        code3: useRef()
    }

    const handleModifyPrize = async() => {
        if(Object.values(newRow).some(item => item === "")){
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        onModify(prize.id, newRow);
        setEdit(false);
    };

    const handleDeletePrize = async() => {
        onDelete(prize.id);
    };

    if(edit) {
        return (
            <tr className={PrizeRowStyle.row}>
                <td>
                    <input
                        type="text"
                        className={TicketStyle.prize_code}
                        ref={prizeRef.code1}
                        value={newRow.code1}
                        onChange={(e) => {
                            if(newRow.code1.length === 0){
                                setNewRow(prevRow => ({...prevRow, code1: e.target.value.toUpperCase()}));
                                prizeRef.code2.current.focus();
                            }
                            else if(e.target.value === ""){
                                setNewRow(prevRow => ({...prevRow, code1: e.target.value.toUpperCase()}));
                            }
                        }}
                    />
    
                    <input
                        type="text"
                        className={TicketStyle.prize_code}
                        ref={prizeRef.code2}
                        value={newRow.code2}
                        onChange={(e) => {
                            if(newRow.code2.length === 0){
                                setNewRow(prevRow => ({...prevRow, code2: e.target.value.toUpperCase()}));
                                prizeRef.code3.current.focus();
                            }
                            else if(e.target.value === ""){
                                setNewRow(prevRow => ({...prevRow, code2: e.target.value.toUpperCase()}));
                                prizeRef.code1.current.focus();
                            }
                        }}
                    />
    
                    <input
                        type="text"
                        className={TicketStyle.prize_code}
                        ref={prizeRef.code3}
                        value={newRow.code3}
                        onChange={(e) => {
                            if(newRow.code3.length === 0){
                                setNewRow(prevRow => ({...prevRow, code3: e.target.value.toUpperCase()}));
                            }
                            else if(e.target.value === ""){
                                setNewRow(prevRow => ({...prevRow, code3: e.target.value.toUpperCase()}));
                                prizeRef.code2.current.focus();
                            }
                        }}
                    />
                </td>

                <td>
                    <OutlinedInput
                        value={newRow.value}
                        onChange={(e) => {
                            if(!isNaN(+e.target.value)) {
                                setNewRow(prevRow => ({...prevRow, value: e.target.value}));
                            }
                        }}
                        sx={{ width: "7vw", height: "28px" }}
                    />
                </td>

                <td>
                    <img
                        src={MoreImg}
                        alt="More"
                        style={{cursor: "pointer"}}
                        onClick={handleModifyPrize}
                    />

                    <img
                        src={MinusImg}
                        alt="Minus"
                        style={{cursor: "pointer"}}
                        onClick={handleDeletePrize}
                    />
                </td>
            </tr>
        );
    }
    else {
        return (
            <tr className={PrizeRowStyle.row}>
                <td>
                    {prize.prize_code}
                </td>

                <td>
                    {prize.prize_value} HUF
                </td>

                <td>
                    <img
                        src={MoreImg}
                        alt="More"
                        style={{cursor: "pointer"}}
                        onClick={() => setEdit(true)}
                    />

                    <img
                        src={MinusImg}
                        alt="Minus"
                        style={{cursor: "pointer"}}
                        onClick={handleDeletePrize}
                    />
                </td>
            </tr>
        );
    }
};

export default PrizeRow;