import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Avatar } from "@mui/material";

import { DataContext } from "../../context/DataContext";

import LogItemStyle from "./LogItem.module.css";

import OpIcon from "../opicon/OpIcon";
import StorePic from "../storepic/StorePic";

const LogItem = ({ log }) => {
    const {setMsg, stores} = useContext(DataContext);

    const params = useParams();

    const navigate = useNavigate();

    const handleGoToLog = () => {
        //homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/log/"+params.store, {state: log});
        }, 400);
        return () => clearTimeout(timer);
    };

    const renderSum = () => {
        if(Number(log.sum) !== 0) {
            return (
                <span className={LogItemStyle.text_sum}>
                    {Number(log.sum)} HUF
                </span>
            );
        }
    };

    return (
        <div
            className={LogItemStyle.log_row}
            onClick={handleGoToLog}
        >
            <div className={LogItemStyle.log_info}>
                <div className={LogItemStyle.log_profile}>
                    <Avatar />

                    <StorePic
                        store={stores.filter(s => s.name === params.store)[0]}
                        header={true}
                    />
                </div>
                
                <span>{log.date.split(" ")[1].split(":")[0]+":"+log.date.split(" ")[1].split(":")[1]}</span>
            </div>

            <div className={LogItemStyle.log_text}>
                <span className={LogItemStyle.text_name}>{log.op_name}</span>
                {renderSum()}
            </div>

            <OpIcon
                op={log}
            />
        </div>
    );
};

export default LogItem;