import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../../../../context/DataContext";

import { Box, Button, Dialog, DialogActions, DialogContentText, IconButton, OutlinedInput, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import Tag from "./content/Tag";
import NewTagDialog from "./content/NewTagDialog";

const TagsDialog = ({ open, setOpen, choosen, barcode, onNext }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {user, setMsg, setLoading} = useContext(DataContext);

    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState("");

    const [newTagDialog, setNewTagDialog] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getTags();
    }, []);

    const getTags = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/producttags/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTags(data.tags);

        setLoading(false);
    };

    const handleNewTag = () => {
        setNewTagDialog(false);
        setNewTag("");
        getTags();
    };

    const handleChooseTag = async(tagItem) => {
        const res = await fetch(apiUrl+"/producttags/set", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                barcode: barcode,
                tag: tagItem.id,
                user: user.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        onNext();
    };

    return (
        <>
            <Dialog
                fullScreen={matches}
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            minWidth: 400
                        }
                    }
                }}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1}
                >
                    <IconButton
                        onClick={() => setOpen(false)}
                        size="small"
                    >
                        <ArrowBackIosIcon style={{ fontSize: 16 }} />
                    </IconButton>

                    <IconButton
                        size="small"
                    >
                        <OpenInFullIcon style={{ fontSize: 16 }} />
                    </IconButton>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <DialogContentText fontSize={20}>
                        {choosen.itemName ? choosen.itemName : choosen.product}
                    </DialogContentText>

                    <DialogContentText fontSize={12}>{barcode}</DialogContentText>

                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        width="95%"
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            alignSelf="flex-start"
                        >
                            <LocalOfferOutlinedIcon />

                            <DialogContentText
                                fontSize={16}
                                paddingLeft={1}
                            >
                                Termék kategória / címke
                            </DialogContentText>
                        </Box>

                        <DialogContentText
                            fontSize={12}
                            alignSelf="flex-start"
                        >
                            Itt feliratozhat és hozzárendelhetsz különböző címkéket a termékekhez, ezáltal tudod kategorizálni és a későbbiekben könnyben tudsz keresni és szűrni a kimutatásokban és az egyébb műveletekben az ilyen és hasonló termékekre.
                        </DialogContentText>
                        
                        <DialogContentText fontSize={12}>Cimkék</DialogContentText>

                        <OutlinedInput
                            value={newTag}
                            onChange={(e) => setNewTag(e.target.value)}
                            size="small"
                            fullWidth
                            endAdornment={
                                <IconButton
                                    onClick={() => setNewTagDialog(true)}
                                    disabled={newTag === ""}
                                >
                                    <AddCircleOutlineIcon style={newTag !== "" ? { color: "#1890FF" } : {}} />
                                </IconButton>
                            }
                        />
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        width="95%"
                        flexWrap="wrap"
                    >
                        {tags.map(item => {
                            return (
                                <Tag
                                    key={item.id}
                                    info={item}
                                    click={() => handleChooseTag(item)}
                                />
                            );
                        })}
                    </Box>
                </Box>

                <DialogActions>
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        <Button
                            variant="outlined"
                            onClick={() => setOpen(false)}
                            sx={{
                                color: "#262626",
                                borderColor: "#D9D9D9",
                                "&:hover": {
                                    color: "#262626",
                                    borderColor: "#D9D9D9"
                                }
                            }}
                        >
                            Vissza
                        </Button>

                        <Button
                            variant="contained"
                            onClick={onNext}
                            sx={{
                                bgcolor: "#8C8C8C",
                                "&:hover": {
                                    bgcolor: "#8C8C8C"
                                }
                            }}
                        >
                            Kihagyom
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <NewTagDialog
                open={newTagDialog}
                setOpen={setNewTagDialog}
                name={newTag}
                onNewTag={handleNewTag}
            />
        </>
    );
};

export default TagsDialog;