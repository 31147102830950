import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import StoreItem from "./content/StoreItem";

const StoreDialog = ({ open, setOpen, stores, onChoose }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 600
                    }
                }
            }}
        >
            <DialogTitle>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <IconButton
                        onClick={() => setOpen(false)}
                        size="small"
                    >
                        <ArrowBackIosIcon style={{ fontSize: 16 }} />
                    </IconButton>

                    <Typography
                        fontSize={12}
                        fontWeight={700}
                    >
                        Telephely kiválasztás
                    </Typography>

                    <IconButton
                        size="small"
                    >
                        <OpenInFullIcon style={{ fontSize: 16 }} />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Stack spacing={1}>
                    {stores.map(item => {
                        return (
                            <StoreItem
                                key={item.id}
                                store={item}
                                onSelect={onChoose}
                            />
                        );
                    })}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default StoreDialog;