import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import { Backdrop, Box, Button, CircularProgress, Container, Grid, Grow, IconButton, OutlinedInput, Paper, Toolbar, Typography } from "@mui/material";

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import ShopAreaJPEG from "../../assets/home/modules/shoparea.jpeg";

import Dashboard from "../../layouts/dashboard/Dashboard";
import Feedback from "../../components/feedback/Feedback";
import ApIcon from "../../layouts/apartmans/content/ApIcon";

const CreateApartman = () => {
    const {user, msg, setMsg, loading, setLoading} = useContext(DataContext);

    const [newAp, setNewAp] = useState({
        name: "",
        address: "",
        colorCode: "#F4F4F4"
    });

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleCreateAp = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/apartman/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                company: user.company.id,
                ap: newAp
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeresen létrehoztad a telephelyet"
        });

        navigate("/home");
        
        setLoading(false);
    };

    return (
        <Dashboard>
            <Backdrop
                sx={{ color: '#fff', zIndex: 10000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {Object.keys(msg).length !== 0 &&
                <Feedback
                    color={msg.color}
                    message={msg.message}
                />
            }

            <Box
                component="main"
                flexGrow={1}
                height="100vh"
                overflow="auto"
                sx={{
                    backgroundColor: (theme) => theme.palette.grey[100]
                }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                    <Paper sx={{ p: 3 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={12} md={6}>
                                <img
                                    src={ShopAreaJPEG}
                                    style={{ width: "100%" }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    gap={2}
                                >
                                    <Typography color="#6A86C4" fontWeight={500}>Apartman adatai</Typography>
                                    
                                    <Typography color="#737373" fontSize={10} fontWeight={300}>
                                        Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor. Nam risus metus, tempor ut ante viverra, blandit vulputate augue. Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor. Nam risus metus, tempor ut ante viverra, blandit vulputate augue. 
                                    </Typography>

                                    <Box alignSelf="center">
                                        <ApIcon
                                            ap={newAp}
                                        />
                                    </Box>

                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        alignSelf="center"
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={() => setNewAp(prev => ({...prev, colorCode: "#95DE64"}))}
                                        >
                                            <FiberManualRecordIcon style={{ color: "#95DE64" }} />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => setNewAp(prev => ({...prev, colorCode: "#FFA940"}))}
                                        >
                                            <FiberManualRecordIcon style={{ color: "#FFA940" }} />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => setNewAp(prev => ({...prev, colorCode: "#1890FF"}))}
                                        >
                                            <FiberManualRecordIcon style={{ color: "#1890FF" }} />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => setNewAp(prev => ({...prev, colorCode: "#F759AB"}))}
                                        >
                                            <FiberManualRecordIcon style={{ color: "#F759AB" }} />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => setNewAp(prev => ({...prev, colorCode: "#F5222D"}))}
                                        >
                                            <FiberManualRecordIcon style={{ color: "#F5222D" }} />
                                        </IconButton>
                                    </Box>

                                    <Grow in={true} timeout={1000}>
                                        <OutlinedInput
                                            placeholder="apartman név"
                                            fullWidth
                                            value={newAp.name}
                                            onChange={(e) => setNewAp(prev => ({...prev, name: e.target.value}))}
                                            size="small"
                                        />
                                    </Grow>

                                    <Grow in={true} timeout={1500}>
                                        <OutlinedInput
                                            placeholder="apartman címe"
                                            fullWidth
                                            value={newAp.address}
                                            onChange={(e) => setNewAp(prev => ({...prev, address: e.target.value}))}
                                            size="small"
                                        />
                                    </Grow>

                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        alignSelf="flex-end"
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={() => navigate("/home")}
                                            sx={{
                                                borderRadius: 8
                                            }}
                                        >
                                            Vissza
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            disabled={Object.values(newAp).some(item => item === "")}
                                            onClick={handleCreateAp}
                                            sx={{
                                                borderRadius: 8,
                                                borderColor: "#6A86C4",
                                                color: "#6A86C4"
                                            }}
                                        >
                                            Létrehozás
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Box>
        </Dashboard>
    );
};

export default CreateApartman;