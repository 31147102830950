import { Avatar, Box, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';

import CurrencyText from "../../../../components/currencytext/CurrencyText";

const PurchaseItem = ({ index, purchase, click }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleShowList = () => {
        if(purchase.delivery_id) {
            click("delivery", purchase.serial_num);
        }
        else {
            click("invoice", purchase.serial_num);
        }
    };

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <TableRow>
            <TableCell>{index}</TableCell>

            <TableCell>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                >
                    <Tooltip
                        arrow
                        title={purchase.last_name+" "+purchase.first_name}
                    >
                        <Avatar
                            src={imageExists(apiUrl+"/"+purchase?.profile_pic) ? apiUrl+"/"+purchase?.profile_pic : null}
                        />
                    </Tooltip>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        alignItems="flex-start"
                    >
                        <Typography fontSize={12} fontWeight={300}>
                            {purchase.delivery_id ?
                                "ODBE (Szállítólevél)"
                            :
                                purchase.name
                            }
                        </Typography>

                        <Typography fontSize={10} fontWeight={300}>{purchase.serial_num}</Typography>
                    </Box>
                </Box>
            </TableCell>

            <TableCell>{purchase.date}</TableCell>

            <TableCell>{purchase.quantity+" "+purchase.unit}</TableCell>

            <TableCell>
                <CurrencyText val={(purchase.purchase_price).toFixed(2)} /> HUF
            </TableCell>

            <TableCell>
                <CurrencyText val={(purchase.purchase_price*(purchase.vat+1)).toFixed(2)} /> HUF
            </TableCell>

            <TableCell align="center">
                <IconButton
                    size="small"
                    onClick={handleShowList}
                >
                    <FolderOpenOutlinedIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default PurchaseItem;