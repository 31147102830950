import { useContext, useEffect, useState } from "react";

import useDebounce from "../../hooks/useDebounce";
import { DataContext } from "../../context/DataContext";

import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";
import TransferItem from "./content/TransferItem";

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: "100%" }}
        >
            {value === index && children}
        </div>
    );
};

const Transfers = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {user, setOpenOper, setMsg, setLoading} = useContext(DataContext);

    const [tab, setTab] = useState(0);
    const [search, setSearch] = useState("");
    const [itemsToShow, setItemsToShow] = useState(30);

    const [invoices, setInvoices] = useState([]);
    const [showInvoices, setShowInvoices] = useState([]);
    const [transfers, setTransfers] = useState({});

    const [openSummary, setOpenSummary] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getNewInvoices();
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            const filteredData = invoices.filter(item => rx.test(item.partner_name) || rx.test(item.serial_num));

            setItemsToShow(30);

            setShowInvoices(filteredData);
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, invoices], 500);

    const getNewInvoices = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/newinvoices", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ token: user.company.nav_token })
        });

        const data = await res.json();

        if(!data.success){
            getInvoices();
            return setMsg({
                color: data.noNew ? "success" : "error",
                message: data.message
            });
        }

        if(data.invoices.length !== 0) {
            return syncPartners(data.invoices);
        }

        getInvoices();
    };

    const syncPartners = async(invoices) => {
        const res = await fetch(apiUrl+"/inbound/sync/partners", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(invoices)
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        syncInvoices(invoices);
    };

    const syncInvoices = async(invoices) => {
        const res = await fetch(apiUrl+"/inbound/sync/invoices", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                invoices: invoices
            })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getInvoices();
    };

    const getInvoices = async() => {
        const res = await fetch(apiUrl+"/inbound/get/transfers/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoices(data.invoices.map(item => ({...item, selected: false})));

        setLoading(false);
    };

    const handleCheck = (invoice) => {
        setShowInvoices(prev => prev.map(item => {
            if(item.id === invoice.id) {
                return {...item, selected: !item.selected};
            }
            return item;
        }));
    };

    const handleSelectAll = (e) => {
        if(e.target.checked) {
            setShowInvoices(prev => prev.map(item => {
                return {...item, selected: true};
            }));
            return;
        }

        setShowInvoices(prev => prev.map(item => {
            return {...item, selected: false};
        }));
    };

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleTransferDialog = () => {
        const partnerGroup = showInvoices.filter(item => item.selected).reduce((group, x) => {
            (group[x["partner"]] = group[x["partner"]] || []).push(x);
            return group;
        }, {});

        setTransfers(partnerGroup);
        setOpenSummary(true);
    };

    const calculateSum = (arr) => {
        return arr.reduce((acc, obj) => {
            return acc + obj.sum;
        }, 0);
    };

    const handleTransfer = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/transfer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                invoices: showInvoices.filter(item => item.selected)
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres utalás"
        });

        setOpenSummary(false);
        setTab(1);
        getInvoices();
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={33}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Grid item xs={12}>
                <Tabs
                    variant="standard"
                    value={tab}
                    onChange={handleChange}
                >
                    <Tab
                        label="Rendezetlen"
                        sx={{
                            "&:hover": {
                                color: "#2278CF"
                            }
                        }}
                    />

                    <Tab
                        label="Rendezve"
                        sx={{
                            "&:hover": {
                                color: "#2278CF"
                            }
                        }}
                    />
                </Tabs>
            </Grid>

            <Grid item xs={12} md={6}>
                <OutlinedInput
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Keresés"
                    size="small"
                    sx={{ bgcolor: "#FFF" }}
                    fullWidth
                />
            </Grid>

            {tab === 0 &&
                <Grid
                    item
                    xs={12}
                    md={3}
                    marginLeft="auto"
                >
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleTransferDialog}
                        disabled={showInvoices.filter(item => item.selected).length === 0}
                    >
                        Utalás
                    </Button>
                </Grid>
            }

            <Dialog
                fullScreen={matches}
                open={openSummary}
                onClose={() => setOpenSummary(false)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            minWidth: 800
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Typography fontSize={20} fontWeight={600}>
                        Összegző
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Stack spacing={1}>
                        {Object.keys(transfers).map(key => {
                            return (
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                >
                                    <Typography flex={1} fontSize={16} fontWeight={500}>{transfers[key][0].partner_name}</Typography>
                                    <Typography flex={1} fontSize={16} fontWeight={500}>{calculateSum(transfers[key])} HUF</Typography>

                                    <Box flex={1} maxWidth={300}>
                                        <Typography>
                                            {transfers[key].map((item, i) => {
                                                if(transfers[key].length-1 === i) return item.serial_num;
                                                return item.serial_num+", ";
                                            })}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button
                        size="small"
                        onClick={() => setOpenSummary(false)}
                    >
                        Mégse
                    </Button>

                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleTransfer}
                    >
                        Véglegesítés
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={12}>
                <TabPanel value={tab} index={0}>
                    <TableContainer>
                        <Table component={Paper}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={showInvoices.filter(item => !item.arranged).slice(0, itemsToShow).length > 0 && showInvoices.filter(item => !item.arranged).slice(0, itemsToShow).filter(item => item.selected).length === showInvoices.filter(item => !item.arranged).slice(0, itemsToShow).length}
                                            indeterminate={showInvoices.filter(item => !item.arranged).slice(0, itemsToShow).filter(item => item.selected).length > 0 && showInvoices.filter(item => !item.arranged).slice(0, itemsToShow).filter(item => item.selected).length < showInvoices.filter(item => !item.arranged).slice(0, itemsToShow).length}
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                    <TableCell>Számlaszám</TableCell>
                                    <TableCell>Partner</TableCell>
                                    <TableCell>Összeg</TableCell>
                                    <TableCell>Fizetési határidő</TableCell>
                                    <TableCell>Státusz</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {showInvoices.filter(item => !item.arranged).slice(0, itemsToShow).map(item => {
                                    return (
                                        <TransferItem
                                            key={item.id}
                                            invoice={item}
                                            onCheck={handleCheck}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </Grid>

            <Grid item xs={12}>
                <TabPanel value={tab} index={1}>
                    <TableContainer>
                        <Table component={Paper}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={showInvoices.filter(item => item.arranged).slice(0, itemsToShow).length > 0 && showInvoices.filter(item => item.arranged).slice(0, itemsToShow).filter(item => item.selected).length === showInvoices.filter(item => item.arranged).slice(0, itemsToShow).length}
                                            indeterminate={showInvoices.filter(item => item.arranged).slice(0, itemsToShow).filter(item => item.selected).length > 0 && showInvoices.filter(item => item.arranged).slice(0, itemsToShow).filter(item => item.selected).length < showInvoices.filter(item => item.arranged).slice(0, itemsToShow).length}
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                    <TableCell>Számlaszám</TableCell>
                                    <TableCell>Partner</TableCell>
                                    <TableCell>Összeg</TableCell>
                                    <TableCell>Fizetési határidő</TableCell>
                                    <TableCell>Státusz</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {showInvoices.filter(item => item.arranged).slice(0, itemsToShow).map(item => {
                                    return (
                                        <TransferItem
                                            key={item.id}
                                            invoice={item}
                                            onCheck={handleCheck}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </Grid>

            <Grid item xs={12}>
                <Button fullWidth onClick={() => setItemsToShow(prev => prev+30)}>Mutass többet</Button>
            </Grid>
        </Grid>
    );
};

export default Transfers;