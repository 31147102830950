import { useContext, useEffect, useState } from "react";

import { motion } from 'framer-motion';
import { io } from "socket.io-client";

import { DataContext } from "../../../../context/DataContext";

import { Box, Grid, Grow } from "@mui/material";

import InvoiceHeader from "./content/header/InvoiceHeader";
import InvoiceBody from "./content/body/InvoiceBody";
import InvoiceFooter from "./content/footer/InvoiceFooter";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const InvoiceItem = ({ invoice, timeout, tags, payments, costplaces, onItemized, onRefresh, onReceived, onIncome }) => {
    const {user, setLoading, setMsg} = useContext(DataContext);

    const [invoicePayment, setInvoicePayment] = useState({});
    const [invoiceCostplace, setInvoiceCostplace] = useState({});
    const [invoiceTags, setInvoiceTags] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getTags();
    }, [invoice]);

    const getTags = () => {
        getPaymentMethod();
        getInvoiceCostplace();
        getInvoiceTags();
    };

    const getPaymentMethod = async() => {
        const res = await fetch(apiUrl+"/inbound/get/payment/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoicePayment(data.payment);
    };

    const getInvoiceCostplace = async() => {
        const res = await fetch(apiUrl+"/inbound/get/costplace/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceCostplace(data.costplace);
    };

    const getInvoiceTags = async() => {
        const res = await fetch(apiUrl+"/inbound/get/tags/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceTags(data.tags);

        setLoading(false);
    };

    const getInvoice = async(store) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/itemized", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: user.company.nav_token,
                invoiceNum: invoice.serial_num
            })
        });

        const data = await res.json();

        if(!data.success){
            getTags();
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        if(data.fromNav) {
            return saveInvoice(data.invoice, store);
        }

        return handleFiling(store);
    };

    const saveInvoice = async(invoiceData, store) => {
        const res = await fetch(apiUrl+"/inbound/itemized/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                serialNum: invoice.serial_num,
                itemized: invoiceData
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        handleFiling(store);
    };

    const handleFiling = async(store) => {
        const res = await fetch(apiUrl+"/inbound/filing", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                serialNum: invoice.serial_num,
                user: user.id,
                store: store.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres iktatás"
        });

        socket.emit("inbound_invoices", {id: user.company.id});

        onRefresh();
    };

    const handleTransfer = async() => {
        const res = await fetch(apiUrl+"/inbound/transfer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                invoices: [invoice]
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres utalás!"
        });

        socket.emit("inbound_invoices", {id: user.company.id});

        onRefresh();
    };

    return (
        <Grid item xs={12} md={4}>
            <Grow in={true} timeout={timeout}>
                <motion.div whileHover={{ scale: 1.02 }}>
                    <Box
                        border="1px solid #E1DEDE"
                        borderRadius={1}
                    >
                        <InvoiceHeader
                            invoice={({
                                ...invoice,
                                ...invoicePayment,
                                ...invoiceCostplace,
                                tags: invoiceTags
                            })}
                            tags={tags}
                            payments={payments}
                            costplaces={costplaces}
                            onRefresh={() => {
                                getTags();
                                onRefresh();
                            }}
                            onFiling={getInvoice}
                        />

                        <InvoiceBody
                            invoice={({
                                ...invoice,
                                ...invoicePayment,
                                ...invoiceCostplace,
                                tags: invoiceTags
                            })}
                            tags={tags}
                            payments={payments}
                            costplaces={costplaces}
                            onRefresh={() => {
                                getTags();
                                onRefresh();
                            }}
                            onItemized={onItemized}
                            onFiling={getInvoice}
                        />

                    <InvoiceFooter
                            invoice={({
                                ...invoice,
                                ...invoicePayment,
                                ...invoiceCostplace,
                                tags: invoiceTags
                            })}
                            tags={tags}
                            payments={payments}
                            costplaces={costplaces}
                            onRefresh={() => {
                                getTags();
                                onRefresh();
                            }}
                            onReceived={onReceived}
                            onFiling={getInvoice}
                            onIncome={onIncome}
                            onTransfer={handleTransfer}
                        />
                    </Box>
                </motion.div>
            </Grow>
        </Grid> 
    );
};

export default InvoiceItem;