import { useContext } from "react";

import { DataContext } from "../../../context/DataContext";

import { Grid } from "@mui/material";

import ShopItem from "../../../components/shopitem/ShopItem";

const ShopItems = ({ ops, onRefresh }) => {
    const {opComps, setOpenOper} = useContext(DataContext);

    const handleToOperation = (choosen) => {
        if(Object.keys(opComps).includes(choosen.operation.toString())) {
            setOpenOper(choosen.operation);
            return;
        }
    };
    
    return (
        <Grid
            item
            container
            spacing={2}
            xs={12}
            marginTop={2}
            marginLeft="auto"
            marginRight="auto"
        >
            {ops.map((item, index) => {
                if(item.seq !== null) {
                    return (
                        <ShopItem
                            key={item.id}
                            op={item}
                            onClick={() => handleToOperation(item)}
                            onRefresh={onRefresh}
                            timeout={index*400}
                        />
                    );
                }
            })}
        </Grid>
    );
};

export default ShopItems;