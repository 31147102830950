import { Box, Button, Dialog, DialogActions, DialogContentText, IconButton, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import StockItem from "./content/StockItem";

const StockDialog = ({ open, setOpen, stock }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <IconButton
                    onClick={() => setOpen(false)}
                    size="small"
                >
                    <ArrowBackIosIcon style={{ fontSize: 16 }} />
                </IconButton>

                <IconButton
                    size="small"
                >
                    <OpenInFullIcon style={{ fontSize: 16 }} />
                </IconButton>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                alignItems="center"
            >
                <DialogContentText fontSize={20}>
                    {stock[0]?.product}
                </DialogContentText>

                <DialogContentText fontSize={12}>{stock[0]?.barcode}</DialogContentText>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                alignItems="center"
                color="#000"
                width="100%"
                p={1}
            >
                <DialogContentText
                    fontSize={16}
                    fontWeight={700}
                    sx={{ alignSelf: "flex-start" }}
                >
                    Készlet adatok
                </DialogContentText>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                >
                    {stock.map(item => {
                        return (
                            <StockItem
                                key={item.id}
                                stockInfo={item}
                            />
                        );
                    })}
                </Box>
            </Box>

            <DialogActions>
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => setOpen(false)}
                >
                    Bezárás
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default StockDialog;