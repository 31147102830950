import { useContext, useEffect, useState } from "react";

import dayjs from "dayjs";

import { DataContext } from "../../../context/DataContext";

import { DateCalendar } from "@mui/x-date-pickers";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";
import ResPersonal from "./ResPersonal";

const ResDates = ({ ap, onClose }) => {
    const {setMsg, setLoading} = useContext(DataContext);

    const [resDates, setResDates] = useState([]);

    const [dates, setDates] = useState({
        from: dayjs(Date.now()),
        to: dayjs(Date.now())
    });

    const [personal, setPersonal] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getReservations();
    }, []);

    const getReservations = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/apartman/reservations/"+ap.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setResDates(data.dates);

        setLoading(false);
    };

    const disableDates = (date) => {
        const realDate = date.add(1, "day");
        return resDates.includes(dayjs(realDate, 'YYYY-MM-DD').toISOString().split("T")[0]);
    };

    const renderPersonal = () => {
        if(personal) {
            return (
                <ResPersonal
                    ap={ap}
                    dates={dates}
                    onClose={() => setPersonal(false)}
                />
            );
        }
    };

    return (
        <>
            {renderPersonal()}

            {!personal &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={35}
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1} alignItems="center">
                            <Typography color="#000">{dayjs(dates.from, 'YYYY-MM-DD').toISOString().split("T")[0]}</Typography>
                            
                            <DateCalendar
                                value={dates.from}
                                onChange={(val) => setDates(prev => ({...prev, from: val}))}
                                disablePast
                                shouldDisableDate={disableDates}
                                sx={{ color: "#000" }}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1} alignItems="center">
                            <Typography color="#000">{dayjs(dates.to, 'YYYY-MM-DD').toISOString().split("T")[0]}</Typography>

                            <DateCalendar
                                value={dates.to}
                                onChange={(val) => setDates(prev => ({...prev, to: val}))}
                                disablePast
                                shouldDisableDate={disableDates}
                                sx={{ color: "#000" }}
                            />
                        </Stack>
                    </Grid>

                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        width="100%"
                    >
                        <Button
                            variant="contained"
                            onClick={() => setPersonal(true)}
                        >
                            Tovább
                        </Button>
                    </Box> 
                </Grid>
            }
        </>
    );
};

export default ResDates;