import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, useMediaQuery, useTheme } from "@mui/material";

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

import DialogHeader from "../../tag/content/DialogHeader";
import Tag from "../../tag/Tag";

const FilingDialog = ({ invoice, payments, costplaces, open, onClose, onVerify, onRefresh }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog fullScreen={matches} open={open} onClose={onClose}>
            <DialogTitle>
                <DialogHeader invoice={invoice} />
            </DialogTitle>

            <DialogContent>
                <DialogContentText margin={2} align='center' fontSize={14}>
                    A rendezéshez az alábbi adatok még hiányoznak. Kérlek ezeket vezesd fel ezeket
                </DialogContentText>

                <Stack spacing={1}>
                    <Tag
                        type="deadline"
                        invoice={invoice}
                        tagIcon={invoice.payment_deadline ? <CalendarTodayOutlinedIcon /> : <ControlPointIcon />} 
                        tagLabel={invoice.payment_deadline ? invoice.payment_deadline : null}
                        tagTipLabel="Fizetési határidő"
                        onRefresh={onRefresh}
                    />

                    <Tag
                        type="payment"
                        invoice={invoice}
                        payments={payments}
                        tagIcon={<AddCardOutlinedIcon/>}
                        tagLabel={invoice.payment_name ? invoice.payment_name : null}
                        tagTipLabel="Fizetési mód"
                        onRefresh={onRefresh}
                    />

                    <Tag
                        type="costplace"
                        invoice={invoice}
                        costplaces={costplaces}
                        tagIcon={<PushPinOutlinedIcon />}
                        tagLabel={invoice.costplace_name ? invoice.costplace_name : null}
                        tagTipLabel="Költséghely"
                        onRefresh={onRefresh}
                    />
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Mégse</Button>
                <Button
                    onClick={onVerify}
                    autoFocus
                    disabled={!invoice.payment_deadline || !invoice.payment_name || !invoice.costplace_name}
                >
                    Rendezés
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilingDialog;