import { useEffect, useState } from "react";

import { Box, Button, Dialog, DialogActions, DialogContentText, Grow, IconButton, MenuItem, OutlinedInput, Select, styled, Switch, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
    width: 37,
    height: 21,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#1890FF',
                opacity: 1,
                border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff'
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 17,
        height: 17
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500
        })
    }
}));

const QuantityDialog = ({ open, setOpen, choosen, barcode, quantity, setQuantity, onNext }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const [unbox, setUnbox] = useState(false);
    const [unit, setUnit] = useState(choosen.unit ? choosen.unit : "DB");

    const [boxQuantity, setBoxQuantity] = useState(1);

    const [comment, setComment] = useState("");

    useEffect(() => {
        if(!unbox) {
            setUnit(choosen.unit ? choosen.unit : "DB");
            setBoxQuantity(1);
            return;
        }
        else {
            setUnit(choosen.unit ? choosen.unit : "DB");
        }
    }, [unbox]);

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <IconButton
                    onClick={() => setOpen(false)}
                    size="small"
                >
                    <ArrowBackIosIcon style={{ fontSize: 16 }} />
                </IconButton>

                <IconButton
                    size="small"
                >
                    <OpenInFullIcon style={{ fontSize: 16 }} />
                </IconButton>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                alignItems="center"
            >
                <DialogContentText fontSize={20}>
                    {choosen.itemName}
                </DialogContentText>

                <DialogContentText fontSize={12}>{barcode}</DialogContentText>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={16}>MENNYISÉG ELLENŐRZÉSE</DialogContentText>

                    <DialogContentText fontSize={12}>
                        Győződj meg róla, hogy a számlán szereplő mennyiség megérkezett. Amennyiben szükséges úgy bontsd tételekre a csomagolást.
                    </DialogContentText>
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    bgcolor="#13C2C2"
                    p={1}
                >
                    <Typography
                        color="#FFF"
                        fontSize={16}
                    >
                        ÉRKEZETT: {choosen.quantity+" "+choosen?.unit}
                    </Typography>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    width="95%"
                >
                    <IOSSwitch
                        checked={unbox}
                        onChange={(e) => setUnbox(e.target.checked)}
                    />

                    <Typography fontSize={12}>Megbontom a csomagot darabra</Typography>

                    <Tooltip>
                        <InfoIcon style={{ fontSize: 16, color: "#096DD9"}} />
                    </Tooltip>
                </Box>

                {unbox &&
                    <Grow in={true} timeout={800}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            width="95%"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                width="47%"
                            >
                                <DialogContentText fontSize={12}>Kiszerelési egység</DialogContentText>

                                <Select
                                    value={unit}
                                    onChange={(e) => setUnit(e.target.value)}
                                    size="small"
                                >
                                    {choosen.unit && <MenuItem value={choosen.unit}>{choosen.unit}</MenuItem>}
                                    <MenuItem value="Darab">Darab</MenuItem>
                                    <MenuItem value="Egység">Egység</MenuItem>
                                </Select>
                            </Box>

                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                width="47%"
                            >
                                <DialogContentText fontSize={12}>Egy csomagban van</DialogContentText>

                                <OutlinedInput
                                    type="number"
                                    value={boxQuantity}
                                    onChange={(e) => setBoxQuantity(e.target.value)}
                                    size="small"
                                    fullWidth
                                />
                            </Box>
                        </Box>
                    </Grow>
                }

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={12}>Számolt</DialogContentText>

                    <OutlinedInput
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        size="small"
                        fullWidth
                        endAdornment={
                            <Box
                                p={0.5}
                                sx={{
                                    backgroundColor: "#8C8C8C",
                                    marginRight: -1.6,
                                    borderTopRightRadius: 2,
                                    borderBottomRightRadius: 2,
                                    height: 30
                                }}
                            >
                                <Typography color="#FFF" fontSize={14} marginTop={0.5}>
                                    {choosen.unit ? choosen.unit : "DB"}
                                </Typography>
                            </Box>
                        }
                        sx={{
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#8C8C8C"
                            },
                            "&:hover": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#8C8C8C"
                                }
                            },
                            "&:focused": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#8C8C8C"
                                }
                            }
                        }}
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={12}>Eltérés</DialogContentText>

                    <OutlinedInput
                        value={(-Number(choosen.quantity)*Number(boxQuantity))+Number(quantity)}
                        readOnly
                        size="small"
                        fullWidth
                        endAdornment={
                            ((-Number(choosen.quantity)*Number(boxQuantity))+Number(quantity)) === 0 ?
                                <CheckCircleOutlineIcon style={{ color: "#13C2C2" }} />
                            :
                                <InfoIcon style={{ color: "#FA541C" }} />
                        }
                        sx={((-Number(choosen.quantity)*Number(boxQuantity))+Number(quantity)) === 0 && {
                            color: "#13C2C2",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#13C2C2"
                            },
                            "&:hover": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#13C2C2"
                                }
                            },
                            "&:focused": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#13C2C2"
                                }
                            }
                        }}
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={12}>Megjegyzés</DialogContentText>

                    <OutlinedInput
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        size="small"
                        fullWidth
                    />
                </Box>
            </Box>

            <DialogActions>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        sx={{
                            color: "#262626",
                            borderColor: "#D9D9D9",
                            "&:hover": {
                                color: "#262626",
                                borderColor: "#D9D9D9"
                            }
                        }}
                    >
                        Vissza
                    </Button>

                    <Button
                        variant="contained"
                        disabled={quantity === ""}
                        onClick={() => onNext(boxQuantity, comment, unit)}
                        sx={{
                            bgcolor: "#8C8C8C",
                            "&:hover": {
                                bgcolor: "#8C8C8C"
                            }
                        }}
                    >
                        Tovább
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default QuantityDialog;