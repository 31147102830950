import { useContext, useEffect, useState } from "react";

import axios from "axios";

import { DataContext } from "../../../context/DataContext";
import { getUserCompaniesStores, getUserStores } from "../../../services/Api";

import { Avatar, Box, Divider, FormLabel, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, TextField, Typography } from "@mui/material";

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import OpHeader from "../../../components/opheader/OpHeader";
import StoreIcon from "../../../layouts/stores/content/StoreIcon";
import Personal from "./content/Personal";
import Company from "./content/Company";
import Permissions from "./content/Permissions";

const EditUser = ({ userInfo, onClose, onSave, getUsers }) => {
    const {user, setUser, setLoading, setMsg} = useContext(DataContext);

    const [userAvatar, setUserAvatar] = useState();

    const [selected, setSelected] = useState(0);
    const [compStores, setCompStores] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        setCompStores([]);

        getUserCompaniesStores(userInfo.id).then(compData => {
            getUserStores(userInfo.id).then(storeData => {
                compData.map(item => {
                    if(storeData.filter(s => s.id === item.id).length !== 0) {
                        return setCompStores(prev => ([...prev, {...item, active: true}]));
                    }
                    else {
                        return setCompStores(prev => ([...prev, {...item, active: false}]));
                    }
                });
            });
        });

        setLoading(false);
    }, []);

    const handleClick = async(store) => {
        if(store.active) {
            const res = await fetch(apiUrl+"/user/store/delete", {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: userInfo.id,
                    store: store.id
                })
            });

            const data = await res.json();

            if(!data.success) {
                return setMsg({
                    color: "error",
                    message: data.message
                });
            }
        }
        else {
            const res = await fetch(apiUrl+"/user/store/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: userInfo.id,
                    store: store.id
                })
            });

            const data = await res.json();

            if(!data.success) {
                return setMsg({
                    color: "error",
                    message: data.message
                });
            }
        }

        setCompStores(prev => prev.map(item => {
            if(item.id === store.id) {
                return {...item, active: !item.active};
            }
            return item;
        }));
    };

    const uploadAvatar = async(e) => {
        const file = new FormData();
        file.append("file", e.target.files[0]);

        axios.post(apiUrl+"/upload/avatar/"+userInfo.id, file)
        .then(res => {
            if(userInfo.id === user.id) {
                return updateUserAvatar(res.data.filename);
            }

            setUserAvatar(res.data.filename);
            getUsers();
        });
    };

    const updateUserAvatar = async(newAvatar) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/user/avatar/"+user.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setUser(prev => ({...prev, profile_pic: data.profile_pic}));

        setUserAvatar(newAvatar);
        getUsers();

        setLoading(false);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OpHeader
                    operation={14}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Paper
                    sx={{ p: 2 }}
                >
                    <Stack spacing={1}>
                        <Typography fontSize={16} fontWeight={500}>Profil</Typography>

                        <Divider />

                        <Stack alignItems="center">
                            <FormLabel
                                htmlFor="avatar"
                                sx={{
                                    position: 'relative',
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    '&:hover .MuiBox-root': { opacity: 1 },
                                    cursor: 'pointer'
                                }}
                            >
                                <Avatar
                                    src={apiUrl+"/"+(userAvatar ? userAvatar : userInfo.profile_pic)}
                                    sx={{ width: 124, height: 124, alignSelf: "center" }}
                                />

                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        backgroundColor: 'rgba(0,0,0,.65)',
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Stack spacing={0.5} alignItems="center">
                                        <CameraAltOutlinedIcon style={{ color: "#FFF" }} />
                                        
                                        <Typography sx={{ color: '#FFF' }} variant="caption">
                                            Feltöltés
                                        </Typography>
                                    </Stack>
                                </Box>
                            </FormLabel>

                            <TextField
                                type="file"
                                id="avatar"
                                variant="outlined"
                                sx={{ display: 'none' }}
                                onChange={uploadAvatar}
                            />
                        </Stack>

                        <Typography
                            fontSize={16}
                            fontWeight={500}
                            sx={{ alignSelf: "center" }}
                        >
                            {userInfo.last_name+" "+userInfo.first_name}
                        </Typography>

                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="#8C8C8C"
                            sx={{ alignSelf: "center" }}
                        >
                            {userInfo.rank_name}
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={1}
                        >
                            <ShoppingCartOutlinedIcon style={{ color: "#8C8C8C" }} />

                            <Typography
                                fontSize={14}
                                fontWeight={400}
                                color="#8C8C8C"
                            >
                                Eladótér hozzáférések
                            </Typography>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            height={40}
                        >
                            {compStores.map(item => {
                                return (
                                    <StoreIcon
                                        key={item.id}
                                        store={item}
                                        type="checkbox"
                                        val={item.active}
                                        onClick={handleClick}
                                    />
                                );
                            })}
                        </Box>

                        <List>
                            <ListItem>
                                <ListItemButton
                                    selected={selected === 0}
                                    onClick={(e) => setSelected(0)}
                                >
                                    <ListItemIcon><PersonOutlineOutlinedIcon /></ListItemIcon>
                                    <ListItemText>Személyes adatok</ListItemText>
                                </ListItemButton>
                            </ListItem>

                            <ListItem>
                                <ListItemButton
                                    selected={selected === 1}
                                    onClick={(e) => setSelected(1)}
                                >
                                    <ListItemIcon><DescriptionOutlinedIcon /></ListItemIcon>
                                    <ListItemText>Vállalati adatok</ListItemText>
                                </ListItemButton>
                            </ListItem>

                            <ListItem>
                                <ListItemButton
                                    selected={selected === 2}
                                    onClick={(e) => setSelected(2)}
                                >
                                    <ListItemIcon><TaskOutlinedIcon /></ListItemIcon>
                                    <ListItemText>Jogosultságok</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Stack>
                </Paper>
            </Grid>

            <Grid item xs={12} md={8}>
                <Paper
                    sx={{ p: 2 }}
                >
                    {selected === 0 && <Personal userInfo={userInfo} onClose={onClose} onSave={onSave} />}
                    {selected === 1 && <Company userInfo={userInfo} onClose={onClose} onSave={onSave} />}
                    {selected === 2 && <Permissions userInfo={userInfo} onClose={onClose} onSave={onSave} />}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default EditUser;