import { Box, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';

import LogoSVG from '../../../../assets/logo.svg';

const NavHeader = ({ open }) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Toolbar>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                gap={3}
                width={isMdDown ? 200 : "auto"}
            >
                <img
                    src={LogoSVG}
                    alt="logo"
                    style={{ width: 32, height: 32 }}
                />

                {open ? <Typography variant="h6">myTrafix</Typography> : null}
            </Box>
        </Toolbar>
    );
};

export default NavHeader;