import { Box, Button, Grid, Paper, Typography } from "@mui/material";

import ShopAreaJPEG from "../../../../assets/home/modules/shoparea.jpeg";

const TypeItem = ({ typeInfo, onChoose }) => {
    return (
        <Grid
            container
            item
            xs={12}
            md={6}
        >
            <Paper sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            gap={2}
                        >
                            <Typography color="#6A86C4" fontWeight={500}>{typeInfo.name}</Typography>
                            
                            <Typography color="#737373" fontSize={10} fontWeight={300}>
                                {typeInfo.description}
                            </Typography>

                            <Button
                                variant="outlined"
                                sx={{
                                    alignSelf: "flex-end",
                                    borderRadius: 8,
                                    borderColor: "#6A86C4",
                                    color: "#6A86C4"
                                }}
                                onClick={onChoose}
                            >
                                Kiválaszt
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <img
                            src={ShopAreaJPEG}
                            style={{ width: "100%" }}
                            alt="type_img"
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default TypeItem;