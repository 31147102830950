import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";
import { getUserOperations } from "../../../services/Api";

import { Box, Button, Grid, Paper, Typography } from "@mui/material";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import OpHeader from "../../../components/opheader/OpHeader";
import InvitePerm from "./InvitePerm";
import RankItem from "./content/RankItem";

const InviteRank = ({ email }) => {
    const {user, setOpenOper, setMsg, setLoading} = useContext(DataContext);

    const [ranks, setRanks] = useState([]);
    const [permPage, setPermPage] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getRanks();
    }, []);

    const getRanks = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/ranks/company/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setRanks(data.ranks);

        setLoading(false);
    };

    const handleNewRank = () => {
        getUserOperations(user.id).then(data => {
            if(data.filter(item => Number(item.operation) === 30).length === 0) {
                return setMsg({
                    color: "error",
                    message: "Nincs jogosultságod a művelethez!"
                });
            }
            else {
                setOpenOper(30);
            }
        });
    };

    const renderPerm = () => {
        if(JSON.stringify(permPage) !== "{}") {
            return (
                <InvitePerm
                    rank={permPage}
                    email={email}
                    onBack={() => setPermPage({})}
                />
            );
        }
    };

    return (
        <>
            {renderPerm()}

            {JSON.stringify(permPage) === "{}" &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={29}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component={Paper}
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            padding={2}
                        >
                            <Typography fontSize={16} fontWeight={500} color="#6489E2">
                                Felhasználó rangja
                            </Typography>

                            <Button
                                size="small"
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={handleNewRank}
                                sx={{
                                    color: "#6489E2",
                                    alignSelf: "flex-start"
                                }}
                            >
                                Új rang hozzáadása
                            </Button>

                            <Typography fontSize={12} color="#737373">
                                Add meg a meghívandó felhasználó rangját
                            </Typography>

                            <Grid container spacing={2} sx={{ marginTop: 5 }}>
                                {ranks.map(item => {
                                    return (
                                        <Grid
                                            item
                                            key={item.id}
                                            xs={12}
                                            md={3}
                                        >
                                            <RankItem
                                                rank={item}
                                                onChoose={() => setPermPage(item)}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default InviteRank;