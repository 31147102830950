import { useRef } from "react";

import { Box, Grid, InputAdornment, TextField, Typography } from "@mui/material";

import CurrencyText from "../currencytext/CurrencyText";

const PcsTable = ({ pcs, setPcs, modify, onEnter }) => {
    const refs = {
        ref20000: useRef(), 
        ref10000: useRef(), 
        ref5000: useRef(), 
        ref2000: useRef(), 
        ref1000: useRef(), 
        ref500: useRef(), 
        ref200: useRef(), 
        ref100: useRef(), 
        ref50: useRef(), 
        ref20: useRef(), 
        ref10: useRef(), 
        ref5: useRef(), 
    };

    return (
        <Grid container item xs={12} md={8} spacing={3}>
            <Grid item xs={12}>
                <Typography fontSize={12} fontWeight={700} color="#000">Címletjegyzék</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={4}
                    color="#000"
                >
                    <TextField
                        value={pcs.huf20000}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf20000: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref20000}
                        variant="filled"
                        label="20 000 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref10000.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf20000*20000} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={pcs.huf10000}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf10000: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref10000}
                        variant="filled"
                        label="10 000 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref5000.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf10000*10000} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={pcs.huf5000}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf5000: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref5000}
                        variant="filled"
                        label="5 000 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref2000.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf5000*5000} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={pcs.huf2000}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf2000: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref2000}
                        variant="filled"
                        label="2 000 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref1000.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf2000*2000} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={pcs.huf1000}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf1000: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref1000}
                        variant="filled"
                        label="1 000 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref500.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf1000*1000} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={pcs.huf500}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf500: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref500}
                        variant="filled"
                        label="500 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref200.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf500*500} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />
                </Box>
            </Grid>

            <Grid item xs={12} md={6}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={4}
                    color="#000"
                >
                    <TextField
                        value={pcs.huf200}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf200: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref200}
                        variant="filled"
                        label="200 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref100.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf200*200} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={pcs.huf100}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf100: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref100}
                        variant="filled"
                        label="100 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref50.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf100*100} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={pcs.huf50}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf50: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref50}
                        variant="filled"
                        label="50 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref20.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf50*50} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={pcs.huf20}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf20: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref20}
                        variant="filled"
                        label="20 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref10.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf20*20} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={pcs.huf10}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf10: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref10}
                        variant="filled"
                        label="10 HUF"
                        size="small"
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                refs.ref5.current.focus();
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf10*10} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />

                    <TextField
                        value={pcs.huf5}
                        onChange={(e) => {
                            if(modify) {
                                if(!isNaN(+e.target.value)) {
                                    setPcs(prev => ({...prev, huf5: e.target.value}))
                                }
                            }
                        }}
                        inputRef={refs.ref5}
                        variant="filled"
                        label="5 HUF"
                        size="small"
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <CurrencyText val={pcs.huf5*5} />
                                        <Typography>HUF</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default PcsTable;