import { useContext, useRef, useState } from "react";

import { motion } from 'framer-motion';

import { DataContext } from "../../../../../../context/DataContext";

import { Typography, Box, Button } from "@mui/material";

import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import ApprovalRoundedIcon from '@mui/icons-material/ApprovalRounded';

import OpsPopper from "./content/OpsPopper";
import FilingDialog from "./content/FilingDialog";

const InvoiceFooter = ({ invoice, payments, costplaces, onRefresh }) => {
    const {setMsg, setLoading} = useContext(DataContext);

    const [open, setOpen] = useState(false);

    const [opsOpen, setOpsOpen] = useState(false);

    const anchorRef = useRef(null);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleFiling = async() => {
        if(!invoice.payment_deadline || !invoice.payment_method || !invoice.costplace) {
            return setOpen(true);
        }

        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/filing", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ serialNum: invoice.serial_num })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeresen rendezve"
        });

        onRefresh();
    };

    const handleToggle = () => {
        setOpsOpen(prev => !prev);
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            padding={1}
            boxSizing="border-box"
        >
            {!invoice.status &&
                <motion.div
                    whileHover={{ scale: 1.07 }}
                    whileTap={{ scale: 0.93 }}
                >
                    <Button
                        variant="contained"
                        onClick={handleFiling}
                        sx={{
                            width: "fit-content",
                            height: "fit-content",
                            color: "#FFF",
                            borderRadius: 1,
                            border: "1px solid var(--Polar-Green-4, #95DE64)",
                            background: "linear-gradient(180deg, #A0E373 0%, #77C157 100%)",
                            boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.40)"
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={0.5}
                        >
                            <ApprovalRoundedIcon style={{ fontSize: 12 }} />
        
                            <Typography
                                fontSize={9}
                                fontWeight={400}
                            >
                                Rendezés
                            </Typography>
                        </Box>
                    </Button>
                </motion.div>
            }

            <FilingDialog
                invoice={invoice}
                payments={payments}
                costplaces={costplaces}
                open={open}
                onClose={() => setOpen(false)}
                onVerify={handleFiling}
                onRefresh={onRefresh}
            />

            <motion.div
                whileHover={{ scale: 1.07 }}
                whileTap={{ scale: 0.93 }}
            >
                <Button
                    ref={anchorRef}
                    aria-controls={opsOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    sx={{
                        width: "fit-content",
                        height: "fit-content",
                        color: "#FFF",
                        borderRadius: 1,
                        border: "1px solid var(--Polar-Green-4, #95DE64)",
                        background: "linear-gradient(180deg, #A0E373 0%, #77C157 100%)",
                        boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.40)"
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={0.5}
                    >
                        <FileOpenOutlinedIcon style={{ fontSize: 12 }} />

                        <Typography
                            fontSize={9}
                            fontWeight={400}
                        >
                            Műveletek
                        </Typography>
                    </Box>
                </Button>
            </motion.div>

            <OpsPopper
                open={opsOpen}
                onClose={() => setOpsOpen(false)}
            />
        </Box>
    );
};

export default InvoiceFooter;