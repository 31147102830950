import { useContext, useEffect, useState } from "react";

import { motion } from 'framer-motion';

import { DataContext } from "../../context/DataContext";
import { isPcsEmpty } from "../../services/Pcs";
import { getStoreStatus } from "../../services/Api";

import { Box, Button, Grid, ListItemAvatar, Paper, Typography } from "@mui/material";

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import StoreIcon from "../../layouts/stores/content/StoreIcon";

import TablePcs from "../../components/tablepcs/TablePcs";
import OpHeader from "../../components/opheader/OpHeader";
import DayopenVerify from "./DayopenVerify";
import CurrencyText from "../../components/currencytext/CurrencyText";

const DayopenPcs = ({ store, onClose }) => {
    const {setMsg, setLoading} = useContext(DataContext);

    const [pcs, setPcs] = useState({
        huf20000: 0,
        huf10000: 0,
        huf5000: 0,
        huf2000: 0,
        huf1000: 0,
        huf500: 0,
        huf200: 0,
        huf100: 0,
        huf50: 0,
        huf20: 0,
        huf10: 0,
        huf5: 0,
        sum: 0
    });

    const [prevSum, setPrevSum] = useState(0);

    const [openVerify, setOpenVerify] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        getStoreStatus(store.name).then(item => {
            setPrevSum(item.sum);
        });

        setLoading(false);
    }, []);

    useEffect(() => {
        calculateSum();
    }, [pcs.huf5, pcs.huf10, pcs.huf20, pcs.huf50, pcs.huf100, pcs.huf200, pcs.huf500, pcs.huf1000,
        pcs.huf2000, pcs.huf5000, pcs.huf10000, pcs.huf20000]);


    const calculateSum = () => {
        const sum = ((20000*pcs.huf20000) + (10000*pcs.huf10000) + (5000*pcs.huf5000) + (2000*pcs.huf2000) + 
        (1000*pcs.huf1000) + (500*pcs.huf500) + (200*pcs.huf200) + (100*pcs.huf100) + 
        (50*pcs.huf50) + (20*pcs.huf20) + (10*pcs.huf10) + (5*pcs.huf5));

        setPcs(prevPcs => ({...prevPcs, sum: sum}));
    };

    const calculateDiff = () => {
        return pcs.sum-prevSum;
    }

    const handleGetDayClose = async() => {
        const res = await fetch(apiUrl+"/store/prevday", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                store: store.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        if(data.prev){
            setPcs({
                huf5: data.prev.huf_5_pcs,
                huf10: data.prev.huf_10_pcs,
                huf20: data.prev.huf_20_pcs,
                huf50: data.prev.huf_50_pcs,
                huf100: data.prev.huf_100_pcs,
                huf200: data.prev.huf_200_pcs,
                huf500: data.prev.huf_500_pcs,
                huf1000: data.prev.huf_1000_pcs,
                huf2000: data.prev.huf_2000_pcs,
                huf5000: data.prev.huf_5000_pcs,
                huf10000: data.prev.huf_10000_pcs,
                huf20000: data.prev.huf_20000_pcs,
                sum: data.prev.sum
            });
        }
    };

    const handleNext = () => {
        if(isPcsEmpty(pcs)){
            return setMsg({
                color: "error",
                message: "Minden mező kitöltése kötelező"
            });
        }

        setMsg({});
        setOpenVerify({
            ...store,
            pcs: pcs,
            prevSum: prevSum
        });
    };

    const renderVerify = () => {
        if(JSON.stringify(openVerify) !== "{}") {
            return (
                <DayopenVerify
                    store={openVerify}
                    onClose={() => setOpenVerify({})}
                    onDone={onClose}
                    onBack={onClose}
                />
            );
        }
    };

    return (
        <>
            {renderVerify()}

            {JSON.stringify(openVerify) === "{}" &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={1}
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Typography>Címletjegyzék</Typography>

                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <ListItemAvatar>
                                    <StoreIcon store={store} />
                                </ListItemAvatar>

                                <Box
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Typography variant="h6" color="#262626">
                                        TOTAL: <CurrencyText val={pcs.sum} /> HUF
                                    </Typography>
                                    <Typography variant="subtitle2" color="#8C8C8C">Eltérés: <CurrencyText val={calculateDiff()} /> HUF</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    <TablePcs
                        pcs={pcs}
                        setPcs={setPcs}
                        onEnter={handleNext}
                    />

                    <Grid item xs={6}>
                        <Button onClick={handleGetDayClose} sx={{ color: "#8C8C8C" }}>
                            <FileCopyOutlinedIcon />
                            <Typography fontSize={12} marginLeft={2}>Előző napi zárás másolása</Typography>
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" gap={2}>
                            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    onClick={onClose}
                                >
                                    <ArrowBackIosIcon fontSize="small" />
                                    <Typography fontSize={12} marginLeft={1}>Vissza</Typography>
                                </Button>
                            </motion.div>

                            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={handleNext}
                                >
                                    <Typography fontSize={12} marginRight={1}>Tovább</Typography>
                                    <ArrowForwardIosIcon fontSize="small" />
                                </Button>
                            </motion.div>
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default DayopenPcs;