import { useContext, useState } from "react";

import dayjs from "dayjs";
import { io } from "socket.io-client";
import { motion } from 'framer-motion';

import useDebounce from "../../../../../../../hooks/useDebounce";
import { DataContext } from "../../../../../../../context/DataContext";

import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, OutlinedInput, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

import { DateCalendar } from "@mui/x-date-pickers";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

import DialogHeader from "../../tag/content/DialogHeader";
import FilingItem from "./content/FilingItem";
import PaymentItem from "./content/PaymentItem";
import StoreDialog from "./StoreDialog";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const FilingDialog = ({ invoice, payments, costplaces, open, type, onClose, onVerify, onRefresh }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {user, setMsg, setLoading} = useContext(DataContext);

    const [storeOpen, setStoreOpen] = useState(false);

    const [deadline] = useState(dayjs());

    const [searchTag, setSearchTag] = useState("");
    const [showTags, setShowTags] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useDebounce(() => {
        try {
            const rx = new RegExp(`${searchTag}`,'i');
            const filteredData = costplaces?.filter(item => rx.test(item.name));

            setShowTags(filteredData);
        }
        catch(err) {
            setSearchTag("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [searchTag], 500);

    const handlePayment = async(id) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/set", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: "payment",
                invoice: invoice,
                company: user.company.id,
                user: user.id,
                newData: id,
                tagId: null
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        socket.emit("inbound_invoices", {id: user.company.id});

        setLoading(false);

        setMsg({
            color: "success",
            message: "Sikeres művelet"
        });

        onRefresh();
    };

    const handleDeadline = async(newDeadline) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/set", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: "deadline",
                invoice: invoice,
                company: user.company.id,
                user: user.id,
                newData: newDeadline,
                tagId: null
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        socket.emit("inbound_invoices", {id: user.company.id});

        setLoading(false);

        setMsg({
            color: "success",
            message: "Sikeres művelet"
        });

        onRefresh();
    };

    const handleCostplace = async(costplaceName) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/set", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: "costplace",
                invoice: invoice,
                company: user.company.id,
                user: user.id,
                newData: costplaceName,
                tagId: null
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        socket.emit("inbound_invoices", {id: user.company.id});

        setLoading(false);

        setMsg({
            color: "success",
            message: "Sikeres művelet"
        });

        onRefresh();
    };

    return (
        <>
            <Dialog
                fullScreen={matches}
                open={open}
                onClose={onClose}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            minWidth: 600,
                            height: "100%"
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <IconButton
                            onClick={onClose}
                            size="small"
                        >
                            <ArrowBackIosIcon style={{ fontSize: 16 }} />
                        </IconButton>

                        <Typography
                            fontSize={12}
                            fontWeight={700}
                        >
                            IKTATÁS
                        </Typography>

                        <IconButton
                            size="small"
                        >
                            <OpenInFullIcon style={{ fontSize: 16 }} />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogHeader invoice={invoice} />

                <DialogContent>
                    <Stack spacing={2}>
                        <FilingItem
                            title="FIZETÉSI MÓD KIVÁLASZTÁSA"
                            subtitle="Válaszd ki a fizetési módot."
                            filled={invoice.payment_name ? true : false}
                            def={type === "payment" ? true : false}
                            chip={
                                <Chip
                                    size="small"
                                    icon={<AccountBalanceOutlinedIcon />}
                                    label={invoice.payment_name}
                                    sx={{ 
                                        backgroundColor: "#E3E3E3",
                                        borderColor: "#E3E3E3", 
                                        borderRadius: 1,
                                        fontSize: 10,
                                        fontWeight: 400
                                    }}
                                />
                            }
                            dropdown={
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={1}
                                    width="100%"
                                >
                                    {payments?.map(item => {
                                        return (
                                            <PaymentItem
                                                key={item.id}
                                                payment={item}
                                                onSelect={handlePayment}
                                            />
                                        );
                                    })}
                                </Box>
                            }
                        />

                        <Divider />

                        <FilingItem
                            title="FIZETÉSI HATÁRIDŐ MEGADÁSA"
                            subtitle="Állítsd be a számlához tartozó fizetési határidőt."
                            filled={invoice.payment_deadline ? true : false}
                            def={type === "deadline" ? true : false}
                            chip={
                                <Chip
                                    size="small"
                                    icon={<CalendarTodayOutlinedIcon />}
                                    label={invoice.payment_deadline}
                                    sx={{ 
                                        backgroundColor: "#E3E3E3",
                                        borderColor: "#E3E3E3", 
                                        borderRadius: 1,
                                        fontSize: 10,
                                        fontWeight: 400
                                    }}
                                />
                            }
                            dropdown={
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    width="100%"
                                >
                                    <DateCalendar
                                        value={deadline}
                                        onChange={(newDeadline) => handleDeadline(newDeadline)}
                                        sx={{ color: "#000" }}
                                    />
                                </Box>
                            }
                        />

                        <Divider />

                        <FilingItem
                            title="KÖLTSÉGHELY / KÖLTSÉGTÉTEL MEGADÁSA"
                            subtitle="Válaszd ki a számlához tartozó költséghelyet és költség típust. Későbbi kimutatások során rendkívül hasznos lesz."
                            filled={invoice.costplace_name ? true : false}
                            def={type === "costplace" ? true : false}
                            chip={
                                <Chip
                                    size="small"
                                    icon={<BookmarkBorderOutlinedIcon />}
                                    label={invoice.costplace_name}
                                    sx={{ 
                                        backgroundColor: "#E3E3E3",
                                        borderColor: "#E3E3E3", 
                                        borderRadius: 1,
                                        fontSize: 10,
                                        fontWeight: 400
                                    }}
                                />
                            }
                            dropdown={
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                    >
                                        <OutlinedInput
                                            value={searchTag}
                                            onChange={(e) => setSearchTag(e.target.value)}
                                            placeholder="Költséghely kersés / létrehozás"
                                            size="small"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setSearchTag("");
                                                            handleCostplace(searchTag);
                                                        }}
                                                        disabled={!searchTag}
                                                    >
                                                        <AddCircleOutlineIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            fullWidth
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 300
                                            }}
                                        />
                                    </Box>

                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        flexWrap="wrap"
                                        gap={1}
                                        marginTop={2}
                                        width="100%"
                                    >
                                        {showTags?.map(item => {
                                            return (
                                                <Tooltip
                                                    key={item.id}
                                                    onClick={() => handleCostplace(item.name)}
                                                >
                                                    <Chip
                                                        size="small"
                                                        icon={<BookmarkBorderOutlinedIcon />}
                                                        label={item.name}
                                                        clickable={true}
                                                        sx={{ 
                                                            backgroundColor: "#E3E3E3",
                                                            borderColor: "#E3E3E3", 
                                                            borderRadius: 1,
                                                            fontSize: 10,
                                                            fontWeight: 400
                                                        }}
                                                    />
                                                </Tooltip>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            }
                        />
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={onClose}
                            sx={{
                                fontSize: 12,
                                fontWeight: 300,
                                textTransform: "none",
                                color: "#8C8C8C",
                                borderColor: "#D9D9D9",
                                "&:hover": {
                                    borderColor: "#8C8C8C",
                                    color: "#8C8C8C",
                                    bgcolor: "#FFF"
                                }
                            }}
                        >
                            Vissza
                        </Button>
                    </motion.div>
                    
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => setStoreOpen(true)}
                            disabled={!invoice.payment_deadline || !invoice.payment_name || !invoice.costplace_name}
                            sx={{
                                fontSize: 12,
                                fontWeight: 300,
                                textTransform: "none",
                                color: "#FFF",
                                bgcolor: "#7DE053",
                                "&:hover": {
                                    color: "#FFF",
                                    bgcolor: "#69BA47"
                                },
                                "&.Mui-disabled": {
                                    color: "#FFF",
                                    bgcolor: "#7DE053",
                                    opacity: 0.7
                                }
                            }}
                        >
                            Tovább
                        </Button>
                    </motion.div>
                </DialogActions>
            </Dialog>

            <StoreDialog
                open={storeOpen}
                onClose={() => setStoreOpen(false)}
                invoice={invoice}
                onVerify={onVerify}
            />
        </>
    );
};

export default FilingDialog;