import { cloneElement, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { DataContext } from '../../../../../../context/DataContext';

import { Chip, Tooltip, Zoom } from '@mui/material';

import InputDialog from './content/InputDialog';

const Tag = ({ invoice, payments, costplaces, type, tagId, tagLabel, tagIcon, tagTipLabel, onRefresh }) => {
    const iconColored = tagIcon && !tagLabel ? cloneElement(tagIcon, {
        style: { color: "#F759AB" }
    }) : null;

    const {user, setMsg, setLoading} = useContext(DataContext);

    const [newTag, setNewTag] = useState(null);
    const [open, setOpen] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setNewTag(() => {
            if(type === "deadline") {
                if(tagLabel) {
                    return dayjs(tagLabel);
                }
                return dayjs();
            }
            else if(type === "payment") {
                return invoice?.payment_id;
            }
            else if(type === "costplace") {
                return invoice?.costplace_id;
            }
            else if(type === "tag") {
                return tagLabel;
            }
        });
    }, [open]);

    const handleVerify = async() => {
        setOpen(false);
        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/set", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: type,
                invoice: invoice,
                user: user.id,
                newData: newTag,
                tagId: type === "tag" ? tagId : null
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres művelet"
        });

        onRefresh();
    };

    const handleDelete = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/tag/delete", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: type,
                invoice: invoice,
                user: user.id,
                tagId: type === "tag" ? tagId : null
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres művelet"
        });

        onRefresh();
    };

    const getInvoice = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/itemized", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ invoiceNum: invoice.serial_num })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        const grossSum = Math.round(data.invoice.reduce((acc, obj) => {
            return acc + (obj.unitPrice * obj.quantity);
        }, 0));

        const netSum = Math.round(data.invoice.reduce((acc, obj) => {
            return acc + ((obj.unitPrice - (obj.unitPrice * obj.vat)) * obj.quantity);
        }, 0));

        saveSumData(grossSum, netSum);
    };

    const saveSumData = async(gross, net) => {
        const res = await fetch(apiUrl+"/outbound/set/sum", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                invoiceId: invoice.id,
                gross: gross,
                net: net
            })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres kalkuláció"
        });

        onRefresh();

        setLoading(false);
    };

    const handleOpen = () => {
        if(type === "calculate") {
            getInvoice();
        }
        else if(type !== "record" && type !== "delivery") {
            setOpen(true);
        }
    };

    if(tagLabel) {
        return ( 
            <>
                <Tooltip
                    arrow
                    title={tagTipLabel} 
                    TransitionComponent={Zoom}
                    onClick={() => (((type !== "record" && type !== "delivery") && !invoice.status) || type === "tag") && setOpen(true)}
                >        
                    <Chip 
                        size="small" 
                        icon={tagIcon}
                        label= {tagLabel}
                        clickable={true}
                        onDelete={(((type !== "record" && type !== "delivery") && !invoice.status) || type === "tag") ? handleDelete : null}
                        variant='outlined'
                        sx={{ 
                            backgroundColor: "#E3E3E3",
                            borderColor: "#E3E3E3", 
                            borderRadius: 1,
                            fontSize: 10,
                            fontWeight: 400
                        }}
                    />
                </Tooltip>

                <InputDialog
                    type={type}
                    invoice={invoice}
                    payments={payments}
                    costplaces={costplaces}
                    open={open}
                    newTag={newTag}
                    setNewTag={setNewTag}
                    onVerify={handleVerify}
                    onClose={() => setOpen(false)}
                />
            </>
        );
    }
    else {
        return (
            <>
                <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    onClick={handleOpen}
                >        
                    <Chip 
                        size="small" 
                        icon={iconColored}
                        label= {tagTipLabel}
                        clickable={true}
                        variant='outlined'
                        sx={{ 
                            borderStyle:"dashed", 
                            color: "#F759AB", 
                            borderColor: "#F759AB", 
                            borderRadius: 1,
                            fontSize: 10,
                            fontWeight: 400,
                            '&:hover': {
                                backgroundColor: '#FFDCED !important',
                                borderStyle: 'solid',
                                transform: 'scale(1.08)',
                            }
                        }}
                    />
                </Tooltip>

                <InputDialog
                    type={type}
                    invoice={invoice}
                    payments={payments}
                    costplaces={costplaces}
                    open={open}
                    newTag={newTag}
                    setNewTag={setNewTag}
                    onVerify={handleVerify}
                    onClose={() => setOpen(false)}
                />
            </>
        );
    }
}
 
export default Tag;