import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";
import * as XLSX from "xlsx";

import useDebounce from "../../../../hooks/useDebounce";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import TicketStockStyle from "./TicketStock.module.css";

import OpsHeader from "../../../../layouts/opsheader/OpsHeader";
import TicketInfoNav from "../../../../layouts/ticketinfonav/TicketInfoNav";

import Feedback from "../../../../components/feedback/Feedback";
import StoreFilter from "../../../../components/filters/storefilter/StoreFilter";
import FilterItem from "../../../../components/filteritem/FilterItem";
import TicketStockInfo from "../../../../components/ticketstockinfo/TicketStockInfo";
import SearchBar from "../../../../components/searchbar/SearchBar";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const TicketStock = () => {
    const {
        user,
        stores,
        msg, setMsg,
        loading, setLoading,
        homeRef,
        searchRef,
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [stock, setStock] = useState([]);
    const [traffic, setTraffic] = useState([]);

    const [tickets, setTickets] = useState([]);
    const [showTickets, setShowTickets] = useState([]);

    const [filter, setFilter] = useState(0);

    const [listStores, setListStores] = useState([]);
    const [storesFilter, setStoresFilter] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        socket.emit("join", ["cid:"+stores.filter(s => s.id === user.store)[0].company]);

        setSearch("");
        setShowSearch("");

        getTickets();

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');

            const searched = tickets.filter(item => rx.test(item.value) || rx.test(item.name) || rx.test(item.store_name));
            
            let filtered = [...searched];

            if(storesFilter.length !== 0) {
                const filter = storesFilter.map(s => s.id);
                filtered = filtered.filter(item => filter.includes(item.store));
            }

            const groupTickets = filtered.reduce((group, item) => {
                const {store} = item;
                group[store] = group[store] ?? [];
                group[store].push(item);
                return group;
            }, {});
    
            const keys = Object.keys(groupTickets);
    
            const result = keys.map(key => {
                return groupTickets[key].reduce((group, item) => {
                    const {ticket} = item;
                    group[ticket] = group[ticket] ?? [];
                    group[ticket].push(item);
                    return group;
                }, {});
            });

            setShowTickets(filtered);
            setStock(result);
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch, tickets, storesFilter]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    useEffect(() => {
        socket.on("update_stock", () => {
            getTickets();
        });
    }, [socket]);

    const getTickets = async() => {
        const res = await fetch(apiUrl+"/tickets/stock/all", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        getStores([...new Set(data.tickets.map(item => item.store))]);

        setTraffic(data.traffic);
        setTickets(data.tickets);
    };
    
    const getStores = async(ids) => {
        if(ids.length === 0) return;

        const res = await fetch(apiUrl+"/stores", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ids: ids
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setListStores(data.stores.map(s => ({...s, ok: false})));
    };

    const calcSum = () => {
        let values = [];

        traffic.reduce((res, obj) => {
            if(!res[obj.ticket]) {
                res[obj.ticket] = { id: obj.ticket, value: 0 };
                values.push(res[obj.ticket]);
            }

            res[obj.ticket].value += obj.sum;
            return res;
        }, {});

        let result = values.reduce((acc, obj) => {
            return acc + obj.value;
        }, 0);

        return showTickets.reduce((acc, obj) => {
            return acc + obj.value;
        }, 0) - result;
    };

    const calcQuantity = () => {
        return showTickets.length - traffic.reduce((acc, obj) => {
            return acc + obj.quantity;
        }, 0)
    };

    const handleExportExcel = () => {
        const format = stock.map(item => {
            return {
                "Név": item.first_name,
                "Telephely": item.store,
                "Dátum": item.date,
                "Típus": item.type,
                "Sorozatszám": item.serial_num,
                "Nyeremény kód": item.prize_code,
                "Nyeremény érték": item.prize_value
            };
        });

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(format);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "Sorsjegy_keszlet.xlsx");
    };

    const handleFilter = (f) => {
        if(filter === f) {
            return setFilter(0);
        }
        setFilter(f);
    };

    const chooseStoreFilter = (choosen) => {
        if(choosen.length !== 0) {
            setListStores(prevStores => prevStores.map(s => {
                if(choosen.filter(c => c.id === s.id).length !== 0) {
                    if(storesFilter.filter(store => store.id === s.id).length === 0) {
                        setStoresFilter(prevFilters => [...prevFilters, s])
                        return {...s, ok: true};
                    }
                    return s;
                }
    
                setStoresFilter(prevFilters => prevFilters.filter(pFilter => pFilter.id !== s.id));
                return {...s, ok: false};
            }));
        }
        else {
            setStoresFilter([]);
            setListStores(prevStores => prevStores.map(s => {
                return {...s, ok: false};
            }));
        }

        setFilter(0);
    };

    const renderFilterList = () => {
        if(filter === 0) {
            if(storesFilter.length === 0) {
                return (
                    <>
                        <span className={TicketStockStyle.ops_tickets_filters_title}>Szűrők</span>
                        <span className={TicketStockStyle.ops_tickets_filters_none}>Nincs kiválasztott szűrés. A szűrés kiválasztásához kattintson egy mezőre.</span>
                    </>
                );
            }
            else {
                return (
                    <>
                        <span className={TicketStockStyle.ops_tickets_filters_title}>Szűrők</span>

                        <FilterItem
                            type="text"
                            text="Telephely"
                            visible={storesFilter.length !== 0}
                            items={storesFilter.map(s => s.name)}
                            onRemove={() => {
                                setListStores(prevStores => prevStores.map(s => ({...s, ok: false})));
                                setStoresFilter([]);
                            }}
                        />
                    </>
                );
            }
        }
        else if(filter === 1) {
            return (
                <>
                    <span className={TicketStockStyle.ops_tickets_filters_title}>Telephely</span>
                    <StoreFilter
                        stores={listStores}
                        onChoose={chooseStoreFilter}
                    />
                </>
            );
        }
    };

    const handleBack = () => {
        navigate("/operations/"+params.store);
    };

    const handleNavSearch = () => {
        searchRef.current.focus();
    };

    return (
        <div
            style={{backgroundColor: "#FFFFFF"}}
            ref={homeRef}
        >
            <div>
                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={17}
                    opsRef={homeRef}
                />

                <SearchBar
                    ph="Keresés"
                    val={search}
                    change={(e) => setSearch(e.target.value)}
                />
            </div>

            <div>
                <TicketInfoNav
                    active={2}
                />

                <div className={TicketStockStyle.ops_tickets_ops}>
                    <div className={TicketStockStyle.operations}>
                        <span>Műveletek</span>

                        <button>Leltár program</button>
                        <button>XLS export</button>
                    </div>
                </div>

                <div className={TicketStockStyle.ops_tickets_filters}>
                    {renderFilterList()}

                    <div className={TicketStockStyle.ops_tickets_info}>
                        <span className={TicketStockStyle.info_quantity}>
                            {calcQuantity()} <span>DB</span>
                        </span>
                        
                        <span className={TicketStockStyle.info_value}>
                            {calcSum()} <span>HUF</span>
                        </span>
                    </div>
                </div>

                <div className={TicketStockStyle.ops_tickets_table}>
                    <table>
                        <thead>
                            <tr>
                                <th
                                    style={{paddingLeft: "8px"}}
                                    onClick={() => handleFilter(1)}
                                >
                                    Telephely
                                </th>
                                <th>
                                    Típus
                                </th>
                                <th>Érték / db</th>
                                <th>Mennyiség</th>
                                <th>Összérték</th>
                            </tr>
                        </thead>

                        {stock.length === 0 ?
                            <tbody>
                                <tr>
                                    <td
                                        className={TicketStockStyle.tickets_none}
                                        colSpan={5}
                                    >
                                        Nem található ilyen sorsjegy
                                    </td>
                                </tr>
                            </tbody>
                        :
                            <tbody>
                                {stock.map(item => {
                                    return Object.keys(item).map(key => {
                                        return (
                                            <TicketStockInfo
                                                key={key}
                                                row={item[key]}
                                            />
                                        );
                                    });
                                })}
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TicketStock;