import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import useDebounce from "../../hooks/useDebounce";
import { DataContext } from "../../context/DataContext";
import { getStoreStatus } from "../../services/Api";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material";

import { motion } from 'framer-motion';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import OpHeader from "../../components/opheader/OpHeader";
import TicketItem from "./content/TicketItem";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Tickets = () => {
    const {user, stores, setOpenOper, setMsg, setLoading} = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [showTickets, setShowTickets] = useState([]);

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("");

    const [open, setOpen] = useState({});

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        socket.emit("join", ["cid:"+stores.filter(s => s.id === user.store)[0].company]);

        getTickets();

        setLoading(false);
    }, []);

    useEffect(() => {
        socket.on("update_tickets", () => {
            getTickets();
        });
    }, [socket]);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            setShowTickets(tickets.filter(item => rx.test(item.name) || rx.test(item.first_name) || rx.test(item.serial_num)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, tickets], 500);

    const handleDeleteRow = async(row) => {
        const res = await fetch(apiUrl+"/ticket/cashout/delete/"+row.id, {
            method: "DELETE"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setOpen({});
        getTickets();

        socket.emit("cashout_tickets", {id: stores.filter(s => s.id === user.store)[0].company});

        setMsg({
            color: "success",
            message: "Sikeres művelet!"
        });
    };

    const getTickets = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/tickets/cashout/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTickets(data.tickets);
        setShowTickets(data.tickets);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={21}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                >
                    <OutlinedInput
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Felhasználók keresése"
                        size="small"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchOutlinedIcon style={{ width: 20 }} />
                            </InputAdornment>
                        }
                        sx={{
                            bgcolor: "#FFF",
                            width: "50%"
                        }}
                    />

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                    >
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <FormControl size="small" sx={{ width: 200, bgcolor: "#FFF" }}>
                                <InputLabel id="sort">Rendezés</InputLabel>

                                <Select
                                    value={sort}
                                    onChange={(e) => setSort(e.target.value)}
                                    labelId="sort"
                                    label="Rendezés"
                                >
                                    <MenuItem value={1}>Növekvő</MenuItem>
                                    <MenuItem value={2}>Csökkenő</MenuItem>
                                </Select>
                            </FormControl>
                        </motion.div>

                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => setOpenOper(12)}
                                sx={{ fontSize: 14 }}
                            >
                                + Új nyertes sorsjegy kifizetése
                            </Button>
                        </motion.div>
                    </Box>
                </Box>
            </Grid>

            {showTickets.map(item => {
                return (
                    <TicketItem
                        key={item.id}
                        info={item}
                        onDelete={(row) => setOpen(row)}
                    />
                );
            })}

            <Dialog
                open={Object.keys(open).length !== 0}
                onClose={() => setOpen({})}
                PaperProps={{
                    sx: { width: 650, maxWidth: 700 }
                }}
            >
                <DialogTitle>
                    Biztosan törlöd ezt a kifizetést?
                </DialogTitle>

                <DialogContent>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={1}
                        >
                            <Stack>
                                <Typography fontSize={14} fontWeight={400}>{open.last_name+" "+open.first_name}</Typography>
                                <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Kifizető</Typography>
                            </Stack>
                        </Box>

                        <Stack>
                            <Typography fontSize={14} fontWeight={400}>{open.name}</Typography>
                            <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Típus</Typography>
                        </Stack>

                        <Stack>
                            <Typography fontSize={14} fontWeight={400}>{open.serial_num}</Typography>
                            <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Sorozatszám</Typography>
                        </Stack>

                        <Stack>
                            <Typography fontSize={14} fontWeight={400}>{open.prize_code}</Typography>
                            <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Nyereménykód</Typography>
                        </Stack>

                        <Stack>
                            <Typography fontSize={14} fontWeight={400}>{open.prize_value} HUF</Typography>
                            <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Nyeremény</Typography>
                        </Stack>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={() => setOpen({})}>
                        Mégsem
                    </Button>

                    <Button variant="contained" onClick={() => handleDeleteRow(open)}>
                        Törlés
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default Tickets;