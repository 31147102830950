import { cloneElement, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { io } from "socket.io-client";

import { DataContext } from '../../../../../../context/DataContext';

import { Chip, Tooltip, Zoom } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import InputDialog from './content/InputDialog';
import FilingDialog from '../footer/content/FilingDialog';

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Tag = ({ invoice, tags, payments, costplaces, locations, type, tagId, tagLabel, tagIcon, tagTipLabel, onRefresh, onFiling }) => {
    const modifyIcon = tagIcon && tagLabel ? cloneElement(tagIcon, {
        style: { fontSize: 14 }
    }) : null;
    
    const {user, setMsg, setLoading} = useContext(DataContext);

    const [newTag, setNewTag] = useState(null);
    const [open, setOpen] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setNewTag(() => {
            if(type === "deadline") {
                if(tagLabel) {
                    return dayjs(tagLabel);
                }
                return dayjs();
            }
            else if(type === "payment") {
                return invoice?.payment_id;
            }
            else if(type === "costplace") {
                return invoice?.costplace_id;
            }
            else if(type === "location") {
                return invoice?.location_id;
            }
            else if(type === "tag") {
                return tagLabel;
            }
        });
    }, [open]);

    const handleVerify = async(tagItem) => {
        setOpen(false);
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/set", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: type,
                invoice: invoice,
                company: user.company.id,
                user: user.id,
                newData: tagItem,
                tagId: type === "tag" ? tagId : null
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        socket.emit("inbound_invoices", {id: user.company.id});

        setMsg({
            color: "success",
            message: "Sikeres művelet"
        });

        onRefresh();
    };

    const handleNewTag = async(searchTag) => {
        setOpen(false);
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/set", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: type,
                invoice: invoice,
                company: user.company.id,
                user: user.id,
                newData: searchTag,
                tagId: type === "tag" ? tagId : null
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres művelet"
        });

        socket.emit("inbound_invoices", {id: user.company.id});

        onRefresh();
    };

    const handleDelete = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/tag/delete", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: type,
                invoice: invoice,
                user: user.id,
                tagId: type === "tag" ? tagId : null
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres művelet"
        });

        socket.emit("inbound_invoices", {id: user.company.id});

        onRefresh();
    };

    const getInvoice = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/itemized", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ invoiceNum: invoice.serial_num })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        const grossSum = Math.round(data.invoice.reduce((acc, obj) => {
            return acc + (obj.unitPrice * obj.quantity);
        }, 0));

        const netSum = Math.round(data.invoice.reduce((acc, obj) => {
            return acc + ((obj.unitPrice - (obj.unitPrice * obj.vat)) * obj.quantity);
        }, 0));

        saveSumData(grossSum, netSum);
    };

    const saveSumData = async(gross, net) => {
        const res = await fetch(apiUrl+"/inbound/set/sum", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                invoiceId: invoice.id,
                gross: gross,
                net: net
            })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres kalkuláció"
        });

        onRefresh();

        setLoading(false);
    };

    const handleOpen = (e) => {
        e.stopPropagation();

        if(type === "calculate") {
            getInvoice();
        }
        else if(type !== "record" && type !== "delivery") {
            setOpen(true);
        }
    };

    if(tagLabel) {
        return ( 
            <>
                <Tooltip
                    arrow
                    title={tagTipLabel} 
                    TransitionComponent={Zoom}
                    onClick={() => (((type !== "record" && type !== "delivery") && !invoice.status && (type === "deadline" && Number(invoice.deadline_nav) !== 1)) || type === "location" || type === "tag") && setOpen(true)}
                >        
                    <Chip 
                        size="small" 
                        icon={modifyIcon}
                        label= {tagLabel}
                        clickable={true}
                        onDelete={(((type !== "record" && type !== "delivery") && !invoice.status  && (type === "deadline" && Number(invoice.deadline_nav) !== 1)) || type === "location" || type === "tag") ? handleDelete : null}
                        variant='outlined'
                        sx={{ 
                            backgroundColor: "#E3E3E3",
                            borderColor: "#E3E3E3", 
                            borderRadius: 1,
                            fontSize: 10,
                            fontWeight: 400
                        }}
                    />
                </Tooltip>

                {type === "newtag" || type === "tag" ?
                    <InputDialog
                        type={type}
                        invoice={invoice}
                        payments={payments}
                        costplaces={costplaces}
                        locations={locations}
                        open={open}
                        tags={tags}
                        newTag={newTag}
                        setNewTag={setNewTag}
                        onVerify={handleVerify}
                        onTag={handleNewTag}
                        onClose={() => setOpen(false)}
                    />
                :
                    <FilingDialog
                        invoice={invoice}
                        payments={payments}
                        costplaces={costplaces}
                        type={type}
                        open={open}
                        onClose={() => setOpen(false)}
                        onVerify={(store) => {
                            setOpen(false);
                            onFiling(store);
                        }}
                        onRefresh={onRefresh}
                    />
                }
            </>
        );
    }
    else {
        return (
            <>
                <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    onClick={handleOpen}
                >        
                    <Chip 
                        size="small" 
                        icon={<AddCircleOutlineIcon style={{ fontSize: 14, color: "#F759AB" }} />}
                        label={tagTipLabel}
                        clickable={true}
                        variant='outlined'
                        sx={{ 
                            borderStyle:"dashed", 
                            color: "#F759AB", 
                            borderColor: "#F759AB", 
                            borderRadius: 1,
                            fontSize: 10,
                            fontWeight: 400,
                            '&:hover': {
                                backgroundColor: '#FFDCED !important',
                                borderStyle: 'solid',
                                transform: 'scale(1.08)',
                            }
                        }}
                    />
                </Tooltip>

                {type === "newtag" || type === "tag" ?
                    <InputDialog
                        type={type}
                        invoice={invoice}
                        payments={payments}
                        costplaces={costplaces}
                        locations={locations}
                        open={open}
                        tags={tags}
                        newTag={newTag}
                        setNewTag={setNewTag}
                        onVerify={handleVerify}
                        onTag={handleNewTag}
                        onClose={() => setOpen(false)}
                    />
                :
                    <FilingDialog
                        invoice={invoice}
                        payments={payments}
                        costplaces={costplaces}
                        type={type}
                        open={open}
                        onClose={() => setOpen(false)}
                        onVerify={(store) => {
                            setOpen(false);
                            onFiling(store);
                        }}
                        onRefresh={onRefresh}
                    />
                }
            </>
        );
    }
}
 
export default Tag;