import { useContext, useEffect } from "react";

import { DataContext } from "../../context/DataContext";

import { Alert, Box, IconButton } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

const Feedback = ({ color, message }) => {
    const {setMsg} = useContext(DataContext);

    useEffect(() => {
        const timer = setTimeout(() => {
            setMsg({});
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Box
            position="absolute"
            display="flex"
            justifyContent="center"
            zIndex={6000}
            width="100%"
            padding={1}
        >
            <Alert
                severity={color}
                action={
                    <IconButton
                        color="inherit"
                        size="small"
                        onClick={() => setMsg({})}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {message}
            </Alert>
        </Box>
    );
};

export default Feedback;