import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Paper, Table, TableBody, TableContainer, Typography } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import OpHeader from "../../../components/opheader/OpHeader";
import TicketItem from "./content/TicketItem";

const TrafficVerify = ({ onClose }) => {
    const {user, setLoading, setMsg, setOpenOper} = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [itemized, setItemized] = useState([]);
    const [traffic, setTraffic] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getTicketTypes();
        getTicketTraffic();
    }, []);

    const getTicketTypes = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/tickets", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTickets(data.tickets);
    };

    const getTicketTraffic = async() => {
        const res = await fetch(apiUrl+"/tickets/traffic/store/"+user.store, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        const itemizedGroup = data.traffic.reduce((group, x) => {
            (group[x["ticket_name"]] = group[x["ticket_name"]] || []).push(x);
            return group;
        }, {});
        
        setTraffic(itemizedGroup);

        setItemized(data.traffic);

        setLoading(false);
    };

    const handleDeleteRow = async(item) => {
        const res = await fetch(apiUrl+"/tickets/traffic/draft/delete/"+item.id, {
            method: "DELETE"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getTicketTraffic();
    };

    const handleEditRow = async(newItem) => {
        const res = await fetch(apiUrl+"/tickets/traffic/draft/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                newRow: newItem
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getTicketTraffic();
    };

    const calculateQuantity = (item) => {
        return item.reduce((acc, obj) => {
            return acc += obj.quantity;
        }, 0);
    };

    const calculateValue = (item) => {
        return item.reduce((acc, obj) => {
            return acc += (obj.ticket_value*obj.quantity);
        }, 0);
    };

    const calculateTotal = () => {
        return itemized.reduce((acc, obj) => {
            return acc += (obj.ticket_value*obj.quantity);
        }, 0);
    };

    const handleVerify = async() => {
        const quantity = itemized.reduce((acc, obj) => {
            return acc += obj.quantity;
        }, 0);

        const res = await fetch(apiUrl+"/tickets/traffic/verify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                sum: calculateTotal(),
                quantity: quantity,
                rows: itemized
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setOpenOper(0);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OpHeader
                    operation={13}
                    onClose={onClose}
                />
            </Grid>

            {Object.keys(traffic).map((key, index) => {
                return (
                    <Grid
                        key={index}
                        item
                        xs={12}
                    >
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Typography fontSize={16} fontWeight={500}>{key}</Typography>

                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={2}
                                        marginRight={2}
                                    >
                                        <Typography fontSize={16} fontWeight={500}>{calculateQuantity(traffic[key])} DB</Typography>
                                        <Typography fontSize={16} fontWeight={500}>{calculateValue(traffic[key])} HUF</Typography>
                                    </Box>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            {traffic[key].map(item => {
                                                return (
                                                    <TicketItem
                                                        key={item.id}
                                                        item={item}
                                                        tickets={tickets}
                                                        onDelete={handleDeleteRow}
                                                        onEdit={handleEditRow}
                                                    />
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                );
            })}

            <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={1}
                        >
                            <Typography fontSize={20} fontWeight={700}>Total: </Typography>
                            <Typography fontSize={20} fontWeight={400}>{calculateTotal()} HUF</Typography>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={1}
                        >
                            <Button variant="outlined" onClick={onClose}>Vissza</Button>
                            <Button variant="contained" onClick={handleVerify}>Véglegesítés</Button>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default TrafficVerify;