import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import ReactEcharts from "echarts-for-react";

import TotalStoresStyle from "./TotalStores.module.css";
import ChartFilter from "../chartfilter/ChartFilter";

const TotalStores = ({ data, onDaily }) => {
    const {stores} = useContext(DataContext);

    const [totalValues, setTotalValues] = useState([]);
    const [prevValues, setPrevValues] = useState([]);

    const [dates, setDates] = useState([]);

    const [trafficStores, setTrafficStores] = useState([]);

    useEffect(() => {
        if(JSON.stringify(data) !== "{}") {
            getStores();
        }
    }, [data?.current?.data]);

    useEffect(() => {
        if(JSON.stringify(data) !== "{}") {
            let current = data.current.data;
            let prevYear = data.prevYear.data;

            stores.map(item => {
                if(!item.show) {
                    current = current.filter(element => element.store !== item.id);
                    prevYear = prevYear.filter(element => element.store !== item.id);
                }
            });

            const showData = {
                ...data,
                current: {
                    ...data.current,
                    data: current
                },
                prevYear: {
                    ...data.prevYear,
                    data: prevYear
                }
            };
            
            calcData(showData);
            calcPrevData(showData);
        }
    }, [trafficStores]);

    const getStores = () => {
        if(trafficStores.length !== 0) {
            const cpyStores = trafficStores;
            
            setTrafficStores(stores.map(item => {
                if(cpyStores.filter(s => s.name === item.name).length !== 0){
                    return {
                        id: item.id,
                        name: item.name,
                        color: item.color_code_2,
                        show: cpyStores.filter(s => s.name === item.name)[0].show
                    };
                }
                else {
                    return {
                        id: item.id,
                        name: item.name,
                        color: item.color_code_2,
                        show: true
                    };
                }
            }));
        }
        else {
            setTrafficStores(stores.map(item => ({
                id: item.id,
                name: item.name,
                color: item.color_code_2,
                show: true
            })));
        }
    };

    const dateRange = (startDate, endDate) => {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate).toISOString("hu-HU").slice(0, 10));
            currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        }

        return dateArray;
    }

    const calcData = (showData) => {
        // const dateGroup = Object.groupBy(data.current.data, ({date}) => date);

        const dateGroup = showData.current.data.reduce((group, x) => {
            (group[x["date"]] = group[x["date"]] || []).push(x);
            return group;
        }, {});

        const dateSum = []
        
        Object.keys(dateGroup).map(key => {
            const sum = dateGroup[key].reduce((acc, obj) => {
                return acc + obj.unit_value;
            }, 0);

            dateSum.push({
                date: key,
                sum: sum
            });
        });

        const datesArr = dateRange(showData.current.from, showData.current.to);

        setDates(datesArr);

        let allDatesSum = [];

        dateSum.map(item => {
            const filtered = datesArr.filter(date => date === item.date);

            if(filtered.length === 0) {
                allDatesSum.push({
                    date: item.date,
                    sum: 0
                });
            }
            else {
                allDatesSum.push(item);
            }
        });

        setTotalValues(allDatesSum);

        /*
        Boltokra lebontva de ide nem ez kell

        const stores = Object.groupBy(data.current.data, ({store_name}) => store_name);

        const storesSum = {};

        Object.keys(stores).map(key => {
            const dateArr = Object.groupBy(stores[key], ({date}) => date.split("T")[0]);

            const dateSum = [];

            Object.keys(dateArr).map(dateKey => {
                const sum = dateArr[dateKey].reduce((acc, obj) => {
                    return acc + obj.unit_value;
                }, 0);

                dateSum.push({
                    date: dateKey,
                    store: dateArr[dateKey][0].store,
                    color: dateArr[dateKey][0].color_code_2,
                    sum: sum
                });
            });

            return storesSum[key] = dateSum;
        });

        const datesArr = dateRange(data.current.from, data.current.to);

        setDates(datesArr);

        let allDatesSum = {};

        Object.keys(storesSum).map(key => {
            allDatesSum = {
                ...allDatesSum,
                [key]: []
            };
        });

        Object.keys(storesSum).map(key => {
            datesArr.map(item => {
                const filtered = storesSum[key].filter(trafficData => trafficData.date === item);

                if(filtered.length === 0) {
                    allDatesSum[key] = [
                        ...allDatesSum[key],
                        {
                            date: item,
                            store: storesSum[key][0].store,
                            color: storesSum[key][0].color,
                            sum: 0
                        }
                    ];
                }
                else {
                    allDatesSum[key] = [
                        ...allDatesSum[key],
                        filtered[0]
                    ];
                }
            });
        });

        setTotalValues(allDatesSum);*/
    };

    const calcPrevData = (showData) => {
        const dateGroup = showData.prevYear.data.reduce((group, x) => {
            (group[x["date"]] = group[x["date"]] || []).push(x);
            return group;
        }, {});

        const dateSum = []
        
        Object.keys(dateGroup).map(key => {
            if(key !== showData.prevYear.from) {
                const sum = dateGroup[key].reduce((acc, obj) => {
                    return acc + obj.unit_value;
                }, 0);

                dateSum.push({
                    date: key,
                    sum: sum
                });
            }
        });

        const datesArr = dateRange(showData.prevYear.from, showData.prevYear.to);

        let allDatesSum = [];

        dateSum.map(item => {
            const filtered = datesArr.filter(date => date === item.date);

            if(filtered.length === 0) {
                allDatesSum.push({
                    date: item.date,
                    sum: 0
                });
            }
            else {
                allDatesSum.push(item);
            }
        });

        setPrevValues(allDatesSum);
    };

    const handleGoToLine = (line) => {
        let current = data.current.data;
        let prevYear = data.prevYear.data;

        trafficStores.map(item => {
            if(!item.show) {
                current = current.filter(element => element.store !== item.id);
                prevYear = prevYear.filter(element => element.store !== item.id);
            }
        });

        const showData = {
            ...data,
            current: {
                ...data.current,
                data: current
            },
            prevYear: {
                ...data.prevYear,
                data: prevYear
            }
        };

        if(line.data.prev) {
            const from = line.data.date.split("-");
            from[0] = (Number(from[0])+1).toString();
            const currentFrom = from.join("-");

            const filtered = totalValues.filter(item => item.date === currentFrom)[0];
            
            onDaily({
                date: filtered.date,
                value: filtered.sum
            }, showData);
        }
        else {
            onDaily(line.data, showData)
        }
    };

    const handleFilter = (store) => {
        const modifyArr = trafficStores.map(item => {
            if(item.id === store.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: !item.show
                };
            }
            return item;
        });

        setTrafficStores(modifyArr);
    };

    return (
        <>
            <div className={TotalStoresStyle.filter_bar}>
                {trafficStores.map(item => {
                    return (
                        <ChartFilter
                            key={item.id}
                            name={item.name}
                            colorCode={item.color}
                            active={item.show}
                            click={() => handleFilter(item)}
                        />
                    );
                })}
            </div>

            <div className={TotalStoresStyle.container}>
                <ReactEcharts
                    option={{
                        title: {
                            text: "Forgalom az adott időszakban",
                            left: "center"
                        },
                        xAxis: {
                            type: "category",
                            boundaryGap: false,
                            data: dates
                        },
                        yAxis: {
                            type: "value"
                        },
                        series: [
                            {
                                type: "line",
                                symbolSize: 10,
                                lineStyle: {
                                    color: "#C4C4C4",
                                    type: "dashed"
                                },
                                itemStyle: {
                                    color: "#C4C4C4"
                                },
                                data: [
                                    ...prevValues.map(item => ({
                                        prev: true,
                                        date: item.date,
                                        value: item.sum
                                    }))
                                ]
                            },
                            {
                                type: "line",
                                symbolSize: 10,
                                data: [
                                    ...totalValues.map((item) => ({
                                        date: item.date,
                                        value: item.sum
                                    }))
                                ]
                            }
                        ],
                        tooltip: {
                            trigger: "axis",
                            valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                        }
                    }}
                    onEvents={{
                        click: (line) => handleGoToLine(line)
                    }}
                    style={{width: "100%", height: "100%"}}
                />
            </div>
        </>
    );
};

export default TotalStores;