import { Box, IconButton, Stack, Typography } from "@mui/material";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import StoreIcon from "../../../../../../../../layouts/stores/content/StoreIcon";

const StoreItem = ({ store, onSelect }) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
        >
            <Box
                display="flex"
                flexDirection="row"
                gap={1}
            >
                <StoreIcon
                    store={store}
                />

                <Stack>
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                    >
                        {store.name}
                    </Typography>

                    <Typography
                        fontSize={12}
                        fontWeight={400}
                        color="#8C8C8C"
                    >
                        {store.address}
                    </Typography>
                </Stack>
            </Box>

            <IconButton
                size="small"
                onClick={() => onSelect(store)}
            >
                <AddCircleOutlineIcon />
            </IconButton>
        </Box>
    );
};

export default StoreItem;