import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import ReactEcharts from "echarts-for-react";

import ProfitVatStyle from "./ProfitVat.module.css";
import ChartFilter from "../chartfilter/ChartFilter";

const ProfitVat = ({ data }) => {
    const {stores} = useContext(DataContext);

    const [storesVat, setStoresVat] = useState({});
    const [profit, setProfit] = useState([]);

    const [trafficStores, setTrafficStores] = useState([]);

    const [selectedVat, setSelectedVat] = useState("");

    useEffect(() => {
        if(JSON.stringify(data) !== "{}") {
            getStores();
        }
    }, [data?.current?.data]);

    useEffect(() => {
        if(JSON.stringify(data) !== "{}") {
            let current = data.current.data;
            let prevYear = data.prevYear.data;

            trafficStores.map(item => {
                if(!item.show) {
                    current = current.filter(element => element.store !== item.id);
                    prevYear = prevYear.filter(element => element.store !== item.id);
                }
            });

            const showData = {
                ...data,
                current: {
                    ...data.current,
                    data: current
                },
                prevYear: {
                    ...data.prevYear,
                    data: prevYear
                }
            };
            
            calcStoresVat(showData);
            calcProfit(showData);
        }
    }, [trafficStores]);

    useEffect(() => {
        if(JSON.stringify(data) !== "{}") {
            let current = data.current.data;
            let prevYear = data.prevYear.data;

            stores.map(item => {
                if(!item.show) {
                    current = current.filter(element => element.store !== item.id);
                    prevYear = prevYear.filter(element => element.store !== item.id);
                }
            });

            const showData = {
                ...data,
                current: {
                    ...data.current,
                    data: current
                },
                prevYear: {
                    ...data.prevYear,
                    data: prevYear
                }
            };

            calcProfit(showData);
        }
    }, [selectedVat]);

    const getStores = () => {
        if(trafficStores.length !== 0) {
            const cpyStores = trafficStores;
            
            setTrafficStores(stores.map(item => {
                if(cpyStores.filter(s => s.name === item.name).length !== 0){
                    return {
                        id: item.id,
                        name: item.name,
                        color: item.color_code_2,
                        show: cpyStores.filter(s => s.name === item.name)[0].show
                    };
                }
                else {
                    return {
                        id: item.id,
                        name: item.name,
                        color: item.color_code_2,
                        show: true
                    };
                }
            }));
        }
        else {
            setTrafficStores(stores.map(item => ({
                id: item.id,
                name: item.name,
                color: item.color_code_2,
                show: true
            })));
        }
    };

    const calcStoresVat = (showData) => {
        // const vats = Object.groupBy(data.current.data, ({vat}) => vat);

        const vats = showData.current.data.reduce((group, x) => {
            (group[x["vat"]] = group[x["vat"]] || []).push(x);
            return group;
        }, {}); // groupBy

        let sumStoresObj = {};

        Object.keys(vats).map(key => {
            // const storesGroup = Object.groupBy(vats[key], ({store_name}) => store_name);

            const storesGroup = showData.current.data.reduce((group, x) => {
                (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
                return group;
            }, {});

            const storesSum = [];

            Object.keys(storesGroup).map(storeKey => {
                const sum = storesGroup[storeKey].reduce((acc, obj) => {
                    return acc + obj.unit_value;
                }, 0);

                storesSum.push({
                    store: storeKey,
                    sum: sum
                });
            });

            return sumStoresObj[key] = storesSum;
        });

        if(JSON.stringify(sumStoresObj) !== "{}") {
            // setStores([...sumStoresObj["0"].map(item => item.store)]);

            setStoresVat(sumStoresObj);
        }
        else {
            setTrafficStores([]);
            
            setStoresVat({});
        }
    };

    const calcProfit = (showData) => {
        // const currentStores = Object.groupBy(data.current.data, ({store_name}) => store_name);

        const currentStores = showData.current.data.reduce((group, x) => {
            (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
            return group;
        }, {});

        const currentStoresProfit = Object.keys(currentStores).map(key => {
            const unitSum = currentStores[key].reduce((acc, obj) => {
                if(selectedVat !== "") {
                    if(obj.vat === Number(selectedVat)) {
                        return acc + obj.unit_value;
                    }

                    return acc;
                }

                return acc + obj.unit_value;
            }, 0);

            const purchaseSum = currentStores[key].reduce((acc, obj) => {
                if(selectedVat !== "") {
                    if(obj.vat === Number(selectedVat)) {
                        return acc + obj.purchase_value;
                    }

                    return acc;
                }

                return acc + obj.purchase_value;
            }, 0);

            return {
                store: key,
                color: currentStores[key][0].color_code_2,
                sum: ((unitSum-purchaseSum).toFixed())
            };
        });

        setProfit(currentStoresProfit);
    };

    const handleSelectVat = (line) => {
        if(line.vat !== selectedVat) {
            setSelectedVat(line.vat);
        }
        else {
            setSelectedVat("");
        }
    };

    const handleFilter = (store) => {
        const modifyArr = trafficStores.map(item => {
            if(item.id === store.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: !item.show
                };
            }
            return item;
        });

        setTrafficStores(modifyArr);
    };

    return (
        <>
            <div className={ProfitVatStyle.filter_bar}>
                {trafficStores.map(item => {
                    return (
                        <ChartFilter
                            key={item.id}
                            name={item.name}
                            colorCode={item.color}
                            active={item.show}
                            click={() => handleFilter(item)}
                        />
                    );
                })}
            </div>

            <div className={ProfitVatStyle.container}>
                <div className={ProfitVatStyle.chart_left}>
                    <ReactEcharts
                        option = {{
                            title: {
                                text: "Forgalom ÁFA körökre lebontva",
                                left: "center"
                            },
                            tooltip: {
                                trigger: 'item',
                                valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                            },
                            grid: {
                                containLabel: true
                            },
                            xAxis: {
                                type: 'value'
                            },
                            yAxis: {
                                type: 'category',
                                data: [...trafficStores.filter(item => item.show).map(item => item.name)]
                            },
                            series: [...Object.keys(storesVat).map(key => ({
                                name: key,
                                type: 'bar',
                                stack: 'total',
                                label: {
                                    show: false
                                },
                                emphasis: {
                                    focus: 'series'
                                },
                                data: [...storesVat[key].map(item => ({
                                    value: item.sum,
                                    vat: key
                                }))]
                            }))]
                        }}
                        onEvents={{
                            click: (line) => handleSelectVat(line.data)
                        }}
                        style={{width: "100%", height: "110%"}}
                    />
                </div>

                <div className={ProfitVatStyle.chart_right}>
                    <ReactEcharts
                        option={{
                            title: {
                                text: "Profit az adott időszakban",
                                left: "center"
                            },
                            series: [
                                {
                                    name: "Profit az adott időszakban",
                                    type: "pie",
                                    radius: ["40%", "70%"],
                                    data: [...profit.map(item => ({
                                        name: item.store,
                                        value: Number(item.sum),
                                        itemStyle: {color: item.color}
                                    }))]
                                }
                            ],
                            tooltip: {
                                trigger: "item",
                                valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                            },
                            grid: {
                                containLabel: true
                            }
                        }}
                        style={{width: "100%", height: "100%"}}
                    />
                </div>
            </div>
        </>
    );
};

export default ProfitVat;