import { useContext, useEffect, useState } from "react";

import { getUserOperations } from "../../../services/Api";
import { DataContext } from "../../../context/DataContext";

import { Box, Button, Grid, Paper, Step, StepButton, Stepper } from "@mui/material";

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import OpHeader from "../../../components/opheader/OpHeader";
import Operation from "../content/Operation";

import NewUserPw from "./NewUserPw";

const NewUserPermission = ({ newUser, onClose, onProfile }) => {
    const {user, setLoading} = useContext(DataContext);

    const [ops, setOps] = useState([]);

    const [next, setNext] = useState(false);

    useEffect(() => {
        setLoading(true);

        getUserOperations(user.id).then(data => {
            setOps(data.map(item => ({...item, active: false})));
        });

        setLoading(false);
    }, []);

    const handleChange = (op) => {
        setOps(prev => prev.map(item => {
            if(item.id === op.id) {
                return {...item, active: !item.active};
            }
            return item;
        }));
    };

    const renderPw = () => {
        if(next) {
            return (
                <NewUserPw
                    newUser={newUser}
                    ops={ops.filter(item => item.active)}
                    onClose={() => setNext(false)}
                    onProfile={onProfile}
                    onPersonal={onClose}
                />
            );
        }
    };

    return (
        <>
            {renderPw()}

            {!next &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={15}
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Paper
                            sx={{ p: 1 }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Stepper activeStep={2}>
                                        <Step>
                                            <StepButton
                                                icon={<PersonOutlineOutlinedIcon />}
                                                onClick={onProfile}
                                            >
                                                Profil
                                            </StepButton>
                                        </Step>

                                        <Step>
                                            <StepButton
                                                icon={<PersonOutlineOutlinedIcon />}
                                                onClick={onClose}
                                            >
                                                Személyes adatok
                                            </StepButton>
                                        </Step>

                                        <Step>
                                            <StepButton icon={<PersonOutlineOutlinedIcon style={{ color: "#1890FF" }} />}>
                                                Jogosultságok
                                            </StepButton>
                                        </Step>

                                        <Step>
                                            <StepButton icon={<PersonOutlineOutlinedIcon />}>
                                                Jelszó
                                            </StepButton>
                                        </Step>
                                    </Stepper>
                                </Grid>

                                {ops.map((item, index) => {
                                    return (
                                        <Operation
                                            key={item.id}
                                            op={item}
                                            val={item.active}
                                            onChange={handleChange}
                                            timeout={index*400}
                                        />
                                    );
                                })}

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        justifyContent="flex-end"
                                    >
                                        <Button variant="outlined" onClick={onClose}>Vissza</Button>
                                        <Button variant="contained" onClick={() => setNext(true)}>Következő</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default NewUserPermission;