import { useContext, useEffect, useState } from "react";

import useDebounce from "../../hooks/useDebounce";

import { DataContext } from "../../context/DataContext";

import { Box, Button, Grid, InputAdornment, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import OpHeader from "../../components/opheader/OpHeader";
import SafeRow from "./content/SafeRow";
import SafeModify from "./SafeModify";
import ImageDialog from "./content/ImageDialog";

const ManageSafe = () => {
    const {user, setMsg, setLoading, setOpenOper} = useContext(DataContext);

    const [showInSafe, setShowInSafe] = useState(true);
    const [showTransit, setShowTransit] = useState(true);
    const [showCashin, setShowCashin] = useState(true);

    const [search, setSearch] = useState("");

    const [packages, setPackages] = useState([]);
    const [showPackages, setShowPackages] = useState([]);

    const [modify, setModify] = useState({});

    const [imageDialog, setImageDialog] = useState("");

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getPackages();
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');

            let filtered = packages;

            if(!showInSafe) {
                filtered = filtered.filter(item => item.date_of_transit);
            }
            
            if(!showTransit) {
                filtered = filtered.filter(item => !item.date_of_transit || item.date_of_cashin);
            }
            
            if(!showCashin) {
                filtered = filtered.filter(item => !item.date_of_cashin);
            }

            setShowPackages(filtered.filter(item => rx.test(item.serial_num)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, showInSafe, showTransit, showCashin, packages], 500);

    const getPackages = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/safe/"+user.store, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPackages(data.safe);

        setLoading(false);
    };

    const handleSave = () => {
        setModify({});
        getPackages();
    };

    const renderModify = () => {
        if(JSON.stringify(modify) !== "{}") {
            return (
                <SafeModify
                    safeItem={modify}
                    onClose={() => setModify({})}
                    onSave={handleSave}
                />
            );
        }
    };

    return (
        <>
            {renderModify()}

            {JSON.stringify(modify) === "{}" &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={8}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            width="100%"
                            color="#000"
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                gap={1}
                                alignItems="center"
                            >
                                <Typography fontSize={16} fontWeight={700}>Széf</Typography>
                                <Typography fontSize={12} fontWeight={300}>( {showPackages.length} tétel )</Typography>
                            </Box>

                            <Button
                                variant="contained"
                                onClick={() => setOpenOper(5)}
                                sx={{ bgcolor: "#13C2C2" }}
                            >
                                <Typography fontSize={12}>Értékleadás a széfbe</Typography>
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <OutlinedInput
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Keresés"
                            fullWidth
                            size="small"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon style={{ width: 20 }} />
                                </InputAdornment>
                            }
                            sx={{
                                fontSize: 12,
                                backgroundColor: "#FFFFFF"
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography fontSize={12} fontWeight={300} color="#000">Szűrők</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={2}
                            color="#000"
                        >
                            <Tooltip
                                onClick={() => setShowInSafe(prev => !prev)}
                                sx={{
                                    cursor: "pointer",
                                    opacity: !showInSafe && 0.5
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap={0.5}
                                >
                                    <AccountBalanceWalletOutlinedIcon style={{ fontSize: 20 }} />
                                    <Typography fontSize={12} fontWeight={300}>széfben</Typography>
                                </Box>
                            </Tooltip>

                            <Tooltip
                                onClick={() => setShowTransit(prev => !prev)}
                                sx={{
                                    cursor: "pointer",
                                    opacity: !showTransit && 0.5
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap={0.5}
                                >
                                    <CasesOutlinedIcon style={{ fontSize: 20 }} />
                                    <Typography fontSize={12} fontWeight={300}>értékszállító</Typography>
                                </Box>
                            </Tooltip>

                            <Tooltip
                                onClick={() => setShowCashin(prev => !prev)}
                                sx={{
                                    cursor: "pointer",
                                    opacity: !showCashin && 0.5
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap={0.5}
                                >
                                    <AccountBalanceIcon style={{ fontSize: 20 }} />
                                    <Typography fontSize={12} fontWeight={300}>befizetve</Typography>
                                </Box>
                            </Tooltip>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell align="center">Státusz</TableCell>
                                        <TableCell>Csomag megnevezése</TableCell>
                                        <TableCell>Széfbekerülés dátuma</TableCell>
                                        <TableCell>Értékszállítás dátuma</TableCell>
                                        <TableCell>Értékszállítás azonosítója</TableCell>
                                        <TableCell>Befizetés dátuma</TableCell>
                                        <TableCell align="center">Műveletek</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {showPackages.map((item, i) => {
                                        return (
                                            <SafeRow
                                                key={item.id}
                                                row={item}
                                                index={i+1}
                                                onModify={() => setModify(item)}
                                                onImage={(image) => setImageDialog(apiUrl+"/"+image)}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <ImageDialog
                        open={imageDialog !== ""}
                        onClose={() => setImageDialog("")}
                        image={imageDialog}
                    />
                </Grid>
            }
        </>
    );
};

export default ManageSafe;