import { useContext, useEffect, useState } from "react";
import { motion } from 'framer-motion';

import { DataContext } from "../../../../context/DataContext";

import { Box, Grid, Grow } from "@mui/material";

import InvoiceHeader from "./content/header/InvoiceHeader";
import InvoiceBody from "./content/body/InvoiceBody";
import InvoiceFooter from "./content/footer/InvoiceFooter";

const InvoiceItem = ({ invoice, tags, payments, costplaces, timeout, onItemized, onRefresh }) => {
    const {setLoading, setMsg} = useContext(DataContext);

    const [invoicePayment, setInvoicePayment] = useState({});
    const [invoiceCostplace, setInvoiceCostplace] = useState({});
    const [invoiceTags, setInvoiceTags] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getTags();
    }, [invoice]);

    const getTags = () => {
        getPaymentMethod();
        getInvoiceCostplace();
        getInvoiceTags();
    };

    const getPaymentMethod = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/get/payment/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoicePayment(data.payment);
    };

    const getInvoiceCostplace = async() => {
        const res = await fetch(apiUrl+"/outbound/get/costplace/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceCostplace(data.costplace);
    };

    const getInvoiceTags = async() => {
        const res = await fetch(apiUrl+"/outbound/get/tags/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceTags(data.tags);

        setLoading(false);
    };

    return (
        <Grid item xs={12} md={4}>
            <Grow in={true} timeout={timeout}>
                <motion.div whileHover={{ scale: 1.02 }}>
                    <Box
                        border="1px solid #E1DEDE"
                        borderRadius={1}
                    >
                        <InvoiceHeader
                            invoice={({
                                ...invoice,
                                ...invoicePayment,
                                ...invoiceCostplace,
                                tags: invoiceTags
                            })}
                            tags={tags}
                            payments={payments}
                            costplaces={costplaces}
                            onRefresh={() => {
                                getTags();
                                onRefresh();
                            }}
                        />

                        <InvoiceBody
                            invoice={({
                                ...invoice,
                                ...invoicePayment,
                                ...invoiceCostplace,
                                tags: invoiceTags
                            })}
                            tags={tags}
                            payments={payments}
                            costplaces={costplaces}
                            onRefresh={() => {
                                getTags();
                                onRefresh();
                            }}
                            onItemized={onItemized}
                        />

                        <InvoiceFooter
                            invoice={({
                                ...invoice,
                                ...invoicePayment,
                                ...invoiceCostplace,
                                tags: invoiceTags
                            })}
                            tags={tags}
                            payments={payments}
                            costplaces={costplaces}
                            onRefresh={() => {
                                getTags();
                                onRefresh();
                            }}
                        />
                    </Box>
                </motion.div>
            </Grow>
        </Grid> 
    );
};

export default InvoiceItem;