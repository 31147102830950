import { useEffect, useState } from "react";

import ReactEcharts from "echarts-for-react";

import { Box, Grid, Stack, Typography } from "@mui/material";

import ChartFilter from "../../../components/charts/chartfilter/ChartFilter";

const TotalYears = ({ traffic, vatValues }) => {
    const [currentBar, setCurrentBar] = useState([]);
    const [prevBar, setPrevBar] = useState([]);

    const [currentPie, setCurrentPie] = useState([]);
    const [prevPie, setPrevPie] = useState([]);

    const [currentStats, setCurrentStats] = useState({
        total: 0,
        avg: 0,
        profit: 0,
        customer: 0,
        basketVal: 0,
        vat: 0
    });

    const [prevStats, setPrevStats] = useState({
        total: 0,
        avg: 0,
        profit: 0,
        customer: 0,
        basketVal: 0,
        vat: 0
    });

    const [avg, setAvg] = useState([
        {
            id: 0,
            name: "Jelenlegi időszak átlag",
            color: "#2A4AAB",
            show: false
        },
        {
            id: 1,
            name: "Múlt évi időszak átlag",
            color: "#686868",
            show: false
        }
    ]);

    const [vats, setVats] = useState([
        {
            id: 0,
            name: "Total",
            color: "#53FA39",
            show: true
        },
        {
            id: 1,
            name: "AJT",
            color: "#53FA39",
            show: false
        },
        {
            id: 2,
            name: "27%",
            color: "#53FA39",
            show: false
        },
        {
            id: 3,
            name: "18%",
            color: "#53FA39",
            show: false
        }
    ]);

    useEffect(() => {
        if(JSON.stringify(traffic) !== "[]") {
            getUnique();
        }
    }, [traffic?.current?.data, traffic?.prevYear?.data, vats]);

    useEffect(() => {
        if(JSON.stringify(currentBar) !== "[]") {
            calcCurrentStats();
        }
    }, [currentBar, currentPie]);

    useEffect(() => {
        if(JSON.stringify(prevBar) !== "[]") {
            calcPrevStats();
        }
    }, [prevBar, prevPie]);

    const getUnique = () => { 
        const currentSort = traffic.current.data.sort((a, b) => new Date(b.date_from) - new Date(a.date_from));

        const currentUnique = currentSort.filter((obj, index) => {
            return index === traffic.current.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        });

        const prevSort = traffic.prevYear.data.sort((a, b) => new Date(b.date_from) - new Date(a.date_from));

        const prevUnique = prevSort.filter((obj, index) => {
            return index === traffic.prevYear.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        });

        calcCurrentBar(currentUnique);
        calcPrevBar(prevUnique);

        calcCurrentPie(currentUnique);
        calcPrevPie(prevUnique);
    };


    const calcCurrentBar = (unique) => {
        const storeGroup = unique.reduce((group, x) => {
            (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
            return group;
        }, {});

        const storeSum = [];

        if(vats[0].show) {
            Object.keys(storeGroup).map(key => {
                const sum = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.daily_traffic_sum;
                }, 0);

                const profit = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.profit;
                }, 0);

                const receipt = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.receipt_quantity;
                }, 0);

                const aVat = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.a_tax;
                }, 0)*0.05;
        
                const bVat = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.b_tax;
                }, 0)*0.18;
        
                const cVat = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.c_tax;
                }, 0)*0.27;

                return storeSum.push({
                    store: key,
                    sum: sum,
                    profit: profit,
                    receipt: receipt,
                    vat: aVat+bVat+cVat
                });
            });
        }
        else if(vats[1].show) {
            Object.keys(storeGroup).map(key => {
                const sum = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.d_tax;
                }, 0);

                const profit = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.profit;
                }, 0);

                const receipt = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.receipt_quantity;
                }, 0);

                return storeSum.push({
                    store: key,
                    sum: sum,
                    profit: profit,
                    receipt: receipt,
                    vat: 0
                });
            });
        }
        else if(vats[2].show) {
            Object.keys(storeGroup).map(key => {
                const sum = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.c_tax;
                }, 0);

                const profit = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.profit;
                }, 0);

                const receipt = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.receipt_quantity;
                }, 0);

                return storeSum.push({
                    store: key,
                    sum: sum,
                    profit: profit,
                    receipt: receipt,
                    vat: sum*0.27
                });
            });
        }
        else if(vats[3].show) {
            Object.keys(storeGroup).map(key => {
                const sum = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.b_tax;
                }, 0);

                const profit = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.profit;
                }, 0);

                const receipt = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.receipt_quantity;
                }, 0);

                return storeSum.push({
                    store: key,
                    sum: sum,
                    profit: profit,
                    receipt: receipt,
                    vat: sum*0.18
                });
            });
        }

        setCurrentBar(storeSum.sort((a, b) => a.store.localeCompare(b.store)));
    };

    const calcPrevBar = (unique) => {
        const storeGroup = unique.reduce((group, x) => {
            (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
            return group;
        }, {});

        const storeSum = [];

        if(vats[0].show) {
            Object.keys(storeGroup).map(key => {
                const sum = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.daily_traffic_sum;
                }, 0);

                const profit = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.profit;
                }, 0);

                const receipt = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.receipt_quantity;
                }, 0);

                const aVat = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.a_tax;
                }, 0)*0.05;
        
                const bVat = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.b_tax;
                }, 0)*0.18;
        
                const cVat = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.c_tax;
                }, 0)*0.27;

                return storeSum.push({
                    store: key,
                    sum: sum,
                    profit: profit,
                    receipt: receipt,
                    vat: aVat+bVat+cVat
                });
            });
        }
        else if(vats[1].show) {
            Object.keys(storeGroup).map(key => {
                const sum = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.d_tax;
                }, 0);

                const profit = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.profit;
                }, 0);

                const receipt = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.receipt_quantity;
                }, 0);

                return storeSum.push({
                    store: key,
                    sum: sum,
                    profit: profit,
                    receipt: receipt,
                    vat: 0
                });
            });
        }
        else if(vats[2].show) {
            Object.keys(storeGroup).map(key => {
                const sum = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.c_tax;
                }, 0);

                const profit = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.profit;
                }, 0);

                const receipt = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.receipt_quantity;
                }, 0);

                return storeSum.push({
                    store: key,
                    sum: sum,
                    profit: profit,
                    receipt: receipt,
                    vat: sum*0.27
                });
            });
        }
        else if(vats[3].show) {
            Object.keys(storeGroup).map(key => {
                const sum = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.b_tax;
                }, 0);

                const profit = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.profit;
                }, 0);

                const receipt = storeGroup[key].reduce((acc, obj) => {
                    return acc + obj.receipt_quantity;
                }, 0);

                return storeSum.push({
                    store: key,
                    sum: sum,
                    profit: profit,
                    receipt: receipt,
                    vat: sum*0.18
                });
            });
        }

        setPrevBar(storeSum.sort((a, b) => a.store.localeCompare(b.store)));
    };

    const calcCurrentPie = (unique) => {
        const currentStores = unique.reduce((group, x) => {
            (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
            return group;
        }, {});

        let currentStoresSum = [];

        if(vats[0].show) {
            currentStoresSum = Object.keys(currentStores).map(key => {
                const sum = currentStores[key].reduce((acc, obj) => {
                    return acc + obj.daily_traffic_sum;
                }, 0);

                return {
                    store: key,
                    color: currentStores[key][0].color_code_2,
                    sum: sum
                };
            });
        }
        else if(vats[1].show) {
            currentStoresSum = Object.keys(currentStores).map(key => {
                const sum = currentStores[key].reduce((acc, obj) => {
                    return acc + obj.d_tax;
                }, 0);

                return {
                    store: key,
                    color: currentStores[key][0].color_code_2,
                    sum: sum
                };
            });
        }
        else if(vats[2].show) {
            currentStoresSum = Object.keys(currentStores).map(key => {
                const sum = currentStores[key].reduce((acc, obj) => {
                    return acc + obj.c_tax;
                }, 0);

                return {
                    store: key,
                    color: currentStores[key][0].color_code_2,
                    sum: sum
                };
            });
        }
        else if(vats[3].show) {
            currentStoresSum = Object.keys(currentStores).map(key => {
                const sum = currentStores[key].reduce((acc, obj) => {
                    return acc + obj.b_tax;
                }, 0);

                return {
                    store: key,
                    color: currentStores[key][0].color_code_2,
                    sum: sum
                };
            });
        }

        setCurrentPie(currentStoresSum.sort((a, b) => a.store.localeCompare(b.store)));
    };

    const calcPrevPie = (unique) => {
        const prevStores = unique.reduce((group, x) => {
            (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
            return group;
        }, {});

        let prevStoresSum = [];

        if(vats[0].show) {
            prevStoresSum = Object.keys(prevStores).map(key => {
                const sum = prevStores[key].reduce((acc, obj) => {
                    return acc + obj.daily_traffic_sum;
                }, 0);

                return {
                    store: key,
                    color: prevStores[key][0].color_code_2,
                    sum: sum
                };
            });
        }
        else if(vats[1].show) {
            prevStoresSum = Object.keys(prevStores).map(key => {
                const sum = prevStores[key].reduce((acc, obj) => {
                    return acc + obj.d_tax;
                }, 0);

                return {
                    store: key,
                    color: prevStores[key][0].color_code_2,
                    sum: sum
                };
            });
        }
        else if(vats[2].show) {
            prevStoresSum = Object.keys(prevStores).map(key => {
                const sum = prevStores[key].reduce((acc, obj) => {
                    return acc + obj.c_tax;
                }, 0);

                return {
                    store: key,
                    color: prevStores[key][0].color_code_2,
                    sum: sum
                };
            });
        }
        else if(vats[3].show) {
            prevStoresSum = Object.keys(prevStores).map(key => {
                const sum = prevStores[key].reduce((acc, obj) => {
                    return acc + obj.b_tax;
                }, 0);

                return {
                    store: key,
                    color: prevStores[key][0].color_code_2,
                    sum: sum
                };
            });
        }

        setPrevPie(prevStoresSum.sort((a, b) => a.store.localeCompare(b.store)));
    };

    const calcCurrentStats = () => {
        const statsObj = {};

        statsObj.total = currentBar.reduce((acc, obj) => {
            return acc + obj.sum;
        }, 0);

        statsObj.avg = (statsObj.total / currentBar.length).toFixed();

        statsObj.profit = currentBar.reduce((acc, obj) => {
            return acc + obj.profit;
        }, 0);

        statsObj.customer = currentBar.reduce((acc, obj) => {
            return acc + obj.receipt;
        }, 0);

        statsObj.basketVal = (statsObj.total / statsObj.customer).toFixed();

        statsObj.vat = vatValues.current;

        setCurrentStats(statsObj);
    };

    const calcPrevStats = () => {
        const statsObj = {};

        statsObj.total = prevBar.reduce((acc, obj) => {
            return acc + obj.sum;
        }, 0);

        statsObj.avg = (statsObj.total / prevBar.length).toFixed();

        statsObj.profit = prevBar.reduce((acc, obj) => {
            return acc + obj.profit;
        }, 0);

        statsObj.customer = prevBar.reduce((acc, obj) => {
            return acc + obj.receipt;
        }, 0);

        statsObj.basketVal = (statsObj.total / statsObj.customer).toFixed();

        statsObj.vat = vatValues.prevYear;

        setPrevStats(statsObj);
    };

    const handleFilter = (vat) => {
        const modifyArr = vats.map(item => {
            if(item.id === vat.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: true
                };
            }

            return {
                id: item.id,
                name: item.name,
                color: item.color,
                show: false
            };
        });

        setVats(modifyArr);
    };

    const handleFilterAvg = (avgItem) => {
        const modifyArr = avg.map(item => {
            if(item.id === avgItem.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: !item.show
                };
            }

            return item;
        });

        setAvg(modifyArr);
    };

    return (
        <Grid item xs={12}>
            <Typography marginBottom={2}>Időszakos forgalom / telephely</Typography>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {vats.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => handleFilter(item)}
                            />
                        );
                    })}
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {avg.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => handleFilterAvg(item)}
                            />
                        );
                    })}
                </Box>
            </Box>

            <Grid item container xs={12}>
                <Grid item xs={12} height={400}>
                    <ReactEcharts
                        option = {{
                            tooltip: {
                                trigger: 'item',
                                valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                            },
                            yAxis: {
                                type: 'value',
                                axisLabel: {
                                    formatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                                }
                            },
                            xAxis: {
                                type: 'category',
                                data: [...currentBar.map(item => item.store)]
                            },
                            grid: {
                                containLabel: true,
                                left: "2%",
                                right: "2%",
                                top: "5%",
                                bottom: "5%"
                            },
                            series: [
                                {
                                    name: "Jelenlegi időszak",
                                    type: 'bar',
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    data: [...currentBar.map(item => ({
                                        value: item.sum,
                                        itemStyle: {color: "#5470C6"}
                                    }))],
                                    markLine: {
                                        label: {
                                            show: false
                                        },
                                        symbol: avg[0].show ? "circle" : "none",
                                        data: [
                                            {
                                                type: "average",
                                                name: "Átlag",
                                                lineStyle: {
                                                    width: avg[0].show ? 1 : 0,
                                                    color: "#2A4AAB"
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    name: "Múlt év időszak",
                                    type: 'bar',
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    data: [...prevBar.map(item => ({
                                        value: item.sum,
                                        itemStyle: {
                                            color: "#C4C4C4"
                                        }
                                    }))],
                                    markLine: {
                                        label: {
                                            show: false
                                        },
                                        symbol: avg[1].show ? "circle" : "none",
                                        data: [
                                            {
                                                type: "average",
                                                name: "Átlag",
                                                lineStyle: {
                                                    width: avg[1].show ? 1 : 0,
                                                    color: "#686868"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }}
                        style={{width: "100%", height: "100%"}}
                    />
                </Grid>

                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={2}
                    >
                        <Stack alignItems="center">
                            <Typography fontSize={16} fontWeight={700}>Totál forgalom</Typography>
                            <Typography fontSize={14} fontWeight={700}>{currentStats.total} HUF</Typography>
                            <Typography fontSize={12}>{prevStats.total} HUF</Typography>
                        </Stack>

                        <Typography fontSize={12}>{currentStats.total === 0 || prevStats.total === 0 ? 0 : (((currentStats.total / prevStats.total) * 100) - 100).toFixed()}%</Typography>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={2}
                    >
                        <Stack alignItems="center">
                            <Typography fontSize={16} fontWeight={700}>Átlag forgalom</Typography>
                            <Typography fontSize={14} fontWeight={700}>{currentStats.avg} HUF</Typography>
                            <Typography fontSize={12}>{prevStats.avg} HUF</Typography>
                        </Stack>

                        <Typography fontSize={12}>{currentStats.avg === 0 || prevStats.avg === 0 ? 0 : (((currentStats.avg / prevStats.avg) * 100) - 100).toFixed()}%</Typography>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={2}
                    >
                        <Stack alignItems="center">
                            <Typography fontSize={16} fontWeight={700}>Profit</Typography>
                            <Typography fontSize={14} fontWeight={700}>{currentStats.profit} HUF</Typography>
                            <Typography fontSize={12}>{prevStats.profit} HUF</Typography>
                        </Stack>

                        <Typography fontSize={12}>{currentStats.profit === 0 || prevStats.profit === 0 ? 0 : (((currentStats.profit / prevStats.profit) * 100) - 100).toFixed()}%</Typography>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={2}
                    >
                        <Stack alignItems="center">
                            <Typography fontSize={16} fontWeight={700}>Vásárlószám</Typography>
                            <Typography fontSize={14} fontWeight={700}>{currentStats.customer} DB</Typography>
                            <Typography fontSize={12}>{prevStats.customer} DB</Typography>
                        </Stack>

                        <Typography fontSize={12}>{currentStats.customer === 0 || prevStats.customer === 0 ? 0 : (((currentStats.customer / prevStats.customer) * 100) - 100).toFixed()}%</Typography>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={2}
                    >
                        <Stack alignItems="center">
                            <Typography fontSize={16} fontWeight={700}>Kosárérték</Typography>
                            <Typography fontSize={14} fontWeight={700}>{currentStats.basketVal} HUF</Typography>
                            <Typography fontSize={12}>{prevStats.basketVal} HUF</Typography>
                        </Stack>

                        <Typography fontSize={12}>{currentStats.basketVal === 0 || prevStats.basketVal === 0 ? 0 : (((currentStats.basketVal / prevStats.basketVal) * 100) - 100).toFixed()}%</Typography>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={2}
                    >
                        <Stack alignItems="center">
                            <Typography fontSize={16} fontWeight={700}>ÁFA</Typography>
                            <Typography fontSize={14} fontWeight={700}>{currentStats.vat} HUF</Typography>
                            <Typography fontSize={12}>{prevStats.vat} HUF</Typography>
                        </Stack>

                        <Typography fontSize={12}>{currentStats.vat === 0 || prevStats.vat === 0 ? 0 : (((currentStats.vat / prevStats.vat) * 100) - 100).toFixed()}%</Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default TotalYears;