import { IconButton } from "@mui/material";

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const OkButton = ({ val, click }) => {
    return (
        <IconButton
            onClick={click}
            size="small"
        >
            <CheckCircleOutlineOutlinedIcon
                style={{
                    fontSize: 32,
                    color: val ? "#13C2C2" : "#8C8C8C"
                }}
            />
        </IconButton>
    );
};

export default OkButton;