import { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import { DataContext } from '../../../context/DataContext';

import { motion } from 'framer-motion';

import { Box, Button, FormControl, Grid, IconButton, InputLabel, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";

import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';

import OpHeader from "../../../components/opheader/OpHeader";
import StockItem from './content/StockItem';
import AddImage from '../../../components/addimage/AddImage';
import StockTag from './content/StockTag';
import PurchaseItem from './content/PurchaseItem';
import TagsDialog from '../../invoices/inbound/content/content/income/content/TagsDialog';
import ProductImage from './content/ProductImage';
import DeliverySummary from './content/DeliverySummary';

const ProductManage = ({ product, stock, onRefresh, onClose }) => {
    const {user, setLoading, setMsg} = useContext(DataContext);

    const [imageKey, setImageKey] = useState(false);

    const [tags, setTags] = useState([]);
    const [purchases, setPurchases] = useState([]);

    const [tagDialog, setTagDialog] = useState(false);

    const [defaultModify, setDefaultModify] = useState(true);
    const [defaultInfo, setDefaultInfo] = useState({
        ...product,
        purchasePrice: Math.round(product.purchasePrice*(product.vat+1)),
        vat: Number(product.vat)*100
    });

    const [priceModify, setPriceModify] = useState(true);

    const [open, setOpen] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getTags();
        getPurchases();
    }, []);

    const getTags = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/product/tags/"+defaultInfo.product_id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTags(data.tags);

        setLoading(false);
    };

    const getPurchases = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/product/purchases", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                product: defaultInfo.product_id
            })
        });

        const data = await res.json();

        if(!data.success) {
            console.log(data.message)
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPurchases(data.purchases);

        setLoading(false);
    };

    const handleDefaultModify = async() => {
        if(!defaultModify) {
            setLoading(true);

            const res = await fetch(apiUrl+"/product/modify", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ product: defaultInfo })
            });

            const data = await res.json();

            if(!data.success) {
                setLoading(false);
                return setMsg({
                    color: "error",
                    message: data.message
                });
            }

            onRefresh();

            setLoading(false);
        }

        setDefaultModify(prev => !prev);
    };

    const handlePriceModify = async() => {
        if(!priceModify) {
            setLoading(true);

            const res = await fetch(apiUrl+"/product/price/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    barcode: product.barcode,
                    company: user.company.id,
                    price: defaultInfo.sellPrice,
                    user: user.id
                })
            });

            const data = await res.json();

            if(!data.success) {
                setLoading(false);
                return setMsg({
                    color: "error",
                    message: data.message
                });
            }

            onRefresh();

            setLoading(false);
        }

        setPriceModify(prev => !prev);
    };

    const handleDeleteTag = async(tag) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/productTags/delete", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                tag: tag.id,
                product: product.product_id
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setLoading(false);

        getTags();
    };

    const handleAddTag = () => {
        getTags();
        setTagDialog(false);
    };

    const uploadImage = async(e) => {
        const file = new FormData();
        file.append("file", e.target.files[0]);

        axios.post(apiUrl+"/product/image/"+defaultInfo.barcode+"/"+defaultInfo.product_id, file)
        .then(res => {
            setDefaultInfo(prev => ({...prev, image: res.data.filename}));
            setImageKey(prev => !prev);

            setMsg({
                color: "success",
                message: "Sikeres feltöltés"
            });
        });
    };

    const renderOpen = () => {
        if(JSON.stringify(open) !== "{}") {
            if(open.type === "delivery") {
                return (
                    <DeliverySummary
                        serialNum={open.serialNum}
                        barcode={product.barcode}
                        onClose={() => setOpen({})}
                    />
                );
            }
        }
    };

    return (
        <>
            {renderOpen()}

            {JSON.stringify(open) === "{}" &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                        >
                            <OpHeader
                                text={
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="flex-start"
                                    >
                                        <Typography fontSize={16} fontWeight={700}>{defaultInfo.product}</Typography>
                                        <Typography fontSize={12} fontWeight={300}>{defaultInfo.barcode}</Typography>
                                    </Box>
                                }
                                onClose={onClose}
                            />

                            <Box
                                display="flex"
                                flexDirection="row"
                                gap={1}
                            >
                                <Tooltip
                                    arrow
                                    title="Forgalmi adatok"
                                >
                                    <motion.div
                                        whileHover={{ scale: 1.15 }}
                                        whileTap={{ scale: 0.85 }}
                                    >
                                        <IconButton>
                                            <PollOutlinedIcon />
                                        </IconButton>
                                    </motion.div>
                                </Tooltip>

                                <Tooltip
                                    arrow
                                    title="Rendelés kalkulátor"
                                >
                                    <motion.div
                                        whileHover={{ scale: 1.15 }}
                                        whileTap={{ scale: 0.85 }}
                                    >
                                        <IconButton>
                                            <CalculateOutlinedIcon />
                                        </IconButton>
                                    </motion.div>
                                </Tooltip>

                                <Tooltip
                                    arrow
                                    title="Termék napló"
                                >
                                    <motion.div
                                        whileHover={{ scale: 1.15 }}
                                        whileTap={{ scale: 0.85 }}
                                    >
                                        <IconButton>
                                            <KitchenOutlinedIcon />
                                        </IconButton>
                                    </motion.div>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            color="#000"
                        >
                            <Typography fontSize={16} fontWeight={700}>Készlet adatok</Typography>

                            <Box
                                display="flex"
                                flexDirection="row"
                                gap={1}
                            >
                                {stock.map(item => {
                                    return (
                                        <StockItem
                                            key={item.id}
                                            stockInfo={item}
                                        />
                                    );
                                })}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            color="#000"
                            alignItems="center"
                        >
                            <Typography
                                fontSize={16}
                                fontWeight={700}
                                alignSelf="flex-start"
                            >
                                Termék adatok
                            </Typography>

                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                gap={1}
                                marginTop={5}
                            >
                                {defaultInfo.image ?
                                    <ProductImage
                                        key={imageKey}
                                        productImage={defaultInfo.image}
                                        onUpload={uploadImage}
                                    />
                                :
                                    <>
                                        <AddImage onUpload={uploadImage} />
                                        <Typography fontSize={12} fontWeight={300}>Kép hozzáadása</Typography>
                                    </>
                                }
                            </Box>

                            <Grid
                                container
                                spacing={1}
                                marginTop={5}
                            >
                                <Grid item>
                                    <StockTag
                                        label="címke hozzáadás"
                                        click={() => setTagDialog(true)}
                                    />

                                    <TagsDialog
                                        open={tagDialog}
                                        setOpen={setTagDialog}
                                        choosen={product}
                                        barcode={product.barcode}
                                        onNext={handleAddTag}
                                    />
                                </Grid>

                                {tags.map(item => {
                                    return (
                                        <Grid item>
                                            <StockTag
                                                label={item.name}
                                                color={item.color_code}
                                                onDelete={() => handleDeleteTag(item)}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            color="#000"
                            gap={1}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                paddingX={1}
                            >
                                <Typography fontSize={16} fontWeight={700}>Alap adatok</Typography>

                                {product.priceType === "N" ?
                                    <Tooltip
                                        arrow
                                        title="Módosítás"
                                    >
                                        <motion.div
                                            whileHover={{ scale: 1.15 }}
                                            whileTap={{ scale: 0.85 }}
                                        >
                                            <IconButton
                                                onClick={handleDefaultModify}
                                                size="small"
                                            >
                                                <BorderColorOutlinedIcon />
                                            </IconButton>
                                        </motion.div>
                                    </Tooltip>
                                :
                                    <HttpsOutlinedIcon />
                                }
                            </Box>

                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={2}
                            >
                                <FormControl>
                                    <InputLabel htmlFor="label">Megnevezés</InputLabel>

                                    <OutlinedInput
                                        id="label"
                                        label="Megnevezés"
                                        readOnly={defaultModify}
                                        value={defaultInfo.product}
                                        onChange={(e) => setDefaultInfo(prev => ({...prev, product: e.target.value}))}
                                    />
                                </FormControl>

                                <FormControl>
                                    <InputLabel htmlFor="label">Vonalkód</InputLabel>

                                    <OutlinedInput
                                        id="label"
                                        label="Vonalkód"
                                        readOnly={defaultModify}
                                        value={defaultInfo.barcode}
                                        onChange={(e) => setDefaultInfo(prev => ({...prev, barcode: e.target.value}))}
                                    />
                                </FormControl>

                                <FormControl>
                                    <InputLabel htmlFor="label">Cikkszám</InputLabel>

                                    <OutlinedInput
                                        id="label"
                                        label="Cikkszám"
                                        readOnly={defaultModify}
                                        value={defaultInfo.articleNum}
                                        onChange={(e) => setDefaultInfo(prev => ({...prev, articleNum: e.target.value}))}
                                    />
                                </FormControl>

                                <FormControl>
                                    <InputLabel htmlFor="label">Áfakör</InputLabel>

                                    <OutlinedInput
                                        id="label"
                                        label="Áfakör"
                                        readOnly={defaultModify}
                                        value={defaultInfo.vat}
                                        onChange={(e) => setDefaultInfo(prev => ({...prev, vat: e.target.value}))}
                                        endAdornment="%"
                                    />
                                </FormControl>

                                <FormControl>
                                    <InputLabel htmlFor="label">Kiszerelés</InputLabel>

                                    <OutlinedInput
                                        id="label"
                                        label="Kiszerelés"
                                        readOnly={defaultModify}
                                        value={defaultInfo.unit}
                                        onChange={(e) => setDefaultInfo(prev => ({...prev, unit: e.target.value}))}
                                    />
                                </FormControl>

                                <FormControl>
                                    <InputLabel htmlFor="label">Rendelési egység</InputLabel>

                                    <OutlinedInput
                                        id="label"
                                        label="Rendelési egység"
                                        readOnly={defaultModify}
                                        value={defaultInfo.unit}
                                        onChange={(e) => setDefaultInfo(prev => ({...prev, unit: e.target.value}))}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            color="#000"
                            height="100%"
                            gap={1}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                paddingX={1}
                            >
                                <Typography fontSize={16} fontWeight={700}>Árak</Typography>

                                {product.priceType === "N" ?
                                    <Tooltip
                                        arrow
                                        title="Módosítás"
                                    >
                                        <motion.div
                                            whileHover={{ scale: 1.15 }}
                                            whileTap={{ scale: 0.85 }}
                                        >
                                            <IconButton
                                                onClick={handlePriceModify}
                                                size="small"
                                            >
                                                <BorderColorOutlinedIcon />
                                            </IconButton>
                                        </motion.div>
                                    </Tooltip>
                                :
                                    <HttpsOutlinedIcon />
                                }
                            </Box>

                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={2}
                                height="100%"
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={1}
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <FormControl sx={{ width: "80%" }}>
                                        <InputLabel htmlFor="label">Bruttó eladási ár</InputLabel>

                                        <OutlinedInput
                                            id="label"
                                            label="Bruttó eladási ár"
                                            readOnly={priceModify}
                                            value={defaultInfo.sellPrice}
                                            onChange={(e) => setDefaultInfo(prev => ({...prev, sellPrice: e.target.value}))}
                                            endAdornment="HUF"
                                            fullWidth
                                        />
                                    </FormControl>

                                    <Typography
                                        fontSize={12}
                                        fontWeight={300}
                                    >
                                        Árrés: <Typography fontSize={20} fontWeight={700} color="#389E0D">{(((defaultInfo.sellPrice/defaultInfo.purchasePrice)-1)*100).toFixed(2)} %</Typography>
                                    </Typography>
                                </Box>

                                <FormControl>
                                    <InputLabel htmlFor="label">Nettó eladási ár</InputLabel>

                                    <OutlinedInput
                                        id="label"
                                        label="Nettó eladási ár"
                                        readOnly
                                        value={(defaultInfo.sellPrice-(defaultInfo.sellPrice*(defaultInfo.vat/100))).toFixed(2)}
                                        endAdornment="HUF"
                                    />
                                </FormControl>

                                <FormControl>
                                    <InputLabel htmlFor="label">Bruttó beszer ár</InputLabel>

                                    <OutlinedInput
                                        id="label"
                                        label="Bruttó beszer ár"
                                        readOnly={priceModify}
                                        value={defaultInfo.purchasePrice}
                                        onChange={(e) => setDefaultInfo(prev => ({...prev, purchasePrice: e.target.value}))}
                                        endAdornment="HUF"
                                    />
                                </FormControl>

                                <FormControl>
                                    <InputLabel htmlFor="label">Nettó beszer ár</InputLabel>

                                    <OutlinedInput
                                        id="label"
                                        label="Nettó beszer ár"
                                        readOnly
                                        value={(defaultInfo.purchasePrice-(defaultInfo.purchasePrice*(defaultInfo.vat/100))).toFixed(2)}
                                        endAdornment="HUF"
                                    />
                                </FormControl>

                                <Button
                                    variant="contained"
                                    size='large'
                                    fullWidth
                                    disabled={product.priceType === "Z"}
                                    sx={{
                                        bgcolor: "#13C2C2",
                                        marginTop: "auto"
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        alignItems="center"
                                    >
                                        <StarBorderOutlinedIcon style={{ fontSize: 14 }} />
                                        <Typography fontSize={12} fontWeight={400}>Akció beállítása</Typography>
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            color="#000"
                        >
                            <Typography fontSize={16} fontWeight={700}>Legutóbbi beszerzések</Typography>

                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Megnevezés</TableCell>
                                            <TableCell>Dátum</TableCell>
                                            <TableCell>Beérkező mennyiség</TableCell>
                                            <TableCell>Nettó egység ár</TableCell>
                                            <TableCell>Bruttó egység ár</TableCell>
                                            <TableCell align="center">Műveletek</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {purchases.map((item, i) => {
                                            return (
                                                <PurchaseItem
                                                    key={item.id}
                                                    index={i+1}
                                                    purchase={item}
                                                    click={(type, serialNum) => setOpen({
                                                        type: type,
                                                        serialNum: serialNum
                                                    })}
                                                />
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default ProductManage;