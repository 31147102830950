import { useContext, useEffect, useState } from "react";

import ReactApexChart from 'react-apexcharts';

import { DataContext } from "../../../../context/DataContext";

import { Box, Card, Grid, Skeleton, Typography } from "@mui/material";

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CurrencyText from "../../../../components/currencytext/CurrencyText";

const DailyChart = ({ date, active, filter }) => {
    const {user, setMsg} = useContext(DataContext);

    const [traffic, setTraffic] = useState([]);
    const [daily, setDaily] = useState({});
    const [prevDay, setPrevDay] = useState({});

    const [hours, setHours] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getDaily();
    }, [date, active]);

    useEffect(() => {
        const filtered = traffic.filter(item =>
            filter.filter(item => item.active)
            .map(item => item.id)
            .includes(item.store)
        );
        
        const storeGroup = filtered.reduce((group, x) => {
            (group[x["store"]] = group[x["store"]] || []).push(x);
            return group;
        }, {});

        setDaily(storeGroup);
    }, [traffic, filter]);

    const getDaily = async() => {
        const res = await fetch(apiUrl+"/traffic/daily", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                day: date,
                info: active,
                company: user.company.id
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPrevDay(data.prevDay);

        setTraffic(data.traffic);

        const hourGroup = data.traffic.reduce((group, x) => {
            (group[x["hour"]] = group[x["hour"]] || []).push(x);
            return group;
        }, {});

        setHours(Object.keys(hourGroup).map(key => Number(key)).sort((a, b) => a - b));
    };

    const calculateSum = () => {
        const sum = traffic.filter(item =>
            filter.filter(item => item.active)
            .map(item => item.id)
            .includes(item.store))
            .reduce((acc, obj) => {
                return acc + obj.sum_traffic;
            },0);

        return sum;
    };

    return (
        <Grid item xs={12} md={8}>
            <Card
                variant="outlined"
                sx={{ p: 2, height: 390 }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    <Typography
                        fontSize={12}
                        fontWeight={400}
                        color="#8C8C8C"
                    >
                        {active === 0 && "Napi forgalom total / órai bontásban"}
                        {active === 1 && "Napi vásárlószám total / órai bontásban"}
                        {active === 2 && "Napi profit total / órai bontásban"}
                        {active === 3 && "Napi árrés total / órai bontásban"}
                        {active === 4 && "Napi kosárérték total / órai bontásban"}
                    </Typography>

                    {traffic.length !== 0 ?
                        <>
                            {(active === 3 || active === 4) &&
                                <>
                                    {((((calculateSum()/traffic.length)/(prevDay.sum_traffic))*100)-100) > 0 ?
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            color="#52C41A"
                                            alignItems="center"
                                        >
                                            <ArrowDropUpIcon style={{ fontSize: 30 }} />

                                            <Typography
                                                fontSize={14}
                                                fontWeight={500}
                                            >
                                                {active === 3 &&
                                                    <>
                                                        {(calculateSum()/traffic.length).toFixed(2)} % ({((((calculateSum()/traffic.length)/(prevDay.sum_traffic))*100)-100).toFixed(2)} %)
                                                    </>
                                                }

                                                {active === 4 &&
                                                    <>
                                                        <CurrencyText val={Math.round((calculateSum()/traffic.length))}/> HUF ({((((calculateSum()/traffic.length)/(prevDay.sum_traffic))*100)-100).toFixed(2)} %)
                                                    </>
                                                }
                                            </Typography>
                                        </Box>
                                    :
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            color="#F5222D"
                                            alignItems="center"
                                        >
                                            <ArrowDropDownIcon style={{ fontSize: 30 }} />

                                            <Typography
                                                fontSize={14}
                                                fontWeight={500}
                                            >
                                                {active === 3 &&
                                                    <>
                                                        {(calculateSum()/traffic.length).toFixed(2)} % ({((((calculateSum()/traffic.length)/(prevDay.sum_traffic))*100)-100).toFixed(2)} %)
                                                    </>
                                                }

                                                {active === 4 &&
                                                    <>
                                                        <CurrencyText val={Math.round((calculateSum()/traffic.length))}/> HUF ({((((calculateSum()/traffic.length)/(prevDay.sum_traffic))*100)-100).toFixed(2)} %)
                                                    </>
                                                }
                                            </Typography>
                                        </Box>
                                    }
                                </>
                            }

                            {(active !== 3 && active !== 4) &&
                                <>  
                                    {(((calculateSum()/(prevDay.sum_traffic))*100)-100) > 0 ?
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        color="#52C41A"
                                        alignItems="center"
                                    >
                                        <ArrowDropUpIcon style={{ fontSize: 30 }} />

                                        <Typography
                                            fontSize={14}
                                            fontWeight={500}
                                        >
                                            {(active === 0 || active === 2) &&
                                                <>
                                                    <CurrencyText val={calculateSum()}/> HUF ({(((calculateSum()/(prevDay.sum_traffic))*100)-100).toFixed(2)} %)
                                                </>
                                            }
                                            
                                            {active === 1 &&
                                                <>
                                                    {calculateSum()} DB ({(((calculateSum()/(prevDay.sum_traffic))*100)-100).toFixed(2)} %)
                                                </>
                                            }
                                        </Typography>
                                    </Box>
                                :
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        color="#F5222D"
                                        alignItems="center"
                                    >
                                        <ArrowDropDownIcon style={{ fontSize: 30 }} />

                                        <Typography
                                            fontSize={14}
                                            fontWeight={500}
                                        >
                                            {(active === 0 || active === 2) &&
                                                <>
                                                    <CurrencyText val={calculateSum()}/> HUF ({(((calculateSum()/(prevDay.sum_traffic))*100)-100).toFixed(2)} %)
                                                </>
                                            }
                                            
                                            {active === 1 &&
                                                <>
                                                    {calculateSum()} DB ({(((calculateSum()/(prevDay.sum_traffic))*100)-100).toFixed(2)} %)
                                                </>
                                            }
                                        </Typography>
                                    </Box>
                                }
                                </>
                            }
                        </>
                    :
                        <Skeleton variant="rounded" width={210} height={20} />
                    }

                    {prevDay.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#8C8C8C"
                        >
                            Összehasonlítás: {prevDay.date}
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={170} height={15} />
                    }

                    {(active === 0 || active === 2 || active === 4) &&
                        <ReactApexChart
                            type="line"
                            height={295}
                            options={{
                                chart: {
                                    height: 295,
                                    type: "line",
                                    toolbar: {
                                        show: false
                                    }
                                },
                                tooltip: {
                                    x: {
                                        formatter: (val) => val+" óra"
                                    },
                                    y: {
                                        formatter: (val) => Math.round(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" HUF"
                                    }
                                },
                                stroke: {
                                    width: 2
                                },
                                grid: {
                                    show: true,
                                    borderColor: '#F0F0F0',
                                    strokeDashArray: 0,
                                    position: 'back',
                                    xaxis: {
                                        lines: {
                                            show: true
                                        }
                                    },
                                    yaxis: {
                                        lines: {
                                            show: true
                                        }
                                    }
                                },
                                legend: {
                                    show: false
                                },
                                markers: {
                                    size: 3
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                yaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        formatter: (val) => Math.round(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" HUF",
                                        style: {
                                            colors: ["#8C8C8C"]
                                        }
                                    }
                                },
                                xaxis: {
                                    categories: hours,
                                    axisBorder: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: hours.map((_) => "#8C8C8C")
                                        }
                                    }
                                }
                            }}
                            series={Object.keys(daily).map(key => {
                                return {
                                    name: daily[key][0].name,
                                    color: daily[key][0].color_code_2,
                                    data: daily[key].map(item => item.sum_traffic)
                                };
                            })}
                        />
                    }

                    {active === 1 &&
                        <ReactApexChart
                            type="line"
                            height={295}
                            options={{
                                chart: {
                                    height: 295,
                                    type: "line",
                                    toolbar: {
                                        show: false
                                    }
                                },
                                tooltip: {
                                    x: {
                                        formatter: (val) => val+" óra"
                                    },
                                    y: {
                                        formatter: (val) => val+" DB"
                                    }
                                },
                                stroke: {
                                    width: 2
                                },
                                grid: {
                                    show: true,
                                    borderColor: '#F0F0F0',
                                    strokeDashArray: 0,
                                    position: 'back',
                                    xaxis: {
                                        lines: {
                                            show: true
                                        }
                                    },
                                    yaxis: {
                                        lines: {
                                            show: true
                                        }
                                    }
                                },
                                legend: {
                                    show: false
                                },
                                markers: {
                                    size: 3
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                yaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        formatter: (val) => val+" DB",
                                        style: {
                                            colors: ["#8C8C8C"]
                                        }
                                    }
                                },
                                xaxis: {
                                    categories: hours,
                                    axisBorder: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: hours.map((_) => "#8C8C8C")
                                        }
                                    }
                                }
                            }}
                            series={Object.keys(daily).map(key => {
                                return {
                                    name: daily[key][0].name,
                                    color: daily[key][0].color_code_2,
                                    data: daily[key].map(item => item.sum_traffic)
                                };
                            })}
                        />
                    }

                    {active === 3 &&
                        <ReactApexChart
                            type="line"
                            height={295}
                            options={{
                                chart: {
                                    height: 295,
                                    type: "line",
                                    toolbar: {
                                        show: false
                                    }
                                },
                                tooltip: {
                                    x: {
                                        formatter: (val) => val+" óra"
                                    },
                                    y: {
                                        formatter: (val) => val+" %"
                                    }
                                },
                                stroke: {
                                    width: 2
                                },
                                grid: {
                                    show: true,
                                    borderColor: '#F0F0F0',
                                    strokeDashArray: 0,
                                    position: 'back',
                                    xaxis: {
                                        lines: {
                                            show: true
                                        }
                                    },
                                    yaxis: {
                                        lines: {
                                            show: true
                                        }
                                    }
                                },
                                legend: {
                                    show: false
                                },
                                markers: {
                                    size: 3
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                yaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        formatter: (val) => val+" %",
                                        style: {
                                            colors: ["#8C8C8C"]
                                        }
                                    }
                                },
                                xaxis: {
                                    categories: hours,
                                    axisBorder: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: hours.map((_) => "#8C8C8C")
                                        }
                                    }
                                }
                            }}
                            series={Object.keys(daily).map(key => {
                                return {
                                    name: daily[key][0].name,
                                    color: daily[key][0].color_code_2,
                                    data: daily[key].map(item => item.sum_traffic)
                                };
                            })}
                        />
                    }
                </Box>
            </Card>
        </Grid>
    );
};

export default DailyChart;