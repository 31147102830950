import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useDebounce from "../../../hooks/useDebounce";
import { DataContext } from "../../../context/DataContext";
import { getModules, getUserCompanies, getUserOperations } from "../../../services/Api";

import { Backdrop, Box, CircularProgress, Container, Grid, InputAdornment, OutlinedInput, Toolbar } from "@mui/material";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import Dashboard from "../../../layouts/dashboard/Dashboard";
import Feedback from "../../../components/feedback/Feedback";
import TypeItem from "./content/TypeItem";

const CompanyType = () => {
    const {
        user, setUser,
        setStores,
        loading, setLoading,
        msg, setMsg,
        setOps,
        setModuleTab
    } = useContext(DataContext);

    const [types, setTypes] = useState([]);
    const [showTypes, setShowTypes] = useState([]);

    const [search, setSearch] = useState("");

    const location = useLocation();
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getTypes();
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            setShowTypes(types.filter(item => rx.test(item.name) || rx.test(item.description)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, types], 500);

    const getTypes = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/company/types/get", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTypes(data.types);

        setLoading(false);
    };

    const handleCreateCompany = async(typeId) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/company/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                company: location.state,
                companyType: typeId
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getUserCompanies(user.id).then(userCompanies => {
            setUser(prev => ({...prev, company: userCompanies.filter(item => item.id === data.newCompany.id)[0], companies: userCompanies}));

            setStores([]);

            getUserOperations(user.id).then(data => {
                setOps(data);
            });

            getModules(userCompanies.filter(item => item.id === data.newCompany.id)[0].id).then(modules => setModuleTab(modules.length+1));
            
            setMsg({
                color: "success",
                message: "Sikeresen létrehoztad a szervezetet!"
            });

            navigate("/home");

            setLoading(false);
        });
    };

    return (
        <Dashboard>
            <Backdrop
                sx={{ color: '#fff', zIndex: 10000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {Object.keys(msg).length !== 0 &&
                <Feedback
                    color={msg.color}
                    message={msg.message}
                />
            }

            <Box
                component="main"
                flexGrow={1}
                height="100vh"
                overflow="auto"
                sx={{ bgcolor: "#FFF" }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <OutlinedInput
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Keresés"
                                fullWidth
                                size="small"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon style={{ width: 20 }} />
                                    </InputAdornment>
                                }
                                sx={{
                                    fontSize: 12,
                                    backgroundColor: "#FFFFFF"
                                }}
                            />
                        </Grid>

                        {showTypes.map(item => {
                            return (
                                <TypeItem
                                    key={item.id}
                                    typeInfo={item}
                                    onChoose={() => handleCreateCompany(item.id)}
                                />
                            );
                        })}
                    </Grid>
                </Container>
            </Box>
        </Dashboard>
    );
};

export default CompanyType;