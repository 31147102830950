import { useEffect, useState } from "react";

import ReactEcharts from "echarts-for-react";

import { Box, Grid, Typography } from "@mui/material";

const StoresTraffic = ({ traffic }) => {

    const [dates, setDates] = useState([]);

    const [storesData, setStoresData] = useState({});

    useEffect(() => {
        if(JSON.stringify(traffic) !== "[]") {
            calcData();
        }
    }, [traffic?.current?.data]);

    const dateRange = (startDate, endDate) => {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate).toISOString("hu-HU").slice(0, 10));
            currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        }

        return dateArray;
    };

    const calcData = () => {
        const unique = traffic.current.data.filter((obj, index) => {
            return index === traffic.current.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        }).reverse();

        const storeGroup = unique.reduce((group, x) => {
            (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
            return group;
        }, {});

        const datesArr = dateRange(traffic.current.from, traffic.current.to);

        setDates(datesArr);

        let allDatesSum = {};

        Object.keys(storeGroup).map(key => {
            allDatesSum[key] = [];

            return storeGroup[key].map(item => {
                const filtered = datesArr.filter(date => date === new Date(item.date_from).toISOString("hu-HU").slice(0, 10));
    
                if(filtered.length === 0) {
                    return allDatesSum[key].push({
                        date: item.date_from,
                        daily_traffic_sum: 0
                    });
                }
                else {
                    return allDatesSum[key].push(item);
                }
            });
        });

        setStoresData(allDatesSum);
    };

    return (
        <Grid item xs={12}>
            <Typography marginBottom={2}>Időszakos forgalom / nap / telephely</Typography>

            <Box
                width="100%"
                height={300}
            >
                <ReactEcharts
                    option={{
                        xAxis: {
                            type: "category",
                            boundaryGap: false,
                            data: dates.map(item => {
                                return item+"\n("+new Date(item).toLocaleDateString("hu-HU", { weekday: "long" })+")";
                            })
                        },
                        yAxis: {
                            type: "value",
                            axisLabel: {
                                formatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                            }
                        },
                        dataZoom: [
                            {
                                type: "slider",
                                show: true,
                                start: 0,
                                end: 100
                            }
                        ],
                        grid: {
                            containLabel: true,
                            left: "2%",
                            right: "2%",
                            top: "15%",
                            bottom: "18%"
                        },
                        legend: {
                            left: "2%",
                            right: "2%",
                            top: 0
                        },
                        series: [
                            ...Object.keys(storesData).map(key => ({
                                type: "line",
                                name: key,
                                symbolSize: 10,
                                lineStyle: {
                                    color: storesData[key][0].color_code_2
                                },
                                itemStyle: {
                                    color: storesData[key][0].color_code_2
                                },
                                data: [
                                    ...storesData[key].map((item) => ({
                                        date: item.date,
                                        value: item.daily_traffic_sum
                                    }))
                                ]
                            }))
                        ],
                        tooltip: {
                            trigger: "axis",
                            valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                        }
                    }}
                    style={{width: "100%", height: "100%"}}
                />
            </Box>
        </Grid>
    );
};

export default StoresTraffic;