import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, FormControl, Grid, InputAdornment, MenuItem, OutlinedInput, Paper, Select, Stack, Step, StepButton, Stepper, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import OpHeader from "../../../components/opheader/OpHeader";

import NewUserPermission from "./NewUserPermission";

const NewUserPersonal = ({ newUser, onClose }) => {
    const {setMsg, setLoading} = useContext(DataContext);

    const [prefix, setPrefix] = useState([]);

    const [personal, setPersonal] = useState({
        birthDay: dayjs(),
        birthPlace: "",
        zip: "",
        city: "",
        address: "",
        phonePrefix: "",
        phoneNum: "",
        email: "",
        bank1: "",
        bank2: "",
        bank3: "",
        social: "",
        tax: "",
        companyIn: dayjs(),
        companyOut: dayjs(),
        medical: dayjs(),
        lung: dayjs()
    });

    const [next, setNext] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        getPrefix();

        setLoading(false);
    }, []);

    const getPrefix = async() => {
        const res = await fetch(apiUrl+"/phone/prefix", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPrefix(data.prefixes);
    };

    const handleNext = () => {
        if(Object.values(personal).some(prop => prop === "")) {
            return setMsg({
                color: "error",
                message: "Minden mező kitöltése kötelező"
            });
        }

        setNext(true);
    };

    const renderPermission = () => {
        if(next) {
            return (
                <NewUserPermission
                    newUser={({...newUser, ...personal})}
                    onClose={() => setNext(false)}
                    onProfile={onClose}
                />
            );
        }
    };

    return (
        <>
            {renderPermission()}

            {!next && 
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={15}
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Paper
                            sx={{ p: 1 }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Stepper activeStep={1}>
                                        <Step>
                                            <StepButton
                                                icon={<PersonOutlineOutlinedIcon />}
                                                onClick={onClose}
                                            >
                                                Profil
                                            </StepButton>
                                        </Step>

                                        <Step>
                                            <StepButton icon={<PersonOutlineOutlinedIcon style={{ color: "#1890FF" }} />}>
                                                Személyes adatok
                                            </StepButton>
                                        </Step>

                                        <Step>
                                            <StepButton icon={<PersonOutlineOutlinedIcon />}>
                                                Jogosultságok
                                            </StepButton>
                                        </Step>

                                        <Step>
                                            <StepButton icon={<PersonOutlineOutlinedIcon />}>
                                                Jelszó
                                            </StepButton>
                                        </Step>
                                    </Stepper>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        justifyContent="space-between"
                                        width={540}
                                    >
                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Születési idő</Typography>
                                            <DatePicker
                                                value={personal.birthDay}
                                                onChange={(newVal) => setPersonal(prev => ({...prev, birthDay: newVal}))}
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>

                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Születési hely</Typography>
                                            <OutlinedInput
                                                value={personal.birthPlace}
                                                onChange={(e) => setPersonal(prev => ({...prev, birthPlace: e.target.value}))}
                                                size="small"
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        justifyContent="space-between"
                                        width={540}
                                    >
                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Irányítószám</Typography>
                                            <OutlinedInput
                                                value={personal.zip}
                                                onChange={(e) => setPersonal(prev => ({...prev, zip: e.target.value}))}
                                                size="small"
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>

                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Város</Typography>
                                            <OutlinedInput
                                                value={personal.city}
                                                onChange={(e) => setPersonal(prev => ({...prev, city: e.target.value}))}
                                                size="small"
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        width={540}
                                    >
                                        <Typography
                                            fontSize={14}
                                            fontWeight={400}
                                            color="#8C8C8C"
                                        >
                                            Cím
                                        </Typography>

                                        <OutlinedInput
                                            value={personal.address}
                                            onChange={(e) => setPersonal(prev => ({...prev, address: e.target.value}))}
                                            fullWidth
                                            size="small"
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        justifyContent="space-between"
                                        width={540}
                                    >
                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Telefonszám</Typography>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                gap={1}
                                            >
                                                <FormControl size="small" sx={{ width: 80 }}>
                                                    <Select
                                                        value={personal.phonePrefix}
                                                        onChange={(e) => setPersonal(prev => ({...prev, phonePrefix: e.target.value}))}
                                                    >
                                                        {prefix.map(item => {
                                                            return (
                                                                <MenuItem key={item.id} value={item.id}>{item.prefix}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>

                                                <OutlinedInput
                                                    value={personal.phoneNum}
                                                    onChange={(e) => setPersonal(prev => ({...prev, phoneNum: e.target.value}))}
                                                    size="small"
                                                    sx={{
                                                        width: 180
                                                    }}
                                                />
                                            </Box>
                                        </Stack>

                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Email cím</Typography>
                                            <OutlinedInput
                                                value={personal.email}
                                                onChange={(e) => setPersonal(prev => ({...prev, email: e.target.value}))}
                                                size="small"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <MailOutlineIcon />
                                                    </InputAdornment>
                                                }
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        justifyContent="space-between"
                                        width={540}
                                    >
                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Bankszámlaszám</Typography>
                                            <OutlinedInput
                                                value={personal.bank1}
                                                onChange={(e) => setPersonal(prev => ({...prev, bank1: e.target.value}))}
                                                size="small"
                                                sx={{
                                                    width: 175
                                                }}
                                            />
                                        </Stack>
                                        
                                        <Stack alignSelf="flex-end">
                                            <OutlinedInput
                                                value={personal.bank2}
                                                onChange={(e) => setPersonal(prev => ({...prev, bank2: e.target.value}))}
                                                size="small"
                                                sx={{
                                                    width: 175
                                                }}
                                            />
                                        </Stack>
                                        

                                        <Stack alignSelf="flex-end">
                                            <OutlinedInput
                                                value={personal.bank3}
                                                onChange={(e) => setPersonal(prev => ({...prev, bank3: e.target.value}))}
                                                size="small"
                                                sx={{
                                                    width: 175
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        justifyContent="space-between"
                                        width={540}
                                    >
                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">TAJ szám</Typography>
                                            <OutlinedInput
                                                value={personal.social}
                                                onChange={(e) => setPersonal(prev => ({...prev, social: e.target.value}))}
                                                size="small"
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>

                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Adószám</Typography>
                                            <OutlinedInput
                                                value={personal.tax}
                                                onChange={(e) => setPersonal(prev => ({...prev, tax: e.target.value}))}
                                                size="small"
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        justifyContent="space-between"
                                        width={540}
                                    >
                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Munkaszerződés kezdete</Typography>
                                            <DatePicker
                                                value={personal.companyIn}
                                                onChange={(newVal) => setPersonal(prev => ({...prev, companyIn: newVal}))}
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>

                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Munkaszerződés vége</Typography>
                                            <DatePicker
                                                value={personal.companyOut}
                                                onChange={(newVal) => setPersonal(prev => ({...prev, companyOut: newVal}))}
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        justifyContent="space-between"
                                        width={540}
                                    >
                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Orvosi alkalmassági érvényessége</Typography>
                                            <DatePicker
                                                value={personal.medical}
                                                onChange={(newVal) => setPersonal(prev => ({...prev, medical: newVal}))}
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>

                                        <Stack>
                                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Tüdőszűrés érvényessége</Typography>
                                            <DatePicker
                                                value={personal.lung}
                                                onChange={(newVal) => setPersonal(prev => ({...prev, lung: newVal}))}
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{
                                                    width: 265
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        justifyContent="flex-end"
                                    >
                                        <Button variant="outlined" onClick={onClose}>Vissza</Button>
                                        <Button variant="contained" onClick={handleNext}>Következő</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default NewUserPersonal;