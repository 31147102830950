import { useState, createContext, useRef } from "react";

import StoreImg from "../assets/body/ops_store.svg";
import OutboundInvoicePNG from "../assets/module/invoices/outbound_invoice.png";
import FilingPNG from "../assets/module/invoices/filing.png";

import DayopenStore from "../pages/dayopen/DayopenStore";
import IncomingTickets from "../pages/tickets/incoming/IncomingTickets";
import TrafficTickets from "../pages/tickets/traffic/TrafficTickets";
import TransitSafe from "../pages/safe/TransitSafe";
import ManageSafe from "../pages/safe/ManageSafe";
import ChangeFile from "../pages/daychange/ChangeFile";
import CloseFile from "../pages/dayclose/CloseFile";
import TicketManage from "../pages/tickets/ticketinfo/ticketmanage/TicketManage";
import CashoutTickets from "../pages/tickets/cashout/CashoutTickets";
import Diagrams from "../pages/diagrams/Diagrams";
import DiagramsDev from "../pages/diadev/DiagramsDev";
import Users from "../pages/usermanage/Users";
import NewUserProfile from "../pages/usermanage/newuser/NewUserProfile";
import Tickets from "../pages/tickets/Tickets";
import ManageTickets from "../pages/tickets/manage/ManageTickets";
import CashOut from "../pages/cashregister/CashOut";
import CashIn from "../pages/cashregister/CashIn";
import SafeCashOut from "../pages/safe/SafeCashOut";
import InvoicesInbound from "../pages/invoices/inbound/InvoicesInbound";
import NewInvoice from "../pages/invoices/inbound/NewInvoice";
import InvoicesOutbound from "../pages/invoices/outbound/InvoicesOutbound";
import IncomingProducts from "../pages/productsdev/IncomingProducts";
import Stock from "../pages/stock/Stock";
import CompanyCreate from "../pages/company/CompanyCreate";
import Invite from "../pages/usermanage/invite/Invite";
import CreateRank from "../pages/ranks/CreateRank";
import ProductsPrice from "../pages/productsdev/ProductsPrice";
import AddProduct from "../pages/productsdev/AddProduct";
import Transfers from "../pages/transfers/Transfers";
import Reservations from "../pages/apartmans/Reservations";
import ResApartman from "../pages/apartmans/newreservation/ResApartman";
import ManageStores from "../pages/shoparea/manage/ManageStores";
import CashinSafe from "../pages/safe/CashinSafe";
import DailyTraffic from "../pages/traffic/DailyTraffic";
import ProductsIncome from "../pages/income/ProductsIncome";

export const DataContext = createContext();

export const StateProvider = ({ children }) => {
    const [user, setUser] = useState("");
    const [ops, setOps] = useState([]);
    const [stores, setStores] = useState([]);
    const [navOpen, setNavOpen] = useState(false);
    const [moduleTab, setModuleTab] = useState(1);

    const [opComps] = useState({
        1: <DayopenStore />,
        2: <CloseFile />,
        3: <ChangeFile />,
        4: <NewInvoice />,
        5: <SafeCashOut />,
        6: <TransitSafe />,
        8: <ManageSafe />,
        9: <CashOut />,
        10: <CashIn />,
        11: <IncomingTickets />,
        12: <CashoutTickets />,
        13: <TrafficTickets />,
        14: <Users />,
        15: <NewUserProfile />,
        16: <ManageTickets />,
        17: <TicketManage />,
        19: <Diagrams />,
        20: <DiagramsDev />,
        21: <Tickets />,
        22: <InvoicesInbound />,
        23: <InvoicesOutbound />,
        24: <ManageStores />,
        26: <IncomingProducts />,
        27: <Stock />,
        28: <CompanyCreate />,
        29: <Invite />,
        30: <CreateRank />,
        31: <ProductsPrice />,
        32: <AddProduct />,
        33: <Transfers />,
        34: <Reservations />,
        35: <ResApartman />,
        37: <CashinSafe />,
        38: <DailyTraffic />,
        39: <ProductsIncome />
    });
    const [openOper, setOpenOper] = useState(0);

    const [icons] = useState([
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        StoreImg,
        StoreImg,
        FilingPNG,
        FilingPNG,
        OutboundInvoicePNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG,
        FilingPNG
    ]);

    const [colors] = useState([
        "green",
        "purple",
        "blue",
        "aqua",
        "magenta"
    ]);

    const [pcs, setPcs] = useState({
        huf5: "",
        huf10: "",
        huf20: "",
        huf50: "",
        huf100: "",
        huf200: "",
        huf500: "",
        huf1000: "",
        huf2000: "",
        huf5000: "",
        huf10000: "",
        huf20000: "",
        sum: 0
    });

    const [newBillStats, setNewBillStats] = useState({
        completion_date: "",
        payment_deadline: "",
        bill_serial_number: "",
        cash: "",
        amount: "",
        pay: ""
    });
    const [traffic, setTraffic] = useState([]);

    const [choosenPart, setChoosenPart] = useState();

    const [msg, setMsg] = useState({});
    const [loading, setLoading] = useState(false);

    const [scroll, setScroll] = useState(0);
    const scrollRef = useRef();

    const homeRef = useRef();
    const searchRef = useRef();
    const slideRef = useRef();
    const headerRef = useRef();

    const [search, setSearch] = useState("");
    const [showSearch, setShowSearch] = useState("");

    const [profile, setProfile] = useState({
        color: "#FFFFFF",
        rank: 0,
        lastName: "",
        firstName: "",
        nickName: "",
        birthPlace: "",
        birthDate: "",
        nameDay: "",
        zip: "",
        city: "",
        address: "",
        social: "",
        personalId: "",
        tax: "",
        bank1: "",
        bank2: "",
        bank3: "",
        email: "",
        medical: "",
        lungScreen: "",
        companyIn: ""
    });

    const value = {
        user,
        setUser,
        ops,
        setOps,
        stores,
        setStores,
        navOpen,
        setNavOpen,
        moduleTab,
        setModuleTab,
        opComps,
        openOper,
        setOpenOper,
        icons,
        colors,
        pcs,
        setPcs,
        choosenPart,
        setChoosenPart,
        newBillStats,
        setNewBillStats,
        traffic,
        setTraffic,
        msg,
        setMsg,
        loading, 
        setLoading,
        scroll,
        setScroll,
        scrollRef,
        homeRef,
        searchRef,
        slideRef,
        headerRef,
        search,
        setSearch,
        showSearch,
        setShowSearch,
        profile,
        setProfile
    };

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};