import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { io } from "socket.io-client";

import { motion } from 'framer-motion';

import { DataContext } from "../../context/DataContext";
import { getCompanyStores, getModules, getUserCompanies, getUserOperations } from "../../services/Api";

import { Avatar, Backdrop, Box, CircularProgress, ClickAwayListener, Container, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Toolbar, Tooltip, Typography } from "@mui/material";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HomeIcon from '@mui/icons-material/Home';

import Dashboard from "../../layouts/dashboard/Dashboard";
import Feedback from "../../components/feedback/Feedback";
import DayopenPcs from "../dayopen/DayopenPcs";

import ShopArea from "./modules/ShopArea";
import Apartmans from "./modules/Apartmans";
import Modules from "./content/Modules";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Home = () => {
    const {
        user, setUser,
        stores, setStores,
        ops, setOps,
        openOper,
        opComps,
        loading, setLoading,
        scrollRef,
        setScroll,
        msg
    } = useContext(DataContext);

    const anchorRef = useRef(null);

    const [modules, setModules] = useState([]);

    const [companyOpen, setCompanyOpen] = useState(false);
    const [openPcs, setOpenPcs] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/");

        socket.emit("join", ["uid:"+user.id, "rid:"+user.rank, "cid:"+user.company.id]);

        getModules(user.company.id).then(res => setModules(res));

        setLoading(false);
    }, [user]);

    useEffect(() => {
        socket.on("update_tech_user", () => {
            getUserCompanies(user.id).then(userCompanies => {
                setUser({...user, company: userCompanies.filter(item => item.id === user.company.id)[0], companies: userCompanies});
            });
        });

        socket.on("update_perm", () => {
            getUserOperations(user.id).then(data => {
                setOps(data);
            });
        });
    }, [socket]);

    const handleToggle = () => {
        setCompanyOpen((prevOpen) => !prevOpen);
    };

    const handleChooseCompany = (e, newCompany) => {
        setUser(prev => ({...prev, company: newCompany}));

        getCompanyStores(newCompany.id).then(storeData => {
            return setStores(storeData);
        });

        getUserOperations(user.id).then(opsData => {
            setOps(opsData.filter(item => Number(item.company) === Number(newCompany.id)));
        });

        handleClose(e);
    };

    const handleClose = (e) => {
        if(anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }

        setCompanyOpen(false);
    };

    const renderOperation = () => {
        if(openOper !== 0) {
            return (
                <>
                    {opComps[openOper]}
                </>
            );
        }
    };

    const renderPcs = () => {
        if(JSON.stringify(openPcs) !== "{}") {
            return (
                <DayopenPcs
                    store={openPcs}
                    onClose={() => setOpenPcs({})}
                />
            );
        }
    };

    return (
        <Dashboard>
            <Backdrop
                sx={{ color: '#fff', zIndex: 10000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {Object.keys(msg).length !== 0 &&
                <Feedback
                    color={msg.color}
                    message={msg.message}
                />
            }

            <Box
                component="main"
                flexGrow={1}
                height="100vh"
                overflow="auto"
                sx={{ bgcolor: "#FFF" }}
                ref={scrollRef}
                onScroll={(e) => setScroll(scrollRef.current.scrollTop)}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                    {renderOperation()}
                    {renderPcs()}

                    {(openOper === 0 && JSON.stringify(openPcs) === "{}") &&
                        <Grid container spacing={2}>
                            {!user.store && <Grid item xs={12}>
                                <Box
                                    component={Paper}
                                    display="flex"
                                    flexDirection="row"
                                    gap={1}
                                    padding={1}
                                    alignItems="center"
                                >
                                    <Avatar
                                        sx={{ width: 20, height: 20 }}
                                    >
                                        <HomeIcon style={{ fontSize: 16 }} />
                                    </Avatar>

                                    <Typography fontSize={14} fontWeight={300}>{user.company?.name}</Typography>
                                
                                    <motion.div
                                        whileHover={{ scale: 1.15 }}
                                        whileTap={{ scale: 0.85 }}
                                        style={{ marginLeft: "auto" }}
                                    >
                                        <Tooltip
                                            arrow
                                            title="Szervezet kezelése"
                                        >
                                            <IconButton
                                                size="small"
                                            >
                                                <SettingsOutlinedIcon style={{ fontSize: 16 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </motion.div>

                                    <motion.div
                                        whileHover={{ scale: 1.15 }}
                                        whileTap={{ scale: 0.85 }}
                                    >
                                        <Tooltip
                                            arrow
                                            title="Új szervezet létrehozása"
                                        >
                                            <IconButton
                                                onClick={() => navigate("/register/company/info")}
                                                size="small"
                                            >
                                                <AddCircleOutlineOutlinedIcon style={{ fontSize: 16 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </motion.div>
                                    
                                    <motion.div
                                        whileHover={{ scale: 1.3 }}
                                        whileTap={{ scale: 0.7 }}
                                    >
                                        <IconButton
                                            ref={anchorRef}
                                            aria-controls={companyOpen ? 'menu-list-grow' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleToggle}
                                            size="small"
                                        >
                                            {companyOpen ? <ExpandLess style={{ fontSize: 16 }} /> : <ExpandMore style={{ fontSize: 16 }} />}
                                        </IconButton>
                                    </motion.div>

                                    <Popper
                                        open={companyOpen}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal
                                        sx={{ zIndex: 100000 }}
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList autoFocusItem={companyOpen} id="menu-list-grow">
                                                            {user.companies.map(item => {
                                                                return (
                                                                    <MenuItem
                                                                        key={item.id}
                                                                        onClick={(e) => handleChooseCompany(e, item)}
                                                                    >
                                                                        {item.name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </Box>
                            </Grid>
                            }

                            {(modules.filter(item => item.id === 1).length !== 0 && ops.filter(item => item.group_id === 1).length > 0) &&
                                <ShopArea
                                    ops={ops.filter(item => item.group_id === 1 && item.operation !== 1)}
                                    onOpen={(store) => setOpenPcs(store)}
                                    stores={stores}
                                />
                            }

                            {modules.filter(item => item.id === 7).length !== 0 &&
                                <Apartmans
                                    moduleInfo={modules.filter(item => item.id === 7)[0]}
                                />
                            }

                            <Modules
                                modules={modules}
                            />
                        </Grid>
                    }
                </Container>
            </Box>
        </Dashboard>
    );
};

export default Home;