import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import AddImage from "../../../../components/addimage/AddImage";

const ImageDialog = ({ open, onClose, image, setImage, onNext }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <IconButton
                    onClick={onClose}
                    size="small"
                >
                    <ArrowBackIosIcon style={{ fontSize: 16 }} />
                </IconButton>

                <IconButton
                    size="small"
                >
                    <OpenInFullIcon style={{ fontSize: 16 }} />
                </IconButton>
            </Box>

            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    <DialogContentText fontSize={14} fontWeight={500}>Értékszállítás</DialogContentText>
                    <DialogContentText fontSize={12} fontWeight={300}>Képfeltöltése az értékszállítási bizonylatról</DialogContentText>
                
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        alignItems="center"
                    >
                        {image ?
                            <>
                                <img
                                    src={URL.createObjectURL(image)}
                                    style={{ width: 200 }}
                                />

                                <IconButton onClick={() => setImage()}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </>
                        :
                            <>
                                <AddImage onUpload={(e) => setImage(e.target.files[0])} />
                            
                                <DialogContentText fontSize={12} fontWeight={300}>Kép hozzáadása</DialogContentText>
                            </>
                        }
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={onClose}
                >
                    Vissza
                </Button>

                <Button
                    variant="contained"
                    size="small"
                    disabled={!image}
                    onClick={onNext}
                >
                    Tovább
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageDialog;