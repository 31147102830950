import { useContext, useEffect, useState } from "react";

import useDebounce from "../../hooks/useDebounce";
import { DataContext } from "../../context/DataContext";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import { Box, Grid, OutlinedInput, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";
import ResItem from "./content/ResItem";

const Reservations = () => {
    const {user, setOpenOper, setLoading, setMsg, colors} = useContext(DataContext);

    const [aps, setAps] = useState([]);

    const [reservations, setReservations] = useState([]);
    const [showRes, setShowRes] = useState([]);
    const [events, setEvents] = useState([]);

    const [tab, setTab] = useState(0);
    const [search, setSearch] = useState("");

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getApartmans();
        getReservations();
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            const filtered = reservations.filter(item => rx.test(item.address) || rx.test(item.name) || rx.test(item.phone) || rx.test(item.email));

            const calendarEvents = filtered.map(item => {
                return {
                    title: item.name+" - "+item.address,
                    start: item.start_date,
                    end: item.end_date,
                    color: colors[item.apartman],
                    apartman: item.apartman
                }
            });

            setEvents(calendarEvents);
            
            setShowRes(filtered);
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [reservations, search], 500);

    const getApartmans = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/apartmans/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setAps(data.aps);
        
        setLoading(false);
    };

    const getReservations = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/apartman/reservations/company/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setReservations(data.reservations);

        setLoading(false);
    };

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={34}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Grid item xs={12}>
                <Tabs
                    variant="standard"
                    value={tab}
                    onChange={handleChange}
                >
                    <Tab
                        label="Összes"
                        value={0}
                        sx={{
                            "&:hover": {
                                color: "#2278CF"
                            }
                        }}
                    />

                    {aps.map(item => {
                        return (
                            <Tab
                                key={item.id}
                                label={item.name}
                                value={item.id}
                                sx={{
                                    "&:hover": {
                                        color: "#2278CF"
                                    }
                                }}
                            />
                        );
                    })}
                </Tabs>
            </Grid>

            <Grid item xs={12}>
                <OutlinedInput
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Keresés"
                    size="small"
                    sx={{ bgcolor: "#FFF" }}
                    fullWidth
                />
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <caption>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                gap={2}
                                color="#000"
                            >
                                <Typography fontSize={14} fontWeight={400}>{showRes.filter(item => item.apartman === tab).length} db</Typography>
                            </Box>
                        </caption>

                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Foglalás időpontja
                                </TableCell>

                                <TableCell>
                                    Teljes név
                                </TableCell>

                                <TableCell>
                                    Email
                                </TableCell>

                                <TableCell>
                                    Telefonszám
                                </TableCell>

                                <TableCell>
                                    Időszak
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {tab === 0 ? showRes.map(item => {
                                return (
                                    <ResItem
                                        key={item.id}
                                        res={item}
                                    />
                                );
                            }) : showRes.filter(item => item.apartman === tab).map(item => {
                                return (
                                    <ResItem
                                        key={item.id}
                                        res={item}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12} sx={{ color: "#000" }}>
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView='dayGridMonth'
                    events={tab === 0 ? events : events.filter(item => item.apartman === tab)}
                    firstDay={1}
                />
            </Grid>
        </Grid>
    );
};

export default Reservations;