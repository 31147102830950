import { motion } from 'framer-motion';

import { Box, Button, Grid } from "@mui/material";

const Buttons = ({ backText, nextText, onBack, onNext }) => {
    return (
        <Grid item xs={12}>
            <Box
                display="flex"
                flexDirection="row"
                gap={1}
                alignItems="center"
                justifyContent="flex-end"
                width="100%"
            >
                <motion.div
                    whileHover={{ scale: 1.15 }}
                    whileTap={{ scale: 0.85 }}
                >
                    <Button
                        variant="outlined"
                        onClick={onBack}
                        sx={{
                            border: "none",
                            color: "#8C8C8C",
                            textTransform: "none",
                            fontSize: 14,
                            fontWeight: 300,
                            "&:hover": {
                                border: "none",
                                bgcolor: "#F1F1F1",
                                color: "#8C8C8C"
                            }
                        }}
                    >
                        {backText}
                    </Button>
                </motion.div>

                <motion.div
                    whileHover={{ scale: 1.15 }}
                    whileTap={{ scale: 0.85 }}
                >
                    <Button
                        variant="outlined"
                        onClick={onNext}
                        sx={{
                            border: "none",
                            color: "#24C8C8",
                            bgcolor: "#E0F9F9",
                            textTransform: "none",
                            fontSize: 14,
                            fontWeight: 300,
                            "&:hover": {
                                border: "none",
                                bgcolor: "#24C8C8",
                                color: "#FFF"
                            }
                        }}
                    >
                        {nextText}
                    </Button>
                </motion.div>
            </Box>
        </Grid>
    );
};

export default Buttons;