import { useEffect, useMemo, useState } from "react";

import ReactEcharts from "echarts-for-react";

import TotalUsersStyle from "./TotalUsers.module.css";

const TotalUsers = ({ data }) => {
    const [profiles, setProfiles] = useState([]);
    const [dayProfs, setDayProfs] = useState({});

    const [dates, setDates] = useState([]);

    const lineChartMemo = useMemo(() => {
        return (
            <ReactEcharts
                option={{
                    title: {
                        text: "Forgalom naponta felhasználónként",
                        left: "center"
                    },
                    grid: {
                        containLabel: true
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: dates
                    },
                    yAxis: {
                        type: "value"
                    },
                    series: [...Object.keys(dayProfs).map(key => ({
                        type: "line",
                        name: dayProfs[key].name,
                        symbolSize: 10,
                        lineStyle: {
                            color: dayProfs[key].color
                        },
                        itemStyle: {
                            color: dayProfs[key].color
                        },
                        data: [
                            ...Object.keys(dayProfs[key]["data"]).map(dateKey => ({
                                    date: dateKey,
                                    value: dayProfs[key]["data"][dateKey].sum
                            }))
                        ]
                    }))],
                    tooltip: {
                        trigger: "axis",
                        valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                    }
                }}
                style={{width: "100%", height: "100%"}}
            />
        );
    }, [dayProfs]);

    useEffect(() => {
        if(JSON.stringify(data) !== "{}") {
            calcProfiles();
            calcDayProfiles();
        }
    }, [data?.current?.data]);

    const dateRange = (startDate, endDate) => {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate).toISOString("hu-HU").slice(0, 10));
            currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        }

        return dateArray;
    }

    const calcProfiles = () => {
        // const currentUsers = Object.groupBy(data.current.data, ({user}) => user);

        const currentUsers = data.current.data.reduce((group, x) => {
            (group[x["user"]] = group[x["user"]] || []).push(x);
            return group;
        }, {});

        const currentUsersSum = Object.keys(currentUsers).map(key => {
            const sum = currentUsers[key].reduce((acc, obj) => {
                return acc + obj.unit_value;
            }, 0);

            return {
                user: key,
                name: currentUsers[key][0].last_name+" "+currentUsers[key][0].first_name,
                color: currentUsers[key][0].profile_color,
                sum: sum
            };
        });

        setProfiles(currentUsersSum.sort((a, b) => a.sum - b.sum));
    };

    const calcDayProfiles = () => {
        const userGroup = data.current.data.reduce((group, x) => {
            (group[x["user"]] = group[x["user"]] || []).push(x);
            return group;
        }, {});

        const userDateGroup = {};

        Object.keys(userGroup).map(key => {
            return userDateGroup[key] = userGroup[key].reduce((group, x) => {
                (group[x["date"]] = group[x["date"]] || []).push(x);
                return group;
            }, {});
        });

        let userDateSum = {};

        Object.keys(userDateGroup).map(key => {
            return Object.keys(userDateGroup[key]).map(dateKey => {
                const sum = userDateGroup[key][dateKey].reduce((acc, obj) => {
                    return acc + obj.unit_value;
                }, 0);

                return userDateSum = {
                    ...userDateSum,
                    [key]: {
                        ...userDateSum[key],
                        name: userDateGroup[key][dateKey][0].last_name+" "+userDateGroup[key][dateKey][0].first_name,
                        color: userDateGroup[key][dateKey][0].profile_color,
                        data: {
                            ...userDateSum[key]?.data,
                            [dateKey]: {
                                sum: sum
                            }
                        }
                    }
                };
            });
        });

        const datesArr = dateRange(data.current.from, data.current.to);

        setDates(datesArr);

        datesArr.map(item => {
            return Object.keys(userDateSum).map(key => {
                if(!Object.keys(userDateSum[key]["data"]).includes(item)) {
                    userDateSum = {
                        ...userDateSum,
                        [key]: {
                            ...userDateSum[key],
                            data: {
                                ...userDateSum[key]?.data,
                                [item]: {
                                    sum: 0
                                }
                            }
                        }
                    };

                    const ordered = Object.keys(userDateSum[key]["data"]).sort().reduce((obj, orderKey) => { 
                        obj[orderKey] = userDateSum[key]["data"][orderKey]; 
                        return obj;
                    }, {});

                    userDateSum = {
                        ...userDateSum,
                        [key]: {
                            ...userDateSum[key],
                            data: {
                                ...ordered
                            }
                        }
                    };
                }

                return;
            });
        });

        setDayProfs(userDateSum);
    };

    return (
        <div className={TotalUsersStyle.container}>
            <div className={TotalUsersStyle.left_chart}>
                <ReactEcharts
                    option={{
                        title: {
                            text: "Forgalom felhasználókra lebontva",
                            left: "center"
                        },
                        grid: {
                            containLabel: true
                        },
                        xAxis: {
                            type: "value",
                            name: "Forint"
                        },
                        yAxis: {
                            type: "category",
                            data: [...profiles.map(item => item.name)]
                        },
                        series: [{
                            type: "bar",
                            smooth: true,
                            showBackground: false,
                            name: "Összes forgalom felhasználónként",
                            data: [...profiles.map(item => ({
                                value: item.sum,
                                itemStyle: {color: "#5470c6"}
                            }))]
                        }],
                        tooltip: {
                            trigger: "axis",
                            valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                        }
                    }}
                    style={{width: "100%", height: "120%"}}
                />
            </div>

            <div className={TotalUsersStyle.right_chart}>
                {lineChartMemo}
            </div>
        </div>
    );
};

export default TotalUsers;