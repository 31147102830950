import { Box, LinearProgress, Typography } from "@mui/material";

import CurrencyText from "../../../../../components/currencytext/CurrencyText";

const UserRow = ({ active, max, name, sum }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={0.5}
            width="100%"
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
            >
                <Typography fontSize={12} fontWeight={400}>
                    {name}
                </Typography>

                <Typography fontSize={12} fontWeight={400}>
                    {active === 0 && <><CurrencyText val={sum} /> HUF</>}
                    {active === 1 && <>{sum} DB</>}
                    {active === 2 && <><CurrencyText val={sum} /> HUF</>}
                    {active === 3 && <>{sum.toFixed(2)} %</>}
                    {active === 4 && <><CurrencyText val={Math.round(sum)} /> HUF</>}
                </Typography>
            </Box>

            <LinearProgress
                variant="determinate"
                value={sum/max*100}
                color="primary"
                sx={{
                    height: 8,
                    borderRadius: 8
                }}
            />
        </Box>
    );
};

export default UserRow;