import { useContext } from "react";

import { motion } from 'framer-motion';

import { io } from "socket.io-client";

import { DataContext } from "../../context/DataContext";

import { Avatar, Badge, Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import StoreIcon from "../../layouts/stores/content/StoreIcon";
import OpHeader from "../../components/opheader/OpHeader";
import CurrencyText from "../../components/currencytext/CurrencyText";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const DayopenVerify = ({ store, onClose, onBack, onDone }) => {
    const {user, setUser, setLoading, setMsg, setOpenOper} = useContext(DataContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = user.profile_pic ? apiUrl+"/"+user.profile_pic : null;

    const handleDayOpen = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/store/daily", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                user_before: null,
                store: store.id,
                operation: 1,
                huf5: Number(store.pcs.huf5),
                huf10: Number(store.pcs.huf10),
                huf20: Number(store.pcs.huf20),
                huf50: Number(store.pcs.huf50),
                huf100: Number(store.pcs.huf100),
                huf200: Number(store.pcs.huf200),
                huf500: Number(store.pcs.huf500),
                huf1000: Number(store.pcs.huf1000),
                huf2000: Number(store.pcs.huf2000),
                huf5000: Number(store.pcs.huf5000),
                huf10000: Number(store.pcs.huf10000),
                huf20000: Number(store.pcs.huf20000),
                sum: Number(store.pcs.sum)
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setUser(prevUser => ({...prevUser, store: store.id}));

        socket.emit("store_op", {id: store.id});

        setOpenOper(0);
        setLoading(false);

        setMsg({
            color: "success",
            message: "Sikeres művelet!"
        });

        onDone();
    };

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OpHeader
                    operation={1}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: "bottom", horizontal: "right"}}
                        badgeContent={
                            <StoreIcon
                                store={store}
                                size="small"
                            />
                        }
                    >
                        <Avatar
                            src={imageExists(avatar) ? avatar : null}
                            sx={{ border: "1.5px solid #FFFFFF" }}
                        />
                    </Badge>

                    <Typography variant="h5" color="#262626">Indulhat a nap?</Typography>
                    <Typography variant="subtitle1" color="#8C8C8C">
                        <CurrencyText val={store.pcs.sum} /> HUF
                    </Typography>

                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button
                            variant="contained"
                            size="medium"
                            onClick={handleDayOpen}
                        >
                            <Typography fontSize={12}>Véglegesítés</Typography>
                        </Button>
                    </motion.div>
                    
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button
                            variant="outlined"
                            size="medium"
                            onClick={onClose}
                        >
                            <Typography fontSize={12}>Vissza</Typography>
                        </Button>
                    </motion.div>
                </Box>
            </Grid>

            <Grid item xs={12} md={6}>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ pl: 3 }}>Címlet</TableCell>
                                    <TableCell>Darabszám</TableCell>
                                    <TableCell>Összeg</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>20 000 HUF</TableCell>
                                    <TableCell>{store.pcs.huf20000} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf20000*20000}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>10 000 HUF</TableCell>
                                    <TableCell>{store.pcs.huf10000} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf10000*10000}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>5 000 HUF</TableCell>
                                    <TableCell>{store.pcs.huf5000} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf5000*5000}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>2 000 HUF</TableCell>
                                    <TableCell>{store.pcs.huf2000} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf2000*2000}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>1 000 HUF</TableCell>
                                    <TableCell>{store.pcs.huf1000} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf1000*1000}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>500 HUF</TableCell>
                                    <TableCell>{store.pcs.huf500} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf500*500}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>200 HUF</TableCell>
                                    <TableCell>{store.pcs.huf200} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf200*200}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>100 HUF</TableCell>
                                    <TableCell>{store.pcs.huf100} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf100*100}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>50 HUF</TableCell>
                                    <TableCell>{store.pcs.huf50} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf50*50}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>20 HUF</TableCell>
                                    <TableCell>{store.pcs.huf20} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf20*20}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>10 HUF</TableCell>
                                    <TableCell>{store.pcs.huf10} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf10*10}
                                        /> HUF
                                    </TableCell>
                                </TableRow>

                                <TableRow hover>
                                    <TableCell sx={{ pl: 3 }}>5 HUF</TableCell>
                                    <TableCell>{store.pcs.huf5} db</TableCell>
                                    <TableCell>
                                        <CurrencyText
                                            val={store.pcs.huf5*5}
                                        /> HUF
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    marginTop={2}
                >
                    <Typography variant="h6" color="#262626">TOTAL: <CurrencyText val={store.pcs.sum} /> HUF</Typography>
                    <Typography variant="subtitle2" color="#8C8C8C">Eltérés: <CurrencyText val={store.pcs.sum-store.prevSum} /> HUF</Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default DayopenVerify;