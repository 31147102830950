import { useContext, useEffect, useState } from "react";

import { isEqual } from 'lodash';

import { DataContext } from "../../../context/DataContext";
import { getUserOperations } from "../../../services/Api";

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, OutlinedInput, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import OpHeader from "../../../components/opheader/OpHeader";
import OpItem from "./content/OpItem";
import InviteStore from "./InviteStore";

const InvitePerm = ({ rank, email, onBack }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {user, setOpenOper, setMsg, setLoading} = useContext(DataContext);

    const [userRank, setUserRank] = useState(rank);
    const [ops, setOps] = useState([]);

    const [checkedItems, setCheckedItems] = useState({});
    const [originalItems, setOriginalItems] = useState({});

    const [rankDialog, setRankDialog] = useState(false);
    const [newData, setNewData] = useState({
        name: "",
        desc: ""
    });

    const [storePage, setStorePage] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getRankOperations();
        
        getUserOperations(user.id).then(data => {
            const grouped = data.filter(item => Number(item.company) === Number(user.company.id)).reduce((group, x) => {
                (group[x["group_id"]] = group[x["group_id"]] || []).push(x);
                return group;
            }, {});

            setOps(grouped);
        });
    }, []);

    const getRankOperations = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/rank/ops/"+rank.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        const actives = data.ops.reduce((acc, obj) => ({ ...acc, [obj.id]: true}), {});

        setOriginalItems(actives);
        setCheckedItems(actives);

        setLoading(false);
    };

    const handleCheckboxChange = (itemId) => {
        setCheckedItems({ ...checkedItems, [itemId]: !checkedItems[itemId] });
    };

    const handleNext = () => {
        let filteredItems = {};

        Object.keys(checkedItems).map(checkId => {
            if(checkedItems[checkId]) {
                return filteredItems = {...filteredItems, [checkId]: true};
            }
        });

        if(!isEqual(originalItems, filteredItems)) return setRankDialog(true);

        setStorePage(true);
    };

    const handleSaveRank = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/rank/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                newData: newData,
                company: user.company.id,
                ops: Object.keys(checkedItems).map(itemId => {
                    if(checkedItems[itemId]) return {operation: Number(itemId)};
                    return;
                })
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setUserRank({id: data.rankId});
        setRankDialog(false);
        setStorePage(true);

        setLoading(false);
    };

    const renderStore = () => {
        if(storePage) {
            return (
                <InviteStore
                    email={email}
                    rank={userRank}
                    onBack={() => setStorePage(false)}
                />
            );
        }
    };

    return (
        <>
            {renderStore()}

            {!storePage &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={29}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component={Paper}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            padding={2}
                        >
                            <Typography fontSize={16} fontWeight={500} color="#6489E2">
                                Kiválasztott jogosultság kezelése
                            </Typography>

                            <Typography fontSize={12} color="#737373">
                                Itt tudod módosítani a jogosultságokat
                            </Typography>

                            <Button
                                variant="outlined"
                                onClick={handleNext}
                                sx={{
                                    color: "#6489E2",
                                    alignSelf: "flex-start",
                                    borderRadius: 6
                                }}
                            >
                                Megerősítés
                            </Button>

                            <Dialog
                                fullScreen={matches}
                                open={rankDialog}
                                onClose={() => setRankDialog(false)}
                            >
                                <DialogTitle>
                                    Új rang létrehozása
                                </DialogTitle>

                                <DialogContent>
                                    <DialogContentText margin={2} align='center' fontSize={14}>
                                        Add meg a rang adatait
                                    </DialogContentText>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        gap={2}
                                        width={500}
                                    >
                                        <OutlinedInput
                                            value={newData.name}
                                            onChange={(e) => setNewData(prev => ({...prev, name: e.target.value}))}
                                            placeholder="megnevezés"
                                            fullWidth
                                            size="small"
                                        />

                                        <OutlinedInput
                                            value={newData.desc}
                                            onChange={(e) => setNewData(prev => ({...prev, desc: e.target.value}))}
                                            placeholder="leírás"
                                            fullWidth
                                            size="small"
                                        />
                                    </Box>
                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        size="small"
                                        onClick={() => setRankDialog(false)}
                                    >
                                        Vissza
                                    </Button>

                                    <Button
                                        variant="contained"
                                        size="small"
                                        disabled={Object.values(newData).some(item => item === "")}
                                        onClick={handleSaveRank}
                                    >
                                        Tovább
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Grid container spacing={3} sx={{ marginTop: 2 }}>
                                {Object.keys(ops).map(groupId => {
                                    return (
                                        <Grid key={groupId} item xs={12}>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography fontSize={16} fontWeight={500}>{ops[groupId][0].group_name}</Typography>
                                                </AccordionSummary>

                                                <AccordionDetails>
                                                    {ops[groupId].map((item) => {
                                                        return (
                                                            <OpItem
                                                                key={item.operation}
                                                                op={item}
                                                                checked={checkedItems[item.operation]}
                                                                onSwitch={() => handleCheckboxChange(item.id)}
                                                            />
                                                        );
                                                    })}
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default InvitePerm;