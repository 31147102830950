import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useDebounce from "../../hooks/useDebounce";

import { DataContext } from "../../context/DataContext";
import { getStoreStatus } from "../../services/Api";

import LogsStyle from "./Logs.module.css";

import SearchFilterImg from "../../assets/body/search_filter.svg";

import OpsHeader from "../../layouts/opsheader/OpsHeader";

import LogItem from "../../components/logitem/LogItem";
import Feedback from "../../components/feedback/Feedback";
import SearchBar from "../../components/searchbar/SearchBar";

const Logs = () => {
    const {
        user,
        stores,
        msg, setMsg,
        loading, setLoading,
        homeRef,
        searchRef,
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [logs, setLogs] = useState([]);
    const [showLogs, setShowLogs] = useState([]);

    const navigate = useNavigate();

    const params = useParams();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/");

        getStoreStatus(params.store).then(data => {
            if(user.stores.filter(store => store.id === data.id).length === 0 || Number(data.operation) === 2) {
                return navigate("/home");
            }
        });

        setSearch("");
        setShowSearch("");

        getStoreLog();

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowLogs(logs.filter(item => rx.test(item.op_name) || rx.test(item.last_name+" "+item.first_name)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch, logs]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    const getStoreLog = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/dailylog", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setLogs(data.logs);
    };

    const handleGoToOps = () => {
        //homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleNavOperations = () => {
        //homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleNavFilter = () => {};

    const handleNavSearch = () => {
        searchRef.current.focus();
    };

    return (
        <div
            style={{backgroundColor: "#FFFFFF"}}
            ref={homeRef}
        >
            <div className={LogsStyle.ops_header}>
                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation="shop"
                    store={params.store}
                />

                <div>
                    <button
                        onClick={handleGoToOps}
                    >
                        Műveletek, események
                    </button>

                    <button
                        onClick={() => navigate("/logs/"+params.store)}
                    >
                        Esemény napló
                    </button>
                </div>

                <div className={LogsStyle.search_row}>
                    <SearchBar
                        ph="Keresés"
                        val={search}
                        change={(e) => setSearch(e.target.value)}
                    />

                    <img
                        src={SearchFilterImg}
                        alt="Filter"
                    />
                </div>
                

                <div className={LogsStyle.logs_count}>
                    <span>Események</span>
                    <span>
                        <span style={{fontWeight: 700}}>{showLogs.length}</span> / {logs.length}
                    </span>
                </div>
            </div>

            <div>
                {showLogs.length === 0 ?
                    <span style={{color: "#A6A6A6", marginTop: "24px"}}>Nem található ilyen esemény</span>
                :
                    showLogs.map((l, i) => {
                        return (
                            <LogItem
                                key={i}
                                log={l}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Logs;