import { useContext, useState } from "react";
import { DataContext } from "../../context/DataContext";

import { Box, Card, Grid, Grow, IconButton, Typography } from "@mui/material";
import { motion } from 'framer-motion';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import Icon from "./content/Icon";

const ShopItem = ({ op, onClick, onRefresh, timeout }) => {
    const {user} = useContext(DataContext);

    const [fav, setFav] = useState(op.favourite);
    const [hover, setHover] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleFavourite = async(e) => {
        e.stopPropagation();

        const res = await fetch(apiUrl+"/operation/favourite", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: user.id,
                opId: op.operation,
                val: fav === 0 ? 1 : 0
            })
        });

        const data = await res.json();

        if(!data.success) return alert(data.message);

        setFav(fav === 0 ? 1 : 0);
        onRefresh();
    };

    return (
        <Grow in={true} timeout={timeout}>
            <Grid
                item
                xs={12}
                md={4}
                lg={3}
            >
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    <Card
                        onClick={onClick}
                        sx={{
                            bgcolor: "#A1A1A1",
                            color: "#FFFFFF",
                            ':hover': {
                                bgcolor: op.color_code_1,
                            },
                            height: 120,
                            p: 1,
                            cursor: "pointer"
                        }}
                    >
                        <Grid container height="100%">
                            <Grid
                                item
                                xs={10}
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                height="100%"
                            >
                                <Box>
                                    <IconButton
                                        onClick={handleFavourite}
                                        size="small"
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                        sx={{ 
                                            "&:hover": { bgcolor: "transparent" }
                                        }}
                                    >
                                        <motion.div whileHover={{ scale: 1.1 }}>
                                            {fav === 0 ?
                                                (hover ?
                                                    <FavoriteIcon style={{ color: "#FFFFFF" }} />
                                                    :
                                                    <FavoriteBorderOutlinedIcon style={{ color: "#FFFFFF", transition: "width 2s" }} />
                                                )
                                                :
                                                <FavoriteIcon style={{ color: "#FFFFFF" }} />
                                            }
                                        </motion.div>
                                    </IconButton>
                                </Box>
                                
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Typography variant="subtitle1">{op.name}</Typography>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.4 }}>{op.group_name}</Typography>
                                </Box>
                            </Grid>
                            
                            <Grid
                                item
                                xs={2}
                                display="flex"
                                height="100%"
                                alignItems="center"
                                sx={{
                                    opacity: 0.4
                                }}
                            >
                                <Icon
                                    op={op}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </motion.div>
            </Grid>
        </Grow>
    );
};

export default ShopItem;