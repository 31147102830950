import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import { Avatar } from "@mui/material";

import SummaryStyle from "./Summary.module.css";

import SummaryOpen from "./summaryopen/SummaryOpen";
import SummaryClose from "./summaryclose/SummaryClose";
import SummaryChange from "./summarychange/SummaryChange";
import SummaryBill from "./summarybill/SummaryBill";
import SummaryCashOutSafe from "./summarycashoutsafe/SummaryCashOutSafe";
import SummaryTransitSafe from "./summarytransitsafe/SummaryTransitSafe";
import SummaryPartner from "./summarypartner/SummaryPartner";
import SummaryManageSafe from "./summarymanagesafe/SummaryManageSafe";
import SummaryCashOut from "./summarycashout/SummaryCashOut";
import SummaryCashIn from "./summarycashin/SummaryCashIn";
import SummaryInTickets from "./summaryintickets/SummaryInTickets";
import SummaryOutTicket from "./summaryoutticket/SummaryOutTicket";
import SummaryTicketTraffic from "./summarytickettraffic/SummaryTicketTraffic";
import SummaryUser from "./summaryuser/SummaryUser";
import SummaryManTickets from "./summarymantickets/SummaryManTickets";

const Summary = ({ info }) => {
    const {setMsg, homeRef} = useContext(DataContext);

    const params = useParams();

    const navigate = useNavigate();

    const handleBack = () => {
        //homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/logs/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    const renderSummary = () => {
        switch(info.operation) {
            case 1:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryOpen
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryClose
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryChange
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryBill
                            log={true}
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryCashOutSafe
                            log={true}
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 6:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryTransitSafe
                            log={true}
                            id={info.id}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 7:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryPartner
                            log={true}
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 8:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryManageSafe
                            log={true}
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 9:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryCashOut
                            log={true}
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 10:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryCashIn
                            log={true}
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 11:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryInTickets
                            log={true}
                            id={info.id}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 12:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryOutTicket
                            log={true}
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 13:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryTicketTraffic
                            log={true}
                            id={info.id}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 14:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        Under dev

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 15:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryUser
                            log={true}
                            id={info.itemid}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
            case 16:
                return (
                    <div className={SummaryStyle.summary_info}>
                        <Avatar />

                        <SummaryManTickets
                            log={true}
                            info={info}
                        />

                        <div
                            style={{margin: "0 auto"}}
                        >
                            <button onClick={handleBack}>Vissza</button>
                            <button>Módosítás</button>
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className={SummaryStyle.summary_body}>
            {renderSummary()}
        </div>
    );
};

export default Summary;