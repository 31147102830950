import { Avatar, IconButton } from "@mui/material";

const ApIcon = ({ ap, size, type, val, onClick }) => {
    if(type === "checkbox") {
        return val ? (
            <IconButton onClick={() => onClick(ap)}>
                <Avatar
                    sx={{
                        color: "#FFFFFF",
                        bgcolor: ap.colorCode,
                        fontSize: 12,
                        width: 32,
                        height: 32
                    }}
                >
                    {ap.name[0]?.toUpperCase()}
                </Avatar>
            </IconButton>
        ) : (
            <IconButton onClick={() => onClick(ap)}>
                <Avatar
                    sx={{
                        color: "#FFFFFF",
                        bgcolor: "#979797",
                        fontSize: 12,
                        width: 28,
                        height: 28,
                        cursor: "pointer"
                    }}
                >
                    {ap.name[0]?.toUpperCase()}
                </Avatar>
            </IconButton>
        );
    }
    else {
        return size === "small" ? (
            <Avatar
                sx={{
                    color: "#FFFFFF",
                    bgcolor: ap.colorCode,
                    fontSize: 12,
                    width: 22,
                    height: 22,
                    border: "1.5px solid #FFFFFF"
                }}
            >
                {ap.name[0]?.toUpperCase()}
            </Avatar>
        ) : size === "medium" ? (
            <Avatar
                sx={{
                    color: "#FFFFFF",
                    bgcolor: ap.colorCode,
                    fontSize: 14,
                    width: 32,
                    height: 32
                }}
            >
                {ap.name[0]?.toUpperCase()}
            </Avatar>
        ) : (
            <Avatar
                sx={{
                    color: "#FFFFFF",
                    bgcolor: ap.colorCode,
                    fontSize: 16,
                    width: 40,
                    height: 40
                }}
            >
                {ap.name[0]?.toUpperCase()}
            </Avatar>
        );
    }
};

export default ApIcon;