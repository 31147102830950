import { Button, TableCell, TableRow } from "@mui/material";

const ProductRow = ({ row, onDelete }) => {
    return (
        <TableRow>
            <TableCell>{row.itemName}</TableCell>
            <TableCell>{row.quantity} DB</TableCell>
            <TableCell>{row.unitPrice} HUF</TableCell>
            <TableCell>{row.vat}</TableCell>
            <TableCell sx={{ textAlign: "right" }}>
                <Button variant="contained" color="error" onClick={onDelete}>Törlés</Button>
            </TableCell>
        </TableRow>
    );
};

export default ProductRow;