import { Avatar, Grid, IconButton, Tooltip } from "@mui/material";

import { motion } from 'framer-motion';

const ProfileItem = ({ profile, onClick }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = profile.profile_pic ? apiUrl+"/"+profile.profile_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <Grid item xs={2}>
            <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                <IconButton onClick={() => onClick(profile)}>
                    <Tooltip
                        title={profile.last_name+" "+profile.first_name}
                        arrow
                    >
                        <Avatar
                            src={imageExists(avatar) ? avatar : null}
                            size="sm"
                            sx={{ opacity: profile.selected ? 1 : 0.3 }}
                        />
                    </Tooltip>
                </IconButton>
            </motion.div>
        </Grid>
    );
};

export default ProfileItem;