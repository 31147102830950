import { useContext } from "react";

import { DataContext } from "../../../../../context/DataContext";

import { Avatar, AvatarGroup, Box, Tooltip, Typography } from "@mui/material";

import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';

import StoreIcon from "../../../../../layouts/stores/content/StoreIcon";
import CurrencyText from "../../../../../components/currencytext/CurrencyText";

const ListItem = ({ pack }) => {
    const {stores} = useContext(DataContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = pack.profile_pic ? apiUrl+"/"+pack.profile_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            gap={1}
            border="1px solid #8C8C8C"
            borderRadius={1}
            padding={1}
            boxSizing="border-box"
            width="100%"
        >
            <AvatarGroup max={2}>
                <StoreIcon
                    store={stores.filter(item => item.id === pack.store)[0]}
                    size="medium"
                />

                <Tooltip
                    title={pack.last_name+" "+pack.first_name}
                    arrow
                >
                    <Avatar
                        src={imageExists(avatar) ? avatar : null}
                        sx={{ zIndex: 2, width: 32, height: 32 }}
                    />
                </Tooltip>
            </AvatarGroup>

            <Box
                display="flex"
                flexDirection="column"
            >
                <Typography fontSize={12} fontWeight={700}>{pack.serial_num}</Typography>
                <Typography fontSize={10} fontWeight={300}>{pack.date_of_record}</Typography>
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                gap={1}
                marginLeft="auto"
                alignItems="center"
            >
                <Typography fontSize={12} fontWeight={700}><CurrencyText val={pack.sum} /> HUF</Typography>
            
                <Tooltip
                    arrow
                    title={pack.comment}
                >
                    <SmsOutlinedIcon />
                </Tooltip>
            </Box>
        </Box>
    );
};

export default ListItem;