import { useContext, useEffect, useState } from "react";

import { io } from "socket.io-client";
import axios from "axios";

import { DataContext } from "../../context/DataContext";
import { getCompanyStores, getUserCompanies, getUserOperations, getUserStores } from "../../services/Api";
import { calculateReceiptNumber, getCardSum, getReturnSum, getTrafficCashIn, getTrafficCashOut, getTrafficProfit, getTrafficSum, getVatProfit, getVatRateSum } from "../../services/TrafficCalc";

import { Box, Grid } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";
import UserItem from "./content/UserItem";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const ChangeLogin = ({ traffic, fileName, round, pcs, comment, image, onClose }) => {
    const {user, setUser, setMsg, setLoading, setOpenOper, setStores, setOps} = useContext(DataContext);

    const [users, setUsers] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getStoreUsers();
    }, []);

    const getStoreUsers = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/store/users", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setUsers(data.users.map(item => ({...item, active: false})));

        setLoading(false);
    };

    const handleChooseUser = (userItem) => {
        setUsers(prev => prev.map(item => {
            if(userItem.active) {
                if(userItem.id === item.id) {
                    return {...item, active: false};
                }

                return item;
            }
            else {
                if(userItem.id === item.id) {
                    return {...item, active: true};
                }

                return {...item, active: false};
            }
        }));
    };

    const handleLogin = async(userItem, pw) => {
        if(pw === ""){
            return setMsg({
                color: "error",
                message: "A jelszó megadása kötelező"
            });
        }

        setLoading(true);

        const res = await fetch(apiUrl+"/user/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: userItem.email,
                pass: pw
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        handleChange(data.user);
    };

    const handleChange = async(nextUser) => {
        const res = await fetch(apiUrl+"/store/daily", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: nextUser.id,
                user_before: user.id,
                store: user.store,
                operation: 3,
                huf5: Number(pcs.huf5),
                huf10: Number(pcs.huf10),
                huf20: Number(pcs.huf20),
                huf50: Number(pcs.huf50),
                huf100: Number(pcs.huf100),
                huf200: Number(pcs.huf200),
                huf500: Number(pcs.huf500),
                huf1000: Number(pcs.huf1000),
                huf2000: Number(pcs.huf2000),
                huf5000: Number(pcs.huf5000),
                huf10000: Number(pcs.huf10000),
                huf20000: Number(pcs.huf20000),
                sum: Number(pcs.sum)
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        handleSaveTrafficItemized(data.id, nextUser);
    };

    const handleSaveTrafficItemized = async(dailyId, nextUser) => {
        const res = await fetch(apiUrl+"/store/traffic/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                traffic: traffic
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        handleSaveTraffic(dailyId, nextUser);
    };

    const handleSaveTraffic = async(dailyId, newUser) => {
        const res = await fetch(apiUrl+"/store/traffic/sum/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                operation: 3,
                daily: dailyId,
                from: traffic[0].date,
                to: traffic[traffic.length-1].date,
                daily_traffic_sum: getTrafficSum(traffic),
                cash_in: getTrafficCashIn(traffic),
                cash_out: getTrafficCashOut(traffic),
                sum: (getTrafficSum(traffic) + getTrafficCashIn(traffic) - getTrafficCashOut(traffic) - getReturnSum(traffic)),
                card: getCardSum(traffic),
                round: round,
                return_sum: getReturnSum(traffic),
                profit: getTrafficProfit(traffic),
                receipt_quantity: calculateReceiptNumber(traffic),
                canceled: 0,
                a_tax: getVatRateSum(traffic, 5),
                b_tax: getVatRateSum(traffic, 18),
                c_tax: getVatRateSum(traffic, 27),
                d_tax: getVatRateSum(traffic, 0),
                e_tax: 0,
                a_tax_profit: getVatProfit(traffic, 5),
                b_tax_profit: getVatProfit(traffic, 18),
                c_tax_profit: getVatProfit(traffic, 27),
                d_tax_profit: getVatProfit(traffic, 0),
                e_tax_profit: 0,
                comment: comment
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        uploadImage(data.id);

        socket.emit("store_op", {id: user.store});

        getUserStores(newUser.id).then(userStores => {
            getUserCompanies(newUser.id).then(userCompanies => {
                setUser(prev => ({...newUser, store: prev.store, stores: userStores, company: userCompanies[0], companies: userCompanies}));
                setMsg({});

                getCompanyStores(userCompanies[0].id).then(storeData => {
                    return setStores(storeData);
                });

                getUserOperations(newUser.id).then(opsData => {
                    setOps(opsData.filter(item => Number(item.company) === Number(userCompanies[0].id)));
                });
        
                setMsg({
                    color: "success",
                    message: "Sikeres műszak váltás!"
                });
                
                setOpenOper(0);
            });
        });
    };

    const uploadImage = async(trafficItem) => {
        const file = new FormData();
        file.append("file", image);

        axios.post(apiUrl+"/store/traffic/image/"+trafficItem, file)
        .then(res => {
            setLoading(false);
        });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OpHeader
                    operation={3}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                    maxWidth={500}
                    marginLeft="auto"
                    marginRight="auto"
                >
                    {users.map(item => {
                        return (
                            <UserItem
                                key={item.id}
                                user={item}
                                onChoose={() => handleChooseUser(item)}
                                onLogin={(pw) => handleLogin(item, pw)}
                            />
                        );
                    })}
                </Box>
            </Grid>
        </Grid>
    );
};

export default ChangeLogin;