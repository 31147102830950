import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Avatar, Box, Button, FormControl, FormLabel, Grid, MenuItem, OutlinedInput, Paper, Select, Stack, Step, StepButton, Stepper, TextField, Typography } from "@mui/material";

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import OpHeader from "../../../components/opheader/OpHeader";
import StoreIcon from "../../../layouts/stores/content/StoreIcon";
import NewUserPersonal from "./NewUserPersonal";

const NewUserProfile = () => {
    const {user, setLoading, setMsg, setOpenOper} = useContext(DataContext);

    const [comps, setComps] = useState([]);
    const [ranks, setRanks] = useState([]);
    const [compStores, setCompStores] = useState([]);

    const [newUser, setNewUser] = useState({
        first: "",
        last: "",
        company: "",
        rank: "",
        profilePic: ""
    });
    const [userAvatar, setUserAvatar] = useState();

    const [next, setNext] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        getCompanies();
        getRanks();

        setLoading(false);
    }, []);

    useEffect(() => {
        getCompanyStores();
    }, [newUser.company]);

    const getCompanies = async() => {
        const res = await fetch(apiUrl+"/companies/user/"+user.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setComps(data.companies);
    };

    const getRanks = async() => {
        const res = await fetch(apiUrl+"/ranks", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setRanks(data.ranks);
    };

    const getCompanyStores = async() => {
        if(newUser.company === "") return;

        const res = await fetch(apiUrl+"/company/stores/"+newUser.company, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setCompStores(data.stores.map(item => ({...item, active: false})));
    };

    const handleClick = (store) => {
        setCompStores(prev => prev.map(item => {
            if(item.id === store.id) {
                return {...item, active: !item.active};
            }
            return item;
        }));
    };

    const handleNext = () => {
        if(Object.values(newUser).some(prop => prop === "")) {
            return setMsg({
                color: "error",
                message: "Minden mező kitöltése kötelező"
            });
        }

        setNext(true);
    };

    const renderPersonal = () => {
        if(next) {
            return (
                <NewUserPersonal
                    newUser={({...newUser, stores: compStores.filter(item => item.active)})}
                    onClose={() => setNext(false)}
                />
            );
        }
    };

    const uploadAvatar = (e) => {
        setNewUser(prev => ({...prev, profilePic: e.target.files[0]}));
        setUserAvatar(URL.createObjectURL(e.target.files[0]));
    };

    return (
        <>
            {renderPersonal()}

            {!next && 
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={15}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Paper
                            sx={{ p: 1 }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Stepper activeStep={0}>
                                        <Step>
                                            <StepButton icon={<PersonOutlineOutlinedIcon style={{ color: "#1890FF" }} />}>
                                                Profil
                                            </StepButton>
                                        </Step>

                                        <Step>
                                            <StepButton icon={<PersonOutlineOutlinedIcon />}>
                                                Személyes adatok
                                            </StepButton>
                                        </Step>

                                        <Step>
                                            <StepButton icon={<PersonOutlineOutlinedIcon />}>
                                                Jogosultságok
                                            </StepButton>
                                        </Step>

                                        <Step>
                                            <StepButton icon={<PersonOutlineOutlinedIcon />}>
                                                Jelszó
                                            </StepButton>
                                        </Step>
                                    </Stepper>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        gap={2}
                                    >
                                        <Stack alignItems="center">
                                            <FormLabel
                                                htmlFor="avatar"
                                                sx={{
                                                    position: 'relative',
                                                    borderRadius: '50%',
                                                    overflow: 'hidden',
                                                    '&:hover .MuiBox-root': { opacity: 1 },
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <Avatar
                                                    src={userAvatar}
                                                    sx={{ width: 124, height: 124, alignSelf: "center" }}
                                                />

                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        backgroundColor: 'rgba(0,0,0,.65)',
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: 0,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <Stack spacing={0.5} alignItems="center">
                                                        <CameraAltOutlinedIcon style={{ color: "#FFF" }} />
                                                        
                                                        <Typography sx={{ color: '#FFF' }} variant="caption">
                                                            Feltöltés
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                            </FormLabel>

                                            <TextField
                                                type="file"
                                                id="avatar"
                                                variant="outlined"
                                                sx={{ display: 'none' }}
                                                onChange={uploadAvatar}
                                            />
                                        </Stack>

                                        <Box>
                                            <Typography
                                                fontSize={14}
                                                fontWeight={400}
                                                color="#8C8C8C"
                                            >
                                                Vezetéknév
                                            </Typography>

                                            <OutlinedInput
                                                value={newUser.last}
                                                onChange={(e) => setNewUser(prev => ({...prev, last: e.target.value}))}
                                                size="small"
                                                sx={{
                                                    width: 300
                                                }}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography
                                                fontSize={14}
                                                fontWeight={400}
                                                color="#8C8C8C"
                                            >
                                                Keresztnév
                                            </Typography>

                                            <OutlinedInput
                                                value={newUser.first}
                                                onChange={(e) => setNewUser(prev => ({...prev, first: e.target.value}))}
                                                size="small"
                                                sx={{
                                                    width: 300
                                                }}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography
                                                fontSize={14}
                                                fontWeight={400}
                                                color="#8C8C8C"
                                            >
                                                Szervezet
                                            </Typography>

                                            <FormControl size="small" sx={{ width: 300 }}>
                                                <Select
                                                    value={newUser.company}
                                                    onChange={(e) => setNewUser(prev => ({...prev, company: e.target.value}))}
                                                >
                                                    {comps.map(item => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box>
                                            <Typography
                                                fontSize={14}
                                                fontWeight={400}
                                                color="#8C8C8C"
                                            >
                                                Jogkör
                                            </Typography>

                                            <FormControl size="small" sx={{ width: 300 }}>
                                                <Select
                                                    value={newUser.rank}
                                                    onChange={(e) => setNewUser(prev => ({...prev, rank: e.target.value}))}
                                                >
                                                    {ranks.map(item => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <ShoppingCartOutlinedIcon style={{ color: "#8C8C8C" }} />

                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={400}
                                                    color="#8C8C8C"
                                                >
                                                    Eladótér hozzáférések
                                                </Typography>
                                            </Box>

                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="space-between"
                                                width={300}
                                                height={40}
                                            >
                                                {compStores.map(item => {
                                                    return (
                                                        <StoreIcon
                                                            key={item.id}
                                                            store={item}
                                                            type="checkbox"
                                                            val={item.active}
                                                            onClick={handleClick}
                                                        />
                                                    );
                                                })}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        justifyContent="flex-end"
                                    >
                                        <Button variant="outlined" onClick={() => setOpenOper(0)}>Vissza</Button>
                                        <Button variant="contained" onClick={handleNext}>Következő</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            }    
        </>
    );
};

export default NewUserProfile;