import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";

import { motion } from 'framer-motion';

import StoreIcon from "../../../../layouts/stores/content/StoreIcon";

const StoreItem = ({ store, click }) => {
    return (
        <Grid item xs={12}>
            <Box
                component={Paper}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                padding={2}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={2}
                >
                    <StoreIcon store={store} />

                    <Stack>
                        <Typography fontSize={18} fontWeight={500}>{store.name}</Typography>
                        <Typography fontSize={14}>{store.address}</Typography>
                    </Stack>
                </Box>

                <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <Button
                        variant="contained"
                        onClick={click}
                    >
                        Módosítás
                    </Button>
                </motion.div>
            </Box>
        </Grid>
    );
};

export default StoreItem;