import { useContext } from "react";

import { DataContext } from "../../../context/DataContext";

const Icon = ({ op }) => {
    const {icons} = useContext(DataContext);

    const renderOpIcon = () => {
        if(Array.isArray(icons[op.operation-1])){
            return (
                <img style={{ width: 28 }} src={icons[op.operation-1][0]} alt="icon" />
            );
        }
        else {
            return (
                <img style={{ width: 28 }} src={icons[op.operation-1]} alt="icon" />
            );
        }
    };

    return renderOpIcon();
};

export default Icon;