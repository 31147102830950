import { Box, Card, Chip, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import CurrencyText from '../../../../components/currencytext/CurrencyText';

const StatCard = ({ title, count, percentage, isLoss, extra, type, active, click }) => {
    const color = isLoss ? "warning" : "primary";

    return (
        <Grid item xs={12} md={2.4}>
            <Tooltip onClick={click}>
                <Card
                    variant="outlined"
                    sx={{
                        p: 2,
                        cursor: "pointer",
                        borderColor: active && "#8C8C8C"
                    }}
                >
                    <Stack spacing={0.5}>
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="textSecondary"
                        >
                            {title}
                        </Typography>

                        <Grid container alignItems="center">
                            <Grid item>
                                {(count && !isNaN(count)) ?
                                    <Typography
                                        fontSize={14}
                                        fontWeight={700}
                                        color="inherit"
                                    >
                                        <CurrencyText val={count} /> {type}
                                    </Typography>
                                :
                                    <Skeleton variant="rounded" width={100} height={20} />
                                }
                            </Grid>

                            {!isNaN(percentage) ?
                                <Grid item>
                                    <Chip
                                        variant="filled"
                                        icon={
                                            <>
                                                {!isLoss && <TrendingUpIcon style={{ fontSize: '0.75rem', color: `${color || 'primary'}.main` }} />}
                                                {isLoss && <TrendingDownIcon style={{ fontSize: '0.75rem', color: `${color || 'warning'}.main` }} />}
                                            </>
                                        }
                                        label={percentage+"%"}
                                        sx={{
                                            bgcolor: isLoss ? "#FFF7E6" : "#E6F7FF",
                                            color: isLoss ? `${color || 'warning'}.main` : `${color || 'primary'}.main`,
                                            borderRadius: 1,
                                            ml: 1.25,
                                            pl: 1
                                        }}
                                        size="small"
                                    />
                                </Grid>
                            :
                                <Grid item>
                                    <Skeleton
                                        variant="rounded"
                                        width={50}
                                        height={20}
                                        sx={{
                                            ml: 1.25
                                        }}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Stack>

                    <Box sx={{ pt: 2.25 }}>
                        {(extra !== 0 && !isNaN(extra)) ?
                            <Typography
                                fontSize={10}
                                fontWeight={400}
                                color="textSecondary"
                            >
                                Különbség előző nap:{' '}
                                <Typography
                                    component="span"
                                    fontSize={10}
                                    fontWeight={400}
                                    sx={{ color: `${color || 'primary'}.main` }}
                                >
                                    <CurrencyText val={extra} /> {type}
                                </Typography>
                            </Typography>
                        :
                            <Skeleton variant="rounded" width={130} height={10}/>
                        }
                    </Box>
                </Card>
            </Tooltip>
        </Grid>
    );
};

export default StatCard;