import { useContext, useEffect, useState } from "react";

import useDebounce from "../../../hooks/useDebounce";
import { DataContext } from "../../../context/DataContext";

import { Grid, OutlinedInput } from "@mui/material";

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import OpHeader from "../../../components/opheader/OpHeader";
import TicketItem from "./content/TicketItem";
import CashoutVerify from "./CashoutVerify";

const CashoutTickets = () => {
    const {user, setOpenOper, setMsg} = useContext(DataContext);

    const [serial, setSerial] = useState("");
    const [cashout, setCashout] = useState({});

    const [tickets, setTickets] = useState([]);
    const [showTickets, setShowTickets] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getTickets();
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${serial}`,'i');
            setShowTickets(tickets.filter(item => rx.test(item.serial_num)).filter((item, index) => index < 15));
        }
        catch(err) {
            setSerial("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [serial], 500);

    const getTickets = async() => {
        const res = await fetch(apiUrl+"/tickets/itemized/"+user.store, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTickets(data.tickets);
    };

    const handleCashout = async(item, prize) => {
        if(prize === null){
            return setMsg({
                color: "error",
                message: "Hibás kód!"
            });
        }

        setMsg({});
        setCashout({
            ticket: item,
            prize: prize
        })
    };

    const renderVerify = () => {
        if(Object.keys(cashout).length !== 0) {
            return (
                <CashoutVerify
                    info={cashout}
                    onClose={() => setCashout({})}
                />
            );
        }
    };

    return (
        <>
            {renderVerify()}

            {Object.keys(cashout).length === 0 &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={12}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>
        
                    <Grid item xs={12}>
                        <OutlinedInput
                            value={serial}
                            onChange={(e) => setSerial(e.target.value)}
                            placeholder="Add meg a sorsjegy sorozatszámát"
                            size="small"
                            fullWidth
                            startAdornment={
                                <EmojiEventsIcon sx={{ marginRight: 1 }} />
                            }
                            sx={{ bgcolor: "#FFF" }}
                        />
                    </Grid>
        
                    {serial !== "" && showTickets.map(item => {
                        return (
                            <TicketItem
                                key={item.id}
                                item={item}
                                onCashout={handleCashout}
                            />
                        );
                    })}
                </Grid>
            }
        </>
    );
};

export default CashoutTickets;