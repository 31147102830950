import { useContext, useState } from "react";

import { DataContext } from "../../context/DataContext";
import { getUserCompanies } from "../../services/Api";

import { motion } from 'framer-motion';

import { Box, Button, Grid, Grow, InputAdornment, OutlinedInput, Stack } from "@mui/material";

import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import OpHeader from "../../components/opheader/OpHeader";

const CompanyCreate = () => {
    const {user, setUser, setOpenOper, setMsg, setStores} = useContext(DataContext);

    const [input, setInput] = useState({
        name: "",
        tax: "",
        address: "",
        bank: ""
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleCompanyCreate = async() => {
        const res = await fetch(apiUrl+"/company/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                company: input
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getUserCompanies(user.id).then(userCompanies => {
            setUser(prev => ({...prev, company: userCompanies.filter(item => item.id === data.newCompany.id)[0], companies: userCompanies}));

            setStores([]);

            setOpenOper(0);

            setMsg({
                color: "success",
                message: "Sikeresen létrehoztad a szervezetet!"
            });
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={28}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Grid
                item
                xs={12}
                md={5}
                marginLeft="auto"
                marginRight="auto"
            >
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            style={{ width: "100%" }}
                        >
                            <Grow in={true} timeout={1000}>
                                <OutlinedInput
                                    type="text"
                                    size="small"
                                    placeholder="cégnév"
                                    fullWidth
                                    value={input.name}
                                    onChange={
                                        (e) => setInput(prevInput => ({...prevInput, name: e.target.value}))
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <BusinessIcon />
                                        </InputAdornment>
                                    }
                                    sx={{
                                        backgroundColor: "#FFFFFF"
                                    }}
                                />
                            </Grow>
                        </motion.div>

                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            style={{ width: "100%" }}
                        >
                            <Grow in={true} timeout={1500}>
                                <OutlinedInput
                                    type="text"
                                    size="small"
                                    placeholder="adószám"
                                    fullWidth
                                    value={input.tax}
                                    onChange={
                                        (e) => setInput(prevInput => ({...prevInput, tax: e.target.value}))
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <BadgeOutlinedIcon />
                                        </InputAdornment>
                                    }
                                    sx={{
                                        backgroundColor: "#FFFFFF"
                                    }}
                                />
                            </Grow>
                        </motion.div>

                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            style={{ width: "100%" }}
                        >
                            <Grow in={true} timeout={2000}>
                                <OutlinedInput
                                    type="text"
                                    size="small"
                                    placeholder="székhely"
                                    fullWidth
                                    value={input.address}
                                    onChange={
                                        (e) => setInput(prevInput => ({...prevInput, address: e.target.value}))
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <HomeIcon />
                                        </InputAdornment>
                                    }
                                    sx={{
                                        backgroundColor: "#FFFFFF"
                                    }}
                                />
                            </Grow>
                        </motion.div>

                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            style={{ width: "100%" }}
                        >
                            <Grow in={true} timeout={2500}>
                                <OutlinedInput
                                    type="text"
                                    size="small"
                                    placeholder="bankszámla"
                                    fullWidth
                                    value={input.bank}
                                    onChange={
                                        (e) => setInput(prevInput => ({...prevInput, bank: e.target.value}))
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <AccountBalanceIcon />
                                        </InputAdornment>
                                    }
                                    sx={{
                                        backgroundColor: "#FFFFFF"
                                    }}
                                />
                            </Grow>
                        </motion.div>
                    </Stack>

                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Grow in={true} timeout={3000}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="small"
                                    disabled={Object.values(input).some(item => item.length === 0)}
                                    onClick={handleCompanyCreate}
                                >
                                    Létrehozás
                                </Button>
                            </Grow>
                        </motion.div>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CompanyCreate;