import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";

import { motion } from 'framer-motion';

import { Avatar, AvatarGroup, Box, Button, Card, Grid, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DarkModeIcon from '@mui/icons-material/DarkMode';

import Store from "../../../layouts/stores/content/Store";
import StoreIcon from "../../../layouts/stores/content/StoreIcon";

const ShopArea = ({ stores, ops, onOpen }) => {
    const {user, opComps, setOpenOper} = useContext(DataContext);

    const [userStore, setUserStore] = useState({
        name: "",
        color_code_2: ""
    });

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = user.profile_pic ? apiUrl+"/"+user.profile_pic : null;

    useEffect(() => {
        if(user.store && stores.length !== 0) {
            setUserStore(stores.filter(item => item.id === user.store)[0]);
        }
    }, [stores]);

    const handleToOperation = (choosen) => {
        if(Object.keys(opComps).includes(choosen.operation.toString())) {
            setOpenOper(choosen.operation);
            return;
        }
    };

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <Grid
            container
            item
            xs={12}
            md={12}
        >
            {user.store ? 
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card
                            variant="outlined"
                            sx={{
                                p: 1
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        alignItems="center"
                                        width="100%"
                                        height="100%"
                                    >
                                        <AvatarGroup max={2}>
                                            <StoreIcon
                                                store={userStore}
                                            />

                                            <Tooltip
                                                title={user.last_name+" "+user.first_name}
                                                arrow
                                            >
                                                <Avatar
                                                    src={imageExists(avatar) ? avatar : null}
                                                    sx={{ zIndex: 2 }}
                                                />
                                            </Tooltip>
                                        </AvatarGroup>

                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Typography fontSize={14}>{userStore.name}</Typography>
                                            <Typography fontSize={12}>{userStore.address}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        alignItems="center"
                                        width="100%"
                                        height="100%"
                                    >
                                        <Button
                                            variant="contained"
                                            size="small"
                                            disabled={ops.filter(item => item.operation === 3).length === 0}
                                            onClick={() => handleToOperation(ops.filter(item => item.operation === 3)[0])}
                                            fullWidth={isMdDown}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: 1,
                                                marginLeft: "auto",
                                                bgcolor: "#949494"
                                            }}
                                        >
                                            <ManageAccountsOutlinedIcon />
                                            <Typography fontSize={12}>Műszak váltás</Typography>
                                        </Button>

                                        <Button
                                            variant="contained"
                                            size="small"
                                            disabled={ops.filter(item => item.operation === 2).length === 0}
                                            onClick={() => handleToOperation(ops.filter(item => item.operation === 2)[0])}
                                            fullWidth={isMdDown}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: 1,
                                                bgcolor: "#949494"
                                            }}
                                        >
                                            <DarkModeIcon />
                                            <Typography fontSize={12}>Napzárás</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            :
                <Grid
                    container
                    item
                    xs={12}
                    md={12}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography color="#000" fontWeight={500}>Telephelyek</Typography>
                        </Grid>

                        {stores.map((item, i) => {
                            return (
                                <Store
                                    store={item}
                                    click={() => onOpen(item)}
                                />
                            );
                        })}

                        <Grid item xs={12} md={4}>
                            <Tooltip onClick={() => navigate("/store/create")}>
                                <motion.div
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            height: 60,
                                            p: 1,
                                            borderRadius: 2,
                                            cursor: "pointer",
                                            opacity: 0.8,
                                            ":hover": {
                                                opacity: 1
                                            }
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            gap={3}
                                            alignItems="center"
                                            height="100%"
                                        >
                                            <Avatar sx={{ bgcolor: "#46A6FF" }}>
                                                <AddIcon />
                                            </Avatar>

                                            <Typography fontSize={14} color="#46A6FF">Új eladótér / telephely létrehozás</Typography>
                                        </Box>
                                    </Card>
                                </motion.div>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export default ShopArea;