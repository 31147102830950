import { useRef } from "react";

import { Grid, Grow, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";

import CurrencyText from "../currencytext/CurrencyText";

const TablePcs = ({ pcs, setPcs, onEnter }) => {
    const refs = {
        ref20000: useRef(), 
        ref10000: useRef(), 
        ref5000: useRef(), 
        ref2000: useRef(), 
        ref1000: useRef(), 
        ref500: useRef(), 
        ref200: useRef(), 
        ref100: useRef(), 
        ref50: useRef(), 
        ref20: useRef(), 
        ref10: useRef(), 
        ref5: useRef(), 
    };

    return (
        <>
            <Grid item xs={12} md={6}>
                <Grow in={true} timeout={400}>
                    <Paper>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ pl: 3 }}>Címlet</TableCell>
                                        <TableCell>Darabszám</TableCell>
                                        <TableCell sx={{ minWidth: 100 }}>Összeg</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>20 000 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf20000}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf20000: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref20000}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref10000.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf20000*20000}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>10 000 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf10000}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf10000: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref10000}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref5000.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>
                                        
                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf10000*10000}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>5 000 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf5000}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf5000: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref5000}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref2000.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>
                                        
                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf5000*5000}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>2 000 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf2000}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf2000: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref2000}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref1000.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>
                                        
                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf2000*2000}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>1 000 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf1000}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf1000: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref1000}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref500.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>
                                        
                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf1000*1000}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>500 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf500}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf500: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref500}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref200.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>
                                        
                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf500*500}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grow>
            </Grid>

            <Grid item xs={12} md={6}>
                <Grow in={true} timeout={800}>
                    <Paper>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ pl: 3 }}>Címlet</TableCell>
                                        <TableCell>Darabszám</TableCell>
                                        <TableCell sx={{ minWidth: 100 }}>Összeg</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>200 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf200}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf200: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref200}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref100.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf200*200}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>100 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf100}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf100: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref100}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref50.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>
                                        
                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf100*100}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>50 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf50}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf50: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref50}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref20.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>
                                        
                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf50*50}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>20 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf20}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf20: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref20}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref10.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf20*20}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>10 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf10}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf10: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref10}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        refs.ref5.current.focus();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>
                                        
                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf10*10}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover>
                                        <TableCell sx={{ pl: 3 }}>5 HUF</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={pcs.huf5}
                                                onChange={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setPcs(prev => ({...prev, huf5: e.target.value}))
                                                    }
                                                }}
                                                inputRef={refs.ref5}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter") {
                                                        onEnter();
                                                    }
                                                }}
                                                onFocus={(e) => e.target.select()}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    maxWidth: 120
                                                }}
                                            />
                                        </TableCell>
                                        
                                        <TableCell>
                                            <CurrencyText
                                                val={pcs.huf5*5}
                                            /> HUF
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grow>
            </Grid>
        </>
    );
};

export default TablePcs;