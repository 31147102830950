import { useState } from "react";

import { Box, IconButton, Typography } from "@mui/material";

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

const FilingItem = ({ title, subtitle, dropdown, filled, chip, def }) => {
    const [open, setOpen] = useState(def ? true : false);

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap={0.5}
                >
                    <Typography
                        fontSize={12}
                        fontWeight={700}
                    >
                        {title}
                    </Typography>
                    
                    {filled ?
                        <>
                            {chip}
                        </>
                    :
                        <Typography
                            fontSize={10}
                            fontWeight={300}
                        >
                            {subtitle}
                        </Typography>   
                    }
                </Box>

                <IconButton
                    size="small"
                    onClick={() => setOpen(prev => !prev)}
                >
                    {open ?
                        <KeyboardArrowUpOutlinedIcon />
                    :
                        <KeyboardArrowDownOutlinedIcon />
                    }
                </IconButton>
            </Box>

            {open && dropdown}
        </>
    );
};

export default FilingItem;