import { useContext, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Grid, Tab, Tabs } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";

import Winner from "./content/Winner";
import Control from "./content/Control";
import Delivery from "./content/Delivery";
import Wait from "./content/Wait";
import Credit from "./content/Credit";
import Wrong from "./content/Wrong";
import PackItemized from "./content/PackItemized";

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: "100%" }}
        >
            {value === index && children}
        </div>
    );
};

const ManageTickets = () => {
    const {setOpenOper} = useContext(DataContext);

    const [tab, setTab] = useState(0);
    const [itemized, setItemized] = useState({});

    const handleItemized = (pack) => {
        setItemized(pack);
    };

    const renderItemized = () => {
        if(Object.keys(itemized).length !== 0) {
            return (
                <PackItemized
                    info={itemized}
                    onClose={() => setItemized({})}
                />
            );
        }
    };

    return (
        <>
            {renderItemized()}

            {Object.keys(itemized).length === 0 &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={16}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Tabs
                            variant="standard"
                            value={tab}
                            onChange={(e, newVal) => setTab(newVal)}
                        >
                            <Tab
                                label="Nyertes"
                                sx={{
                                    "&:hover": {
                                        color: "#2278CF"
                                    }
                                }}
                            />

                            <Tab
                                label="Ellenőrzés alatt"
                                sx={{
                                    "&:hover": {
                                        color: "#2278CF"
                                    }
                                }}
                            />

                            <Tab
                                label="Leadva SZRT"
                                sx={{
                                    "&:hover": {
                                        color: "#2278CF"
                                    }
                                }}
                            />

                            <Tab
                                label="Befizetésre vár"
                                sx={{
                                    "&:hover": {
                                        color: "#2278CF"
                                    }
                                }}
                            />

                            <Tab
                                label="Befizetve"
                                sx={{
                                    "&:hover": {
                                        color: "#2278CF"
                                    }
                                }}
                            />

                            <Tab
                                label="Téves kifizetés"
                                sx={{
                                    "&:hover": {
                                        color: "#2278CF"
                                    }
                                }}
                            />
                        </Tabs>
                    </Grid>

                    <TabPanel value={tab} index={0}>
                        <Winner
                            onControl={() => setTab(1)}
                        />
                    </TabPanel>

                    <TabPanel value={tab} index={1}>
                        <Control
                            onDelivery={() => setTab(2)}
                        />
                    </TabPanel>

                    <TabPanel value={tab} index={2}>
                        <Delivery
                            onItemized={handleItemized}
                            onWait={() => setTab(3)}
                        />
                    </TabPanel>

                    <TabPanel value={tab} index={3}>
                        <Wait
                            onItemized={handleItemized}
                            onCredit={() => setTab(4)}
                        />
                    </TabPanel>

                    <TabPanel value={tab} index={4}>
                        <Credit
                            onItemized={handleItemized}
                        />
                    </TabPanel>

                    <TabPanel value={tab} index={5}>
                        <Wrong />
                    </TabPanel>
                </Grid>
            }
        </>
    );
};

export default ManageTickets;