import { useContext, useState } from 'react';

import { DataContext } from '../../../../../context/DataContext';

import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, OutlinedInput, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';

import ControlPointIcon from '@mui/icons-material/ControlPoint';

const ItemizedTag = ({ partner, type, companyData, tagLabel, onRefresh }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {setMsg, setLoading} = useContext(DataContext);

    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState("");

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleVerify = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/company", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: type,
                partner: partner,
                companyData: companyData,
                info: info
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setOpen(false);
        onRefresh();
        setMsg({
            color: "success",
            message: "Sikeres adat módosítás"
        });

        setLoading(false);
    };

    return (
        <>
            <Tooltip
                onClick={() => setOpen(true)}
                sx={{ cursor: "pointer" }}
            >
                <Typography
                    fontSize={12}
                    fontWeight={300}
                    color="#096DD9"
                >
                    {tagLabel}
                </Typography>
            </Tooltip>

            <Dialog
                fullScreen={matches}
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={!matches && true}
                maxWidth={!matches && "sm"}
            >
                <DialogTitle>
                    {type === "address" && "Cím hozzáadása"}
                    {type === "tax" && "Adószám hozzáadása"}
                    {type === "bank" && "Bankszámla hozzáadása"}
                </DialogTitle>

                <DialogContent>
                    <Stack spacing={2}>
                        {type === "address" && <DialogContentText>Kérlek add meg a cég címét</DialogContentText>}
                        {type === "tax" && <DialogContentText>Kérlek add meg a cég adószámát</DialogContentText>}
                        {type === "bank" && <DialogContentText>Kérlek add meg a cég bankszámlaszámát</DialogContentText>}

                        <OutlinedInput
                            value={info}
                            onChange={(e) => setInfo(e.target.value)}
                            size="small"
                            fullWidth
                        />
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Mégse</Button>
                    <Button disabled={info.length === 0} onClick={handleVerify}>Tovább</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ItemizedTag;