import { useContext, useEffect, useState } from "react";

import useDebounce from "../../../hooks/useDebounce";
import { DataContext } from "../../../context/DataContext";

import { Box, Grid, OutlinedInput, Pagination, Tab, Tabs } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";
import InvoiceItem from "./content/InvoiceItem";
import InvoiceItemized from "./InvoiceItemized";

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: "100%" }}
        >
            {value === index && children}
        </div>
    );
};

const InvoicesOutbound = () => {
    const {user, setMsg, setLoading, setOpenOper} = useContext(DataContext);

    const [isNAV, setIsNAV] = useState(true);

    const [payments, setPayments] = useState([]);
    const [costplaces, setCostplaces] = useState([]);
    const [tags, setTags] = useState([]);

    const [invoiceData, setInvoiceData] = useState([]);
    const [showInvoiceData, setShowInvoiceData] = useState([]);

    const [search, setSearch] = useState("");
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [tab, setTab] = useState(0);

    const [open, setOpen] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setInvoiceData([{},{},{},{},{},{},{},{},{},{},{},{}]);
        setShowInvoiceData([{},{},{},{},{},{},{},{},{},{},{},{}]);
    }, []);

    useEffect(() => {
        if(!user.company.nav_token) {
            setMsg({
                color: "error",
                message: "Nincs megadva technikai felhasználó a szervezethez"
            });
            return setOpenOper(0);
        }

        getCompanyTags();
        getPayments();
        getCostplaces();

        getNewInvoices();
    }, [user]);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            const filteredData = invoiceData.filter(item => rx.test(item.partner_name) || rx.test(item.serial_num));

            setPages(Math.round(filteredData.length/12));

            setShowInvoiceData(filteredData);
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, invoiceData], 700);

    const getCompanyTags = async() => {
        const res = await fetch(apiUrl+"/tags/company/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTags(data.tags);
    };

    const getPayments = async() => {
        const res = await fetch(apiUrl+"/inbound/payments", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPayments(data.payments);
    };

    const getCostplaces = async() => {
        const res = await fetch(apiUrl+"/inbound/costplaces", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setCostplaces(data.costplaces);
    };

    const getNewInvoices = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/newinvoices", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ token: user.company.nav_token })
        });

        const data = await res.json();

        if(!data.success){
            setIsNAV(data.noNew ? true : false);
            getInvoices();
            return setMsg({
                color: data.noNew ? "success" : "error",
                message: data.message
            });
        }

        if(data.invoices.length !== 0) {
            return syncPartners(data.invoices);
        }

        getInvoices();
    };

    const syncPartners = async(invoices) => {
        const res = await fetch(apiUrl+"/outbound/sync/partners", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(invoices)
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        syncInvoices(invoices);
    };

    const syncInvoices = async(invoices) => {
        const res = await fetch(apiUrl+"/outbound/sync/invoices", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                invoices: invoices
            })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getInvoices();
    };

    const getInvoices = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/get/all", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceData(data.invoices);
        setLoading(false);
    };

    const getInvoice = async(serialNum) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/get/serial", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ serialNum: serialNum })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceData(prev => {
            const index = prev.findIndex(item => item.serial_num === data.invoice.serial_num);

            if(JSON.stringify(open) !== "{}") {
                setOpen(data.invoice);
            }

            return [
                ...prev.slice(0, index),
                data.invoice,
                ...prev.slice(index+1)
            ];
        });

        setLoading(false);
    };

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const renderItemized = () => {
        if(JSON.stringify(open) !== "{}") {
            return (
                <InvoiceItemized
                    invoice={open}
                    payments={payments}
                    costplaces={costplaces}
                    tags={tags}
                    onRefresh={() =>{
                        getCompanyTags();
                        getPayments();
                        getCostplaces();
                        getInvoice(open.serial_num);
                    }}
                    onClose={() => setOpen({})}
                />
            );
        }
    };

    return (
        <>
            {renderItemized()}

            {JSON.stringify(open) === "{}" &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={23}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12} md={8}>
                                <Tabs
                                    variant="standard"
                                    value={tab}
                                    onChange={handleChange}
                                >
                                    <Tab
                                        label="Kimenő számlák"
                                        sx={{
                                            "&:hover": {
                                                color: "#2278CF"
                                            }
                                        }}
                                    />

                                    <Tab
                                        label="Rendezve"
                                        sx={{
                                            "&:hover": {
                                                color: "#2278CF"
                                            }
                                        }}
                                    />
                                </Tabs>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <OutlinedInput
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Keresés"
                                    size="small"
                                    sx={{ bgcolor: "#FFF" }}
                                    fullWidth
                                />
                            </Grid>

                            {/*
                            <Grid
                                item
                                xs={12}
                                md={6}
                                marginTop="auto"
                                marginBottom="auto"
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={2}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography color="#747474" fontSize={14}>Utolsó szinkronizáció: {invoiceData[0]?.record_datetime}</Typography>
                                    <Tooltip
                                        arrow
                                        title={isNAV ? "Van NAV kapcsolat" : "Nincs NAV kapcsolat"}
                                    >
                                        <Badge color={isNAV ? "primary" : "secondary"} badgeContent=" " variant="dot" />
                                    </Tooltip>
                                </Box>
                            </Grid>
                            */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TabPanel value={tab} index={0}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        <Pagination
                                            count={pages}
                                            variant="outlined"
                                            shape="rounded"
                                            color="primary"
                                            page={page}
                                            onChange={handleChangePage}
                                        />
                                    </Box>
                                </Grid>

                                {showInvoiceData.filter(item => !item.status).filter((_, i) => i >= (page-1)*12 && i < ((page-1)*12)+12).map((item, index) => {
                                    return (
                                        <InvoiceItem
                                            key={item.id}
                                            invoice={item}
                                            timeout={(index+1)*400}
                                            payments={payments}
                                            costplaces={costplaces}
                                            onItemized={() => isNAV && setOpen(item)}
                                            onRefresh={() => getInvoice(item.serial_num)}
                                        />
                                    );
                                })}
                            </Grid>
                        </TabPanel>
                    </Grid>

                    <Grid item xs={12}>
                        <TabPanel value={tab} index={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        <Pagination
                                            count={pages}
                                            variant="outlined"
                                            shape="rounded"
                                            color="primary"
                                            page={page}
                                            onChange={handleChangePage}
                                        />
                                    </Box>
                                </Grid>

                                {showInvoiceData.filter(item => item.status === 1).filter((_, i) => i >= (page-1)*12 && i < ((page-1)*12)+12).map((item, index) => {
                                    return (
                                        <InvoiceItem
                                            key={item.id}
                                            invoice={item}
                                            timeout={(index+1)*400}
                                            payments={payments}
                                            costplaces={costplaces}
                                            onItemized={() => isNAV && setOpen(item)}
                                            onRefresh={() => getInvoice(item.serial_num)}
                                        />
                                    );
                                })}
                            </Grid>
                        </TabPanel>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default InvoicesOutbound;