import { Avatar, Box, Stack, Typography } from "@mui/material";
import CurrencyText from "../../../../../../../components/currencytext/CurrencyText";

const DialogHeader = ({ invoice }) => {
    return ( 
        <Box 
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding={1}
            sx={{ backgroundColor: "#B7EB8F"}}
        >
            <Stack>
                <Typography
                    fontSize={12}
                    fontWeight={300}
                >
                    {invoice.partner_name}
                </Typography>

                <Typography
                    fontSize={10}
                    fontWeight={300}
                >
                    {invoice.serial_num}
                </Typography>
            </Stack>

            <Stack>
                <Typography
                    fontSize={14}
                    fontWeight={400}
                >
                    <CurrencyText val={invoice.sum} /> Ft
                </Typography>

                <Typography
                    fontSize={12}
                    fontWeight={400}
                    color="#8C8C8C"
                >
                    <CurrencyText val={invoice.sum} /> Ft
                </Typography>
            </Stack>
        </Box>
     );
}
 
export default DialogHeader;