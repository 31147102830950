import { useContext } from "react";

import { DataContext } from "../../../context/DataContext";

import { Avatar, AvatarGroup, Box, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

import StoreIcon from "../../../layouts/stores/content/StoreIcon";

const SafeRow = ({ row, index, onModify, onImage }) => {
    const {stores} = useContext(DataContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = row.profile_pic ? apiUrl+"/"+row.profile_pic : null;
    const avatarTransit = row.transit_pic ? apiUrl+"/"+row.transit_pic : null;
    const avatarCashin = row.cashin_pic ? apiUrl+"/"+row.cashin_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <TableRow>
            <TableCell>{index}</TableCell>

            <TableCell align="center">
                {(!row.date_of_cashin && !row.date_of_transit) &&
                    <AccountBalanceWalletOutlinedIcon />
                }

                {(row.date_of_transit && !row.date_of_cashin) &&
                    <CasesOutlinedIcon />
                }

                {row.date_of_cashin &&
                    <AccountBalanceIcon />
                }
            </TableCell>

            <TableCell>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                >
                    <AvatarGroup max={2}>
                        <StoreIcon
                            store={stores.filter(item => item.id === row.store)[0]}
                            size="medium"
                        />

                        <Tooltip
                            title={row.last_name+" "+row.first_name}
                            arrow
                        >
                            <Avatar
                                src={imageExists(avatar) ? avatar : null}
                                sx={{ zIndex: 2, width: 32, height: 32 }}
                            />
                        </Tooltip>
                    </AvatarGroup>

                    <Typography fontSize={12} fontWeight={300}>{row.serial_num}</Typography>
                </Box>
            </TableCell>

            <TableCell>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>{row.date_of_record}</Typography>

                    <IconButton
                        onClick={() => onImage(row.record_image)}
                        size="small"
                    >
                        <CameraAltOutlinedIcon style={{ fontSize: 20 }} />
                    </IconButton>
                </Box>
            </TableCell>

            <TableCell>
                {row.date_of_transit &&
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        alignItems="center"
                    >
                        <Typography fontSize={12} fontWeight={300}>{row.date_of_transit}</Typography>

                        <IconButton
                            onClick={() => onImage(row.transit_image)}
                            size="small"
                        >
                            <CameraAltOutlinedIcon style={{ fontSize: 20 }} />
                        </IconButton>
                    </Box>
                }
            </TableCell>

            <TableCell>
                {row.date_of_transit &&
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        alignItems="center"
                    >
                        <Tooltip
                            title={row.transit_last+" "+row.transit_first}
                            arrow
                        >
                            <Avatar
                                src={imageExists(avatarTransit) ? avatarTransit : null}
                                sx={{ zIndex: 2, width: 32, height: 32 }}
                            />
                        </Tooltip>

                        <Typography fontSize={12} fontWeight={300}>{row.transit_num}</Typography>
                    </Box>
                }
            </TableCell>

            <TableCell>
                {row.date_of_cashin &&
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        alignItems="center"
                    >
                        <Tooltip
                            title={row.cashin_last+" "+row.cashin_first}
                            arrow
                        >
                            <Avatar
                                src={imageExists(avatarCashin) ? avatarCashin : null}
                                sx={{ zIndex: 2, width: 32, height: 32 }}
                            />
                        </Tooltip>

                        <Typography fontSize={12} fontWeight={300}>{row.date_of_cashin}</Typography>
                    </Box>
                }
            </TableCell>

            <TableCell align="center">
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                >
                    <Tooltip
                        arrow
                        title={row.comment}
                    >
                        <SmsOutlinedIcon />
                    </Tooltip>

                    <IconButton
                        onClick={onModify}
                        size="small"
                    >
                        <MoreHorizOutlinedIcon />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default SafeRow;