const CurrencyText = ({ val, style }) => {
    const displayText = () => {
        if(val) return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        else return 0;
    };

    return (
        <span style={style}>{displayText()}</span>
    );
};

export default CurrencyText;