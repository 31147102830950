import { useContext } from "react";

import { io } from "socket.io-client";

import { DataContext } from "../../../../../../context/DataContext";

import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import OpHeader from "../../../../../../components/opheader/OpHeader";
import VerifyItem from "./content/VerifyItem";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const IncomeVerify = ({ invoice, rows, store, onClose }) => {
    const {user, setLoading, setMsg, setOpenOper} = useContext(DataContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const setInvoiceStatus = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/instock", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                serialNum: invoice.serial_num
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres bevételezés"
        });
        
        socket.emit("inbound_invoices", {id: user.company.id});

        setOpenOper(0);

        setLoading(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    text="Bevételezés"
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#FAFAFA" }}>
                                <TableCell sx={{ color: "#434343" }}>#</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Megnevezés</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Mennyiség</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Egységár</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Összesen</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows.map((item, index) => {
                                return (
                                    <VerifyItem
                                        key={index}
                                        id={index+1}
                                        line={item}
                                        status={invoice.status}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12}>
                <Box
                    component={Paper}
                    p={2}
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    justifyContent="flex-end"
                >
                    <Button
                        variant="contained"
                        onClick={setInvoiceStatus}
                    >
                        Véglegesítés
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default IncomeVerify;