import { Avatar, Box, Tooltip, Typography } from "@mui/material";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ProfileItem = ({ user }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = user.profile_pic ? apiUrl+"/"+user.profile_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={1}
            width="100%"
        >
            <Box
                display="flex"
                flexDirection="row"
                gap={1}
                alignItems="center"
                border="1px solid #13C2C2"
                borderRadius={1}
                padding={2}
                boxSizing="border-box"
                width="100%"
            >
                <Tooltip
                    title={user.last_name+" "+user.first_name}
                    arrow
                >
                    <Avatar
                        src={imageExists(avatar) ? avatar : null}
                        sx={{ zIndex: 2, width: 32, height: 32 }}
                    />
                </Tooltip>

                <Typography
                    fontSize={12}
                    fontWeight={700}
                    color="#000"
                >
                    {user.last_name+" "+user.first_name}
                </Typography>

                <CheckCircleOutlineIcon
                    style={{
                        color: "#13C2C2",
                        fontSize: 32,
                        marginLeft: "auto"
                    }}
                />
            </Box>
        </Box>
    );
};

export default ProfileItem;