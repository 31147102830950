import { Avatar, AvatarGroup, TableCell, TableRow } from "@mui/material";

import StoreIcon from "../../../../layouts/stores/content/StoreIcon";
import CurrencyText from "../../../../components/currencytext/CurrencyText";

const TableItem = ({ line }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = line.profile_pic ? apiUrl+"/"+line.profile_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("GET", url, true);
        http.send();
    
        return http.status !== 404;
    }

    return (
        <TableRow hover>
            <TableCell>
                <AvatarGroup>
                    <StoreIcon
                        store={line}
                        size="small"
                    />

                    <Avatar
                        src={imageExists(avatar) ? avatar : null}
                        sx={{ height: 22, width: 22 }}
                    />
                </AvatarGroup>
            </TableCell>

            <TableCell>
                {new Date(line.date).toLocaleString("hu-HU")}
            </TableCell>

            <TableCell>
                {line.receipt_num_1}
            </TableCell>

            <TableCell>
                {line.barcode}
            </TableCell>

            <TableCell>
                {line.item_name}
            </TableCell>

            <TableCell>
                {line.quantity} DB
            </TableCell>

            <TableCell>
                <CurrencyText val={line.unit_price} /> HUF
            </TableCell>

            <TableCell>
                <CurrencyText val={line.purchase_price} /> HUF
            </TableCell>

            <TableCell>
                <CurrencyText val={line.unit_value} /> HUF
            </TableCell>

            <TableCell>
                <CurrencyText val={line.purchase_value} /> HUF
            </TableCell>

            <TableCell>
                {line.vat === 0 ? "AJT" : line.vat+" %"}
            </TableCell>

            <TableCell>
                {(Number(line.margin)).toFixed(2)} %
            </TableCell>
        </TableRow>
    );
};

export default TableItem;