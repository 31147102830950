import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";

const SummaryOpen = ({ id }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getLog();
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/store/dailylog/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
        return setLogData(data.log);
    };

    return (
        <table className={SummaryStyle.summary_table}>
            <tbody>
                <tr>
                    <td>Dátum</td>
                    <th>{logData.date}</th>
                </tr>
                <tr>
                    <td>20 000 HUF</td>
                    <th>{logData.huf_20000_pcs} DB</th>
                </tr>
                <tr>
                    <td>10 000 HUF</td>
                    <th>{logData.huf_10000_pcs} DB</th>
                </tr>
                <tr>
                    <td>5 000 HUF</td>
                    <th>{logData.huf_5000_pcs} DB</th>
                </tr>
                <tr>
                    <td>2 000 HUF</td>
                    <th>{logData.huf_2000_pcs} DB</th>
                </tr>
                <tr>
                    <td>1 000 HUF</td>
                    <th>{logData.huf_1000_pcs} DB</th>
                </tr>
                <tr>
                    <td>500 HUF</td>
                    <th>{logData.huf_500_pcs} DB</th>
                </tr>
                <tr>
                    <td>200 HUF</td>
                    <th>{logData.huf_200_pcs} DB</th>
                </tr>
                <tr>
                    <td>100 HUF</td>
                    <th>{logData.huf_100_pcs} DB</th>
                </tr>
                <tr>
                    <td>50 HUF</td>
                    <th>{logData.huf_50_pcs} DB</th>
                </tr>
                <tr>
                    <td>20 HUF</td>
                    <th>{logData.huf_20_pcs} DB</th>
                </tr>
                <tr>
                    <td>10 HUF</td>
                    <th>{logData.huf_10_pcs} DB</th>
                </tr>
                <tr>
                    <td>5 HUF</td>
                    <th>{logData.huf_5_pcs} DB</th>
                </tr>
                <tr>
                    <td>Összesen</td>
                    <th>
                        {logData.sum} HUF
                    </th>
                </tr>
            </tbody>
        </table>
    );
};

export default SummaryOpen;