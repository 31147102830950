import { useContext, useEffect, useState } from "react";

import useDebounce from "../../../../../../hooks/useDebounce";
import { DataContext } from "../../../../../../context/DataContext";

import { Box, Button, Grid, InputAdornment, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import OpHeader from "../../../../../../components/opheader/OpHeader";
import IncomeVerify from "./IncomeVerify";
import InvoiceItem from "./content/InvoiceItem";
import TableItem from "./content/TableItem";
import CurrencyText from "../../../../../../components/currencytext/CurrencyText";
import BarcodeDialog from "./content/BarcodeDialog";
import QuantityDialog from "./content/QuantityDialog";
import SumDialog from "./content/SumDialog";
import NameDialog from "./content/NameDialog";
import NewNameDialog from "./content/NewNameDialog";
import TagsDialog from "./content/TagsDialog";

const IncomeTable = ({ invoice, store, onClose }) => {
    const {user, setMsg, setLoading} = useContext(DataContext);

    const [search, setSearch] = useState("");

    const [rows, setRows] = useState([]);
    const [showRows, setShowRows] = useState([]);
    const [choosen, setChoosen] = useState({});
    const [newProduct, setNewProduct] = useState({});

    const [barcodeDialog, setBarcodeDialog] = useState(false);
    const [barcode, setBarcode] = useState("");

    const [newNameDialog, setNewNameDialog] = useState(false);

    const [tagDialog, setTagDialog] = useState(false);

    const [quantityDialog, setQuantityDialog] = useState(false);
    const [boxQuantity, setBoxQuantity] = useState(1);
    const [quantity, setQuantity] = useState("");
    const [comment, setComment] = useState("");
    const [unit, setUnit] = useState("");

    const [sumDialog, setSumDialog] = useState(false);

    const [nameDialog, setNameDialog] = useState(false);
    
    const [verify, setVerify] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getInvoice();
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            setShowRows(rows.filter(item => rx.test(item.itemName)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, rows], 500);

    const getInvoice = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/itemized", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: user.company.nav_token,
                invoiceNum: invoice.serial_num
            })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setRows(data.invoice);

        setLoading(false);
    };

    const handleBarcode = (row) => {
        setChoosen(row);

        setBarcodeDialog(true);
    };

    const handleProduct = async() => {
        const res = await fetch(apiUrl+"/product/barcode", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                barcode: barcode,
                product: choosen,
                company: user.company.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setNewProduct(data.product);

        if(data.newItem) {
            setBarcodeDialog(false);
            setNewNameDialog(true);
        }
        else {
            if(data.product.name === choosen.itemName) {
                setBarcodeDialog(false);
                getIncome(data.product);
            }
            else {
                setBarcodeDialog(false);
                setNameDialog(true);
            }
        }
    };

    const handleNewName = async(choosenName) => {
        if(choosenName !== newProduct.name) {
            const res = await fetch(apiUrl+"/product/newname", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: newProduct.id,
                    newName: choosenName
                })
            });

            const data = await res.json();

            if(!data.success) {
                return setMsg({
                    color: "error",
                    message: data.message
                });
            }

            setNewNameDialog(false);
            setTagDialog(true);
        }
        else {
            setNewNameDialog(false);
            setTagDialog(true);
        }
    };

    const handleTagsDialog = () => {
        setTagDialog(false);
        getIncome(newProduct);
    };

    const handleName = async(choosenName) => {
        if(choosenName !== newProduct.name) {
            const res = await fetch(apiUrl+"/product/newname", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: newProduct.id,
                    newName: choosenName
                })
            });

            const data = await res.json();

            if(!data.success) {
                return setMsg({
                    color: "error",
                    message: data.message
                });
            }

            setNameDialog(false);
            getIncome(newProduct);
        }
        else {
            setNameDialog(false);
            getIncome(newProduct);
        }
    };

    const getIncome = async(product) => {
        const res = await fetch(apiUrl+"/product/income/last", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                product: product.id,
                store: store.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        if(data.income) {
            if(Number(data.income.purchase_price) !== Number(choosen.unitPrice)) {
                return savePriceChange(product.id, Number(data.income.purchase_price), Number(choosen.unitPrice));
            }

            return setQuantityDialog(true);
        }
        else {
            return savePriceChange(product.id, 0, Number(choosen.unitPrice));
        }
    };

    const savePriceChange = async(product, oldPrice, newPrice) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/product/price/change", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                product: product,
                incomeId: choosen.id,
                company: user.company.id,
                oldPrice: oldPrice,
                newPrice: newPrice
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setQuantityDialog(true);

        setLoading(false);
    };

    const handleSum = (bq, com, u) => {
        setBoxQuantity(bq);
        setComment(com);
        setUnit(u);

        setQuantityDialog(false);
        setSumDialog(true);
    };

    const handleSave = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/product/income/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                barcode: barcode,
                inputQuantity: quantity,
                company: user.company.id,
                store: store.id,
                product: choosen,
                invoice: invoice.id,
                unit: unit,
                comment: comment
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        saveStock();
    };

    const saveStock = async() => {
        const res = await fetch(apiUrl+"/product/stock", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                barcode: barcode,
                company: user.company.id,
                store: store.id,
                quantity: choosen.quantity
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setBarcode("");
        setQuantity("");
        setSumDialog(false);

        setMsg({
            color: "success",
            message: "Sikeres bevételezés"
        });

        setLoading(false);

        getInvoice();
    };

    const handleDelete = async(row) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/product/delete", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ row: row })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setLoading(false);

        getInvoice();
    };

    const calcNetSum = () => {
        return rows.reduce((acc, obj) => {
            return acc += (obj.unitPrice*obj.inputQuantity);
        }, 0);
    };

    const calcVatSum = () => {
        return rows.reduce((acc, obj) => {
            return acc += ((obj.unitPrice*obj.inputQuantity)*obj.vat);
        }, 0);
    };

    const calcGrossSum = () => {
        return rows.reduce((acc, obj) => {
            return acc += ((obj.unitPrice*obj.inputQuantity)*(obj.vat+1));
        }, 0);
    };

    const renderVerify = () => {
        if(verify) {
            return (
                <IncomeVerify
                    invoice={invoice}
                    rows={rows}
                    store={store}
                    onClose={() => setVerify(false)}
                />
            );
        }
    };

    return (
        <>
            {renderVerify()}

            {!verify &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            text="Bevételezés"
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <OutlinedInput
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Keresés"
                            fullWidth
                            size="small"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon style={{ width: 20 }} />
                                </InputAdornment>
                            }
                            sx={{
                                fontSize: 12,
                                backgroundColor: "#FFFFFF"
                            }}
                        />
                    </Grid>

                    {showRows?.filter(item => !item.barcode).map((item, index) => {
                        return (
                            <InvoiceItem
                                key={index}
                                line={item}
                                onBarcode={() => handleBarcode(item)}
                            />
                        );
                    })}

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                        >
                            <Typography
                                fontSize={16}
                                fontWeight={500}
                                color="#000"
                            >
                                Bevételezési lista
                            </Typography>

                            <Typography
                                fontSize={12}
                                color="#000"
                            >
                                ({rows.filter(item => item.barcode).length} termék hozzáadva)
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <caption>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        gap={2}
                                        color="#000"
                                    >
                                        <Typography fontSize={14} fontWeight={400}>
                                            Nettó összesen: <Typography fontWeight={600}><CurrencyText val={calcNetSum()?.toFixed(2)} /> HUF</Typography>
                                        </Typography>

                                        <Typography fontSize={14} fontWeight={400}>
                                            Áfa összesen: <Typography fontWeight={600}><CurrencyText val={calcVatSum()?.toFixed(2)} /> HUF</Typography>
                                        </Typography>

                                        <Typography fontSize={14} fontWeight={400}>
                                            Bruttó összesen: <Typography fontWeight={600}><CurrencyText val={calcGrossSum()?.toFixed(2)} /> HUF</Typography>
                                        </Typography>
                                    </Box>
                                </caption>

                                <TableHead>
                                    <TableRow sx={{ bgcolor: "#FAFAFA" }}>
                                        <TableCell sx={{ color: "#434343" }}>#</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Megnevezés</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Számlázva</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Bevételezve</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Info</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Áfa</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Nettó egységár</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Bruttó egységár</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Nettó összesen</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Bruttó összesen</TableCell>
                                        <TableCell sx={{ textAlign: "center", color: "#434343" }}>Műveletek</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {rows.filter(item => item.barcode).map((item, index) => {
                                        return (
                                            <TableItem
                                                key={index}
                                                id={index+1}
                                                line={item}
                                                onDelete={() => handleDelete(item)}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component={Paper}
                            p={2}
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            justifyContent="flex-end"
                        >
                            <Button
                                variant="contained"
                                disabled={rows.filter(item => !item.inputQuantity).length !== 0}
                                onClick={() => setVerify(true)}
                            >
                                Tovább
                            </Button>
                        </Box>
                    </Grid>

                    <BarcodeDialog
                        open={barcodeDialog}
                        setOpen={setBarcodeDialog}
                        barcode={barcode}
                        setBarcode={setBarcode}
                        choosen={choosen}
                        onNext={handleProduct}
                    />

                    <NewNameDialog
                        open={newNameDialog}
                        setOpen={setNewNameDialog}
                        choosen={choosen}
                        barcode={barcode}
                        nameInvoice={choosen.itemName}
                        onNext={handleNewName}
                    />

                    <TagsDialog
                        open={tagDialog}
                        setOpen={setTagDialog}
                        choosen={choosen}
                        barcode={barcode}
                        onNext={handleTagsDialog}
                    />

                    <NameDialog
                        open={nameDialog}
                        setOpen={setNameDialog}
                        choosen={choosen}
                        barcode={barcode}
                        nameProduct={newProduct.name}
                        nameInvoice={choosen.itemName}
                        onNext={handleName}
                    />

                    <QuantityDialog
                        open={quantityDialog}
                        setOpen={setQuantityDialog}
                        choosen={choosen}
                        barcode={barcode}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        onNext={handleSum}
                    />

                    <SumDialog
                        open={sumDialog}
                        setOpen={setSumDialog}
                        choosen={choosen}
                        barcode={barcode}
                        quantity={quantity}
                        boxQuantity={boxQuantity}
                        comment={comment}
                        unit={unit}
                        onNext={handleSave}
                    />
                </Grid>
            }
        </>
    );
};

export default IncomeTable;