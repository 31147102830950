import { useContext, useEffect, useState } from "react";

import { motion } from 'framer-motion';

import useDebounce from "../../../../../../hooks/useDebounce";
import { DataContext } from "../../../../../../context/DataContext";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Input, InputAdornment, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import PackLine from "./content/PackLine";

const NewPackDialog = ({ open, setOpen, transferInvs, onRefresh }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {user, setLoading, setMsg} = useContext(DataContext);

    const [packs, setPacks] = useState([]);
    const [showPacks, setShowPacks] = useState([]);

    const [packName, setPackName] = useState("");

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getPacks();
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${packName}`,'i');
            const filteredData = packs.filter(item => rx.test(item.name));

            setShowPacks(filteredData);
        }
        catch(err) {
            setPackName("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [packs, packName], 300);

    const getPacks = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/transfer/packs/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPacks(data.packs);

        setLoading(false);
    };

    const handleAddToPack = async(packId) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/transfer/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                packId: packId,
                transferIds: transferInvs.map(item => item.id),
                sum: transferInvs.reduce((acc, obj) => {
                    return acc += obj.sum;
                }, 0)
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setLoading(false);
        setOpen(false);

        getPacks();
        onRefresh();
    };

    const handleNewPack = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/transfer/new", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: packName,
                company: user.company.id,
                transferIds: transferInvs.map(item => item.id),
                sum: transferInvs.reduce((acc, obj) => {
                    return acc += obj.sum;
                }, 0)
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setLoading(false);
        setOpen(false);

        getPacks();
        onRefresh();
    };

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400,
                        height: "100%"
                    }
                }
            }}
        >
            <DialogTitle>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <IconButton
                        onClick={() => setOpen(false)}
                        size="small"
                    >
                        <ArrowBackIosIcon style={{ fontSize: 16 }} />
                    </IconButton>

                    <IconButton
                        size="small"
                    >
                        <OpenInFullIcon style={{ fontSize: 16 }} />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography
                        fontSize={20}
                        fontWeight={700}
                        color="#8C8C8C"
                    >
                        Utalási listához rendel
                    </Typography>

                    <Typography
                        fontSize={12}
                        fontWeight={300}
                        color="#8C8C8C"
                    >
                        Válazd ki meglévő utalási listák közül amelyhez a kiválasztott tételeket hozzá szeretnéd rendeli, Vagy hozz létre új utalási listát, a kiválasztott elemeknek.
                    </Typography>

                    <Stack spacing={0.5}>
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#8C8C8C"
                        >
                            Utalási lista keresés / létrehozás
                        </Typography>

                        <Input
                            value={packName}
                            onChange={(e) => setPackName(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        disabled={packName === ""}
                                        onClick={handleNewPack}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Stack>

                    {showPacks.slice(0, 3).map(item => {
                        return (
                            <PackLine
                                key={item.id}
                                pack={item}
                                onAdd={handleAddToPack}
                            />
                        );
                    })}
                </Box>
            </DialogContent>

            <DialogActions>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => setOpen(false)}
                            sx={{
                                fontSize: 12,
                                fontWeight: 300,
                                textTransform: "none",
                                color: "#8C8C8C",
                                borderColor: "#D9D9D9",
                                "&:hover": {
                                    borderColor: "#8C8C8C",
                                    color: "#8C8C8C",
                                    bgcolor: "#FFF"
                                }
                            }}
                        >
                            Vissza
                        </Button>
                    </motion.div>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default NewPackDialog;