import { useContext, useEffect, useState } from "react";

import { io } from "socket.io-client";

import { DataContext } from "../../../../context/DataContext";

import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { motion } from 'framer-motion';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import OpHeader from "../../../../components/opheader/OpHeader";
import PackTicket from "./content/PackTicket";
import Filters from "./content/Filters";
import CurrencyText from "../../../../components/currencytext/CurrencyText";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const PackItemized = ({ info, onClose }) => {
    const {user, setMsg, setLoading} = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [showTickets, setShowTickets] = useState([]);

    const [filterOpen, setFilterOpen] = useState(false);
    const [filters, setFilters] = useState({
        profiles: [],
        stores: [],
        dates: {
            from: null,
            to: null
        },
        values: {
            from: "",
            to: ""
        }
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        socket.emit("join", ["cid:"+user.company.id]);
        getPackInfo();
    }, []);

    useEffect(() => {
        socket.on("update_wrong", () => {
            getPackInfo();
        });
    }, [socket]);

    useEffect(() => {
        let filtered = tickets;

        if(filters.profiles.length !== 0) {
            filtered = filtered.filter(item => filters.profiles.includes(item.uid));
        }

        if(filters.stores.length !== 0) {
            filtered = filtered.filter(item => filters.stores.includes(item.sid));
        }

        if(filters.dates.from !== null && filters.dates.to !== null) {
            const from = new Date(filters.dates.from).toLocaleString("hu-HU", {year: "numeric", month: "2-digit", day: "2-digit"});
            const to = new Date(filters.dates.to).toLocaleString("hu-HU", {year: "numeric", month: "2-digit", day: "2-digit"});

            filtered = filtered.filter(item => {
                const itemDate = new Date(item.date).toLocaleString("hu-HU", {year: "numeric", month: "2-digit", day: "2-digit"});

                if(itemDate >= from && itemDate <= to) {
                    return item;
                }
            });
        }

        if(filters.values.from !== "" && filters.values.to !== "") {
            filtered = filtered.filter(item => {
                if(Number(item.prize_value) >= Number(filters.values.from) && Number(item.prize_value) <= Number(filters.values.to)) {
                    return item;
                }
            });
        }

        setShowTickets(filtered);
    }, [tickets, filters]);

    const getPackInfo = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/tickets/manage/pack/info/"+info.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTickets(data.pack.map(item => ({...item, selected: false})));

        setLoading(false);
    };

    const handleTicketCheck = (ticket, val) => {
        setShowTickets(prev => prev.map(item => {
            if(item.id === ticket.id) {
                return {...item, selected: val};
            }
            return item;
        }));
    };

    const handleSelectAll = (e) => {
        if(e.target.checked) {
            setShowTickets(prev => prev.map(item => {
                return {...item, selected: true};
            }));
            return;
        }

        setShowTickets(prev => prev.map(item => {
            return {...item, selected: false};
        }));
    };

    const handleBadCashout = async(faultType) => {
        const res = await fetch(apiUrl+"/tickets/manage/badcashout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                tickets: showTickets.filter(item => item.selected),
                fault: faultType
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        socket.emit("wrong_tickets", {id: user.company.id});

        getPackInfo();
    };

    const handleRemoveBad = async(ticket) => {
        const res = await fetch(apiUrl+"/tickets/manage/badcashout/remove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                tickets: [ticket]
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        socket.emit("wrong_tickets", {id: user.company.id});
        
        getPackInfo();
    };

    const calculateSum = () => {
        return showTickets.reduce((acc, obj) => {
            return acc += obj.prize_value;
        }, 0);
    };

    const handleFilter = (filter) => {
        setFilters(filter);
        setFilterOpen(false);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OpHeader
                    text={info.name}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        width="50%"
                    >
                        <OutlinedInput
                            placeholder="Felhasználók keresése"
                            size="small"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon style={{ width: 20 }} />
                                </InputAdornment>
                            }
                            sx={{
                                bgcolor: "#FFF",
                                width: "90%"
                            }}
                        />

                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <IconButton onClick={() => setFilterOpen(true)}>
                                <FilterAltOutlinedIcon />
                            </IconButton>
                        </motion.div>

                        <Filters
                            open={filterOpen}
                            onClose={handleFilter}
                            tickets={tickets}
                            onFilter={handleFilter}
                            isPack={false}
                        />
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                    >
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Button
                                variant="contained"
                                color="warning"
                                disabled={showTickets.filter(item => item.selected).length === 0}
                                onClick={() => handleBadCashout(0)}
                            >
                                + Serült sorsjegy
                            </Button>
                        </motion.div>

                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Button
                                variant="contained"
                                color="error"
                                disabled={showTickets.filter(item => item.selected).length === 0}
                                onClick={() => handleBadCashout(1)}
                            >
                                + Téves kifizetés
                            </Button>
                        </motion.div>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <caption>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                gap={2}
                                color="#000"
                            >
                                <Typography fontSize={14} fontWeight={400}>{showTickets.length} db</Typography>
                                <Typography fontSize={16} fontWeight={500}>/</Typography>
                                <Typography fontSize={14} fontWeight={700}>
                                    <CurrencyText
                                        val={calculateSum()}
                                    /> HUF
                                </Typography>
                            </Box>
                        </caption>
                        
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={showTickets.length > 0 && showTickets.filter(item => item.selected).length === showTickets.length}
                                        indeterminate={showTickets.filter(item => item.selected).length > 0 && showTickets.filter(item => item.selected).length < showTickets.length}
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>

                                <TableCell align="center">
                                    Kifizetve
                                </TableCell>

                                <TableCell>
                                    Típus
                                </TableCell>

                                <TableCell>
                                    Sorozatszám
                                </TableCell>

                                <TableCell>
                                    Kód
                                </TableCell>

                                <TableCell>
                                    Kifizetés
                                </TableCell>

                                <TableCell>
                                    Hibás kifizetés
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {showTickets.map(item => {
                                return (
                                    <PackTicket
                                        key={item.id}
                                        ticket={item}
                                        onCheck={handleTicketCheck}
                                        onRemove={handleRemoveBad}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default PackItemized;