import { useState } from "react";

import { Box, Grid, Grow, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import UserRow from "./UserRow";

const UserTable = ({ users, onEdit, onInactive }) => {
    const [page, setPage] = useState(1);

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Grid item xs={12}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Jogosultság</TableCell>
                                <TableCell>Felhasználó</TableCell>
                                <TableCell>Eladótér hozzáférés</TableCell>
                                <TableCell>Szerződés kezdetet</TableCell>
                                <TableCell>Szerződés vége</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {users.filter((_, i) => i >= (page-1)*15 && i < ((page-1)*15)+15).map((item, index) => {
                                return (
                                    <UserRow
                                        key={item.id}
                                        userInfo={item}
                                        timeout={(index+1)*400}
                                        onEdit={() => onEdit(item)}
                                        onInactive={() => onInactive(item)}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    marginLeft: "auto",
                    marginTop: 2
                }}
            >
                <Grow in={true} timeout={400}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                    >
                        <Pagination
                            count={Math.ceil(users.length/15)}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            page={page}
                            onChange={handleChange}
                        />
                    </Box>
                </Grow>
            </Grid>
        </>
    );
};

export default UserTable;