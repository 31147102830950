import { useEffect, useState } from "react";

import { getCardSum, getTrafficCashIn, getTrafficCashOut, getTrafficSum } from "../../services/TrafficCalc";

import { Box, Grid, TextField, Typography } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";
import PcsTable from "../../components/pcstable/PcsTable";
import InfoRow from "../daychange/content/InfoRow";
import ModifyRow from "../daychange/content/ModifyRow";
import CloseVat from "./CloseVat";
import Buttons from "../daychange/content/Buttons";

const ClosePcs = ({ traffic, fileName, round, onClose }) => {
    const [pcs, setPcs] = useState({
        huf20000: 0,
        huf10000: 0,
        huf5000: 0,
        huf2000: 0,
        huf1000: 0,
        huf500: 0,
        huf200: 0,
        huf100: 0,
        huf50: 0,
        huf20: 0,
        huf10: 0,
        huf5: 0,
        sum: 0
    });
    const [cancel, setCancel] = useState(0);
    const [comment, setComment] = useState("");

    const [change, setChange] = useState(false);

    useEffect(() => {
        calculateSum();
    }, [pcs.huf5, pcs.huf10, pcs.huf20, pcs.huf50, pcs.huf100, pcs.huf200, pcs.huf500, pcs.huf1000,
        pcs.huf2000, pcs.huf5000, pcs.huf10000, pcs.huf20000]);

    const calculateSum = () => {
        const sum = ((20000*pcs.huf20000) + (10000*pcs.huf10000) + (5000*pcs.huf5000) + (2000*pcs.huf2000) + 
        (1000*pcs.huf1000) + (500*pcs.huf500) + (200*pcs.huf200) + (100*pcs.huf100) + 
        (50*pcs.huf50) + (20*pcs.huf20) + (10*pcs.huf10) + (5*pcs.huf5));

        setPcs(prevPcs => ({...prevPcs, sum: sum}));
    };

    const renderCloseVat = () => {
        if(change) {
            return (
                <CloseVat
                    traffic={traffic}
                    fileName={fileName}
                    round={round}
                    pcs={pcs}
                    comment={comment}
                    onClose={() => setChange(false)}
                />
            );
        }
    };

    return (
        <>
            {renderCloseVat()}

            {!change &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={2}
                            onClose={onClose}
                        />
                    </Grid>

                    <PcsTable
                        pcs={pcs}
                        setPcs={setPcs}
                        modify={true}
                    />

                    <Grid item xs={12} md={4}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Typography fontSize={12} fontWeight={700} color="#000" marginBottom={1}>Fiók tartalom</Typography>
                        
                            <InfoRow
                                text="Fiók tartalma"
                                val={(getTrafficSum(traffic)+getTrafficCashIn(traffic))-getTrafficCashOut(traffic)+Number(round)-getCardSum(traffic)}
                                noIcon={true}
                            />

                            <ModifyRow
                                text="Kézi sztornó összesen"
                                val={cancel}
                                change={(e) => {
                                    if(!isNaN(+e.target.value)) {
                                        setCancel(e.target.value)
                                    }
                                }}
                            />

                            <InfoRow
                                text="Eltérés"
                                val={pcs.sum-((getTrafficSum(traffic)+getTrafficCashIn(traffic))-getTrafficCashOut(traffic)+Number(round)-getCardSum(traffic))}
                                noIcon={true}
                            />

                            <InfoRow
                                text="Összeg"
                                val={pcs.sum}
                                noIcon={true}
                            />

                            <TextField
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                variant="filled"
                                label="Megjegyzés"
                                size="small"
                                sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                            />
                        </Box>

                        <Box
                            marginTop={3}
                        >
                            <Buttons
                                backText="Vissza"
                                nextText="Következő"
                                onBack={onClose}
                                onNext={() => setChange(true)}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default ClosePcs;