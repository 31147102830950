import { useContext, useEffect, useState } from "react";

import { motion } from 'framer-motion';

import { io } from "socket.io-client";

import { DataContext } from "../../../context/DataContext";
import { getUserOperations, getUserStores } from "../../../services/Api";

import { Avatar, AvatarGroup, Box, Card, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Store = ({ store, click }) => {
    const {user, setUser, setMsg} = useContext(DataContext);

    const [stat, setStat] = useState({});
    const [beforeStat, setBeforeStat] = useState({});
    const [ops, setOps] = useState([]);
    const [dayOpen, setDayOpen] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        socket.emit("join", ["uid:"+user.id, "sid:"+store.id]);

        getOpDayOpen();
        getStoreStatus();

        getUserOperations(user.id).then(data => {
            setOps(data);
        });
    }, []);

    useEffect(() => {
        socket.on("update_store", () => {
            getStoreStatus();
        });

        socket.on("update_perm", () => {
            getUserStores(user.id).then(stores => {
                setUser(prevUser => ({...prevUser, stores: stores}));
            });
        });
    }, [socket]);

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("GET", url, true);
        http.send();
    
        return http.status !== 404;
    }

    const getOpDayOpen = async() => {
        const res = await fetch(apiUrl+"/operation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: 1
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setDayOpen(data.op);
    };

    const getStoreStatus = async() => {
        const res = await fetch(apiUrl+"/store/status/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setStat(data.stat);

        if(data.stat.operation === 3){
            getStoreBeforeStatus();
        }
    };

    const getStoreBeforeStatus = async() => {
        const res = await fetch(apiUrl+"/store/status/before/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setBeforeStat(data.stat);
    };

    const handleToDayOpen = async() => {
        const res = await fetch(apiUrl+"/user/store", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        const perm = ops.filter(item => item.operation === 1);

        if(perm.length === 0) {
            return setMsg({
                color: "#F58E8E",
                message: "Nincs jogosultságod ehhez a művelethez"
            });
        }

        if(data.store !== null) {
            return setMsg({
                color: "#F58E8E",
                message: "Nem léphetsz be két boltba"
            });
        }

        setMsg({});
        click();
    };

    if(stat.operation === 1) {
        if(user.store === stat.id) {
            // eladótér betöltése
            return (
                <Grid item xs={12} md={4}>
                    <Tooltip>
                        <motion.div
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                        >
                            <Card
                                variant="outlined"
                                sx={{
                                    height: 60,
                                    p: 1,
                                    borderRadius: 2,
                                    cursor: "pointer",
                                    "&:hover": {
                                        borderColor: store.color_code_2,
                                        color: store.color_code_2,
                                        "& .txt": {
                                            color: store.color_code_2
                                        },
                                        "& .avatar": {
                                            bgcolor: store.color_code_2
                                        }
                                    }
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={2}
                                    alignItems="center"
                                    width="100%"
                                    height="100%"
                                >
                                    <Avatar
                                        className="avatar"
                                        sx={{
                                            color: "#FFFFFF",
                                            bgcolor: "#979797",
                                            fontSize: 16,
                                            width: 40,
                                            height: 40
                                        }}
                                    >
                                        {store.name[0]?.toUpperCase()}
                                    </Avatar>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Typography className="txt" fontSize={14} color="#979797">{store.name}</Typography>
                                        <Typography className="txt" fontSize={12} color="#979797">{store.address}</Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </motion.div>
                    </Tooltip>
                </Grid>
            );
        }
        else {
            // opacity es avatar
            return (
                <Grid item xs={12} md={4}>
                    <Tooltip>
                        <motion.div
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                        >
                            <Card
                                variant="outlined"
                                sx={{
                                    height: 60,
                                    p: 1,
                                    borderRadius: 2,
                                    cursor: "pointer",
                                    "&:hover": {
                                        borderColor: store.color_code_2,
                                        color: store.color_code_2,
                                        "& .txt": {
                                            color: store.color_code_2
                                        },
                                        "& .avatar": {
                                            bgcolor: store.color_code_2
                                        }
                                    }
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={2}
                                    alignItems="center"
                                    width="100%"
                                    height="100%"
                                >
                                    <Avatar
                                        className="avatar"
                                        sx={{
                                            color: "#FFFFFF",
                                            bgcolor: "#979797",
                                            fontSize: 16,
                                            width: 40,
                                            height: 40
                                        }}
                                    >
                                        {store.name[0]?.toUpperCase()}
                                    </Avatar>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Typography className="txt" fontSize={14} color="#979797">{store.name}</Typography>
                                        <Typography className="txt" fontSize={12} color="#979797">{store.address}</Typography>
                                    </Box>

                                    <Tooltip
                                        arrow
                                        title={stat.last_name+" "+stat.first_name}
                                        sx={{ marginLeft: "auto" }}
                                    >
                                        <Avatar
                                            src={imageExists(apiUrl+"/"+stat?.avatar) ? apiUrl+"/"+stat?.avatar : null}
                                        />
                                    </Tooltip>
                                </Box>
                            </Card>
                        </motion.div>
                    </Tooltip>
                </Grid>
            );
        }
    }
    else if(stat.operation === 2) {
        if(user.stores.filter(s => s.id === store.id).length === 0) {
            // opacity (nincs jogosultság)
            return (
                <Grid item xs={12} md={4}>
                    <Tooltip>
                        <motion.div
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                        >
                            <Card
                                variant="outlined"
                                sx={{
                                    height: 60,
                                    p: 1,
                                    borderRadius: 2,
                                    cursor: "pointer",
                                    opacity: 0.5,
                                    "&:hover": {
                                        borderColor: store.color_code_2,
                                        color: store.color_code_2,
                                        "& .txt": {
                                            color: store.color_code_2
                                        },
                                        "& .avatar": {
                                            bgcolor: store.color_code_2
                                        }
                                    }
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={2}
                                    alignItems="center"
                                    width="100%"
                                    height="100%"
                                >
                                    <Avatar
                                        className="avatar"
                                        sx={{
                                            color: "#FFFFFF",
                                            bgcolor: "#979797",
                                            fontSize: 16,
                                            width: 40,
                                            height: 40
                                        }}
                                    >
                                        {store.name[0]?.toUpperCase()}
                                    </Avatar>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Typography className="txt" fontSize={14} color="#979797">{store.name}</Typography>
                                        <Typography className="txt" fontSize={12} color="#979797">{store.address}</Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </motion.div>
                    </Tooltip>
                </Grid>
            );
        }
        else {
            if(user.store === null) {
                // napnyitás button
                return (
                    <Grid item xs={12} md={4}>
                        <Tooltip>
                            <motion.div
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        height: 60,
                                        p: 1,
                                        borderRadius: 2,
                                        cursor: "pointer",
                                        "&:hover": {
                                            borderColor: store.color_code_2,
                                            color: store.color_code_2,
                                            "& .btn": {
                                                color: store.color_code_2
                                            },
                                            "& .txt": {
                                                color: store.color_code_2
                                            },
                                            "& .avatar": {
                                                bgcolor: store.color_code_2
                                            }
                                        }
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={2}
                                        alignItems="center"
                                        width="100%"
                                        height="100%"
                                    >
                                        <Avatar
                                            className="avatar"
                                            sx={{
                                                color: "#FFFFFF",
                                                bgcolor: "#979797",
                                                fontSize: 16,
                                                width: 40,
                                                height: 40
                                            }}
                                        >
                                            {store.name[0]?.toUpperCase()}
                                        </Avatar>

                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Typography className="txt" fontSize={14} color="#979797">{store.name}</Typography>
                                            <Typography className="txt" fontSize={12} color="#979797">{store.address}</Typography>
                                        </Box>

                                        <motion.div
                                            whileHover={{ scale: 1.15 }}
                                            whileTap={{ scale: 0.85 }}
                                            style={{ marginLeft: "auto" }}
                                        >
                                            <Tooltip
                                                arrow
                                                title="Napnyitás"
                                            >
                                                <IconButton
                                                    size="small"
                                                    onClick={handleToDayOpen}
                                                >
                                                    <PlayCircleOutlineIcon
                                                        className="btn"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </motion.div>
                                    </Box>
                                </Card>
                            </motion.div>
                        </Tooltip>
                    </Grid>
                );
            }
            else {
                // napnyitás button opacityvel és nem lehet rákattintani
                return (
                    <Grid item xs={12} md={4}>
                        <Tooltip>
                            <motion.div
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        height: 60,
                                        p: 1,
                                        borderRadius: 2,
                                        cursor: "pointer",
                                        "&:hover": {
                                            borderColor: store.color_code_2,
                                            color: store.color_code_2,
                                            "& .txt": {
                                                color: store.color_code_2
                                            },
                                            "& .avatar": {
                                                bgcolor: store.color_code_2
                                            }
                                        }
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={2}
                                        alignItems="center"
                                        width="100%"
                                        height="100%"
                                    >
                                        <Avatar
                                            className="avatar"
                                            sx={{
                                                color: "#FFFFFF",
                                                bgcolor: "#979797",
                                                fontSize: 16,
                                                width: 40,
                                                height: 40
                                            }}
                                        >
                                            {store.name[0]?.toUpperCase()}
                                        </Avatar>

                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Typography className="txt" fontSize={14} color="#979797">{store.name}</Typography>
                                            <Typography className="txt" fontSize={12} color="#979797">{store.address}</Typography>
                                        </Box>

                                        <motion.div
                                            whileHover={{ scale: 1.15 }}
                                            whileTap={{ scale: 0.85 }}
                                            style={{ marginLeft: "auto" }}
                                        >
                                            <Tooltip
                                                arrow
                                                title="Napnyitás"
                                            >
                                                <IconButton
                                                    size="small"
                                                    disabled
                                                >
                                                    <PlayCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </motion.div>
                                    </Box>
                                </Card>
                            </motion.div>
                        </Tooltip>
                    </Grid>
                );
            }
        }
    }
    else if(stat.operation === 3) {
        if(user.store === stat.id) {
            // eladótér betöltése
            return (
                <Grid item xs={12} md={4}>
                    <Tooltip>
                        <motion.div
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                        >
                            <Card
                                variant="outlined"
                                sx={{
                                    height: 60,
                                    p: 1,
                                    borderRadius: 2,
                                    cursor: "pointer",
                                    "&:hover": {
                                        borderColor: store.color_code_2,
                                        color: store.color_code_2,
                                        "& .txt": {
                                            color: store.color_code_2
                                        },
                                        "& .avatar": {
                                            bgcolor: store.color_code_2
                                        }
                                    }
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={2}
                                    alignItems="center"
                                    width="100%"
                                    height="100%"
                                >
                                    <Avatar
                                        className="avatar"
                                        sx={{
                                            color: "#FFFFFF",
                                            bgcolor: "#979797",
                                            fontSize: 16,
                                            width: 40,
                                            height: 40
                                        }}
                                    >
                                        {store.name[0]?.toUpperCase()}
                                    </Avatar>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Typography className="txt" fontSize={14} color="#979797">{store.name}</Typography>
                                        <Typography className="txt" fontSize={12} color="#979797">{store.address}</Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </motion.div>
                    </Tooltip>
                </Grid>
            );
        }
        else {
            // nyitó profil, váltó profil
            return (
                <Grid item xs={12} md={4}>
                    <Tooltip>
                        <motion.div
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                        >
                            <Card
                                variant="outlined"
                                sx={{
                                    height: 60,
                                    p: 1,
                                    borderRadius: 2,
                                    cursor: "pointer",
                                    "&:hover": {
                                        borderColor: store.color_code_2,
                                        color: store.color_code_2,
                                        "& .txt": {
                                            color: store.color_code_2
                                        },
                                        "& .avatar": {
                                            bgcolor: store.color_code_2
                                        }
                                    }
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={2}
                                    alignItems="center"
                                    width="100%"
                                    height="100%"
                                >
                                    <Avatar
                                        className="avatar"
                                        sx={{
                                            color: "#FFFFFF",
                                            bgcolor: "#979797",
                                            fontSize: 16,
                                            width: 40,
                                            height: 40
                                        }}
                                    >
                                        {store.name[0]?.toUpperCase()}
                                    </Avatar>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Typography className="txt" fontSize={14} color="#979797">{store.name}</Typography>
                                        <Typography className="txt" fontSize={12} color="#979797">{store.address}</Typography>
                                    </Box>

                                    <AvatarGroup sx={{ marginLeft: "auto" }}>
                                        <Tooltip
                                            arrow
                                            title={beforeStat.last_name+" "+beforeStat.first_name}
                                        >
                                            <Avatar
                                                src={imageExists(apiUrl+"/"+beforeStat?.avatar) ? apiUrl+"/"+beforeStat?.avatar : null}
                                            />
                                        </Tooltip>

                                        <Tooltip
                                            arrow
                                            title={stat.last_name+" "+stat.first_name}
                                        >
                                            <Avatar
                                                src={imageExists(apiUrl+"/"+stat?.avatar) ? apiUrl+"/"+stat?.avatar : null}
                                            />
                                        </Tooltip>
                                    </AvatarGroup>
                                </Box>
                            </Card>
                        </motion.div>
                    </Tooltip>
                </Grid>
            );
        }
    }
};

export default Store;