import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { io } from "socket.io-client";

import { DataContext } from "../../context/DataContext";
import { getUserCompanies } from "../../services/Api";

import { Backdrop, Box, Button, CircularProgress, Container, Grid, Grow, IconButton, InputAdornment, OutlinedInput, Paper, Toolbar, Typography } from "@mui/material";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import TechUserJPEG from "../../assets/home/modules/tech_user.jpeg";

import Dashboard from "../../layouts/dashboard/Dashboard";
import Feedback from "../../components/feedback/Feedback";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const TechUser = () => {
    const {user, setUser, msg, setMsg, loading, setLoading} = useContext(DataContext);

    const [inputs, setInputs] = useState({
        name: "",
        pw: "",
        sign: "",
        change: "",
        tax: user.company.tax.substring(0, 8)
    });
    const [showPw, setShowPw] = useState(false);

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleCreateToken = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/company/tech/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                stats: inputs
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        testTechToken(data.token);
    };

    const testTechToken = async(token) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/newinvoices", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ token: token, company: user.company.id })
        });

        const data = await res.json();

        if(!data.success && !data.noNew){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }   
        
        saveTech(token);
    };

    const saveTech = async(token) => {
        const res = await fetch(apiUrl+"/company/tech/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                token: token
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getUserCompanies(user.id).then(userComps => {
            const newComp = userComps.filter(item => item.id === data.company.id)[0];

            setUser({...user, company: newComp, companies: userComps});

            socket.emit("tech_user", {id: newComp.id});

            setMsg({
                color: "success",
                message: "Sikeres összekapcsolás!"
            });

            setLoading(false);

            navigate("/home");
        });
    };

    return (
        <Dashboard>
            <Backdrop
                sx={{ color: '#fff', zIndex: 10000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {Object.keys(msg).length !== 0 &&
                <Feedback
                    color={msg.color}
                    message={msg.message}
                />
            }

            <Box
                component="main"
                flexGrow={1}
                height="100vh"
                overflow="auto"
                sx={{ bgcolor: "#FFF" }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                    <Paper sx={{ p: 3 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={12} md={6}>
                                <img
                                    src={TechUserJPEG}
                                    alt="techuser"
                                    style={{ width: "100%" }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    gap={2}
                                >
                                    <Typography color="#6A86C4" fontWeight={500}>NAV Technikai felhasználó</Typography>
                                    
                                    <Typography color="#737373" fontSize={10} fontWeight={300}>
                                        Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor. Nam risus metus, tempor ut ante viverra, blandit vulputate augue. Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor. Nam risus metus, tempor ut ante viverra, blandit vulputate augue. 
                                    </Typography>

                                    <Grow in={true} timeout={1000}>
                                        <OutlinedInput
                                            placeholder="felhasználónév"
                                            fullWidth
                                            value={inputs.name}
                                            onChange={(e) => setInputs(prev => ({...prev, name: e.target.value}))}
                                            size="small"
                                            sx={{ bgcolor: "#FFF" }}
                                        />
                                    </Grow>

                                    <Grow in={true} timeout={1500}>
                                        <OutlinedInput
                                            type={showPw ? "text" : "password"}
                                            fullWidth
                                            placeholder="jelszó"
                                            value={inputs.pw}
                                            onChange={(e) => setInputs(prev => ({...prev, pw: e.target.value}))}
                                            size="small"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPw(prev => !prev)}
                                                    >
                                                        {showPw ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{ bgcolor: "#FFF" }}
                                        />
                                    </Grow>

                                    <Grow in={true} timeout={2000}>
                                        <OutlinedInput
                                            placeholder="aláírókulcs"
                                            fullWidth
                                            value={inputs.sign}
                                            onChange={(e) => setInputs(prev => ({...prev, sign: e.target.value}))}
                                            size="small"
                                            sx={{ bgcolor: "#FFF" }}
                                        />
                                    </Grow>

                                    <Grow in={true} timeout={2500}>
                                        <OutlinedInput
                                            placeholder="cserekulcs"
                                            fullWidth
                                            value={inputs.change}
                                            onChange={(e) => setInputs(prev => ({...prev, change: e.target.value}))}
                                            size="small"
                                            sx={{ bgcolor: "#FFF" }}
                                        />
                                    </Grow>

                                    <Grow in={true} timeout={3000}>
                                        <OutlinedInput
                                            placeholder="adószám"
                                            fullWidth
                                            value={inputs.tax}
                                            onChange={(e) => setInputs(prev => ({...prev, tax: e.target.value}))}
                                            size="small"
                                            sx={{ bgcolor: "#FFF" }}
                                        />
                                    </Grow>

                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={1}
                                        alignSelf="flex-end"
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={() => navigate("/home")}
                                            sx={{
                                                borderRadius: 8
                                            }}
                                        >
                                            Vissza
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            disabled={Object.values(inputs).some(item => item === "")}
                                            onClick={handleCreateToken}
                                            sx={{
                                                borderRadius: 8,
                                                borderColor: "#6A86C4",
                                                color: "#6A86C4"
                                            }}
                                        >
                                            Összekapcsolás
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Box>
        </Dashboard>
    );
};

export default TechUser;