import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";
import { getStoreStatus } from "../../services/Api";

import DiagramsStyle from "./Diagrams.module.css";

import OpsHeader from "../../layouts/opsheader/OpsHeader";

import PrevTotalChart from "../../components/charts/prevtotalchart/PrevTotalChart";
import TotalStores from "../../components/charts/totalstores/TotalStores";
import ProfitVat from "../../components/charts/profitvat/ProfitVat";
import TotalUsers from "../../components/charts/totalusers/TotalUsers";
import Feedback from "../../components/feedback/Feedback";
import DailyVat from "../../components/charts/dailyvat/DailyVat";

const Diagrams = () => {
    const {
        user,
        msg, setMsg,
        loading, setLoading,
        slideRef,
        setOpenOper
    } = useContext(DataContext);

    const [info, setInfo] = useState({
        from: "",
        to: ""
    });
    const [traffic, setTraffic] = useState({});
    const [showTraffic, setShowTraffic] = useState({});
    const [trafficStores, setTrafficStores] = useState([]);

    const [daily, setDaily] = useState(false);
    const [dailyLine, setDailyLine] = useState({});

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    useEffect(() => {
        if(JSON.stringify(traffic) !== "{}") {
            let current = traffic.current.data;
            let prevYear = traffic.prevYear.data;

            trafficStores.map(item => {
                if(!item.show) {
                    current = current.filter(element => element.store !== item.id);
                    prevYear = prevYear.filter(element => element.store !== item.id);
                }
            });

            setShowTraffic(prevTraffic => ({
                ...prevTraffic,
                current: {
                    ...prevTraffic.current,
                    data: current
                },
                prevYear: {
                    ...prevTraffic.prevYear,
                    data: prevYear
                }
            }));
        }
    }, [trafficStores]);

    const getTraffic = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/diagrams/traffic/all", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                from: info.from,
                to: info.to
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        /*const storesArr = data.info.current.data.filter((item, index) => {
            return index === data.info.current.data.findIndex(o => item.store === o.store);
        });*/

        /*setTrafficStores(stores.map(item => ({
            id: item.id,
            name: item.name,
            color: item.color_code_2,
            show: true
        })));*/

        setShowTraffic(data.info);

        setTraffic(data.info);

        setLoading(false);
    };

    const handleBack = () => {
        //slideRef.current.className = HomeStyle.op_content_close;

        const timer = setTimeout(() => {
            setMsg({});
            setOpenOper(0);
        }, 1000);
        return () => clearTimeout(timer);
    };

    const handleGoToDaily = (line, showData) => {
        setDailyLine({
            line: line,
            data: showData
        });

        setDaily(true);
    };

    const renderDaily = () => {
        if(daily) {
            return (
                <DailyVat
                    data={dailyLine.data}
                    line={dailyLine.line}
                    onClose={() => setDaily(false)}
                />
            );
        }
    };

    const handleFilter = (store) => {
        const modifyArr = trafficStores.map(item => {
            if(item.id === store.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: !item.show
                };
            }
            return item;
        });

        setTrafficStores(modifyArr);
    };

    return (
        <>
            {renderDaily()}

            <div style={{overflow: "hidden"}}>
                <div
                    style={{backgroundColor: "#FFFFFF"}}
                    ref={slideRef}
                >

                    <div>
                        {
                            Object.keys(msg).length !== 0 ?
                                <Feedback
                                    color={msg.color}
                                    message={msg.message}
                                />
                                :
                                <></>
                        }

                        <OpsHeader
                            operation={19}
                            opsRef={slideRef}
                            /*chart={trafficStores}
                            onChartFilter={item => handleFilter(item)}*/
                        />
                    </div>

                    <div>
                        <div className={DiagramsStyle.header}>
                            <span className={DiagramsStyle.title}>LEKÉRDEZÉS ADATAI</span>
                            <span
                                className={DiagramsStyle.title}
                                style={{opacity: "0.4", fontWeight: "400"}}
                            >
                                (Hint: Vonalkód: 5998817310069, Dátum-tól: 2022. 01. 02., Dátum-ig: 2022. 01. 16.)
                            </span>

                            <div className={DiagramsStyle.data}>
                                <input
                                    type="date"
                                    placeholder="Dátum-tól"
                                    value={info.from}
                                    onChange={(e) => setInfo(prevData => ({...prevData, from: e.target.value}))}
                                />

                                <input
                                    type="date"
                                    placeholder="Dátum-ig"
                                    value={info.to}
                                    onChange={(e) => setInfo(prevData => ({...prevData, to: e.target.value}))}
                                />
                            </div>

                            <button
                                className={DiagramsStyle.submit_btn}
                                onClick={getTraffic}
                            >
                                LEKÉRDEZÉS
                            </button>
                        </div>

                        <div className={DiagramsStyle.diagram_body}>
                            <PrevTotalChart
                                data={showTraffic}
                            />

                            <TotalStores
                                data={showTraffic}
                                onDaily={handleGoToDaily}
                            />

                            <ProfitVat
                                data={showTraffic}
                            />

                            <TotalUsers
                                data={showTraffic}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Diagrams;