import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Box, Grow, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import ErrorIcon from '@mui/icons-material/Error';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';

import CurrencyText from "../../../components/currencytext/CurrencyText";

const StockRow = ({ index, stockInfo, timeout, click, onStock }) => {
    const {setMsg} = useContext(DataContext);

    const [purchasePrice, setPurchasePrice] = useState("");
    const [sellPrice, setSellPrice] = useState("");

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getPurchasePrice();
        getSellPrice();
    });

    const getPurchasePrice = async() => {
        const res = await fetch(apiUrl+"/product/price/last", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                product: stockInfo.product_id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPurchasePrice(data.income.purchase_price);
    };

    const getSellPrice = async() => {
        const res = await fetch(apiUrl+"/product/sell/price/"+stockInfo.product_id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setSellPrice(data.sellPrice);
    };

    return (
        <Grow in={true} timeout={timeout}>
            <TableRow hover>
                <TableCell>
                    <Typography fontSize={12} fontWeight={300}>{index}</Typography>
                </TableCell>

                <TableCell>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        alignItems="flex-start"
                    >
                        <Typography fontSize={12} fontWeight={300}>{stockInfo.product}</Typography>
                        <Typography fontSize={10} fontWeight={300}>{stockInfo.barcode}</Typography>
                    </Box>
                </TableCell>

                <TableCell align="center">
                    <Tooltip
                        arrow
                        title="Készlet megtekintése"
                        onClick={onStock}
                        sx={{ cursor: "pointer" }}
                    >
                        <ErrorIcon style={{ color: "#2196f3" }} />
                    </Tooltip>
                </TableCell>

                <TableCell>
                    <Typography fontSize={12} fontWeight={300}>
                        <CurrencyText
                            val={Number(purchasePrice).toFixed(2)}
                        /> HUF
                    </Typography>
                </TableCell>

                <TableCell>
                    <Typography fontSize={12} fontWeight={300}>
                        <CurrencyText
                            val={(purchasePrice*(Number(stockInfo.vat)+1)).toFixed(2)}
                        /> HUF
                    </Typography>
                </TableCell>

                <TableCell>
                    <Typography fontSize={12} fontWeight={300}>{Number(stockInfo.vat)*100} %</Typography>
                </TableCell>

                <TableCell>
                    <Typography fontSize={12} fontWeight={300}>
                        <CurrencyText
                            val={(Number(sellPrice)/((Number(stockInfo.vat)+1))).toFixed(2)}
                        /> HUF
                    </Typography>
                </TableCell>

                <TableCell>
                    <Typography fontSize={12} fontWeight={300}>
                    <CurrencyText
                            val={Number(sellPrice)}
                        /> HUF
                    </Typography>
                </TableCell>

                <TableCell>
                    <Typography fontSize={12} fontWeight={300}>{purchasePrice === 0 || sellPrice === 0 ? 0 : (((((Number(sellPrice)/((Number(stockInfo.vat)+1))).toFixed(2))/purchasePrice)-1)*100).toFixed(2)} %</Typography>
                </TableCell>

                <TableCell align="center">
                    <IconButton
                        size="small"
                        onClick={() => click(purchasePrice, sellPrice)}
                    >
                        <FolderOpenOutlinedIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </Grow>
    );
};

export default StockRow;