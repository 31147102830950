import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Login from "./pages/login/Login";

import Register from './pages/register/Register';
import Company from './pages/register/company/Company';
import CompanyInfo from './pages/register/company/CompanyInfo';
import CompanyType from './pages/register/company/CompanyType';

import Home from "./pages/home/Home";
import TechUser from './pages/invoices/TechUser';

import CreateStore from './pages/shoparea/CreateStore';
import CreateApartman from './pages/apartmans/CreateApartman';

import ShopArea from "./pages/shoparea/ShopArea";
import Logs from "./pages/logs/Logs";
import Log from "./pages/log/Log";

import TicketManage from "./pages/tickets/ticketinfo/ticketmanage/TicketManage";
import TicketStock from "./pages/tickets/ticketinfo/ticketstock/TicketStock";
import Ticket from "./pages/tickets/ticketinfo/ticket/Ticket";
import NewTicket from "./pages/tickets/ticketinfo/newticket/NewTicket";
import NewTicketPrize from "./pages/tickets/ticketinfo/newticketprize/NewTicketPrize";
import NewTicketVerify from "./pages/tickets/ticketinfo/newticketverify/NewTicketVerify";

import { StateProvider } from "./context/DataContext";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ResDates from './features/apartmans/reservation/ResDates';

const App = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StateProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<><Login /></>} />

                        <Route path="/register" element={<><Register /></>} />
                        <Route path="/register/token/:id" element={<><Register /></>} />
                        <Route path="/register/company" element={<><Company /></>} />
                        <Route path="/register/company/info" element={<><CompanyInfo /></>} />
                        <Route path="/register/company/type" element={<><CompanyType /></>} />

                        <Route path="/home" element={<><Home /></>} />
                        <Route path="/invoice/user" element={<><TechUser /></>} />

                        <Route path="/store/create" element={<><CreateStore /></>} />
                        <Route path="/apartman/create" element={<><CreateApartman /></>} />

                        <Route path="/shoparea" element={<><ShopArea /></>} />
                        <Route path="/shoparea/:store" element={<><ShopArea /></>} />
                        <Route path="/logs/:store" element={<><Logs /></>} />
                        <Route path="/log/:store" element={<><Log /></>} />

                        <Route path="/ticketinfo/manage/:store" element={<><TicketManage /></>} />
                        <Route path="/ticketinfo/stock/:store" element={<><TicketStock /></>} />
                        <Route path="/ticketinfo/ticket/:store" element={<><Ticket /></>} />
                        <Route path="/ticketinfo/new/info/:store" element={<><NewTicket /></>} />
                        <Route path="/ticketinfo/new/prize/:store" element={<><NewTicketPrize /></>} />
                        <Route path="/ticketinfo/new/verify/:store" element={<><NewTicketVerify /></>} />

                        <Route path="/reservation/:id" element={<><ResDates /></>} />
                    </Routes>
                </Router>
            </StateProvider>
        </LocalizationProvider>
    );
};

export default App;