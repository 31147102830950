import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";

const ProductsPrice = () => {
    const {user, setOpenOper, setMsg, setLoading} = useContext(DataContext);

    const [changes, setChanges] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getChanges();
    }, []);

    const getChanges = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/product/changes/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setChanges(data.changes);

        setLoading(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={31}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Grid item xs={12}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Vonalkód</TableCell>
                                <TableCell>Megnevezés</TableCell>
                                <TableCell>Előző beszerzési érték</TableCell>
                                <TableCell>Új beszerzési érték</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {changes.map(item => {
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.barcode}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.old_price} HUF</TableCell>
                                        <TableCell>{item.new_price} HUF</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default ProductsPrice;