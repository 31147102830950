import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../../context/DataContext";

import { Avatar, Box, Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import RestoreIcon from '@mui/icons-material/Restore';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import CurrencyText from "../../../../../components/currencytext/CurrencyText";

const PackItem = ({ pack, onCheck, onItemized, onLog }) => {
    const {setLoading, setMsg} = useContext(DataContext);

    const [packInfo, setPackInfo] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = pack.profile_pic ? apiUrl+"/"+pack.profile_pic : null;

    useEffect(() => {
        getPackInfo();
    }, []);

    const getPackInfo = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/tickets/manage/pack/info/"+pack.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPackInfo(data.pack.map(item => ({...item, selected: false})));

        setLoading(false);
    };

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    }

    return (
        <TableRow sx={pack?.checked_date && { bgcolor: "#C7FFEB" }}>
            <TableCell>
                <Checkbox
                    checked={pack.selected}
                    onChange={(e) => onCheck(pack, e.target.checked)}
                    disabled={pack?.checked_date}
                />
            </TableCell>

            <TableCell>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    <Tooltip
                        title={pack.last_name+" "+pack.first_name}
                        arrow
                    >
                        <Avatar src={imageExists(avatar) ? avatar : null} size="sm" />
                    </Tooltip>

                    <Box
                        display="flex"
                        flexDirection="column"
                    >
                        <Typography fontSize={14} fontWeight={400}>{pack.name}</Typography>
                        <Typography fontSize={12} fontWeight={400} color="#8C8C8C">{pack.date}</Typography>
                    </Box>
                </Box>
            </TableCell>

            <TableCell>
                {packInfo.length} db
            </TableCell>

            <TableCell>
                <CurrencyText
                    val={pack.sum}
                /> HUF
            </TableCell>

            <TableCell>
                <CurrencyText
                    val={pack.bad_value ? pack.bad_value : 0}
                /> HUF
            </TableCell>

            <TableCell>
                <CurrencyText
                    val={pack.sum-pack.bad_value}
                /> HUF
            </TableCell>

            <TableCell>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <IconButton onClick={() => onLog(pack)}>
                        <RestoreIcon />
                    </IconButton>

                    <IconButton onClick={() => onItemized(pack)}>
                        <FormatListBulletedIcon />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default PackItem;