import { Box, Grid, Typography } from "@mui/material";

import PackageItem from "./content/PackageItem";

const TransitPackages = ({ packages }) => {
    return (
        <Grid item xs={12} md={4}>
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
            >
                <Typography fontSize={12} fontWeight={700} color="#000">Átadott csomagok</Typography>

                {packages.map(item => {
                    return (
                        <PackageItem
                            key={item.id}
                            pack={item}
                            summary={true}
                        />
                    );
                })}
            </Box>
        </Grid>
    );
};

export default TransitPackages;