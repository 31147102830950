import { useContext, useEffect, useMemo, useState } from "react";

import ReactEcharts from "echarts-for-react";

import { DataContext } from "../../../context/DataContext";

import { Box, Grid, Typography } from "@mui/material";

import ChartFilter from "../../../components/charts/chartfilter/ChartFilter";

const ProductTraffic = ({ traffic, gross, onDaily }) => {
    const {setMsg, stores} = useContext(DataContext);

    const [topProducts, setTopProducts] = useState([]);
    const [showBar, setShowBar] = useState([]);

    const [trafficStores, setTrafficStores] = useState([]);
    const [bars, setBars] = useState([
        {
            id: 0,
            name: "Forgalom",
            color: "#5470C6",
            show: true
        },
        {
            id: 1,
            name: "Profit",
            color: "#5470C6",
            show: false
        },
        {
            id: 2,
            name: "Darabszám",
            color: "#5470C6",
            show: false
        }
    ]);

    const apiUrl = process.env.REACT_APP_API_URL;

    const barChartMemo = useMemo(() => {
        return (
            <ReactEcharts
                option={{
                    grid: {
                        containLabel: true,
                        left: "4%",
                        right: "4%",
                        top: "0%",
                        bottom: "0%"
                    },
                    xAxis: {
                        type: "value",
                        axisLabel: {
                            formatter: (val) => {
                                if(bars[2].show) {
                                    return val+" db";
                                }
                                else {
                                    return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                                }  
                            } 
                        }
                    },
                    yAxis: {
                        type: "category",
                        data: showBar.map(item => item.item_name)
                    },
                    series: [{
                        type: "bar",
                        smooth: true,
                        showBackground: false,
                        name: "Forgalom",
                        data: showBar.map(item => ({
                            value: item.sum,
                            barcode: item.barcode,
                            item_name: item.item_name,
                            from: traffic.current.from,
                            to: traffic.current.to,
                            itemStyle: {color: "#5470c6"}
                        }))
                    }],
                    tooltip: {
                        trigger: "axis",
                        valueFormatter: (val) => {
                            if(bars[2].show) {
                                return val+" db";
                            }
                            else {
                                return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                            }  
                        } 
                    }
                }}
                onEvents={{
                    click: (bar) => onDaily(bar.data)
                }}
                style={{width: "100%",  height: (showBar.length*30)+"px"}}
            />
        );
    }, [showBar]);

    useEffect(() => {
        getStores();
    }, []);

    useEffect(() => {
        if(JSON.stringify(traffic) !== "[]") {
            getTopProducts();
        } 
    }, [traffic?.current?.data]);

    useEffect(() => {
        const filtered = [];

        trafficStores.map(item => {
            if(item.show) {
                return filtered.push(...topProducts.filter(element => element.store === item.id));
            }

            return 0;
        });

        const barcodeGroup = filtered.reduce((group, x) => {
            (group[x["barcode"]] = group[x["barcode"]] || []).push(x);
            return group;
        }, {});

        const topArr = [];

        if(bars[0].show) {
            Object.keys(barcodeGroup).map(key => {
                const sum = barcodeGroup[key].reduce((acc, obj) => {
                    if(gross) return acc + obj.sum_traffic;
                    else return acc + (obj.sum_traffic-obj.sum_vat);
                }, 0);
    
                return topArr.push({
                    item_name: barcodeGroup[key][0].item_name,
                    barcode: barcodeGroup[key][0].barcode,
                    sum: sum
                });
            });
        }
        else if(bars[1].show) {
            Object.keys(barcodeGroup).map(key => {
                const sum = barcodeGroup[key].reduce((acc, obj) => {
                    if(gross) return acc + obj.sum_profit;
                    else return acc + (obj.sum_profit-obj.sum_vat_profit);
                }, 0);
    
                return topArr.push({
                    item_name: barcodeGroup[key][0].item_name,
                    barcode: barcodeGroup[key][0].barcode,
                    sum: sum
                });
            });
        }
        else if(bars[2].show) {
            Object.keys(barcodeGroup).map(key => {
                const sum = barcodeGroup[key].reduce((acc, obj) => {
                    return acc + obj.sum_quantity;
                }, 0);
    
                return topArr.push({
                    item_name: barcodeGroup[key][0].item_name,
                    barcode: barcodeGroup[key][0].barcode,
                    sum: sum
                });
            });
        }

        setShowBar(topArr.sort((a, b) => {
            return Number(a.sum) - Number(b.sum);
        }));
    }, [topProducts, bars, trafficStores, gross]);

    const getStores = () => {
        setTrafficStores(stores.map(item => {
            return {
                id: item.id,
                name: item.name,
                color: item.color_code_2,
                show: true
            };
        }));
    };

    const getTopProducts = async() => {
        const res = await fetch(apiUrl+"/diadev/top", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                from: traffic.current.from,
                to: traffic.current.to
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTopProducts(data.top);
    };

    const handleFilterStore = (store) => {
        const modifyArr = trafficStores.map(item => {
            if(item.id === store.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: !item.show
                };
            }
            
            return item;
        });

        setTrafficStores(modifyArr);
    };

    const handleFilterBar = (bar) => {
        const modifyArr = bars.map(item => {
            if(item.id === bar.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: true
                };
            }
            
            return {
                id: item.id,
                name: item.name,
                color: item.color,
                show: false
            };
        });

        setBars(modifyArr);
    };

    return (
        <Grid item xs={12}>
            <Typography marginBottom={2}>Időszakos top 20 termék</Typography>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {trafficStores.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => handleFilterStore(item)}
                            />
                        );
                    })}
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {bars.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => handleFilterBar(item)}
                            />
                        );
                    })}
                </Box>
            </Box>

            <Box width="100%">
                {barChartMemo}
            </Box>
        </Grid>
    );
};

export default ProductTraffic;