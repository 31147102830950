import { Box, Skeleton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CurrencyText from "../../../../components/currencytext/CurrencyText";

const ItemizedItem = ({ line, id, status, onStock }) => {
    return (
        <TableRow hover>
            <TableCell>
                {id}
            </TableCell>

            {line.itemName ?
                <TableCell>
                    {line.barcode ?
                        <Tooltip
                            onClick={() => onStock(line.barcode)}
                            sx={{ cursor: "pointer" }}
                        >
                            <Typography color="#096DD9">
                                {line.itemName}
                            </Typography>
                        </Tooltip>
                    :
                        <Typography>
                            {line.itemName}
                        </Typography>
                    }
                </TableCell>
            :
                <TableCell>
                    <Skeleton variant="rounded" width={200} height={20} />
                </TableCell>
            }

            {line.quantity ?
                <TableCell>
                    {line.quantity+" "+(line.unit ? line.unit : "DB")}
                </TableCell>
            :
                <TableCell>
                    <Skeleton variant="rounded" width={50} height={20} />
                </TableCell>
            }

            {line.unitPrice ?
                <TableCell>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                    >
                        <Typography fontSize={14}>
                            <CurrencyText
                                val={Number(line.unitPrice).toFixed(3)}
                            /> Ft
                        </Typography>

                        {status === 2 &&
                            <>
                                {line.priceChange === 0 &&
                                    <HorizontalRuleIcon style={{color: "orange"}} />
                                }

                                {line.priceChange === 1 &&
                                    <AddIcon style={{color: "green"}} />
                                }

                                {line.priceChange === 2 &&
                                    <KeyboardArrowUpIcon style={{color: "red"}} />
                                }

                                {line.priceChange === 3 &&
                                    <KeyboardArrowDownIcon style={{color: "green"}} />
                                }
                            </>
                        }
                    </Box>
                </TableCell>
            :
                <TableCell>
                    <Skeleton variant="rounded" width={120} height={20} />
                </TableCell>
            }

            {line.itemName ?
                <TableCell>
                    {line.vat*100} %
                </TableCell>
            :
                <TableCell>
                    <Skeleton variant="rounded" width={30} height={20} />
                </TableCell>
            }

            {(line.unitPrice || line.vat) ?
                <TableCell>
                    <CurrencyText
                        val={Math.round((line.vat+1)*line.unitPrice)}
                    /> Ft
                </TableCell>
            :
                <TableCell>
                    <Skeleton variant="rounded" width={120} height={20} />
                </TableCell>
            }

            {(line.unitPrice || line.vat) ?
                <TableCell>
                    <CurrencyText
                        val={Math.round(line.vat*line.unitPrice)}
                    /> Ft
                </TableCell>
            :
                <TableCell>
                    <Skeleton variant="rounded" width={70} height={20} />
                </TableCell>
            }

            {(line.unitPrice || line.quantity) ?
                <TableCell>
                    <CurrencyText
                        val={Math.round(line.quantity*line.unitPrice)}
                    /> Ft
                </TableCell>
            :
                <TableCell>
                    <Skeleton variant="rounded" width={120} height={20} />
                </TableCell>
            }

            {(line.unitPrice || line.quantity || line.vat) ?
                <TableCell>
                    <CurrencyText
                        val={Math.round((line.vat*line.unitPrice)*line.quantity)}
                    /> Ft
                </TableCell>
            :
                <TableCell>
                    <Skeleton variant="rounded" width={100} height={20} />
                </TableCell>
            }

            {(line.unitPrice || line.quantity || line.vat) ?
                <TableCell>
                    <CurrencyText
                        val={Math.round((line.unitPrice*(line.vat+1))*line.quantity)}
                    /> Ft
                </TableCell>
            :
                <TableCell>
                    <Skeleton variant="rounded" width={120} height={20} />
                </TableCell>
            }
        </TableRow>
    );
};

export default ItemizedItem;