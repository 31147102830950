import { useContext } from "react";

import { DataContext } from "../../../context/DataContext";

import { Avatar, AvatarGroup, Box, Grid, Tooltip, Typography } from "@mui/material";

import StoreIcon from "../../../layouts/stores/content/StoreIcon";

const CashinItem = ({ pack }) => {
    const {stores} = useContext(DataContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = pack.profile_pic ? apiUrl+"/"+pack.profile_pic : null;
    const avatarCashin = pack.cashin_pic ? apiUrl+"/"+pack.cashin_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    }; 

    return (
        <Grid
            item
            xs={12}
            marginLeft={1}
            marginRight={1}
        >
            <Box
                display="flex"
                flexDirection="row"
                gap={1}
                border="1px solid #8C8C8C"
                borderRadius={1}
                padding={1}
                boxSizing="border-box"
                width="100%"
            >
                <AvatarGroup max={2}>
                    <StoreIcon
                        store={stores.filter(item => item.id === pack.store)[0]}
                        size="medium"
                    />

                    <Tooltip
                        title={pack.last_name+" "+pack.first_name}
                        arrow
                    >
                        <Avatar
                            src={imageExists(avatar) ? avatar : null}
                            sx={{ zIndex: 2, width: 32, height: 32 }}
                        />
                    </Tooltip>
                </AvatarGroup>

                <Box
                    display="flex"
                    flexDirection="column"
                    color="#000"
                >
                    <Typography fontSize={12} fontWeight={700}>{pack.serial_num}</Typography>
                    <Typography fontSize={10} fontWeight={300}>{pack.date_of_record}</Typography>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    marginLeft="auto"
                    alignItems="center"
                >
                    <Tooltip
                        title={pack.cashin_last+" "+pack.cashin_first}
                        arrow
                    >
                        <Avatar
                            src={imageExists(avatarCashin) ? avatarCashin : null}
                            sx={{ zIndex: 2, width: 32, height: 32 }}
                        />
                    </Tooltip>

                    <Box
                        display="flex"
                        flexDirection="column"
                        color="#000"
                    >
                        <Typography fontSize={12} fontWeight={700}>Jóváhagyva</Typography>
                        <Typography fontSize={10} fontWeight={300}>{pack.date_of_cashin}</Typography>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};

export default CashinItem;