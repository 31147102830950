import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import OpHeader from "../../../../components/opheader/OpHeader";

const InvoiceSummary = ({ invoice, onClose }) => {
    const calculateSum = () => {
        const sum = invoice.reduce((acc, obj) => {
            return acc + (Number(obj.quantity) * Number(obj.unitPrice));
        }, 0);

        return sum;
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={22}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Megnevezés</TableCell>
                                <TableCell>Beszerzési érték</TableCell>
                                <TableCell>Darabszám</TableCell>
                                <TableCell>Összesen</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {invoice.map(item => {
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.itemName}</TableCell>
                                        <TableCell>{item.unitPrice} HUF</TableCell>
                                        <TableCell>{item.quantity} DB</TableCell>
                                        <TableCell>{Number(item.unitPrice)*Number(item.quantity)} HUF</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                >
                    <Typography color="#000" fontSize={16} fontWeight={600}>
                        Összesen: {calculateSum()} HUF
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InvoiceSummary;