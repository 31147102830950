import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import CurrencyText from "../../../../../../../../components/currencytext/CurrencyText";

const TransferDialog = ({ open, setOpen, invs, sum, comment, onTransfer }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 300
                    }
                }
            }}
        >
            <DialogTitle>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <IconButton
                        onClick={() => setOpen(false)}
                        size="small"
                    >
                        <ArrowBackIosIcon style={{ fontSize: 16 }} />
                    </IconButton>

                    <IconButton
                        size="small"
                    >
                        <OpenInFullIcon style={{ fontSize: 16 }} />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography
                        fontSize={20}
                        fontWeight={700}
                        color="#8C8C8C"
                    >
                        Utalás
                    </Typography>

                    <Typography
                        fontSize={12}
                        fontWeight={300}
                        color="#8C8C8C"
                    >
                        Válaszd ki meglévő utalási listák közül amelyhez a kiválasztott tételeket hozzá szeretnéd rendeli, Vagy hozz létre új utalási listát, a kiválasztott elemeknek.
                    </Typography>

                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Stack>
                            <Typography
                                fontSize={10}
                                fontWeight={300}
                            >
                                Partner
                            </Typography>

                            <Typography
                                fontSize={12}
                                fontWeight={700}
                            >
                                {invs[0].partnerName}
                            </Typography>
                        </Stack>

                        <IconButton
                            size="small"
                            onClick={() =>  navigator.clipboard.writeText(invs[0].partnerName)}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Stack>
                            <Typography
                                fontSize={10}
                                fontWeight={300}
                            >
                                Számlaszám
                            </Typography>

                            <Typography
                                fontSize={12}
                                fontWeight={700}
                            >
                                {invs[0].bank_account ? invs[0].bank_account : "-"}
                            </Typography>
                        </Stack>

                        <IconButton
                            size="small"
                            onClick={() =>  navigator.clipboard.writeText(invs[0].bank_account ? invs[0].bank_account : "-")}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Stack>
                            <Typography
                                fontSize={10}
                                fontWeight={300}
                            >
                                Összeg
                            </Typography>

                            <Typography
                                fontSize={12}
                                fontWeight={700}
                            >
                                <CurrencyText val={sum} /> Ft
                            </Typography>
                        </Stack>

                        <IconButton
                            size="small"
                            onClick={() =>  navigator.clipboard.writeText(sum)}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Stack>
                            <Typography
                                fontSize={10}
                                fontWeight={300}
                            >
                                Közlemény
                            </Typography>

                            <Typography
                                fontSize={12}
                                fontWeight={700}
                            >
                                {comment}
                            </Typography>
                        </Stack>

                        <IconButton
                            size="small"
                            onClick={() =>  navigator.clipboard.writeText(comment)}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="flex-end"
                    width="100%"
                >
                    <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        sx={{
                            color: "#262626",
                            borderColor: "#D9D9D9",
                            "&:hover": {
                                color: "#262626",
                                borderColor: "#D9D9D9"
                            }
                        }}
                    >
                        Vissza
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => {
                            onTransfer(invs);
                            setOpen(false);
                        }}
                    >
                        Utalás véglegesítése
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default TransferDialog;