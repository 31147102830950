import { Avatar, IconButton } from "@mui/material";

const StoreIcon = ({ store, size, type, val, onClick }) => {
    if(type === "checkbox") {
        return val ? (
            <IconButton
                onClick={() => onClick(store)}
                size="small"
            >
                <Avatar
                    sx={{
                        color: "#FFFFFF",
                        bgcolor: store?.color_code_2,
                        fontSize: 12,
                        width: 32,
                        height: 32
                    }}
                >
                    {store?.name[0]?.toUpperCase()}
                </Avatar>
            </IconButton>
        ) : (
            <IconButton
                onClick={() => onClick(store)}
                size="small"
            >
                <Avatar
                    sx={{
                        color: "#FFFFFF",
                        bgcolor: "#979797",
                        fontSize: 12,
                        width: 32,
                        height: 32,
                        cursor: "pointer"
                    }}
                >
                    {store?.name[0]?.toUpperCase()}
                </Avatar>
            </IconButton>
        );
    }
    else {
        return size === "small" ? (
            <Avatar
                sx={{
                    color: "#FFFFFF",
                    bgcolor: store?.color_code_2,
                    fontSize: 12,
                    width: 22,
                    height: 22,
                    border: "1.5px solid #FFFFFF"
                }}
            >
                {store?.name[0]?.toUpperCase()}
            </Avatar>
        ) : size === "medium" ? (
            <Avatar
                sx={{
                    color: "#FFFFFF",
                    bgcolor: store?.color_code_2,
                    fontSize: 14,
                    width: 32,
                    height: 32
                }}
            >
                {store?.name[0]?.toUpperCase()}
            </Avatar>
        ) : (
            <Avatar
                sx={{
                    color: "#FFFFFF",
                    bgcolor: store?.color_code_2,
                    fontSize: 16,
                    width: 40,
                    height: 40
                }}
            >
                {store?.name[0]?.toUpperCase()}
            </Avatar>
        );
    }
};

export default StoreIcon;