import { useState } from "react";

import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import OpHeader from "../../components/opheader/OpHeader";
import AddImage from "../../components/addimage/AddImage";
import ChangeLogin from "./ChangeLogin";
import Buttons from "./content/Buttons";

const ChangeImage = ({ traffic, fileName, round, pcs, comment, onClose }) => {
    const [trafficImg, setTrafficImg] = useState();

    const [change, setChange] = useState(false);

    const handleUploadImage = (e) => {
        setTrafficImg(e.target.files[0]);
    };

    const renderChangeLogin = () => {
        if(change) {
            return (
                <ChangeLogin
                    traffic={traffic}
                    fileName={fileName}
                    round={round}
                    pcs={pcs}
                    comment={comment}
                    image={trafficImg}
                    onClose={() => setChange(false)}
                />
            );
        }
    };

    return (
        <>
            {renderChangeLogin()}

            {!change &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={3}
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="100%"
                        >
                            {trafficImg ?
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    gap={1}
                                    padding={10}
                                >
                                    <img
                                        src={URL.createObjectURL(trafficImg)}
                                        style={{ width: 300 }}
                                    />

                                    <IconButton onClick={() => setTrafficImg()}>
                                        <DeleteOutlineIcon />
                                    </IconButton>

                                    <Typography
                                        fontSize={12}
                                        fontWeight={300}
                                        color="#000"
                                    >
                                        Kép törlése
                                    </Typography>
                                </Box>
                            :
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    gap={1}
                                    border="1px dashed #D9D9D9"
                                    borderRadius={1}
                                    padding={10}
                                >
                                    <AddImage onUpload={handleUploadImage} />

                                    <Typography
                                        fontSize={12}
                                        fontWeight={300}
                                        color="#000"
                                    >
                                        Kép hozzáadása
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Grid>

                    <Box
                        marginLeft="auto"
                        marginRight="auto"
                        marginTop={3}
                    >
                        <Buttons
                            backText="Vissza"
                            nextText="Következő"
                            onBack={onClose}
                            onNext={() => setChange(true)}
                        />
                    </Box>
                </Grid>
            }
        </>
    );
};

export default ChangeImage;