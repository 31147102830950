import { useContext, useEffect, useState } from "react";

import { io } from "socket.io-client";

import useDebounce from "../../../hooks/useDebounce";
import { DataContext } from "../../../context/DataContext";

import { Box, Grid, IconButton, InputAdornment, OutlinedInput, Pagination, Tab, Tabs, Typography } from "@mui/material";

import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import CloseIcon from '@mui/icons-material/Close';

import OpHeader from "../../../components/opheader/OpHeader";
import InvoiceItem from "./content/InvoiceItem";
import InvoiceItemized from "./InvoiceItemized";
import InvoiceSummary from "./content/InvoiceSummary";
import IncomeStore from "./content/content/income/IncomeStore";
import TransferTable from "./content/TransferTable";
import TransferList from "./content/TransferList";
import ArrangedList from "./content/ArrangedList";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: "100%" }}
        >
            {value === index && children}
        </div>
    );
};

const InvoicesInbound = () => {
    const {user, scroll, scrollRef, setMsg, setOpenOper} = useContext(DataContext);

    const [saveScroll, setSaveScroll] = useState(0);
    const [isNAV, setIsNAV] = useState(true);

    const [tags, setTags] = useState([]);
    const [payments, setPayments] = useState([]);
    const [costplaces, setCostplaces] = useState([]);

    const [invoiceData, setInvoiceData] = useState([]);
    const [showInvoiceData, setShowInvoiceData] = useState([]);

    const [search, setSearch] = useState("");
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [tab, setTab] = useState(0);

    const [open, setOpen] = useState({});
    const [summary, setSummary] = useState([]);
    const [income, setIncome] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setInvoiceData([{},{},{},{},{},{},{},{},{},{},{},{}]);
        setShowInvoiceData([{},{},{},{},{},{},{},{},{},{},{},{}]);
    }, []);

    useEffect(() => {
        if(!user.company.nav_token) {
            setMsg({
                color: "error",
                message: "Nincs megadva technikai felhasználó a szervezethez"
            });
            return setOpenOper(0);
        }

        socket.emit("join", ["cid:"+user.company.id]);

        getCompanyTags();
        getPayments();
        getCostplaces();

        getNewInvoices();
        // getInvoices(); // Ha nincs NAV
    }, [user]);

    useEffect(() => {
        if(JSON.stringify(open) === "{}") {
            if(scrollRef.current) {
                scrollRef.current.scrollTop = saveScroll;
            }
        }
        else {
            setSaveScroll(scroll);
        }
    }, [open]);

    useEffect(() => {
        socket.on("update_inbound", () => {
            getInvoices();
        });
    }, [socket]);

    useEffect(() => {
        scrollRef.current.scrollTop = 0;
    }, [page]);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            const filteredData = invoiceData.filter(item => rx.test(item.partner_name) || rx.test(item.serial_num));

            setPages(Math.round(filteredData.length/12));

            setShowInvoiceData(filteredData);
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, invoiceData], 700);

    const getCompanyTags = async() => {
        const res = await fetch(apiUrl+"/tags/company/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTags(data.tags);
    };

    const getPayments = async() => {
        const res = await fetch(apiUrl+"/inbound/payments", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPayments(data.payments);
    };

    const getCostplaces = async() => {
        const res = await fetch(apiUrl+"/inbound/costplaces/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setCostplaces(data.costplaces);
    };

    const getNewInvoices = async() => {
        const res = await fetch(apiUrl+"/inbound/newinvoices", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ token: user.company.nav_token, company: user.company.id })
        });

        const data = await res.json();

        if(!data.success){
            setIsNAV(data.noNew ? true : false);
            getInvoices();
            return setMsg({
                color: data.noNew ? "success" : "error",
                message: data.message
            });
        }

        if(data.invoices.length !== 0) {
            return syncPartners(data.invoices);
        }

        getInvoices();
    };

    const syncPartners = async(invoices) => {
        const res = await fetch(apiUrl+"/inbound/sync/partners", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(invoices)
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        syncInvoices(invoices);
    };

    const syncInvoices = async(invoices) => {
        const res = await fetch(apiUrl+"/inbound/sync/invoices", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                invoices: invoices
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getInvoices();
    };

    const getInvoices = async() => {
        const res = await fetch(apiUrl+"/inbound/get/all/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPages(Math.round(data.invoices.length/12));
        setInvoiceData(data.invoices);
    };

    const getInvoice = async(serialNum) => {
        const res = await fetch(apiUrl+"/inbound/get/serial", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ serialNum: serialNum })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceData(prev => {
            const index = prev.findIndex(item => item.serial_num === data.invoice.serial_num);

            if(JSON.stringify(open) !== "{}") {
                setOpen(data.invoice);
            }

            return [
                ...prev.slice(0, index),
                data.invoice,
                ...prev.slice(index+1)
            ];
        });
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const renderItemized = () => {
        if(JSON.stringify(open) !== "{}") {
            return (
                <InvoiceItemized
                    invoice={open}
                    payments={payments}
                    costplaces={costplaces}
                    tags={tags}
                    onRefresh={() =>{
                        getCompanyTags();
                        getPayments();
                        getCostplaces();
                        getInvoice(open.serial_num);
                    }}
                    onClose={() => setOpen({})}
                />
            );
        }
    };

    const renderSummary = () => {
        if(summary.length !== 0) {
            return (
                <InvoiceSummary
                    invoice={summary}
                    onClose={() => setSummary([])}
                />
            );
        }
    };

    const renderIncome = () => {
        if(JSON.stringify(income) !== "{}") {
            return (
                <IncomeStore
                    invoice={income}
                    onClose={() => setIncome({})}
                />
            );
        }
    };

    return (
        <>
            {renderItemized()}
            {renderSummary()}
            {renderIncome()}

            {(JSON.stringify(open) === "{}" && summary.length === 0 && JSON.stringify(income) === "{}") &&
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <OpHeader
                                operation={22}
                                onClose={() => setOpenOper(0)}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                        >
                            <Grid item container xs={12} spacing={1}>
                                <Grid item xs={12}>
                                    <Tabs
                                        variant="scrollable"
                                        scrollButtons
                                        value={tab}
                                        onChange={handleChange}
                                        sx={{
                                            ".MuiTabs-scrollButtons": {
                                                color: "#000"
                                            }
                                        }}
                                    >
                                        <Tab
                                            icon={<CloudDownloadOutlinedIcon style={{ fontSize: 32 }} />}
                                            label={<Typography fontSize={12}>Új számla</Typography>}
                                            sx={{
                                                flex: 1,
                                                gap: 1,
                                                "&:hover": {
                                                    color: "#2278CF"
                                                }
                                            }}
                                        />

                                        <Tab
                                            icon={<PostAddOutlinedIcon style={{ fontSize: 32 }} />}
                                            label={<Typography fontSize={12}>Iktatott számla</Typography>}
                                            sx={{
                                                flex: 1,
                                                gap: 1,
                                                "&:hover": {
                                                    color: "#2278CF"
                                                }
                                            }}
                                        />

                                        <Tab
                                            icon={<TaskOutlinedIcon style={{ fontSize: 32 }} />}
                                            label={<Typography fontSize={12}>Bevételezett számla</Typography>}
                                            sx={{
                                                flex: 1,
                                                gap: 1,
                                                "&:hover": {
                                                    color: "#2278CF"
                                                }
                                            }}
                                        />

                                        <Tab
                                            icon={<PersonAddOutlinedIcon style={{ fontSize: 32 }} />}
                                            label={<Typography fontSize={12}>Utalandó</Typography>}
                                            sx={{
                                                flex: 1,
                                                gap: 1,
                                                "&:hover": {
                                                    color: "#2278CF"
                                                }
                                            }}
                                        />

                                        <Tab
                                            icon={<PersonAddOutlinedIcon style={{ fontSize: 32 }} />}
                                            label={<Typography fontSize={12}>Utalási lista</Typography>}
                                            sx={{
                                                flex: 1,
                                                gap: 1,
                                                "&:hover": {
                                                    color: "#2278CF"
                                                }
                                            }}
                                        />

                                        <Tab
                                            icon={<PersonAddOutlinedIcon style={{ fontSize: 32 }} />}
                                            label={<Typography fontSize={12}>Utalva</Typography>}
                                            sx={{
                                                flex: 1,
                                                gap: 1,
                                                "&:hover": {
                                                    color: "#2278CF"
                                                }
                                            }}
                                        />
                                    </Tabs>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <OutlinedInput
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder="Keresés"
                                        size="small"
                                        sx={{ bgcolor: "#FFF" }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={search === ""}
                                                    onClick={() => setSearch("")}
                                                    size="small"
                                                >
                                                    <CloseIcon style={{ fontSize: 14 }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                
                                {/*
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    marginTop="auto"
                                    marginBottom="auto"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={2}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Typography color="#747474" fontSize={14}>Utolsó szinkronizáció: {invoiceData[0]?.record_datetime}</Typography>
                                        <Tooltip
                                            arrow
                                            title={isNAV ? "Van NAV kapcsolat" : "Nincs NAV kapcsolat"}
                                        >
                                            <Badge color={isNAV ? "primary" : "secondary"} badgeContent=" " variant="dot" />
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                */}
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <TabPanel value={tab} index={0}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            <Pagination
                                                count={pages}
                                                variant="outlined"
                                                shape="rounded"
                                                color="primary"
                                                page={page}
                                                onChange={handleChangePage}
                                            />
                                        </Box>
                                    </Grid>

                                    {showInvoiceData.filter(item => !item.status).filter((_, i) => i >= (page-1)*12 && i < ((page-1)*12)+12).map((item, index) => {
                                        return (
                                            <InvoiceItem
                                                key={item.id}
                                                invoice={item}
                                                tags={tags}
                                                timeout={(index+1)*400}
                                                payments={payments}
                                                costplaces={costplaces}
                                                onItemized={() => isNAV && setOpen(item)}
                                                onRefresh={() => {
                                                    getCompanyTags();
                                                    getPayments();
                                                    getCostplaces();
                                                    getInvoice(item.serial_num);
                                                }}
                                                onReceived={(invoiceInfo) => setSummary(invoiceInfo)}
                                                onIncome={() => setIncome(item)}
                                            />
                                        );
                                    })}

                                    <Grid item xs={12}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            <Pagination
                                                count={pages}
                                                variant="outlined"
                                                shape="rounded"
                                                color="primary"
                                                page={page}
                                                onChange={handleChangePage}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={tab} index={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            <Pagination
                                                count={pages}
                                                variant="outlined"
                                                shape="rounded"
                                                color="primary"
                                                page={page}
                                                onChange={handleChangePage}
                                            />
                                        </Box>
                                    </Grid>

                                    {showInvoiceData.filter(item => item.status === 1).filter((_, i) => i >= (page-1)*12 && i < ((page-1)*12)+12).map((item, index) => {
                                        return (
                                            <InvoiceItem
                                                key={item.id}
                                                invoice={item}
                                                tags={tags}
                                                timeout={(index+1)*400}
                                                payments={payments}
                                                costplaces={costplaces}
                                                onItemized={() => isNAV && setOpen(item)}
                                                onRefresh={() => {
                                                    getCompanyTags();
                                                    getInvoice(item.serial_num);
                                                }}
                                                onReceived={(invoiceInfo) => setSummary(invoiceInfo)}
                                                onIncome={() => setIncome(item)}
                                            />
                                        );
                                    })}

                                    <Grid item xs={12}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            <Pagination
                                                count={pages}
                                                variant="outlined"
                                                shape="rounded"
                                                color="primary"
                                                page={page}
                                                onChange={handleChangePage}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={tab} index={2}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            <Pagination
                                                count={pages}
                                                variant="outlined"
                                                shape="rounded"
                                                color="primary"
                                                page={page}
                                                onChange={handleChangePage}
                                            />
                                        </Box>
                                    </Grid>

                                    {showInvoiceData.filter(item => item.status === 2).filter((_, i) => i >= (page-1)*12 && i < ((page-1)*12)+12).map((item, index) => {
                                        return (
                                            <InvoiceItem
                                                key={item.id}
                                                invoice={item}
                                                tags={tags}
                                                payments={payments}
                                                costplaces={costplaces}
                                                onItemized={() => isNAV && setOpen(item)}
                                                onRefresh={() => {
                                                    getCompanyTags();
                                                    getInvoice(item.serial_num);
                                                }}
                                                onReceived={(invoiceInfo) => setSummary(invoiceInfo)}
                                                onIncome={() => setIncome(item)}
                                            />
                                        );
                                    })}

                                    <Grid item xs={12}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            <Pagination
                                                count={pages}
                                                variant="outlined"
                                                shape="rounded"
                                                color="primary"
                                                page={page}
                                                onChange={handleChangePage}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={tab} index={3}>
                                <TransferTable
                                    invoices={showInvoiceData.filter(item => (item.status === 1 || item.status === 2) && !item.transfer_id)}
                                    onRefresh={getInvoices}
                                />
                            </TabPanel>

                            <TabPanel value={tab} index={4}>
                                <TransferList
                                    onRefresh={getInvoices}
                                />
                            </TabPanel>

                            <TabPanel value={tab} index={5}>
                                <ArrangedList
                                    invoices={showInvoiceData.filter(item => item.arranged)}
                                />
                            </TabPanel>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
};

export default InvoicesInbound;