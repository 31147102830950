import { Badge, Box, Skeleton, Typography } from "@mui/material";

import CheckCircleSVG from "../../../../../../assets/invoices/CheckCircle.svg";

import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

const InvoiceHeader = ({ invoice }) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding={1}
            sx={{
                background: "linear-gradient(90deg, #B5B3B3 0%, #D6D4D5 100%)",
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3
            }}
        >
            {invoice.serial_num ?
                <Box
                    sx={{
                        paddingX: 1,
                        paddingY: 0.5,
                        borderRadius: 1,
                        background: "#FFF",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.35) inset"
                    }}
                >
                    <Typography
                        fontSize={10}
                        fontWeight={300}
                        color="#8C8C8C"
                    >
                        {invoice.serial_num}
                    </Typography>
                </Box>
            :
                <Skeleton variant="rounded" width={90} height={20} />
            }

            <Box
                display="flex"
                flexDirection="row"
                gap={1}
                alignItems="center"
            >
                {invoice.serial_num ?
                    <>
                        {invoice.status === 1 &&
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: "bottom", horizontal: "right"}}
                                badgeContent={
                                    <img src={CheckCircleSVG} alt="badge" />
                                }
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={1}
                                    sx={{
                                        borderRadius: "50%",
                                        background: "#FFF",
                                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.35) inset"
                                    }}
                                >
                                    <FileOpenOutlinedIcon style={{ fontSize: 16, color: "#8C8C8C"}}/>
                                </Box>
                            </Badge>
                        }

                        {invoice.status === 2 &&
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: "bottom", horizontal: "right"}}
                                badgeContent={
                                    <img src={CheckCircleSVG} alt="badge" />
                                }
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={1}
                                    sx={{
                                        borderRadius: "50%",
                                        background: "#FFF",
                                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.35) inset"
                                    }}
                                >
                                    <InventoryOutlinedIcon style={{ fontSize: 16, color: "#8C8C8C"}}/>
                                </Box>
                            </Badge>
                        }

                        {invoice.arranged === 1 &&
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: "bottom", horizontal: "right"}}
                                badgeContent={
                                    <img src={CheckCircleSVG} alt="badge" />
                                }
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={1}
                                    sx={{
                                        borderRadius: "50%",
                                        background: "#FFF",
                                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.35) inset"
                                    }}
                                >
                                    <CreditCardIcon style={{ fontSize: 16, color: "#8C8C8C"}}/>
                                </Box>
                            </Badge>
                        }
                    </>
                :
                    <>
                        <Skeleton variant="circular" width={32} height={32} />
                        <Skeleton variant="circular" width={32} height={32} />
                        <Skeleton variant="circular" width={32} height={32} />
                    </>
                }
            </Box>
        </Box>
    );
};

export default InvoiceHeader;