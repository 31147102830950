import { useEffect, useContext, useState } from "react";

import { io } from "socket.io-client";

import { DataContext } from "../../context/DataContext";

import { Grid, Grow, ListItemAvatar } from "@mui/material";

import Stores from "../../layouts/stores/Stores";
import Store from "../../layouts/stores/content/Store";
import OpHeader from "../../components/opheader/OpHeader";
import DayopenPcs from "./DayopenPcs";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const DayopenStore = () => {
    const {
        user,
        stores,
        setLoading,
        setOpenOper
    } = useContext(DataContext);

    const [openPcs, setOpenPcs] = useState({});

    useEffect(() => {
        setLoading(true);

        socket.emit("join", ["uid:"+user.id, "rid:"+user.rank]);

        setLoading(false);
    }, []);

    const renderPcs = () => {
        if(JSON.stringify(openPcs) !== "{}") {
            return (
                <DayopenPcs
                    store={openPcs}
                    onClose={() => setOpenPcs({})}
                />
            );
        }
    };

    return (
        <>
            {renderPcs()}

            {JSON.stringify(openPcs) === "{}" &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={1}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        marginLeft={2}
                    >
                        <Grid item xs={12}>
                            <Stores>
                                {stores.map((item, i) => {
                                    return (
                                        <Grow key={i} in={true} timeout={(i+1)*500}>
                                            <ListItemAvatar>
                                                <Store
                                                    store={item}
                                                    click={() => setOpenPcs(item)}
                                                />
                                            </ListItemAvatar>
                                        </Grow>
                                    );
                                })}
                            </Stores>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default DayopenStore;