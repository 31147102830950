import { motion } from 'framer-motion';

import { Box, Grid, Grow, Tooltip, Typography } from "@mui/material";

const TrafficMode = ({ text, icon, click, active, timeout }) => {
    return (
        <Grid item xs={12} sx={!active && { opacity: 0.5 }}>
            <Grow in={true} timeout={timeout}>
                <Tooltip onClick={active && click}>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        style={active && { cursor: "pointer" }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            border={active ? "1px solid #13C2C2" : "1px solid #8C8C8C"}
                            borderRadius={1}
                            padding={2}
                            boxSizing="border-box"
                            width="100%"
                            color={active ? "#13C2C2" : "#8C8C8C"}
                        >
                            <Typography fontSize={12} fontWeight={300} color="#8C8C8C">{text}</Typography>

                            {icon}
                        </Box>
                    </motion.div>
                </Tooltip>
            </Grow>
        </Grid>
    );
};

export default TrafficMode;