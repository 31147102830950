import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";

import { Avatar, AvatarGroup, Box, Button, Divider, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import OpHeader from "../../components/opheader/OpHeader";
import StoreIcon from "../../layouts/stores/content/StoreIcon";
import TransitPackages from "./content/transit/TransitPackages";
import CashinItem from "./content/CashinItem";
import PcsTable from "../../components/pcstable/PcsTable";

const SafeModify = ({ safeItem, onSave, onClose }) => {
    const {user, stores, setMsg, setLoading} = useContext(DataContext);

    const [recordAcc, setRecordAcc] = useState(true);
    const [transitAcc, setTransitAcc] = useState(true);
    const [cashinAcc, setCashinAcc] = useState(true);

    const [packages, setPackages] = useState([]);

    const [pcs, setPcs] = useState({
        huf20000: safeItem.huf_20000_pcs,
        huf10000: safeItem.huf_10000_pcs,
        huf5000: safeItem.huf_5000_pcs,
        huf2000: safeItem.huf_200_pcs,
        huf1000: safeItem.huf_1000_pcs,
        huf500: safeItem.huf_500_pcs,
        huf200: safeItem.huf_200_pcs,
        huf100: safeItem.huf_100_pcs,
        huf50: safeItem.huf_50_pcs,
        huf20: safeItem.huf_20_pcs,
        huf10: safeItem.huf_10_pcs,
        huf5: safeItem.huf_5_pcs,
        sum: 0
    });
    const [serialNum, setSerialNum] = useState(safeItem.serial_num);
    const [comment, setComment] = useState(safeItem.comment);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = safeItem.profile_pic ? apiUrl+"/"+safeItem.profile_pic : null;
    const avatarTransit = safeItem.transit_pic ? apiUrl+"/"+safeItem.transit_pic : null;
    const avatarCashin = safeItem.cashin_pic ? apiUrl+"/"+safeItem.cashin_pic : null;

    const imageRecord = safeItem.record_image ? apiUrl+"/"+safeItem.record_image : null;
    const imageTransit = safeItem.transit_image ? apiUrl+"/"+safeItem.transit_image : null;

    useEffect(() => {
        getPackages();
    }, []);

    useEffect(() => {
        calculateSum();
    }, [pcs.huf5, pcs.huf10, pcs.huf20, pcs.huf50, pcs.huf100, pcs.huf200, pcs.huf500, pcs.huf1000,
        pcs.huf2000, pcs.huf5000, pcs.huf10000, pcs.huf20000]);

    const getPackages = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/safe/pack/"+safeItem.transit_pack, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPackages(data.packages);

        setLoading(false);
    };

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };    

    const displayCurrency = () => {
        if(pcs.sum) return pcs.sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        else return 0;
    };

    const calculateSum = () => {
        const sum = ((20000*pcs.huf20000) + (10000*pcs.huf10000) + (5000*pcs.huf5000) + (2000*pcs.huf2000) + 
        (1000*pcs.huf1000) + (500*pcs.huf500) + (200*pcs.huf200) + (100*pcs.huf100) + 
        (50*pcs.huf50) + (20*pcs.huf20) + (10*pcs.huf10) + (5*pcs.huf5));

        setPcs(prevPcs => ({...prevPcs, sum: sum}));
    };

    const handleModify = async() => {
        const res = await fetch(apiUrl+"/safe/modify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                safeItem: safeItem.id,
                newSerial: serialNum,
                huf5: Number(pcs.huf5),
                huf10: Number(pcs.huf10),
                huf20: Number(pcs.huf20),
                huf50: Number(pcs.huf50),
                huf100: Number(pcs.huf100),
                huf200: Number(pcs.huf200),
                huf500: Number(pcs.huf500),
                huf1000: Number(pcs.huf1000),
                huf2000: Number(pcs.huf2000),
                huf5000: Number(pcs.huf5000),
                huf10000: Number(pcs.huf10000),
                huf20000: Number(pcs.huf20000),
                newSum: Number(pcs.sum),
                comment: comment
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres módosítás! (Összeg: "+pcs.sum+" HUF)"
        });

        onSave();
    };

    const handleDelete = async() => {
        const res = await fetch(apiUrl+"/safe/delete", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ safeItem: safeItem.id })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres törlés!"
        });

        onSave();
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    text={<Typography fontSize={20} fontWeight={700}>{safeItem.serial_num}</Typography>}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    color="#000"
                    width="100%"
                >
                    <AvatarGroup max={2}>
                        <StoreIcon
                            store={stores.filter(item => item.id === safeItem.store)[0]}
                            size="medium"
                        />

                        <Tooltip
                            title={safeItem.last_name+" "+safeItem.first_name}
                            arrow
                        >
                            <Avatar
                                src={imageExists(avatar) ? avatar : null}
                                sx={{ zIndex: 2, width: 32, height: 32 }}
                            />
                        </Tooltip>
                    </AvatarGroup>

                    <Box
                        display="flex"
                        flexDirection="column"
                    >
                        <Typography fontSize={14} fontWeight={500}>Széfbe helyezve</Typography>
                        <Typography fontSize={12} fontWeight={300}>{safeItem.date_of_record}</Typography>
                    </Box>

                    {recordAcc ? 
                        <IconButton
                            onClick={() => setRecordAcc(prev => !prev)}
                            size="small"
                            sx={{ marginLeft: "auto" }}
                        >
                            <KeyboardArrowUpIcon />
                        </IconButton>
                    :
                        <IconButton
                            onClick={() => setRecordAcc(prev => !prev)}
                            size="small"
                            sx={{ marginLeft: "auto" }}
                        >
                            <KeyboardArrowDownIcon />
                        </IconButton>
                    }
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            {recordAcc &&
                <>
                    <PcsTable
                        pcs={pcs}
                        setPcs={setPcs}
                        modify={safeItem.transit_pack ? false : true}
                    />

                    <Grid item xs={12} md={4}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Typography fontSize={12} fontWeight={700} color="#000" marginBottom={1}>Csomagolási adatok és képek</Typography>
                        
                            <TextField
                                value={serialNum}
                                onChange={(e) => !safeItem.transit_pack && setSerialNum(e.target.value)}
                                variant="filled"
                                label="Csomag menevezése"
                                size="small"
                                sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                            />

                            <TextField
                                value={comment}
                                onChange={(e) => !safeItem.transit_pack && setComment(e.target.value)}
                                variant="filled"
                                label="Megjegyzés"
                                size="small"
                                sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                            />

                            <TextField
                                value={displayCurrency()+" HUF"}
                                variant="filled"
                                label="Összesen"
                                size="small"
                                sx={{ "& .MuiFilledInput-root": { bgcolor: "#FFF", fontSize: 24, fontWeight: 700 } }}
                            />

                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                alignItems="center"
                                width="100%"
                            >
                                <img
                                    src={imageExists(imageRecord) ? imageRecord : null}
                                    style={{ width: 200 }}
                                />
                            </Box>

                            {!safeItem.transit_pack &&
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={1}
                                    alignSelf="flex-end"
                                    marginTop={3}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handleDelete}
                                        sx={{ bgcolor: "#FF7875" }}
                                    >
                                        <Typography fontSize={12} fontWeight={300}>Törlés</Typography>
                                    </Button>

                                    <Button
                                        variant="contained"
                                        disabled={pcs.sum === 0 || serialNum === ""}
                                        onClick={handleModify}
                                        sx={{ bgcolor: "#13C2C2" }}
                                    >
                                        <Typography fontSize={12} fontWeight={300}>Módosítás</Typography>
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Grid>
                </>
            }

            {safeItem.transit_pack &&
                <>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                            color="#000"
                            width="100%"
                        >
                            <AvatarGroup max={2}>
                                <StoreIcon
                                    store={stores.filter(item => item.id === safeItem.store)[0]}
                                    size="medium"
                                />

                                <Tooltip
                                    title={safeItem.transit_last+" "+safeItem.transit_first}
                                    arrow
                                >
                                    <Avatar
                                        src={imageExists(avatarTransit) ? avatarTransit : null}
                                        sx={{ zIndex: 2, width: 32, height: 32 }}
                                    />
                                </Tooltip>
                            </AvatarGroup>

                            <Box
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography fontSize={14} fontWeight={500}>Átadva értékszállításra</Typography>
                                <Typography fontSize={12} fontWeight={300}>{safeItem.date_of_transit}</Typography>
                            </Box>

                            {transitAcc ? 
                                <IconButton
                                    onClick={() => setTransitAcc(prev => !prev)}
                                    size="small"
                                    sx={{ marginLeft: "auto" }}
                                >
                                    <KeyboardArrowUpIcon />
                                </IconButton>
                            :
                                <IconButton
                                    onClick={() => setTransitAcc(prev => !prev)}
                                    size="small"
                                    sx={{ marginLeft: "auto" }}
                                >
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            }
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>

                    {transitAcc &&
                        <>
                            <TransitPackages
                                packages={packages}
                            />

                            <Grid item xs={12} md={4}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={1}
                                >
                                    <TextField
                                        value={safeItem.transit_num}
                                        variant="filled"
                                        label="Azonosító"
                                        size="small"
                                        sx={{ "& .MuiFilledInput-root": { bgcolor: "#FFF" } }}
                                    />

                                    <TextField
                                        value={safeItem.transit_serial}
                                        variant="filled"
                                        label="Értékszállító azonosító"
                                        size="small"
                                        sx={{ "& .MuiFilledInput-root": { bgcolor: "#FFF" } }}
                                    />

                                    <TextField
                                        value={safeItem.transit_comment}
                                        variant="filled"
                                        label="Megjegyzés"
                                        size="small"
                                        sx={{ "& .MuiFilledInput-root": { bgcolor: "#FFF" } }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    width="100%"
                                >
                                    <img
                                        src={imageExists(imageTransit) ? imageTransit : null}
                                        style={{ width: 200 }}
                                    />
                                </Box>
                            </Grid>
                        </>
                    }
                </>
            }

            {safeItem.cashin_user &&
                <>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                            color="#000"
                            width="100%"
                        >
                            <Tooltip
                                title={safeItem.cashin_last+" "+safeItem.cashin_first}
                                arrow
                            >
                                <Avatar
                                    src={imageExists(avatarCashin) ? avatarCashin : null}
                                    sx={{ zIndex: 2, width: 32, height: 32 }}
                                />
                            </Tooltip>

                            <Box
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography fontSize={14} fontWeight={500}>Banki befizetés jóváhagyása</Typography>
                                <Typography fontSize={12} fontWeight={300}>{safeItem.date_of_cashin}</Typography>
                            </Box>

                            {cashinAcc ? 
                                <IconButton
                                    onClick={() => setCashinAcc(prev => !prev)}
                                    size="small"
                                    sx={{ marginLeft: "auto" }}
                                >
                                    <KeyboardArrowUpIcon />
                                </IconButton>
                            :
                                <IconButton
                                    onClick={() => setCashinAcc(prev => !prev)}
                                    size="small"
                                    sx={{ marginLeft: "auto" }}
                                >
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            }
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>

                    {cashinAcc &&
                        <>
                            <Grid item xs={12}>
                                <Typography fontSize={12} fontWeight={700} color="#000">Banki befizetés jóváhagyása és dátuma</Typography>
                            </Grid>

                            <CashinItem
                                pack={safeItem}
                            />
                        </>
                    }
                </>
            }
        </Grid>
    );
};

export default SafeModify;