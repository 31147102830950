import { Checkbox, TableCell, TableRow, Typography } from "@mui/material";

import CurrencyText from "../../../components/currencytext/CurrencyText";

const IncomeItem = ({ id, row, onCheck }) => {
    return (
        <TableRow hover>
            <TableCell>{id}</TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.barcode}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.itemId}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.itemName}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.qty} db</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={row.salesPrice} /> Ft
                </Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={Math.round(row.lineAmount/row.qty)} /> Ft
                </Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={Math.round(row.salesPrice*row.qty)} /> Ft
                </Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={row.lineAmount} /> Ft
                </Typography>
            </TableCell>

            <TableCell>
                <Checkbox
                    checked={row.ok}
                    onChange={() => onCheck(row.barcode)}
                />
            </TableCell>
        </TableRow>
    );
};

export default IncomeItem;