import { Box, IconButton, TableCell, TableRow, Typography } from "@mui/material";

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FiberNewIcon from '@mui/icons-material/FiberNew';

import CurrencyText from "../../../../../../../components/currencytext/CurrencyText";

const TableItem = ({ line, id, onDelete }) => {
    return (
        <TableRow hover>
            <TableCell>{id}</TableCell>

            <TableCell>
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <Typography fontSize={12}>{line.itemName}</Typography>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                    >
                        <Typography fontSize={10}>{line.barcode}</Typography>
                        
                        {line.priceChange === 1 &&
                            <FiberNewIcon />
                        }
                    </Box>
                </Box>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{line.quantity+" "+(line.unit ? line.unit : "DB")}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{line.inputQuantity+" "+(line.unit ? line.unit : "DB")}</Typography>
            </TableCell>

            <TableCell>
                {Number(line.quantity) === Number(line.inputQuantity) ? <CheckCircleOutlineIcon style={{ color: "#61D890"}} /> : <ErrorIcon style={{ color: "#FA541C" }} />}
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{(line.vat*100)+" %"}</Typography>
            </TableCell>

            <TableCell>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {line.priceChange === 0 &&
                        <HorizontalRuleIcon style={{ color: "orange" }} />
                    }

                    {line.priceChange === 1 &&
                        <KeyboardArrowUpIcon style={{ color: "red" }} />
                    }

                    {line.priceChange === 2 &&
                        <KeyboardArrowUpIcon style={{ color: "red" }} />
                    }

                    {line.priceChange === 3 &&
                        <KeyboardArrowDownIcon style={{ color: "green" }} />
                    }

                    <Typography fontSize={12}>
                        <CurrencyText val={(line.unitPrice).toFixed(3)} /> HUF
                    </Typography>
                </Box>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={(line.unitPrice*(line.vat+1)).toFixed(3)} /> HUF
                </Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={(line.unitPrice*line.inputQuantity).toFixed(3)} /> HUF
                </Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={((line.unitPrice*(line.vat+1))*line.inputQuantity).toFixed(3)} /> HUF
                </Typography>
            </TableCell>

            <TableCell align="center">
                <IconButton onClick={onDelete}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default TableItem;