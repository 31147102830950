import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { jwtDecode } from "jwt-decode";

import { GoogleLogin } from '@react-oauth/google';

import { motion } from 'framer-motion';

import { DataContext } from "../../context/DataContext";
import { getModules, getUserCompanies, getUserCompaniesStores, getUserOperations, getUserStores } from "../../services/Api";

import { Box, Button, Grow, IconButton, InputAdornment, OutlinedInput, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CloseIcon from '@mui/icons-material/Close';

import Feedback from "../../components/feedback/Feedback";

const Register = () => {
    const {setUser, setStores, setOps, msg, setMsg, setLoading, setModuleTab} = useContext(DataContext);

    const [input, setInput] = useState({
        last: "",
        first: "",
        email: "",
        emailVerify: "",
        pass: "",
        passVerify: ""
    });

    const [showPw, setShowPw] = useState(false);

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();

    const params = useParams();

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleNext = async(e) => {
        e.preventDefault();

        const res = await fetch(apiUrl+"/user/email/"+input.email, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        if(data.user.length !== 0) {
            return setMsg({
                color: "error",
                message: "Ez az email cím használatban van"
            });
        }

        register();
    };

    const handleNextGoogle = async(info) => {
        const res = await fetch(apiUrl+"/user/email/"+info.email, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        if(data.user.length !== 0) {
            return setMsg({
                color: "error",
                message: "Ez az email cím használatban van"
            });
        }

        registerGoogle(info);
    };

    const registerGoogle = async(info) => {
        const res = await fetch(apiUrl+"/user/register/google", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: info,
                token: params?.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getUserStores(data.user.id).then(userStores => {
            getUserCompanies(data.user.id).then(userCompanies => {
                if(userCompanies.length === 0) {
                    setUser({...data.user});
                    setMsg({});

                   navigate("/register/company");
                }
                else {
                    setUser({...data.user, stores: userStores, company: userCompanies[0], companies: userCompanies});
                    setMsg({});

                    getUserCompaniesStores(data.user.id).then(storeData => {
                        return setStores(storeData);
                    });

                    getModules(userCompanies[0].id).then(modules => setModuleTab(modules.length+1));

                    setMsg({});
                    return navigate("/home");
                }
            });
        });
    };

    const register = async() => {
        if(input.email !== input.emailVerify) {
            return setMsg({
                color: "error",
                message: "Nem egyeznek az email címek"
            });
        }

        if(input.pass !== input.passVerify) {
            return setMsg({
                color: "error",
                message: "Nem egyeznek a jelszavak"
            });
        }

        setLoading(true);

        const res = await fetch(apiUrl+"/user/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: input,
                token: params?.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getUserStores(data.user.id).then(userStores => {
            getUserCompanies(data.user.id).then(userCompanies => {
                if(userCompanies.length === 0) {
                    setUser({...data.user});
                    setMsg({});

                    setLoading(false);
                    return navigate("/register/company");
                }
                else {
                    setUser({...data.user, stores: userStores, company: userCompanies[0], companies: userCompanies});
                    setMsg({});

                    getUserCompaniesStores(data.user.id).then(storeData => {
                        return setStores(storeData);
                    });

                    getUserOperations(data.user.id).then(opsData => {
                        setOps(opsData.filter(item => Number(item.company) === Number(userCompanies[0].id)));
                    });

                    getModules(userCompanies[0].id).then(modules => setModuleTab(modules.length+1));

                    setMsg({});
                    setLoading(false);
                    return navigate("/home");
                }
            });
        });
    };

    return (
        <>
            {Object.keys(msg).length !== 0 &&
                <Feedback
                    color={msg.color}
                    message={msg.message}
                />
            }

            <Box
                minHeight="100vh"
                display="flex"
                flexDirection="column"
                padding={3}
                boxSizing="border-box"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                >
                    <Typography variant="subtitle1">myTRAFIX</Typography>
                </Box>

                <Box
                    flex="1"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <form
                        onSubmit={handleNext}
                        style={isMdDown ? { width: "100%" } : { width: 400 }}
                    >
                        <Stack spacing={3}>
                            <Grow in={true} timeout={500}>
                                <Typography variant="subtitle2">Regisztráció</Typography>
                            </Grow>
                            
                            <Stack spacing={1}>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    style={{ width: "100%" }}
                                >
                                    <Grow in={true} timeout={1000}>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="vezetéknév"
                                            fullWidth
                                            value={input.last}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, last: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <BadgeOutlinedIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disabled={input.last === ""}
                                                        onClick={() => setInput(prevInput => ({...prevInput, last: ""}))}
                                                        size="small"
                                                    >
                                                        <CloseIcon style={{ fontSize: 14 }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>
                                </motion.div>

                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    style={{ width: "100%" }}
                                >
                                    <Grow in={true} timeout={1500}>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="keresznév"
                                            fullWidth
                                            value={input.first}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, first: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <BadgeOutlinedIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disabled={input.first === ""}
                                                        onClick={() => setInput(prevInput => ({...prevInput, first: ""}))}
                                                        size="small"
                                                    >
                                                        <CloseIcon style={{ fontSize: 14 }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>
                                </motion.div>

                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    style={{ width: "100%" }}
                                >
                                    <Grow in={true} timeout={2000}>
                                        <OutlinedInput
                                            type="email"
                                            placeholder="email"
                                            fullWidth
                                            value={input.email}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, email: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <MailOutlineIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disabled={input.email === ""}
                                                        onClick={() => setInput(prevInput => ({...prevInput, email: ""}))}
                                                        size="small"
                                                    >
                                                        <CloseIcon style={{ fontSize: 14 }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>
                                </motion.div>

                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    style={{ width: "100%" }}
                                >
                                    <Grow in={true} timeout={2500}>
                                        <OutlinedInput
                                            type="email"
                                            placeholder="email megerősítés"
                                            fullWidth
                                            value={input.emailVerify}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, emailVerify: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <MailOutlineIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disabled={input.emailVerify === ""}
                                                        onClick={() => setInput(prevInput => ({...prevInput, emailVerify: ""}))}
                                                        size="small"
                                                    >
                                                        <CloseIcon style={{ fontSize: 14 }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>
                                </motion.div>

                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    style={{ width: "100%" }}
                                >
                                    <Grow in={true} timeout={3000}>
                                        <OutlinedInput
                                            type={showPw ? "text" : "password"}
                                            placeholder="jelszó"
                                            fullWidth
                                            value={input.pass}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, pass: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <LockIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPw(prev => !prev)}
                                                        size="small"
                                                    >
                                                        {showPw ? <VisibilityIcon style={{ fontSize: 14 }} /> : <VisibilityOffIcon style={{ fontSize: 14 }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>
                                </motion.div>

                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    style={{ width: "100%" }}
                                >
                                    <Grow in={true} timeout={3000}>
                                        <OutlinedInput
                                            type={showPw ? "text" : "password"}
                                            placeholder="jelszó megerősítés"
                                            fullWidth
                                            value={input.passVerify}
                                            onChange={
                                                (e) => setInput(prevInput => ({...prevInput, passVerify: e.target.value}))
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <LockIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPw(prev => !prev)}
                                                        size="small"
                                                    >
                                                        {showPw ? <VisibilityIcon style={{ fontSize: 14 }} /> : <VisibilityOffIcon style={{ fontSize: 14 }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                        />
                                    </Grow>
                                </motion.div>
                            </Stack>

                            <Stack spacing={1}>
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    style={{ width: "100%" }}
                                >
                                    <Grow in={true} timeout={4000}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            disabled={Object.values(input).filter(item => item === "").length !== 0}
                                            sx={{
                                                ":disabled": {
                                                    color: "#e0e0e0"
                                                }
                                            }}
                                        >
                                            Tovább
                                        </Button>
                                    </Grow>
                                </motion.div>

                                <Button onClick={() => navigate("/")}>
                                    Vissza
                                </Button>

                                <GoogleLogin
                                    onSuccess={res => handleNextGoogle(jwtDecode(res.credential))}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </>
    );
};

export default Register;