import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, OutlinedInput, useMediaQuery, useTheme } from "@mui/material";

import BarcodeImg from "../../../../../../../assets/invoices/barcode.svg";
import BarcodeIconImg from "../../../../../../../assets/invoices/barcode_icon.svg";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const BarcodeDialog = ({ open, setOpen, barcode, setBarcode, choosen, onNext }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <DialogTitle>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <IconButton
                        onClick={() => setOpen(false)}
                        size="small"
                    >
                        <ArrowBackIosIcon style={{ fontSize: 16 }} />
                    </IconButton>

                    <IconButton
                        size="small"
                    >
                        <OpenInFullIcon style={{ fontSize: 16 }} />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <DialogContentText fontSize={20}>
                        {choosen.itemName}
                    </DialogContentText>

                    <img
                        src={BarcodeImg}
                        alt="barcode"
                    />

                    <OutlinedInput
                        value={barcode}
                        onChange={(e) => setBarcode(e.target.value)}
                        placeholder="VONALKÓD BEOLVASÁSA"
                        size="small"
                        fullWidth
                        endAdornment={
                            <Box
                                p={0.5}
                                sx={{
                                    backgroundColor: "#6BAEFB",
                                    marginRight: -1.6,
                                    borderTopRightRadius: 4,
                                    borderBottomRightRadius: 4
                                }}
                            >
                                <img src={BarcodeIconImg} alt="barcode_icon" />
                            </Box>
                        }
                        sx={{
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6BAEFB"
                            },
                            "&:hover": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#6BAEFB"
                                }
                            },
                            "&:focused": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#6BAEFB"
                                }
                            }
                        }}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        sx={{
                            color: "#262626",
                            borderColor: "#D9D9D9",
                            "&:hover": {
                                color: "#262626",
                                borderColor: "#D9D9D9"
                            }
                        }}
                    >
                        Vissza
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: "#FAAD14",
                            "&:hover": {
                                bgcolor: "#FAAD14"
                            }
                        }}
                    >
                        Nem érkezett meg
                    </Button>

                    <Button
                        variant="contained"
                        disabled={barcode === ""}
                        onClick={onNext}
                        sx={{
                            bgcolor: "#8C8C8C",
                            "&:hover": {
                                bgcolor: "#8C8C8C"
                            }
                        }}
                    >
                        Tovább
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default BarcodeDialog;