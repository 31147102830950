import { useState } from "react";

import { Box, Button, InputAdornment, OutlinedInput } from "@mui/material";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const SearchInput = ({ onSearch }) => {
    const [inputSearch, setInputSearch] = useState("");

    return (
        <Box
            display="flex"
            flexDirection="row"
            gap={2}
        >
            <OutlinedInput
                type="text"
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                placeholder="Keresés"
                fullWidth
                size="small"
                startAdornment={
                    <InputAdornment position="start">
                        <SearchOutlinedIcon style={{ width: 20 }} />
                    </InputAdornment>
                }
                sx={{
                    fontSize: 12,
                    backgroundColor: "#FFFFFF"
                }}
            />

            <Button
                variant="contained"
                size="small"
                onClick={() => onSearch(inputSearch)}
            >
                Keresés
            </Button>
        </Box>
    );
};

export default SearchInput;