import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import useDebounce from "../../../hooks/useDebounce";
import { DataContext } from "../../../context/DataContext";
import { getUserOperations } from "../../../services/Api";

import { Box, Button, Card, Grid, InputAdornment, OutlinedInput, Stack, Tab, Tabs, Typography } from "@mui/material";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DescriptionIcon from '@mui/icons-material/Description';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LiquorIcon from '@mui/icons-material/Liquor';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

import Operation from "./content/Operation";

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: "100%" }}
        >
            {value === index && children}
        </div>
    );
};

const Modules = ({ modules }) => {
    const {user, ops, setOps, setMsg, moduleTab, setModuleTab} = useContext(DataContext);

    const [search, setSearch] = useState("");

    const [showOps, setShowOps] = useState([]);

    const [icons] = useState({
        1: <PointOfSaleIcon style={{ fontSize: 32 }} />,
        2: <DescriptionIcon style={{ fontSize: 32 }} />,
        3: <ManageAccountsIcon style={{ fontSize: 32 }} />,
        4: <LiquorIcon style={{ fontSize: 32 }} />,
        5: <LocalActivityIcon style={{ fontSize: 32 }} />,
        8: <SettingsSuggestIcon style={{ fontSize: 32 }} />,
        9: <AccountBalanceWalletOutlinedIcon style={{ fontSize: 32 }} />
    });

    const navigate = useNavigate();

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');

            let filtered = ops;

            if(!user.company.nav_token) {
                filtered = filtered.filter(item => item.group_id !== 2);
            }

            setShowOps(filtered.filter(item => rx.test(item.name) || rx.test(item.description)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, moduleTab, modules, ops], 500);

    const handleChange = (event, newValue) => {
        setModuleTab(newValue);
    };

    return (
        <Grid
            container
            item
            xs={12}
            md={12}
            spacing={2}
        >
            <Grid item xs={12}>
                <Typography color="#000" fontWeight={500}>Modulok</Typography>
            </Grid>

            <Grid item xs={12}>
                <Tabs
                    variant="scrollable"
                    scrollButtons
                    value={moduleTab}
                    onChange={handleChange}
                    sx={{
                        ".MuiTabs-scrollButtons": {
                            color: "#000"
                        }
                    }}
                >
                    <Tab
                        value={modules.length+1}
                        icon={<GridViewIcon style={{ fontSize: 32 }} />}
                        label={<Typography fontSize={12}>Összes</Typography>}
                        sx={{
                            flex: 1,
                            gap: 1,
                            "&:hover": {
                                color: "#2278CF"
                            }
                        }}
                    />

                    <Tab
                        value={modules.length+2}
                        icon={<FavoriteBorderOutlinedIcon style={{ fontSize: 32 }} />}
                        label={<Typography fontSize={12}>Kedvencek</Typography>}
                        sx={{
                            flex: 1,
                            gap: 1,
                            "&:hover": {
                                color: "#2278CF"
                            }
                        }}
                    />

                    {modules.map((item, i) => {
                        if(item.id === 2) {
                            if(ops.filter(op => op.group_id === item.id).length !== 0) {
                                return (
                                    <Tab
                                        key={item.id}
                                        icon={icons[item.id]}
                                        label={<Typography fontSize={12}>{item.short_name}</Typography>}
                                        value={i}
                                        disabled={!user.company.nav_token}
                                        sx={{
                                            flex: 1,
                                            gap: 1,
                                            "&:hover": {
                                                color: "#2278CF"
                                            }
                                        }}
                                    />
                                );
                            }
                        }

                        if(ops.filter(op => op.group_id === item.id).length !== 0) {
                            return (
                                <Tab
                                    key={item.id}
                                    icon={icons[item.id]}
                                    label={<Typography fontSize={12}>{item.short_name}</Typography>}
                                    value={i}
                                    sx={{
                                        flex: 1,
                                        gap: 1,
                                        "&:hover": {
                                            color: "#2278CF"
                                        }
                                    }}
                                />
                            );
                        } 
                    })}
                </Tabs>
            </Grid>

            {(!user.company.nav_token && ops.filter(item => item.group_id === 2).length !== 0) &&
                <Grid item xs={12}>
                    <Card
                        sx={{
                            p: 2,
                            bgcolor: "#FDCABE"
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item md={9} xs={12}>
                                <Stack spacing={1} width="100%">
                                    <Typography fontSize={16} fontWeight={600}>Számla beállítások</Typography>
                                    <Typography fontSize={12} fontWeight={300}>Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor.</Typography>
                                </Stack>
                            </Grid>
                            
                            <Grid item md={3} xs={12}>
                                <Box
                                    display="flex"
                                    alignItems="flex-end"
                                    justifyContent="flex-end"
                                    width="100%"
                                    height="100%"
                                >
                                    <Button
                                        variant="contained"
                                        size="small"
                                        disabled={user.company.rank !== 1}
                                        onClick={() => navigate("/invoice/user")}
                                        sx={{
                                            padding: 1,
                                            bgcolor: "#EA7559",
                                            color: "#FFF",
                                            fontSize: 10,
                                            fontWeight: 300,
                                            borderRadius: 4,
                                            "&:hover": {
                                                bgcolor: "#EA7559"
                                            }
                                        }}
                                    >
                                        Számla műveletek aktiválása
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            }

            <Grid item xs={12}>
                <OutlinedInput
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Keresés"
                    fullWidth
                    size="small"
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchOutlinedIcon style={{ width: 20 }} />
                        </InputAdornment>
                    }
                    sx={{
                        fontSize: 12,
                        backgroundColor: "#FFFFFF"
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <TabPanel value={moduleTab} index={modules.length+1}>
                    <Grid container spacing={1.2}>
                        {showOps.filter(item => user.store ? item.visibility === 3 || item.visibility === 2 : item.visibility === 3).map(item => {
                            return (
                                <Operation
                                    key={item.id}
                                    op={item}
                                    onRefresh={() => {
                                        getUserOperations(user.id).then(data => {
                                            setOps(data);
                                        });
                                    }}
                                />
                            );
                        })}
                    </Grid>
                </TabPanel>

                <TabPanel value={moduleTab} index={modules.length+2}>
                    <Grid container spacing={1.2}>
                        {showOps.filter(item => user.store ? (item.visibility === 3 && item.favourite === 1) || (item.visibility === 2 && item.favourite === 1) : item.visibility === 3 && item.favourite === 1).map(item => {
                            return (
                                <Operation
                                    key={item.id}
                                    op={item}
                                    onRefresh={() => {
                                        getUserOperations(user.id).then(data => {
                                            setOps(data);
                                        });
                                    }}
                                />
                            );
                        })}
                    </Grid>
                </TabPanel>

                {modules.map((item, i) => {
                    return (
                        <TabPanel key={item.id} value={moduleTab} index={i}>
                            <Grid container spacing={1.2}>
                                {showOps.filter(op => user.store ? (op.visibility === 3 && op.group_id === item.id) || (op.visibility === 2 && op.group_id === item.id) : op.visibility === 3 && op.group_id === item.id).map(op => {
                                    return (
                                        <Operation
                                            key={op.id}
                                            op={op}
                                            onRefresh={() => {
                                                getUserOperations(user.id).then(data => {
                                                    setOps(data);
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </Grid>
                        </TabPanel>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default Modules;