import { Box, Grid, IconButton, Tooltip } from "@mui/material";

import { motion } from 'framer-motion';

import StoreIcon from "../../../../../../../layouts/stores/content/StoreIcon";

const StoreItem = ({ store, onClick }) => {
    return (
        <Grid item xs={2}>
            <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                <IconButton onClick={() => onClick(store)}>
                    <Tooltip
                        title={store.store}
                        arrow
                    >
                        <Box sx={{ opacity: store.selected ? 1 : 0.3 }}>
                        <StoreIcon
                            store={{
                                name: store.store,
                                color_code_2: store.store_color
                            }}
                            size="medium"
                        />
                        </Box>
                    </Tooltip>
                </IconButton>
            </motion.div>
        </Grid>
    );
};

export default StoreItem;