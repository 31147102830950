import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import CurrencyText from "../../../../components/currencytext/CurrencyText";

const SummaryDialog = ({ open, onClose, packages, onNext }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const calculateSum = () => {
        return packages.reduce((acc, obj) => {
            return acc += obj.sum;
        }, 0);
    };

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <IconButton
                    onClick={onClose}
                    size="small"
                >
                    <ArrowBackIosIcon style={{ fontSize: 16 }} />
                </IconButton>

                <IconButton
                    size="small"
                >
                    <OpenInFullIcon style={{ fontSize: 16 }} />
                </IconButton>
            </Box>

            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    <DialogContentText fontSize={14} fontWeight={500}>Véglegesítés</DialogContentText>
                    <DialogContentText fontSize={12} fontWeight={300}>Válaszd ki a csomagokat amelyeket az értékszállítónak átadsz</DialogContentText>

                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        alignItems="center"
                        marginTop={3}
                    >
                        <Typography fontSize={16} fontWeight={400}>Biztos véglegesítedt a befizetési tételeket?</Typography>

                        <Typography fontSize={14} fontWeight={300}>{packages.length} tétel</Typography>

                        <Typography fontSize={14} fontWeight={600}>
                            <CurrencyText
                                val={calculateSum()}
                            /> HUF
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={onClose}
                >
                    Vissza
                </Button>

                <Button
                    variant="contained"
                    size="small"
                    onClick={onNext}
                >
                    Véglegesítés
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SummaryDialog;