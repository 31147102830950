import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { MenuItem, Select } from "@mui/material";

import ReactEcharts from "echarts-for-react";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import DailyUserStyle from "./DailyUser.module.css";

import OpsHeader from "../../../../layouts/opsheader/OpsHeader";

import Feedback from "../../../../components/feedback/Feedback";
import ChartFilter from "../../../../components/charts/chartfilter/ChartFilter";
import FilterItem from "../../../../components/filteritem/FilterItem";

const DailyUser = ({ data, onClose }) => {
    const {user, msg, setMsg} = useContext(DataContext);

    const [loading, setLoading] = useState(false);

    const [dates, setDates] = useState({
        from: dayjs(data.from),
        to: dayjs(data.to)
    });

    const [lineInfo, setLineInfo] = useState([]);
    const [lineAvg, setLineAvg] = useState({});
    const [pieInfo, setPieInfo] = useState([]);

    const [lines, setLines] = useState([
        {
            id: 0,
            name: "Forgalom",
            color: "#5470C6",
            show: true
        },
        {
            id: 1,
            name: "Profit",
            color: "#5470C6",
            show: false
        },
        {
            id: 2,
            name: "Darabszám",
            color: "#5470C6",
            show: false
        }
    ]);

    const [users, setUsers] = useState([]);
    const [isClickable, setIsClickable] = useState(false);
    const [newUser, setNewUser] = useState(0);
    const [userLines, setUserLines] = useState([]);

    const [op, setOp] = useState({});

    const chartRef = useRef();

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const lineChartMemo = useMemo(() => {
        return (
            <ReactEcharts
                option={op}
                style={{width: "100%",  height: "100%"}}
            />
        );
    }, [op]);

    useEffect(() => {
        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getUserTraffic();
        getUsers();
    }, []);

    useEffect(() => {
        setOp({
            grid: {
                containLabel: true,
                left: "2%",
                right: "2%",
                top: "5%",
                bottom: "18%"
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: lineInfo.map(item => item.date)
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    formatter: (val) => {
                        if(lines[2].show) return val+" db";
    
                        return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                    } 
                }
            },
            dataZoom: [
                {
                    show: true,
                    start: 0,
                    end: 100
                }
            ],
            series: [
                {
                    type: "line",
                    name: data.name,
                    symbolSize: 10,
                    data: [
                        ...lineInfo.map((item) => ({
                            value: lines[0].show ? item.sum_traffic : lines[1].show ? item.sum_profit : item.sum_quantity
                        }))
                    ]
                },
                {
                    type: "line",
                    name: "Átlag",
                    symbolSize: 0,
                    data: [
                        ...lineInfo.map((_) => ({
                            value: lines[0].show ? lineAvg.traffic : lines[1].show ? lineAvg.profit : lineAvg.quantity
                        }))
                    ]
                },
                ...userLines.map(u => {
                    return {
                        type: "line",
                        name: u[0].name,
                        symbolSize: 10,
                        data: [
                            ...u.map((item) => ({
                                value: lines[0].show ? item.sum_traffic : lines[1].show ? item.sum_profit : item.sum_quantity
                            }))
                        ]
                    };
                })
            ],
            tooltip: {
                trigger: "axis",
                valueFormatter: (val) => {
                    if(lines[2].show) return val+" db";
    
                    return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                }  
            }
        });
    }, [userLines, lineInfo, lines]);

    const dateRange = (startDate, endDate) => {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate).toISOString("hu-HU").slice(0, 10));
            currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        }

        return dateArray;
    }

    const getUserTraffic = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/diadev/user", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: data.user,
                from: new Date(dates.from).toISOString("hu-HU").slice(0, 10),
                to: new Date(dates.to).toISOString("hu-HU").slice(0, 10)
            })
        });

        const fetchData = await res.json();

        if(!fetchData.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPieInfo(fetchData.pie);

        const avgTraffic = fetchData.line.reduce((acc, obj) => {
            return acc + obj.sum_traffic;
        }, 0) / fetchData.line.length;

        const avgProfit = fetchData.line.reduce((acc, obj) => {
            return acc + obj.sum_profit;
        }, 0) / fetchData.line.length;

        const avgQuantity = fetchData.line.reduce((acc, obj) => {
            return acc + obj.sum_quantity;
        }, 0) / fetchData.line.length;

        setLineAvg({
            traffic: Number(avgTraffic.toFixed()),
            profit: Number(avgProfit.toFixed()),
            quantity: Number(avgQuantity.toFixed())
        });

        const datesArr = dateRange(dates.from, dates.to);

        const allDatesSum = [];

        datesArr.map(date => {
            const filtered = fetchData.line.filter(item => new Date(date).toISOString("hu-HU").slice(0, 10) === item.date);
            
            if(filtered.length === 0) {
                return allDatesSum.push({
                    date: new Date(date).toISOString("hu-HU").slice(0, 10),
                    sum_traffic: 0,
                    sum_profit: 0,
                    sum_quantity: 0
                });
            }
            else {
                return allDatesSum.push(filtered[0]);
            }
        });

        setLineInfo(allDatesSum);

        setLoading(false);
    };

    const getUsers = async() => {
        const res = await fetch(apiUrl+"/users/mycompanies", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                choosen: Number(data.user)
            })
        });

        const fetchData = await res.json();

        if(!fetchData.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }
        
        setUsers(fetchData.users);

        setIsClickable(true);
    }

    const handleGetCompareInfo = async() => {
        if(userLines.filter(item => Number(item.user) === Number(newUser)).length !== 0) {
            return setMsg({
                color: "error",
                message: "Ez a felhasználó már hozzá van adva a listához"
            }); 
        }

        setLoading(true);

        const res = await fetch(apiUrl+"/diadev/user", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: newUser,
                from: new Date(dates.from).toISOString("hu-HU").slice(0, 10),
                to: new Date(dates.to).toISOString("hu-HU").slice(0, 10)
            })
        });

        const fetchData = await res.json();

        if(!fetchData.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        const datesArr = dateRange(data.from, data.to);

        const filterName = users.filter(item => Number(item.id) === Number(newUser))[0];
        const name = filterName?.last_name+" "+filterName?.first_name;

        const allDatesSum = [];

        datesArr.map(date => {
            const filtered = fetchData.line.filter(item => new Date(date).toISOString("hu-HU").slice(0, 10) === item.date);
            
            if(filtered.length === 0) {
                return allDatesSum.push({
                    user: Number(newUser),
                    name: name,
                    date: new Date(date).toISOString("hu-HU").slice(0, 10),
                    sum_traffic: 0,
                    sum_profit: 0,
                    sum_quantity: 0
                });
            }
            else {
                return allDatesSum.push({
                    ...filtered[0],
                    name: name
                });
            }
        });

        setUserLines(prev => ([...prev, allDatesSum]));

        setLoading(false);
    };

    const handleBack = () => {
        //chartRef.current.className = HomeStyle.op_content_close;

        const timer = setTimeout(() => {
            setMsg({});
            onClose();
        }, 1000);
        return () => clearTimeout(timer);
    };

    const handleFilter = (line) => {
        const modifyArr = lines.map(item => {
            if(item.id === line.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: true
                };
            }
            
            return {
                id: item.id,
                name: item.name,
                color: item.color,
                show: false
            };
        });

        setLines(modifyArr);
    };

    const handleRemoveUser = (removeUser) => {
        const newUsers = userLines.filter(item => item[0]?.user !== removeUser[0]?.user);

        setUserLines(newUsers);
    };

    return (
        <div style={{overflow: "hidden"}}>
            <div
                style={{backgroundColor: "#FFFFFF", zIndex: "15"}}
                ref={chartRef}
            >
                <div>
                    {
                        Object.keys(msg).length !== 0 ?
                            <Feedback
                                color={msg.color}
                                message={msg.message}
                            />
                            :
                            <></>
                    }

                    <OpsHeader
                        operation={19}
                        opsRef={chartRef}
                        headerItem={(
                            <div className={DailyUserStyle.dates_row}>
                                <DatePicker
                                    label="Dátum-tól"
                                    value={dates.from}
                                    onChange={(val) => setDates(prevDates => ({...prevDates, from: val}))}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: "32px"
                                        }
                                    }}
                                />

                                <DatePicker
                                    label="Dátum-ig"
                                    value={dates.to}
                                    onChange={(val) => setDates(prevDates => ({...prevDates, to: val}))}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: "32px"
                                        }
                                    }}
                                />

                                <button onClick={getUserTraffic}>Alkalmaz</button>
                            </div>
                        )}
                    />
                </div>

                <div>
                    <div className={DailyUserStyle.content}>
                        <div className={DailyUserStyle.title_row}>
                            <div className={DailyUserStyle.title_info}>
                                <span className={DailyUserStyle.title}>Felhasználó forgalom / nap</span>
                                <span className={DailyUserStyle.user_name}>{data.name}</span>
                            </div>

                            <div className={DailyUserStyle.user_compare}>
                                <Select
                                    value={newUser}
                                    onChange={(e) => setNewUser(e.target.value)}
                                    style={{height: "32px"}}
                                >
                                    <MenuItem value={0}>Felhasználó</MenuItem>
                                    
                                    {users.map(item => {
                                        return (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.last_name+" "+item.first_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>

                                <button
                                    onClick={handleGetCompareInfo}
                                    disabled={!isClickable}
                                >
                                    Hozzáadás
                                </button>
                            </div>
                        </div>

                        <div className={DailyUserStyle.filter_bar}>
                            {lines.map(item => {
                                return (
                                    <ChartFilter
                                        key={item.id}
                                        name={item.name}
                                        colorCode={item.color}
                                        active={item.show}
                                        click={() => handleFilter(item)}
                                    />
                                );
                            })}
                        </div>

                        <div className={DailyUserStyle.users}>
                            {userLines.map(item => {
                                return (
                                    <FilterItem
                                        key={item[0].user}
                                        type="chart"
                                        text="Felhasználó"
                                        items={item[0].name}
                                        onRemove={() => handleRemoveUser(item)}
                                        visible={true}
                                    />
                                );
                            })}
                        </div>

                        <div className={DailyUserStyle.charts}>
                            <div className={DailyUserStyle.line_chart}>
                                {lineChartMemo}
                            </div>

                            <div className={DailyUserStyle.pie_chart}>
                                <ReactEcharts
                                    option={{
                                        series: [
                                            {
                                                name: "Felhasználó forgalom / adó",
                                                type: "pie",
                                                data: [...pieInfo.map(item => ({
                                                    name: item.vat,
                                                    value: lines[0].show ? item.sum_traffic : lines[1].show ? item.sum_profit : item.sum_quantity
                                                }))]
                                            }
                                        ],
                                        tooltip: {
                                            trigger: "item",
                                            valueFormatter: (val) => {
                                                if(lines[2].show) return val+" db";

                                                return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                                            }
                                        },
                                        grid: {
                                            containLabel: true
                                        }
                                    }}
                                    style={{width: "100%", height: "100%"}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyUser;