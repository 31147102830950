import { TableCell, TableRow } from "@mui/material";

const ItemizedItem = ({ line, id }) => {
    return (
        <TableRow hover>
            <TableCell>{id}</TableCell>
            <TableCell>{line.itemName}</TableCell>
            <TableCell>{line.quantity+" "+(line.unit ? line.unit : "DB")}</TableCell>
            <TableCell>{Math.round(line.unitPrice)} HUF</TableCell>
            <TableCell>{Math.round(line.quantity*line.unitPrice)} HUF</TableCell>
        </TableRow>
    );
};

export default ItemizedItem;