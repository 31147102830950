import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";

const SummaryManageSafe = ({ id, safeItem, log, serialNum, comment }) => {
    const {setMsg, pcs} = useContext(DataContext);

    const [logData, setLogData] = useState({
        sum: 0
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log) {
            getLog();
        }
        else {
            getPackage();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/safe/logid/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setLogData(data.log);
    };

    const getPackage = async() => {
        const res = await fetch(apiUrl+"/safe/package/"+safeItem, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setLogData(data.pack);
    };

    const renderManageSafe = () => {
        if(log) {
            return (
                <table className={SummaryStyle.summary_table}>
                    <tbody>
                        <tr>
                            <td>Dátum</td>
                            <th>{logData.date}</th>
                        </tr>
                        <tr>
                            <td>Előző sorozatszám</td>
                            <th>{logData.prev_serial}</th>
                        </tr>
                        <tr>
                            <td>Új sorozatszám</td>
                            <th>{logData.new_serial}</th>
                        </tr>
                        <tr>
                            <td>Előző összeg</td>
                            <th>
                                {logData.prev_sum} HUF
                            </th>
                        </tr>
                        <tr>
                            <td>Új összeg</td>
                            <th>
                                {logData.new_sum} HUF
                            </th>
                        </tr>
                        <tr>
                            <td>Megjegyzés</td>
                            <th>{logData.comment}</th>
                        </tr>
                    </tbody>
                </table>
            );
        }
        else {
            return (
                <table className={SummaryStyle.summary_table}>
                    <tbody>
                        <tr>
                            <td>Előző sorozatszám</td>
                            <th>{logData.serial_num}</th>
                        </tr>
                        <tr>
                            <td>Új sorozatszám</td>
                            <th>{serialNum}</th>
                        </tr>
                        <tr>
                            <td>Előző összeg</td>
                            <th>
                                {logData.sum} HUF
                            </th>
                        </tr>
                        <tr>
                            <td>Új összeg</td>
                            <th>
                                {pcs.sum} HUF
                            </th>
                        </tr>
                        <tr>
                            <td>Megjegyzés</td>
                            <th>{comment}</th>
                        </tr>
                    </tbody>
                </table>
            );
        }
    };

    return renderManageSafe();
};

export default SummaryManageSafe;