import { useContext, useEffect, useState } from "react";
import axios from "axios";

import { DataContext } from "../../context/DataContext";

import { Grid } from "@mui/material";

import PackageDialog from "./content/transit/PackageDialog";
import InfoDialog from "./content/transit/InfoDialog";
import ImageDialog from "./content/transit/ImageDialog";
import SummaryDialog from "./content/transit/SummaryDialog";

const TransitSafe = () => {
    const {user, setMsg, setOpenOper, setLoading} = useContext(DataContext);

    const [packages, setPackeges] = useState([]);
    const [choosen, setChoosen] = useState([]);

    const [serialNum, setSerialNum] = useState("");
    const [transitSerial, setTransitSerial] = useState("");
    const [comment, setComment] = useState("");

    const [transitImage, setTransitImage] = useState();

    const [packageDialog, setPackageDialog] = useState(true);
    const [infoDialog, setInfoDialog] = useState(false);
    const [imageDialog, setImageDialog] = useState(false);
    const [summaryDialog, setSummaryDialog] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getPackeges();
    }, []);

    const getPackeges = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/safe/"+user.store, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPackeges(data.safe.filter(item => item.transit_pack === null));

        setLoading(false);
    };

    const handleAddItem = (p) => {
        setChoosen(prevChoosen => [...prevChoosen, p]);
    };

    const handleRemoveItem = (p) => {
        setChoosen(prevChoosen => prevChoosen.filter(item => item.id !== p.id));
    };

    const handlePackInfo = async() => {
        if(choosen.length === 0){
            return setMsg({
                color: "#F58E8E",
                message: "Válaszd ki a szállítandó csomagokat"
            });
        }

        setMsg({});
        setPackageDialog(false);
        setInfoDialog(true);
    };

    const handleImage = () => {
        setInfoDialog(false);
        setImageDialog(true);
    };

    const handleSummary = () => {
        setImageDialog(false);
        setSummaryDialog(true);
    };

    const handleTransit = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/safe/transit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                packeges: choosen,
                serialNum: serialNum,
                transitSerial: transitSerial,
                comment: comment
            })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        uploadImage(data.pack);
    };

    const uploadImage = async(pack) => {
        const file = new FormData();
        file.append("file", transitImage);

        axios.post(apiUrl+"/safe/image/transit/"+pack, file)
        .then(res => {
            setLoading(false);

            setMsg({
                color: "success",
                message: "Sikeres művelet!"
            });

            setOpenOper(0);
        });
    };

    return (
        <Grid container>
            <PackageDialog
                open={packageDialog}
                onClose={() => setOpenOper(0)}
                packages={packages}
                onChoose={handleAddItem}
                onRemove={handleRemoveItem}
                onNext={handlePackInfo}
            />

            <InfoDialog
                open={infoDialog}
                serialNum={serialNum}
                setSerialNum={setSerialNum}
                transitSerial={transitSerial}
                setTransitSerial={setTransitSerial}
                comment={comment}
                setComment={setComment}
                onClose={() => {
                    setInfoDialog(false);
                    setPackageDialog(true);
                }}
                onNext={handleImage}
            />

            <ImageDialog
                open={imageDialog}
                image={transitImage}
                setImage={setTransitImage}
                onClose={() => {
                    setImageDialog(false);
                    setInfoDialog(true);
                }}
                onNext={handleSummary}
            />

            <SummaryDialog
                open={summaryDialog}
                onClose={() => {
                    setSummaryDialog(false);
                    setImageDialog(true);
                }}
                packages={choosen}
                image={transitImage}
                serialNum={serialNum}
                transitSerial={transitSerial}
                comment={comment}
                onNext={handleTransit}
            />
        </Grid>
    );
};

export default TransitSafe;