import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, Grid, Paper, Typography } from "@mui/material";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import OpHeader from "../../../components/opheader/OpHeader";
import { getUserOperations } from "../../../services/Api";
import StoreItem from "./content/StoreItem";
import InviteVerify from "./InviteVerify";

const InviteStore = ({ email, rank, onBack }) => {
    const {user, setOpenOper, setMsg, setLoading} = useContext(DataContext);

    const [stores, setStores] = useState([]);
    const [verifyPage, setVerifyPage] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getStores();
    }, []);

    const getStores = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/company/stores/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setStores(data.stores.map(item => ({...item, active: false})));

        setLoading(false);
    };

    const handleNewStore = () => {
        getUserOperations(user.id).then(data => {
            if(data.filter(item => Number(item.operation) === 25).length === 0) {
                return setMsg({
                    color: "error",
                    message: "Nincs jogosultságod a művelethez!"
                });
            }
            else {
                setOpenOper(25);
            }
        });
    };

    const handleStoreSwitch = (store) => {
        setStores(prev => prev.map(item => {
            if(item.id === store.id) {
                return {...item, active: !item.active}
            }

            return item;
        }));
    };

    const handleSkip = () => {
        setStores([]);
        setVerifyPage(true);
    };

    const handleNext = () => {
        setVerifyPage(true);
    };

    const renderVerify = () => {
        if(verifyPage) {
            return (
                <InviteVerify
                    email={email}
                    rank={rank}
                    stores={stores.filter(item => item.active)}
                    onBack={() => setVerifyPage(false)}
                />
            );
        }
    };

    return (
        <>
            {renderVerify()}

            {!verifyPage &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={29}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component={Paper}
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            padding={2}
                        >
                            <Typography fontSize={16} fontWeight={500} color="#6489E2">
                                Eladótér jogosultságok kiválasztása
                            </Typography>

                            <Button
                                size="small"
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={handleNewStore}
                                sx={{
                                    color: "#6489E2",
                                    alignSelf: "flex-start"
                                }}
                            >
                                Új telephely rögzítése
                            </Button>

                            <Typography fontSize={12} color="#737373">
                                Add meg a meghívandó felhasználó telephely jogosultságait
                            </Typography>

                            <Grid container sx={{ marginTop: 5 }}>
                                {stores.map(item => {
                                    return (
                                        <StoreItem
                                            key={item.id}
                                            store={item}
                                            onSwitch={() => handleStoreSwitch(item)}
                                        />
                                    );
                                })}
                            </Grid>

                            <Box
                                display="flex"
                                flexDirection="row"
                                gap={2}
                                alignSelf="flex-end"
                            >
                                <Button
                                    variant="outlined"
                                    onClick={handleSkip}
                                    sx={{
                                        color: "#737373",
                                        borderColor: "#737373",
                                        alignSelf: "flex-start",
                                        borderRadius: 6
                                    }}
                                >
                                    Kihagyom
                                </Button>

                                <Button
                                    variant="outlined"
                                    onClick={handleNext}
                                    sx={{
                                        color: "#6489E2",
                                        alignSelf: "flex-start",
                                        borderRadius: 6
                                    }}
                                >
                                    Tovább
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default InviteStore;