import { Box, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material";

import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const ValueFilter = ({ values, onFrom, onTo, onRemove }) => {
    return (
        <Stack spacing={1}>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>Érték</Typography>
                <IconButton
                    disabled={values.from === "" && values.to === ""}
                    onClick={onRemove}
                >
                    <FilterAltOffIcon />
                </IconButton>
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                gap={1}
            >
                <OutlinedInput
                    value={values.from}
                    onChange={onFrom}
                    size="small"
                    endAdornment={
                        <InputAdornment>HUF</InputAdornment>
                    }
                />

                <OutlinedInput
                    value={values.to}
                    onChange={onTo}
                    size="small"
                    endAdornment={
                        <InputAdornment>HUF</InputAdornment>
                    }
                />
            </Box>
        </Stack>
    );
};

export default ValueFilter;