import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";
import { getStoreStatus } from "../../services/Api";

import OpsHeader from "../../layouts/opsheader/OpsHeader";

import Feedback from "../../components/feedback/Feedback";
import Summary from "../../components/summary/Summary";

const Log = () => {
    const {user, msg, loading, setLoading} = useContext(DataContext);

    const location = useLocation();

    const [info] = useState(location.state);

    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <div style={{backgroundColor: "#FFFFFF"}}>
                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={info.operation}
                    store={params.store}
                />

                <div>
                    <div>
                        <Summary
                            info={info}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Log;