import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../../context/DataContext";
import { TableCell, TableRow, Typography } from "@mui/material";
import CurrencyText from "../../../../../components/currencytext/CurrencyText";

const DeliveryItem = ({ id, row, selected }) => {
    const {user, setLoading, setMsg} = useContext(DataContext);

    const [prices, setPrices] = useState({
        oldPrice: 0,
        newPrice: 0
    })

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getPrice();
    }, []);

    const getPrice = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/product/pricechange/last", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                barcode: row.barcode
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPrices({
            oldPrice: data.priceChange[0].old_price,
            newPrice: data.priceChange[0].new_price
        });

        setLoading(false);
    };

    return (
        <TableRow hover>
            <TableCell>{id}</TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.barcode}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.article_num}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12} color={selected && "red"}>{row.name}</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>{row.quantity} db</Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={prices.newPrice} /> Ft
                </Typography>
            </TableCell>

            <TableCell>
                <Typography fontSize={12}>
                    <CurrencyText val={prices.oldPrice} /> Ft
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default DeliveryItem;