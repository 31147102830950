import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";
import TicketItem from "./content/TicketItem";
import TrafficVerify from "./TrafficVerify";

const TrafficTickets = () => {
    const {user, setOpenOper, setLoading, setMsg} = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [row, setRow] = useState({
        type: "",
        quantity: ""
    });
    const [traffic, setTraffic] = useState([]);

    const [next, setNext] = useState(false);
    const [open, setOpen] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getTicketTypes();
        getTicketTraffic();
    }, []);

    const getTicketTypes = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/tickets", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTickets(data.tickets);
    };

    const getTicketTraffic = async() => {
        const res = await fetch(apiUrl+"/tickets/traffic/store/"+user.store, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTraffic(data.traffic);

        setLoading(false);
    };

    const handleAddRow = async() => {
        if(row.type === "" || row.quantity === "") {
            return setMsg({
                color: "error",
                message: "Minden mező kitöltése kötelező!"
            });
        }
        
        const res = await fetch(apiUrl+"/tickets/traffic/draft", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                row: row
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setRow({
            type: 0,
            quantity: ""
        });
        getTicketTraffic();
    };

    const handleDeleteRow = async(item) => {
        const res = await fetch(apiUrl+"/tickets/traffic/draft/delete/"+item.id, {
            method: "DELETE"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setOpen({});
        getTicketTraffic();
    };

    const handleEditRow = async(newItem) => {
        const res = await fetch(apiUrl+"/tickets/traffic/draft/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                newRow: newItem
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getTicketTraffic();
    };

    const handleNext = () => {
        if(traffic.length === 0) {
            return setMsg({
                color: "error",
                message: "Először adj hozzá elemeket"
            });
        }

        setNext(true);
    };

    const renderVerify = () => {
        if(next) {
            return (
                <TrafficVerify
                    onClose={() => setNext(false)}
                />
            );
        }
    };

    return (
        <>
            {renderVerify()}

            {!next &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={13}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead sx={{ bgcolor: "#F5F5F5" }}>
                                    <TableRow>
                                        <TableCell>Sorsjegy</TableCell>
                                        <TableCell>Mennyiség</TableCell>
                                        <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow>
                                        <TableCell width="65%">
                                            <FormControl size="small" sx={{ width: "100%" }}>
                                                <InputLabel id="select_label">Sorsjegy típus</InputLabel>

                                                <Select
                                                    value={row.type}
                                                    onChange={(e) => setRow(prevTick => ({...prevTick, type: e.target.value}))}
                                                    label="Sorsjegy típus"
                                                    labelId="select_label"
                                                >
                                                    {tickets.map(item => {
                                                        return (
                                                            <MenuItem
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </TableCell>

                                        <TableCell>
                                            <OutlinedInput
                                                type="number"
                                                value={row.quantity}
                                                onChange={(e) => {
                                                    if(e.target.value >= 0) {
                                                        setRow(prev => ({...prev, quantity: e.target.value}));
                                                    }
                                                }}
                                                size="small"
                                                fullWidth
                                            />
                                        </TableCell>

                                        <TableCell align="right">
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={handleAddRow}
                                            >
                                                + Hozzáadás
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead sx={{ bgcolor: "#F5F5F5" }}>
                                    <TableRow>
                                        <TableCell>Eladott / Felvezetett sorsjegyek</TableCell>
                                        <TableCell align="center">Mennyiség</TableCell>
                                        <TableCell>HUF / DB</TableCell>
                                        <TableCell>Érték</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {traffic.map(item => {
                                        return (
                                            <TicketItem
                                                key={item.id}
                                                item={item}
                                                tickets={tickets}
                                                onDelete={(ticket) => setOpen(ticket)}
                                                onEdit={handleEditRow}
                                            />
                                        );
                                    })}

                                    <Dialog
                                        open={Object.keys(open).length !== 0}
                                        onClose={() => setOpen({})}
                                    >
                                        <DialogTitle>
                                            Biztosan törlöd ezt a sort?
                                        </DialogTitle>

                                        <DialogContent>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                gap={3}
                                            >
                                                <Stack>
                                                    <Typography fontSize={14} fontWeight={400}>{open.ticket_name}</Typography>
                                                    <Typography fontSize={12} fontWeight={400} color="#8C8C8C">
                                                        {new Date(open.date).toLocaleString("hu-HU")}
                                                    </Typography>
                                                </Stack>

                                                <Typography fontSize={14} fontWeight={400}>{open.quantity} DB</Typography>

                                                <Typography fontSize={14} fontWeight={400}>{open.ticket_value} HUF</Typography>
                                            </Box>
                                        </DialogContent>

                                        <DialogActions>
                                            <Button variant="outlined" onClick={() => setOpen({})}>
                                                Mégsem
                                            </Button>

                                            <Button variant="contained" onClick={() => handleDeleteRow(open)}>
                                                Törlés
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </TableBody>
                            </Table>

                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                padding={1}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                >
                                    Tovább
                                </Button>
                            </Box>
                        </TableContainer>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default TrafficTickets;