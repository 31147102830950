import { useContext, useEffect, useState } from "react";

import { io } from "socket.io-client";

import { DataContext } from "../../../../context/DataContext";
import { getUserOperations } from "../../../../services/Api";

import { Box, Button, Divider, Grid, Typography } from "@mui/material";

import Operation from "../../content/Operation";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Permissions = ({ userInfo, onClose, onSave }) => {
    const {user, setLoading, setMsg} = useContext(DataContext);

    const [ops, setOps] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        getUserOperations(user.id).then(data => {
            getUserOperations(userInfo.id).then(userData => {
                setOps(data.filter(item => Number(item.company) === Number(user.company.id)).map(item => {
                    if(userData.filter(element => element.operation === item.operation).length > 0) {
                        return {...item, active: true};
                    }
                    else {
                        return {...item, active: false};
                    }
                }));
            });
        });

        setLoading(false);
    }, []);

    const handleChange = (op) => {
        setOps(prev => prev.map(item => {
            if(item.id === op.id) {
                return {...item, active: !item.active};
            }
            return item;
        }));
    };

    const handleSave = async() => {
        const res = await fetch(apiUrl+"/user/modify/permission", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                editUser: userInfo.id,
                company: user.company.id,
                ops: ops.filter(item => item.active)
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        socket.emit("user_perm", {id: userInfo.id});

        setMsg({
            color: "success",
            message: "Sikeres művelet!"
        });

        onSave();
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography fontSize={16} fontWeight={500}>Jogosultságok</Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            {ops.map((item, index) => {
                return (
                    <Operation
                        key={item.id}
                        op={item}
                        val={item.active}
                        onChange={handleChange}
                        timeout={index*400}
                    />
                );
            })}

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    justifyContent="flex-end"
                >
                    <Button variant="outlined" onClick={onClose}>Mégse</Button>
                    <Button variant="contained" onClick={handleSave}>Mentés</Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Permissions;