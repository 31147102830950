import { styled } from '@mui/material/styles';
import { Grid, ListItemButton, ListItemIcon, ListItemText, Switch, Typography } from "@mui/material";

import StoreIcon from '../../../../layouts/stores/content/StoreIcon';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
    width: 32,
    height: 16,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff'
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 12,
        height: 12
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500
        })
    }
}));

const StoreItem = ({ store, onSwitch }) => {
    return (
        <Grid item xs={12}>
            <ListItemButton>
                <ListItemIcon>
                    <StoreIcon store={store} />
                </ListItemIcon>

                <ListItemText
                    primary={
                        <Typography variant="subtitle1" color="#6A86C4">{store.name}</Typography>
                    }
                />

                <IOSSwitch
                    checked={store.active}
                    onChange={onSwitch}
                />
            </ListItemButton>
        </Grid>
    );
};

export default StoreItem;