import { Box, FormLabel, TextField } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';

const AddImage = ({ onUpload }) => {
    return (
        <>
            <FormLabel
                htmlFor="upload"
                sx={{
                    cursor: 'pointer'
                }}
            >
                <Box
                    height={100}
                    width={100}
                    bgcolor="#FAFAFA"
                    border="1px dashed #D9D9D9"
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <AddIcon />
                </Box>
            </FormLabel>

            <TextField
                type="file"
                id="upload"
                variant="outlined"
                sx={{ display: 'none' }}
                onChange={onUpload}
            />
        </>
    );
};

export default AddImage;