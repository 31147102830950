import { Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

import Tag from "./content/tag/Tag";

const ItemizedTags = ({ invoice, payments, costplaces, tags, onRefresh }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack
            component={Paper}
            spacing={1}
        >
            <Grid
                container
                spacing={0.8}
                paddingRight={2}
                sx={matches && { marginBottom: 2 }}
            >
                <Grid item>
                    <Tag
                        type="record"
                        invoice={invoice}
                        tagIcon={invoice.record_date ? <CalendarTodayOutlinedIcon /> : <ControlPointIcon />} 
                        tagLabel={invoice.record_date ? invoice.record_date : null}
                        tagTipLabel="Rögzítés dátuma"
                        onRefresh={onRefresh}
                    />
                </Grid>

                <Grid item>
                    <Tag
                        type="delivery"
                        invoice={invoice}
                        tagIcon={invoice.delivery_date ? <CalendarTodayOutlinedIcon /> : <ControlPointIcon />} 
                        tagLabel={invoice.delivery_date ? invoice.delivery_date : null}
                        tagTipLabel="Teljesítés dátuma"
                        onRefresh={onRefresh}
                    />
                </Grid>

                <Grid item>
                    <Tag
                        type="deadline"
                        invoice={invoice}
                        tagIcon={invoice.payment_deadline ? <CalendarTodayOutlinedIcon /> : <ControlPointIcon />} 
                        tagLabel={invoice.payment_deadline ? invoice.payment_deadline : null}
                        tagTipLabel="Fizetési határidő"
                        onRefresh={onRefresh}
                    />
                </Grid>

                <Grid item>
                    <Tag
                        type="payment"
                        invoice={invoice}
                        payments={payments}
                        tagIcon={<AddCardOutlinedIcon/>}
                        tagLabel={invoice.payment_name ? invoice.payment_name : null}
                        tagTipLabel="Fizetési mód"
                        onRefresh={onRefresh}
                    />
                </Grid>

                <Grid item>
                    <Tag
                        type="costplace"
                        invoice={invoice}
                        costplaces={costplaces}
                        tagIcon={<PushPinOutlinedIcon />}
                        tagLabel={invoice.costplace_name ? invoice.costplace_name : null}
                        tagTipLabel="Költséghely"
                        onRefresh={onRefresh}
                    />
                </Grid>

                <Grid item marginLeft="auto">
                    <Typography fontSize={20} fontWeight={500}>{invoice.serial_num}</Typography>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={0.8}
                paddingBottom={1}
            >
                <Grid item>
                    <Tag
                        type="newtag"
                        invoice={invoice}
                        tagIcon={<LocalOfferOutlinedIcon />}
                        tagLabel={null}
                        tags={tags}
                        tagTipLabel="+ címke"
                        onRefresh={onRefresh}
                    />
                </Grid>

                {invoice.tags?.map(item => {
                    return (
                        <Grid item>
                            <Tag
                                type="tag"
                                invoice={invoice}
                                tagId={item.id}
                                tagIcon={<LocalOfferOutlinedIcon />}
                                tagLabel={item.name}
                                tags={tags}
                                tagTipLabel={null}
                                onRefresh={onRefresh}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Stack>
    );
};

export default ItemizedTags;