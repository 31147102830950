import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

const NavItem = ({ name, icon, open, click }) => {
    return (
        <ListItemButton
            onClick={click}
            sx={{
                paddingLeft: 3,
                ...(!open ? {
                    marginBottom: 2
                } : {})
            }}
        >
            <ListItemIcon>
                {icon}
            </ListItemIcon>

            {open ?
                <ListItemText secondary={name} />
            : null}
        </ListItemButton>
    );
};

export default NavItem;