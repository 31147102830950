import { Avatar, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import CurrencyText from "../../../../../../components/currencytext/CurrencyText";

const ArrangedItem = ({ row }) => {
    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <TableRow>
            <TableCell
                sx={{
                    fontSize: 11,
                    fontWeight: 400
                }}
            >
                {row.transfer_date}
            </TableCell>

            <TableCell
                sx={{
                    fontSize: 11,
                    fontWeight: 400
                }}
            >
                {row.pack_name ? row.pack_name : "-"}
            </TableCell>

            <TableCell>
                <Tooltip
                    title={row.last_name+" "+row.first_name}
                    arrow
                >
                    <Avatar
                        src={imageExists(row.profile_pic) ? row.profile_pic : null}
                        sx={{ zIndex: 2, width: 32, height: 32 }}
                    />
                </Tooltip>
            </TableCell>

            <TableCell
                sx={{
                    fontSize: 11,
                    fontWeight: 400
                }}
            >
                {row.partner_name}
            </TableCell>

            <TableCell
                sx={{
                    fontSize: 11,
                    fontWeight: 400
                }}
            >
                {row.partner_bank ? row.partner_bank : "-"}
            </TableCell>

            <TableCell
                sx={{
                    fontSize: 11,
                    fontWeight: 400
                }}
            >
                <CurrencyText val={row.sum} /> Ft
            </TableCell>

            <TableCell
                sx={{
                    fontSize: 11,
                    fontWeight: 400,
                    color: "#8C8C8C"
                }}
            >
                {row.serial_num}
            </TableCell>

            <TableCell>
                <IconButton disabled>
                    <MoreHorizIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default ArrangedItem;