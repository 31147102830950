import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import XLSImg from "../../../assets/invoices/export_xls.svg";
import XMLImg from "../../../assets/invoices/export_xml.svg";
import PrintImg from "../../../assets/invoices/print.svg";

import OpHeader from "../../../components/opheader/OpHeader";
import ItemizedItem from "./content/ItemizedItem";
import ItemizedTags from "./content/ItemizedTags";
import ItemizedHeader from "./content/ItemizedHeader";
import FilingDialog from "./content/content/footer/content/FilingDialog";
import CurrencyText from "../../../components/currencytext/CurrencyText";

const InvoiceItemized = ({ invoice, payments, costplaces, tags, onRefresh, onClose }) => {
    const {user, setMsg, setLoading} = useContext(DataContext);

    const [invoicePayment, setInvoicePayment] = useState({});
    const [invoiceCostplace, setInvoiceCostplace] = useState({});
    const [invoiceTags, setInvoiceTags] = useState([]);

    const [invoiceItems, setInvoiceItems] = useState([]);

    const [open, setOpen] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setInvoiceItems([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

        getInvoice();
    }, []);

    useEffect(() => {
        getTags();
    }, [invoice]);

    const getTags = () => {
        getPaymentMethod();
        getInvoiceCostplace();
        getInvoiceTags();
    };

    const getPaymentMethod = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/get/payment/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoicePayment(data.payment);
    };

    const getInvoiceCostplace = async() => {
        const res = await fetch(apiUrl+"/outbound/get/costplace/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceCostplace(data.costplace);
    };

    const getInvoiceTags = async() => {
        const res = await fetch(apiUrl+"/outbound/get/tags/"+invoice.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceTags(data.tags);

        setLoading(false);
    };

    const getInvoice = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/itemized", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: user.company.nav_token,
                invoiceNum: invoice.serial_num
            })
        });

        const data = await res.json();

        if(!data.success){
            getTags();

            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoiceItems(data.invoice);

        setLoading(false);
    };

    const handleFiling = async() => {
        if(!invoice.payment_deadline || !invoice.payment_method || !invoice.costplace) {
            return setOpen(true);
        }

        setLoading(true);

        const res = await fetch(apiUrl+"/outbound/filing", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ serialNum: invoice.serial_num })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeresen rendezve"
        });
        setOpen(false);
        onRefresh();
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <OpHeader
                        operation={22}
                        onClose={onClose}
                    />

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                    >
                        <IconButton>
                            <img
                                src={XLSImg}
                                alt="xls"
                                style={{ width: 24, height: 24 }}
                            />
                        </IconButton>

                        <IconButton>
                            <img
                                src={XMLImg}
                                alt="xml"
                                style={{ width: 24, height: 24 }}
                            />
                        </IconButton>

                        <IconButton>
                            <img
                                src={PrintImg}
                                alt="print"
                                style={{ width: 24, height: 24 }}
                            />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <ItemizedTags
                    invoice={({
                        ...invoice,
                        ...invoicePayment,
                        ...invoiceCostplace,
                        tags: invoiceTags
                    })}
                    payments={payments}
                    costplaces={costplaces}
                    tags={tags}
                    onRefresh={() => {
                        getTags();
                        onRefresh();
                    }}
                />
            </Grid>

            <ItemizedHeader
                invoice={invoice}
            />

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <caption>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                gap={2}
                                color="#000"
                            >
                                <Typography fontSize={14} fontWeight={400}>
                                    Nettó összesen: <Typography fontWeight={600}><CurrencyText val={0} /> Ft</Typography>
                                </Typography>

                                <Typography fontSize={14} fontWeight={400}>
                                    Áfa összesen: <Typography fontWeight={600}><CurrencyText val={0} /> Ft</Typography>
                                </Typography>

                                <Typography fontSize={14} fontWeight={400}>
                                    Bruttó összesen: <Typography fontWeight={600}><CurrencyText val={0} /> Ft</Typography>
                                </Typography>
                            </Box>
                        </caption>

                        <TableHead>
                            <TableRow sx={{ bgcolor: "#FAFAFA" }}>
                                <TableCell sx={{ color: "#434343" }}>#</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Megnevezés</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Mennyiség</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Egységár</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Összesen</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {invoiceItems.map((item, index) => {
                                return (
                                    <ItemizedItem
                                        key={index}
                                        id={index+1}
                                        line={item}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12}>
                <Box
                    component={Paper}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    gap={1}
                    padding={1}
                    boxSizing="border-box"
                    width="100%"
                    height="100%"
                >
                    <Button variant="outlined" onClick={onClose}>Vissza</Button>
                    {!invoice.status && <Button variant="contained" onClick={handleFiling}>Rendezés</Button>}
                </Box>
            </Grid>

            <FilingDialog
                invoice={({
                    ...invoice,
                    ...invoicePayment,
                    ...invoiceCostplace,
                    tags: invoiceTags
                })}
                payments={payments}
                costplaces={costplaces}
                open={open}
                onClose={() => setOpen(false)}
                onVerify={handleFiling}
                onRefresh={onRefresh}
            />
        </Grid>
    );
};

export default InvoiceItemized;