import { useState } from "react";

import { Avatar, Box, FormControl, IconButton, MenuItem, OutlinedInput, Select, Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import CurrencyText from "../../../../components/currencytext/CurrencyText";

const TicketItem = ({ item, tickets, onDelete, onEdit }) => {
    const [edit, setEdit] = useState(false);
    const [newItem, setNewItem] = useState(item);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = item.profile_pic ? apiUrl+"/"+item.profile_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("GET", url, true);
        http.send();
    
        return http.status !== 404;
    };

    const handleEdit = () => {
        onEdit(newItem);
        setEdit(false);
    };

    if(edit) {
        return (
            <TableRow>
                <TableCell width="40%">
                    <FormControl size="small" sx={{ width: "100%" }}>
                        <Select
                            value={newItem.ticket}
                            onChange={(e) => setNewItem(prev => ({...prev, ticket: e.target.value}))}
                        >
                            {tickets.map(t => {
                                return (
                                    <MenuItem
                                        key={t.id}
                                        value={t.id}
                                    >
                                        {t.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell align="center">
                    <OutlinedInput
                        type="number"
                        value={newItem.quantity}
                        onChange={(e) => setNewItem(prev => ({...prev, quantity: e.target.value}))}
                        size="small"
                    />
                </TableCell>
                <TableCell>{item.ticket_value} HUF</TableCell>
                <TableCell>{item.quantity*item.ticket_value} HUF</TableCell>
                <TableCell align="center">
                    <IconButton onClick={handleEdit}>
                        <BorderColorOutlinedIcon />
                    </IconButton>
    
                    <IconButton onClick={() => onDelete(item)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
    else {
        return (
            <TableRow>
                <TableCell>
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        alignItems="center"
                    >
                        <Tooltip
                            title={item.last_name+" "+item.first_name}
                            arrow
                        >
                            <Avatar src={imageExists(avatar) ? avatar : null} />
                        </Tooltip>
    
                        <Stack>
                            <Typography fontSize={14} fontWeight={400}>{item.ticket_name}</Typography>
                            <Typography fontSize={12} fontWeight={400} color="#8C8C8C">
                                {new Date(item.date).toLocaleString("hu-HU")}
                            </Typography>
                        </Stack>
                    </Box>
                </TableCell>
                <TableCell align="center">{item.quantity}</TableCell>
                <TableCell>
                    <CurrencyText
                        val={item.ticket_value}
                    /> HUF
                </TableCell>
                <TableCell>
                    <CurrencyText
                        val={item.quantity*item.ticket_value}
                    /> HUF
                </TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => setEdit(true)}>
                        <BorderColorOutlinedIcon />
                    </IconButton>
    
                    <IconButton onClick={() => onDelete(item)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
};

export default TicketItem;