import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useDebounce from "../../../hooks/useDebounce";
import { DataContext } from "../../../context/DataContext";

import { Avatar, Box, Card, Grid, Tooltip, Typography } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';

import Apartman from "../../../layouts/apartmans/Apartman";

const Apartmans = ({ moduleInfo }) => {
    const {user, setMsg} = useContext(DataContext);

    const [aps, setAps] = useState([]);
    const [showAps, setShowAps] = useState([]);

    const [search, setSearch] = useState("");

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getApartmans();
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            setShowAps(aps.filter(item => rx.test(item.name)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, aps], 500);

    const getApartmans = async() => {
        const res = await fetch(apiUrl+"/apartmans/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setAps(data.aps);
    };

    return (
        <Grid
            container
            item
            xs={12}
            md={12}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography color="#000" fontWeight={500}>{moduleInfo.name}</Typography>
                </Grid>

                {showAps.map((item, i) => {
                    return (
                        <Apartman
                            key={i}
                            ap={item}
                        />
                    );
                })}

                <Grid item xs={12} md={4}>
                    <Tooltip onClick={() => navigate("/apartman/create")}>
                        <Card
                            variant="outlined"
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                cursor: "pointer",
                                opacity: 0.8,
                                ":hover": {
                                    opacity: 1
                                }
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                gap={2}
                                alignItems="center"
                            >
                                <Avatar sx={{ bgcolor: "#46A6FF" }}>
                                    <AddIcon />
                                </Avatar>

                                <Typography fontSize={12} color="#46A6FF">Új apartman létrehozás</Typography>
                            </Box>
                        </Card>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Apartmans;