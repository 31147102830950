import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const ImageDialog = ({ open, onClose, image }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <IconButton
                    onClick={onClose}
                    size="small"
                >
                    <ArrowBackIosIcon style={{ fontSize: 16 }} />
                </IconButton>

                <IconButton
                    size="small"
                >
                    <OpenInFullIcon style={{ fontSize: 16 }} />
                </IconButton>
            </Box>

            <DialogContent>
                <img
                    src={imageExists(image) ? image : null}
                    style={{ width: 400 }}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={onClose}
                >
                    Vissza
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageDialog;