import { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { DataContext } from '../../../../context/DataContext';
import { getUserCompanies } from '../../../../services/Api';

import { Box, Button, Divider, FormControl, Grid, InputAdornment, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Personal = ({ userInfo, onClose, onSave }) => {
    const {user, setLoading, setMsg} = useContext(DataContext);

    const [comps, setComps] = useState([]);
    const [ranks, setRanks] = useState([]);
    const [prefix, setPrefix] = useState([]);

    const [userRank, setUserRank] = useState(null);

    const [personal, setPersonal] = useState({
        id: userInfo.id,
        first: userInfo.first_name,
        last: userInfo.last_name,
        birthDay: dayjs(userInfo.birth_date),
        birthPlace: userInfo.place_of_birth,
        zip: userInfo.zip_code,
        city: userInfo.city,
        address: userInfo.address,
        phonePrefix: userInfo.phone_prefix,
        phoneNum: userInfo.phone_num,
        email: userInfo.email,
        bank1: userInfo.bank_account?.split("-")[0],
        bank2: userInfo.bank_account?.split("-")[1],
        bank3: userInfo.bank_account?.split("-")[2]
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        getUserRank();
        getCompanies();
        getRanks();
        getPrefix();

        getUserCompanies(userInfo.id).then(data => setPersonal(prev => ({...prev, company: data[0].id})));

        setLoading(false);
    }, []);

    const getUserRank = async() => {
        const res = await fetch(apiUrl+"/user/rank/"+user.id+"/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setUserRank(data.userRank);
    };

    const getCompanies = async() => {
        const res = await fetch(apiUrl+"/companies/user/"+user.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setComps(data.companies);
    };

    const getRanks = async() => {
        const res = await fetch(apiUrl+"/ranks", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setRanks(data.ranks);
    };

    const getPrefix = async() => {
        const res = await fetch(apiUrl+"/phone/prefix", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPrefix(data.prefixes);
    };

    const handleSave = async() => {
        if(Object.values(personal).some(prop => prop === "0" || prop === "")) {
            return setMsg({
                color: "error",
                message: "Minden mező kitöltése kötelező"
            });
        }

        const res = await fetch(apiUrl+"/user/modify/personal", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                company: user.company.id,
                profile: {
                    ...personal,
                    rank: userRank
                }
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres művelet!"
        });

        onSave();
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography fontSize={16} fontWeight={500}>Személyes adatok</Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    marginLeft="auto"
                    marginRight="auto"
                    justifyContent="space-between"
                    width={540}
                >
                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Jogkör</Typography>
                        <FormControl size="small" sx={{ width: 265 }}>
                            <Select
                                value={userRank}
                                onChange={(e) => setUserRank(e.target.value)}
                            >
                                {ranks.map(item => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    marginLeft="auto"
                    marginRight="auto"
                    justifyContent="space-between"
                    width={540}
                >
                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Vezetéknév</Typography>
                        <OutlinedInput
                            value={personal.last}
                            onChange={(e) => setPersonal(prev => ({...prev, last: e.target.value}))}
                            size="small"
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Keresztnév</Typography>
                        <OutlinedInput
                            value={personal.first}
                            onChange={(e) => setPersonal(prev => ({...prev, first: e.target.value}))}
                            size="small"
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    marginLeft="auto"
                    marginRight="auto"
                    justifyContent="space-between"
                    width={540}
                >
                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Születési idő</Typography>
                        <DatePicker
                            value={personal.birthDay}
                            onChange={(newVal) => setPersonal(prev => ({...prev, birthDay: newVal}))}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Születési hely</Typography>
                        <OutlinedInput
                            value={personal.birthPlace}
                            onChange={(e) => setPersonal(prev => ({...prev, birthPlace: e.target.value}))}
                            size="small"
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    marginLeft="auto"
                    marginRight="auto"
                    justifyContent="space-between"
                    width={540}
                >
                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Irányítószám</Typography>
                        <OutlinedInput
                            value={personal.zip}
                            onChange={(e) => setPersonal(prev => ({...prev, zip: e.target.value}))}
                            size="small"
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Város</Typography>
                        <OutlinedInput
                            value={personal.city}
                            onChange={(e) => setPersonal(prev => ({...prev, city: e.target.value}))}
                            size="small"
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="column"
                    marginLeft="auto"
                    marginRight="auto"
                    width={540}
                >
                    <Typography
                        fontSize={14}
                        fontWeight={400}
                        color="#8C8C8C"
                    >
                        Cím
                    </Typography>

                    <OutlinedInput
                        value={personal.address}
                        onChange={(e) => setPersonal(prev => ({...prev, address: e.target.value}))}
                        fullWidth
                        size="small"
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    marginLeft="auto"
                    marginRight="auto"
                    justifyContent="space-between"
                    width={540}
                >
                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Telefonszám</Typography>
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={1}
                        >
                            <FormControl size="small" sx={{ width: 80 }}>
                                <Select
                                    value={personal.phonePrefix}
                                    onChange={(e) => setPersonal(prev => ({...prev, phonePrefix: e.target.value}))}
                                >
                                    {prefix.map(item => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>{item.prefix}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <OutlinedInput
                                value={personal.phoneNum}
                                onChange={(e) => setPersonal(prev => ({...prev, phoneNum: e.target.value}))}
                                size="small"
                                sx={{
                                    width: 180
                                }}
                            />
                        </Box>
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Email cím</Typography>
                        <OutlinedInput
                            value={personal.email}
                            onChange={(e) => setPersonal(prev => ({...prev, email: e.target.value}))}
                            size="small"
                            startAdornment={
                                <InputAdornment position="start">
                                    <MailOutlineIcon />
                                </InputAdornment>
                            }
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    marginLeft="auto"
                    marginRight="auto"
                    justifyContent="space-between"
                    width={540}
                >
                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Bankszámlaszám</Typography>
                        <OutlinedInput
                            value={personal.bank1}
                            onChange={(e) => setPersonal(prev => ({...prev, bank1: e.target.value}))}
                            size="small"
                            sx={{
                                width: 175
                            }}
                        />
                    </Stack>
                    
                    <Stack alignSelf="flex-end">
                        <OutlinedInput
                            value={personal.bank2}
                            onChange={(e) => setPersonal(prev => ({...prev, bank2: e.target.value}))}
                            size="small"
                            sx={{
                                width: 175
                            }}
                        />
                    </Stack>
                    

                    <Stack alignSelf="flex-end">
                        <OutlinedInput
                            value={personal.bank3}
                            onChange={(e) => setPersonal(prev => ({...prev, bank3: e.target.value}))}
                            size="small"
                            sx={{
                                width: 175
                            }}
                        />
                    </Stack>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    justifyContent="flex-end"
                >
                    <Button variant="outlined" onClick={onClose}>Mégse</Button>
                    <Button variant="contained" onClick={handleSave}>Mentés</Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Personal;