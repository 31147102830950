import { useEffect, useState } from "react";

import { Button, Drawer, Stack, Typography } from "@mui/material";

import { motion } from 'framer-motion';

import ProfileFilter from "./content/ProfileFilter";
import StoreFilter from "./content/StoreFilter";
import DateFilter from "./content/DateFilter";
import ValueFilter from "./content/ValueFilter";

const Filters = ({ open, onClose, tickets, onFilter, isPack }) => {
    const [filterProfiles, setFilterProfiles] = useState([]);
    const [filterStores, setFilterStores] = useState([]);
    const [filterDate, setFilterDate] = useState({
        from: null,
        to: null
    });
    const [filterValue, setFilterValue] = useState({
        from: "",
        to: ""
    });

    useEffect(() => {
        getProfiles();
        getStores();
    }, [tickets]);

    const getProfiles = () => {
        const arr = tickets.reduce((unique, obj) => {
            if(!unique.some(item => item.uid === obj.uid)) {
                unique.push(obj);
            }

            return unique;
        }, []);

        setFilterProfiles(arr.map(item => ({...item, selected: false})));
    };

    const getStores = () => {
        const arr = tickets.reduce((unique, obj) => {
            if(!unique.some(item => item.sid === obj.sid)) {
                unique.push(obj);
            }

            return unique;
        }, []);

        setFilterStores(arr.map(item => ({...item, selected: false})));
    };

    const handleProfileClick = (profile) => {
        setFilterProfiles(prev => prev.map(item => {
            if(item.uid === profile.uid) {
                return {...item, selected: !item.selected};
            }
            return item;
        }));
    };

    const handleStoreClick = (store) => {
        setFilterStores(prev => prev.map(item => {
            if(item.sid === store.sid) {
                return {...item, selected: !item.selected};
            }
            return item;
        }));
    };

    const handleFilter = () => {
        onFilter({
            profiles: filterProfiles.filter(item => item.selected).map(item => item.uid),
            stores: filterStores.filter(item => item.selected).map(item => item.sid),
            dates: filterDate,
            values: filterValue
        })
    };

    const handleRemoveProfiles = () => {
        setFilterProfiles(prev => prev.map(item => ({...item, selected: false})));
    };

    const handleRemoveStores = () => {
        setFilterStores(prev => prev.map(item => ({...item, selected: false})));
    };

    const handleRemoveDates = () => {
        setFilterDate({
            from: null,
            to: null
        });
    };

    const handleRemoveValues = () => {
        setFilterValue({
            from: "",
            to: ""
        });
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => onClose({
                profiles: filterProfiles.filter(item => item.selected).map(item => item.uid),
                stores: filterStores.filter(item => item.selected).map(item => item.sid),
                dates: filterDate,
                values: filterValue
            })}
            sx={{ zIndex: 1300 }}
            PaperProps={{
                sx: {
                    width: "30%",
                    p: 1,
                    boxSizing: "border-box"
                }
            }}
        >
            <Stack spacing={3}>
                <Typography
                    fontSize={16}
                    fontWeight={600}
                    textAlign="center"
                >
                    Szűrők
                </Typography>

                <ProfileFilter
                    profiles={filterProfiles}
                    onClick={handleProfileClick}
                    onRemove={handleRemoveProfiles}
                />

                {!isPack &&
                    <StoreFilter
                        stores={filterStores}
                        onClick={handleStoreClick}
                        onRemove={handleRemoveStores}
                    />
                }

                <DateFilter
                    dates={filterDate}
                    onFrom={(newVal) => setFilterDate(prev => ({...prev, from: newVal}))}
                    onTo={(newVal) => setFilterDate(prev => ({...prev, to: newVal}))}
                    onRemove={handleRemoveDates}
                />

                <ValueFilter
                    values={filterValue}
                    onFrom={(e) => setFilterValue(prev => ({...prev, from: e.target.value}))}
                    onTo={(e) => setFilterValue(prev => ({...prev, to: e.target.value}))}
                    onRemove={handleRemoveValues}
                />
            </Stack>

            <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{ marginTop: "auto", padding: 2 }}
            >
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleFilter}
                    fullWidth
                >
                    Mentés
                </Button>
            </motion.div>
        </Drawer>
    );
};

export default Filters;