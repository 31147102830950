import { useContext, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { FormControl, IconButton, MenuItem, OutlinedInput, Select, TableCell, TableRow } from "@mui/material";

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const TicketItem = ({ item, tickets, onDelete, onEdit }) => {
    const {setMsg} = useContext(DataContext);

    const [edit, setEdit] = useState(false);
    const [newItem, setNewItem] = useState(item);

    const handleEdit = () => {
        if(newItem.type === 0 || newItem.from === "" || newItem.to === "") {
            return setMsg({
                color: "error",
                message: "Minden mező kitöltése kötelező"
            });
        }

        onEdit(newItem);
        setEdit(false);
    };

    const handleChange = (e) => {
        setNewItem(prevItem => ({
            ...prevItem,
            type: e.target.value,
            name: tickets.filter(t => t.id === Number(e.target.value))[0].name
        }));
    };

    const renderQuantity = () => {
        const from = item.from.toString().split("-");
        const to = item.to.toString().split("-");

        return (Number(to[to.length-1]) - Number(from[from.length-1])) + 1;
    };

    if(edit){
        return (
            <TableRow>
                <TableCell width={220}>
                    <FormControl size="small" sx={{ width: 220 }}>
                        <Select
                            value={newItem.type}
                            onChange={handleChange}
                        >
                            {tickets.map(t => {
                                return (
                                    <MenuItem
                                        key={t.id}
                                        value={t.id}
                                    >
                                        {t.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </TableCell>

                <TableCell>
                    <OutlinedInput
                        value={newItem.from}
                        onChange={(e) => setNewItem(prevItem => ({...prevItem, from: e.target.value}))}
                        size="small"
                        fullWidth
                    />
                </TableCell>

                <TableCell>
                    <OutlinedInput
                        value={newItem.to}
                        onChange={(e) => setNewItem(prevItem => ({...prevItem, to: e.target.value}))}
                        size="small"
                        fullWidth
                    />
                </TableCell>

                <TableCell>{renderQuantity()}</TableCell>

                <TableCell align="right">
                    <IconButton onClick={handleEdit}>
                        <BorderColorOutlinedIcon />
                    </IconButton>

                    <IconButton onClick={() => onDelete(item)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
    else {
        return (
            <TableRow>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.from}</TableCell>
                <TableCell>{item.to}</TableCell>
                <TableCell>{renderQuantity()}</TableCell>
                <TableCell align="right">
                    <IconButton onClick={() => setEdit(true)}>
                        <BorderColorOutlinedIcon />
                    </IconButton>

                    <IconButton onClick={() => onDelete(item)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
    
};

export default TicketItem;