import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { io } from "socket.io-client";

import useDebounce from "../../hooks/useDebounce";
import { DataContext } from "../../context/DataContext";
import { getUserOperations } from "../../services/Api";

import { Backdrop, Box, CircularProgress, Container, Grid, InputAdornment, OutlinedInput, Tab, Tabs, Toolbar, Typography, tabsClasses } from "@mui/material";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SavingsIcon from '@mui/icons-material/Savings';
import LightModeIcon from '@mui/icons-material/LightMode';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AppsIcon from '@mui/icons-material/Apps';

import Dashboard from "../../layouts/dashboard/Dashboard";
import ShopItems from "./content/ShopItems";
import Feedback from "../../components/feedback/Feedback";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: "100%" }}
        >
            {value === index && children}
        </div>
    );
};

const ShopArea = () => {
    const {user, loading, setLoading, scrollRef, setScroll, openOper, opComps, msg, setMsg} = useContext(DataContext);

    const [search, setSearch] = useState("");
    const [ops, setOps] = useState([]);
    const [filteredOps, setFilteredOps] = useState([]);
    const [tab, setTab] = useState(0);

    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/");

        socket.emit("join", ["uid:"+user.id, "rid:"+user.rank]);

        if(user.store) {
            getUserOperations(user.id).then(data => {
                setOps(data.filter(item => Number(item.company) === Number(user.company.id)).filter(item => Number(item.visibility) === 2 || Number(item.visibility) === 3));
            });
        }
        else {
            getUserOperations(user.id).then(data => {
                setOps(data.filter(item => Number(item.company) === Number(user.company.id)).filter(item => Number(item.visibility) === 1 || Number(item.visibility) === 3));
            });
        }

        setLoading(false);
    }, [user]);

    useEffect(() => {
        socket.on("update_perm", () => {
            if(user.store) {
                getUserOperations(user.id).then(data => {
                    setOps(data.filter(item => Number(item.visibility) === 2 || Number(item.visibility) === 3));
                });
            }
            else {
                getUserOperations(user.id).then(data => {
                    setOps(data.filter(item => Number(item.visibility) === 1 || Number(item.visibility) === 3));
                });
            }
        });
    }, [socket]);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            setFilteredOps(ops.filter(item => rx.test(item.name) || rx.test(item.description)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, ops], 500);

    const renderOperation = () => {
        if(openOper !== 0) {
            return (
                <>
                    {opComps[openOper]}
                </>
            );
        }
    };

    return (
        <Dashboard>
            <Backdrop
                sx={{ color: '#fff', zIndex: 10000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {Object.keys(msg).length !== 0 &&
                <Feedback
                    color={msg.color}
                    message={msg.message}
                />
            }

            <Box
                component="main"
                flexGrow={1}
                height="100vh"
                overflow="scroll"
                sx={{
                    backgroundColor: (theme) => theme.palette.grey[100]
                }}
                ref={scrollRef}
                onScroll={(e) => setScroll(scrollRef.current.scrollTop)}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                    {renderOperation()}

                    {openOper === 0 ? 
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography color="#262626" variant="h6">
                                    {user.store ? "Eladótér" : "Home"}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Tabs
                                    variant="scrollable"
                                    scrollButtons
                                    value={tab}
                                    onChange={handleChange}
                                    sx={{
                                        ".MuiTabs-scrollButtons": {
                                            color: "#9F9F9F"
                                        },
                                        [`& .${tabsClasses.scrollButtons}`]: {
                                            '&.Mui-disabled': { opacity: 0.3 }
                                        }
                                    }}
                                >
                                    <Tab
                                        label="Összes"
                                        icon={<AppsIcon />}
                                        iconPosition="start"
                                        sx={{
                                            "&:hover": {
                                                color: "#2278CF"
                                            }
                                        }}
                                    />

                                    <Tab
                                        label="Kedvencek"
                                        icon={<FavoriteIcon />}
                                        iconPosition="start"
                                        sx={{
                                            "&:hover": {
                                                color: "#2278CF"
                                            }
                                        }}
                                    />

                                    <Tab
                                        label="Napi"
                                        icon={<LightModeIcon />}
                                        iconPosition="start"
                                        sx={{
                                            "&:hover": {
                                                color: "#2278CF"
                                            }
                                        }}
                                    />

                                    <Tab
                                        label="Pénztárgép"
                                        icon={<PointOfSaleIcon />}
                                        iconPosition="start"
                                        sx={{
                                            "&:hover": {
                                                color: "#2278CF"
                                            }
                                        }}
                                    />

                                    <Tab
                                        label="Széf"
                                        icon={<SavingsIcon />}
                                        iconPosition="start"
                                        sx={{
                                            "&:hover": {
                                                color: "#2278CF"
                                            }
                                        }}
                                    />

                                    <Tab
                                        label="Sorsjegyek"
                                        icon={<LocalActivityIcon />}
                                        iconPosition="start"
                                        sx={{
                                            "&:hover": {
                                                color: "#2278CF"
                                            }
                                        }}
                                    />

                                    <Tab
                                        label="Felhasználók"
                                        icon={<PeopleAltIcon />}
                                        iconPosition="start"
                                        sx={{
                                            "&:hover": {
                                                color: "#2278CF"
                                            }
                                        }}
                                    />
                                </Tabs>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={4}
                                display="flex"
                                alignItems="center"
                            >
                                <OutlinedInput
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Keresés"
                                    fullWidth
                                    size="small"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchOutlinedIcon style={{ width: 20 }} />
                                        </InputAdornment>
                                    }
                                    sx={{
                                        fontSize: 12,
                                        backgroundColor: "#FFFFFF"
                                    }}
                                />
                            </Grid>

                            <TabPanel value={tab} index={0}>
                                <ShopItems
                                    ops={filteredOps}
                                    onRefresh={() => {
                                        getUserOperations(user.id).then(data => {
                                            setOps(data);
                                        });
                                    }}
                                />
                            </TabPanel>

                            <TabPanel value={tab} index={1}>
                                <ShopItems
                                    ops={filteredOps.filter(item => item.favourite === 1)}
                                    onRefresh={() => {
                                        getUserOperations(user.id).then(data => {
                                            setOps(data);
                                        });
                                    }}
                                />
                            </TabPanel>

                            <TabPanel value={tab} index={2}>
                                <ShopItems
                                    ops={filteredOps.filter(item => item.group_id === 1)}
                                    onRefresh={() => {
                                        getUserOperations(user.id).then(data => {
                                            setOps(data);
                                        });
                                    }}
                                />
                            </TabPanel>

                            <TabPanel value={tab} index={3}>
                                <ShopItems
                                    ops={filteredOps.filter(item => item.group_id === 3)}
                                    onRefresh={() => {
                                        getUserOperations(user.id).then(data => {
                                            setOps(data);
                                        });
                                    }}
                                />
                            </TabPanel>

                            <TabPanel value={tab} index={4}>
                                <ShopItems
                                    ops={filteredOps.filter(item => item.group_id === 4)}
                                    onRefresh={() => {
                                        getUserOperations(user.id).then(data => {
                                            setOps(data);
                                        });
                                    }}
                                />
                            </TabPanel>

                            <TabPanel value={tab} index={5}>
                                <ShopItems
                                    ops={filteredOps.filter(item => item.group_id === 6)}
                                    onRefresh={() => {
                                        getUserOperations(user.id).then(data => {
                                            setOps(data);
                                        });
                                    }}
                                />
                            </TabPanel>

                            <TabPanel value={tab} index={6}>
                                <ShopItems
                                    ops={filteredOps.filter(item => item.group_id === 2)}
                                    onRefresh={() => {
                                        getUserOperations(user.id).then(data => {
                                            setOps(data);
                                        });
                                    }}
                                />
                            </TabPanel>
                        </Grid>
                    : null}
                </Container>
            </Box>
        </Dashboard>
    );
};

export default ShopArea;