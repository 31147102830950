import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import NewTicketVerifyStyle from "./NewTicketVerify.module.css";

import OpsHeader from "../../../../layouts/opsheader/OpsHeader";

import Feedback from "../../../../components/feedback/Feedback";
import SummaryNewTicket from "../../../../components/summary/summarynewticket/SummaryNewTicket";

const NewTicketVerify = () => {
    const {user, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const location = useLocation();

    const [ticket] = useState(location.state.ticket);
    const [prizes] = useState(location.state.prizes);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleBack = () => {
        navigate("/ticketinfo/prize/"+params.store, {state: {ticket: ticket, prizes: prizes}});
    };

    const handleNext = async() => {
        const res = await fetch(apiUrl+"/ticket/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                ticket: ticket,
                prizes: prizes
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet!"
        });

        navigate("/ticketinfo/manage/"+params.store, {state: {back: true}})
    };

    return (
        <div
            style={{backgroundColor: "#FFFFFF"}}
            ref={homeRef}
        >
            <div>
                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={18}
                    opsRef={homeRef}
                />
            </div>

            <div>
                <span className={NewTicketVerifyStyle.ops_ticket_text}>
                    Rögzíted az alábbi sorsjegyet?
                </span>
                
                <SummaryNewTicket
                    log={false}
                    info={ticket}
                />
            </div>
        </div>
    );
};

export default NewTicketVerify;