import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";

import { Grid } from "@mui/material";

import PackageDialog from "./content/cashin/PackageDialog";
import ListDialog from "./content/cashin/ListDialog";
import SummaryDialog from "./content/cashin/SummaryDialog";

const CashinSafe = () => {
    const {user, setMsg, setOpenOper, setLoading} = useContext(DataContext);

    const [packages, setPackeges] = useState([]);
    const [choosen, setChoosen] = useState([]);

    const [packageDialog, setPackageDialog] = useState(true);
    const [listDialog, setListDialog] = useState(false);
    const [summaryDialog, setSummaryDialog] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getPackeges();
    }, []);

    const getPackeges = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/safe/"+user.store, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPackeges(data.safe.filter(item => item.transit_pack));

        setLoading(false);
    };

    const handleAddItem = (p) => {
        setChoosen(prevChoosen => [...prevChoosen, p]);
    };

    const handleRemoveItem = (p) => {
        setChoosen(prevChoosen => prevChoosen.filter(item => item.id !== p.id));
    };

    const handlePackInfo = async() => {
        if(choosen.length === 0){
            return setMsg({
                color: "#F58E8E",
                message: "Válaszd ki a szállítandó csomagokat"
            });
        }

        setMsg({});
        setPackageDialog(false);
        setListDialog(true);
    };

    const handleSummary = () => {
        setListDialog(false);
        setSummaryDialog(true);
    };

    const handleCashin = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/safe/cashin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                packs: choosen
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setLoading(false);

        setMsg({
            color: "success",
            message: "Sikeres művelet!"
        });

        setOpenOper(0);
    };

    return (
        <Grid container>
            <PackageDialog
                open={packageDialog}
                onClose={() => setOpenOper(0)}
                packages={packages}
                onChoose={handleAddItem}
                onRemove={handleRemoveItem}
                onNext={handlePackInfo}
            />

            <ListDialog
                open={listDialog}
                onClose={() => {
                    setListDialog(false);
                    setPackageDialog(true);
                }}
                packages={choosen}
                onNext={handleSummary}
            />

            <SummaryDialog
                open={summaryDialog}
                onClose={() => {
                    setSummaryDialog(false);
                    setListDialog(true);
                }}
                packages={choosen}
                onNext={handleCashin}
            />
        </Grid>
    );
};

export default CashinSafe;