import { Box, TableCell, TableRow, Typography } from "@mui/material";

import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CurrencyText from "../../../../../../../components/currencytext/CurrencyText";

const VerifyItem = ({ line, id }) => {
    return (
        <TableRow hover>
            <TableCell>{id}</TableCell>
            <TableCell>{line.itemName}</TableCell>
            <TableCell>{line.quantity+" "+(line.unit ? line.unit : "DB")}</TableCell>
            <TableCell>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    <Typography fontSize={14}>
                        <CurrencyText
                            val={Math.round(line.unitPrice)}
                        /> HUF
                    </Typography>

                    {line.priceChange === 0 &&
                        <HorizontalRuleIcon style={{color: "orange"}} />
                    }

                    {line.priceChange === 1 &&
                        <KeyboardArrowUpIcon style={{ color: "red" }} />
                    }

                    {line.priceChange === 2 &&
                        <KeyboardArrowUpIcon style={{color: "red"}} />
                    }

                    {line.priceChange === 3 &&
                        <KeyboardArrowDownIcon style={{color: "green"}} />
                    }
                </Box>
            </TableCell>
            <TableCell>
                <CurrencyText
                    val={Math.round(line.quantity*line.unitPrice)}
                /> HUF
            </TableCell>
        </TableRow>
    );
};

export default VerifyItem;