import { motion } from 'framer-motion';

import { Avatar, Box, Button, Card, Grid, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Stack, Tooltip, Typography } from "@mui/material";

import ApIcon from "./content/ApIcon";

const Apartman = ({ ap, click, res }) => {
    if(res) {
        return (
            <ListItemButton sx={{ borderRadius: 3 }}>
                <ListItemAvatar>
                    <ApIcon ap={ap} />
                </ListItemAvatar>

                <ListItemText
                    primary={
                        <Typography variant="subtitle1" color="#262626">{ap.name}</Typography>
                    }
                />
                
                <ListItemSecondaryAction>
                    <Stack alignItems="flex-end">
                        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                            <Button
                                variant="contained"
                                onClick={() => click(ap)}
                                size="medium"
                            >
                                <Typography fontSize={12}>KIVÁLASZT</Typography>
                            </Button>
                        </motion.div>
                    </Stack>
                </ListItemSecondaryAction>
            </ListItemButton>
        );
    }
    else {
        return (
            <Grid item xs={12} md={4}>
                <Tooltip>
                    <Card
                        variant="outlined"
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            cursor: "pointer",
                            "&:hover": {
                                borderColor: ap.color_code,
                                color: ap.color_code,
                                "& .txt": {
                                    color: ap.color_code
                                },
                                "& .avatar": {
                                    bgcolor: ap.color_code
                                }
                            }
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={2}
                            alignItems="center"
                        >
                            <Avatar
                                className="avatar"
                                sx={{
                                    color: "#FFFFFF",
                                    bgcolor: "#979797",
                                    fontSize: 16,
                                    width: 40,
                                    height: 40
                                }}
                            >
                                {ap.name[0]?.toUpperCase()}
                            </Avatar>

                            <Box
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography className="txt" fontSize={14} color="#979797">{ap.name}</Typography>
                                <Typography className="txt" fontSize={12} color="#979797">{ap.address}</Typography>
                            </Box>
                        </Box>
                    </Card>
                </Tooltip>
            </Grid>
        );
    }
};

export default Apartman;