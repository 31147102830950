import { useState } from "react";

import { OutlinedInput } from "@mui/material";

const CurrencyInput = ({ size, endAdorment, val, change, ph, sx, fullWidth }) => {
    const [value, setValue] = useState({
        displayValue: "",
        actualValue: val
    });

    const handleChange = (e) => {
        const strNumber = e.target.value.replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        setValue({
            displayValue: strNumber,
            actualValue: strNumber.replace(/ /g, "")
        });

        change(strNumber.replace(/ /g, ""));
    };

    return (
        <OutlinedInput
            value={value.displayValue}
            size={size}
            fullWidth={fullWidth}
            placeholder={ph}
            endAdornment={endAdorment}
            onChange={handleChange}
            sx={sx}
        />
    );
};

export default CurrencyInput;