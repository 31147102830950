import { useRef, useState } from "react";

import { motion } from 'framer-motion';

import { Typography, Box, Button } from "@mui/material";

import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';

import FilingDialog from "./content/FilingDialog";
import StoreDialog from "./content/StoreDialog";
import NewPackDialog from "../transfer/NewPackDialog";
import OpsPopper from "./content/OpsPopper";

const InvoiceFooter = ({ invoice, payments, costplaces, onRefresh, onFiling, onIncome, onTransfer }) => {
    const [open, setOpen] = useState(false);
    const [opsOpen, setOpsOpen] = useState(false);
    const [storeOpen, setStoreOpen] = useState(false);
    const [packOpen, setPackOpen] = useState(false);

    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpsOpen(prev => !prev);
    };

    const handleClose = (e) => {
        if(anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }

        setOpsOpen(false);
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            padding={1}
            boxSizing="border-box"
        >
            {!invoice.status ?
                <>
                    <motion.div
                        whileHover={{ scale: 1.07 }}
                        whileTap={{ scale: 0.93 }}
                    >
                        <Button
                            onClick={() => {
                                if(!invoice.payment_deadline || !invoice.payment_method || !invoice.costplace) {
                                    return setOpen(true);
                                }
                                setStoreOpen(true);
                            }}
                            sx={{
                                width: "fit-content",
                                height: "fit-content",
                                color: "#FFF",
                                borderRadius: 1,
                                border: "1px solid var(--Polar-Green-4, #95DE64)",
                                background: "linear-gradient(180deg, #A0E373 0%, #77C157 100%)",
                                boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.40)"
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                gap={0.5}
                            >
                                <FileOpenOutlinedIcon style={{ fontSize: 12 }} />

                                <Typography
                                    fontSize={9}
                                    fontWeight={400}
                                >
                                    Iktatás / Beérkeztetés
                                </Typography>
                            </Box>
                        </Button>
                    </motion.div>

                    <StoreDialog
                        open={storeOpen}
                        onClose={() => setStoreOpen(false)}
                        invoice={invoice}
                        onVerify={onFiling}
                    />
                </>
            :
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                >
                    {invoice.status === 1 &&
                        <motion.div
                            whileHover={{ scale: 1.07 }}
                            whileTap={{ scale: 0.93 }}
                        >
                            <Button
                                onClick={onIncome}
                                sx={{
                                    width: "fit-content",
                                    height: "fit-content",
                                    color: "#FFF",
                                    borderRadius: 1,
                                    border: "1px solid var(--Polar-Green-4, #95DE64)",
                                    background: "linear-gradient(180deg, #A0E373 0%, #77C157 100%)",
                                    boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.40)"
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={0.5}
                                >
                                    <FileOpenOutlinedIcon style={{ fontSize: 12 }} />
                
                                    <Typography
                                        fontSize={9}
                                        fontWeight={400}
                                    >
                                        Bevételezés
                                    </Typography>
                                </Box>
                            </Button>
                        </motion.div>
                    }

                    {!invoice.transfer_id &&
                        <>
                            <motion.div
                                whileHover={{ scale: 1.07 }}
                                whileTap={{ scale: 0.93 }}
                            >
                                <Button
                                    onClick={() => setPackOpen(true)}
                                    sx={{
                                        width: "fit-content",
                                        height: "fit-content",
                                        color: "#FFF",
                                        borderRadius: 1,
                                        border: "1px solid var(--Polar-Green-4, #95DE64)",
                                        background: "linear-gradient(180deg, #A0E373 0%, #77C157 100%)",
                                        boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.40)"
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap={0.5}
                                    >
                                        <FileOpenOutlinedIcon style={{ fontSize: 12 }} />
                    
                                        <Typography
                                            fontSize={9}
                                            fontWeight={400}
                                        >
                                            Utalási listához
                                        </Typography>
                                    </Box>
                                </Button>
                            </motion.div>

                            <NewPackDialog
                                open={packOpen}
                                setOpen={setPackOpen}
                                transferInvs={[invoice]}
                                onRefresh={onRefresh}
                            />
                        </>
                    }
                </Box>
            }

            <FilingDialog
                invoice={invoice}
                payments={payments}
                costplaces={costplaces}
                open={open}
                onClose={() => setOpen(false)}
                onVerify={(store) => {
                    setOpen(false);
                    onFiling(store);
                }}
                onRefresh={onRefresh}
            />

            <motion.div
                whileHover={{ scale: 1.07 }}
                whileTap={{ scale: 0.93 }}
            >
                <Button
                    ref={anchorRef}
                    aria-controls={opsOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    sx={{
                        width: "fit-content",
                        height: "fit-content",
                        color: "#FFF",
                        borderRadius: 1,
                        border: "1px solid var(--Polar-Green-4, #95DE64)",
                        background: "linear-gradient(180deg, #A0E373 0%, #77C157 100%)",
                        boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.40)"
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={0.5}
                    >
                        <FileOpenOutlinedIcon style={{ fontSize: 12 }} />

                        <Typography
                            fontSize={9}
                            fontWeight={400}
                        >
                            Műveletek
                        </Typography>
                    </Box>
                </Button>
            </motion.div>

            <OpsPopper
                open={opsOpen}
                onClose={() => setOpsOpen(false)}
            />

            {/*
            <Popper
                open={opsOpen}
                anchorEl={anchorRef.current}
                transition
                placement="top-end"
                disablePortal={true}
                sx={{ zIndex: 100000 }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="menu-list-grow">
                                    <MenuItem>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            gap={0.5}
                                        >
                                            <HistoryIcon style={{ fontSize: 20, color: "#8C8C8C"}} />

                                            <Typography
                                                fontSize={12}
                                                fontWeight={300}
                                                color="#8C8C8C"
                                            >
                                                Számla történet
                                            </Typography>
                                        </Box>
                                    </MenuItem>

                                    <MenuItem>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            gap={0.5}
                                        >
                                            <PrintOutlinedIcon style={{ fontSize: 20, color: "#8C8C8C"}} />

                                            <Typography
                                                fontSize={12}
                                                fontWeight={300}
                                                color="#8C8C8C"
                                            >
                                                Nyomtatás
                                            </Typography>
                                        </Box>
                                    </MenuItem>

                                    <MenuItem>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            gap={0.5}
                                        >
                                            <FileDownloadOutlinedIcon style={{ fontSize: 20, color: "#8C8C8C"}} />

                                            <Typography
                                                fontSize={12}
                                                fontWeight={300}
                                                color="#8C8C8C"
                                            >
                                                Letöltés PDF
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            */}
        </Box>
    );
};

export default InvoiceFooter;