import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import ReactEcharts from "echarts-for-react";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import DailyProductStyle from "./DailyProduct.module.css";

import OpsHeader from "../../../../layouts/opsheader/OpsHeader";

import Feedback from "../../../../components/feedback/Feedback";
import ChartFilter from "../../../../components/charts/chartfilter/ChartFilter";
import FilterItem from "../../../../components/filteritem/FilterItem";

const DailyProduct = ({ data, onClose }) => {
    const {user, msg, setMsg, stores} = useContext(DataContext);

    const [loading, setLoading] = useState(false);

    const [barcode, setBarcode] = useState(data.barcode);
    const [dates, setDates] = useState({
        from: dayjs(data.from),
        to: dayjs(data.to)
    });
    const [productInfo, setProductInfo] = useState([]);
    const [showInfo, setShowInfo] = useState([]);

    const [currentBar, setCurrentBar] = useState([]);
    const [prevBar, setPrevBar] = useState([]);

    const [newBarcode, setNewBarcode] = useState("");
    const [isClickable, setIsClickable] = useState(true);
    const [otherProducts, setOtherProducts] = useState([]);
    const [showProducts, setShowProducts] = useState([]);

    const [trafficStores, setTrafficStores] = useState([]);
    const [lines, setLines] = useState([
        {
            id: 0,
            name: "Forgalom",
            color: "#5470C6",
            show: true
        },
        {
            id: 1,
            name: "Profit",
            color: "#5470C6",
            show: false
        },
        {
            id: 2,
            name: "Vásárlószám",
            color: "#5470C6",
            show: false
        }
    ]);

    const chartRef = useRef();

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const lineChartMemo = useMemo(() => {
        return (
            <ReactEcharts
                option={{
                    grid: {
                        containLabel: true,
                        left: "2%",
                        right: "2%",
                        top: "5%",
                        bottom: "18%"
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: showInfo.map(item => item.day)
                    },
                    yAxis: {
                        type: "value",
                        axisLabel: {
                            formatter: (val) => {
                                if(lines[2].show) {
                                    return val+" db";
                                }
                                else {
                                    return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                                }  
                            } 
                        }
                    },
                    dataZoom: [
                        {
                            show: true,
                            start: 0,
                            end: 100
                        }
                    ],
                    series: [
                        ...showProducts.map(product => ({
                            type: "line",
                            name: product[0].item_name,
                            symbolSize: 10,
                            lineStyle: {
                                color: "#C4C4C4"
                            },
                            itemStyle: {
                                color: "#C4C4C4"
                            },
                            data: [
                                ...product.map((item) => ({
                                    value: item.sum
                                }))
                            ]
                        })),
                        {
                            type: "line",
                            name: productInfo[0]?.item_name,
                            symbolSize: 10,
                            lineStyle: {
                                color: lines.filter(item => item.show)[0].color
                            },
                            itemStyle: {
                                color: lines.filter(item => item.show)[0].color
                            },
                            data: [
                                ...showInfo.map((item) => ({
                                    value: item.sum
                                }))
                            ]
                        }
                    ],
                    tooltip: {
                        trigger: "axis",
                        valueFormatter: (val) => {
                            if(lines[2].show) {
                                return val+" db";
                            }
                            else {
                                return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                            }  
                        } 
                    }
                }}
                style={{width: "100%", height: "100%"}}
            />
        );
    }, [showInfo, showProducts]);

    useEffect(() => {
        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getStores();
        getProductTraffic();
    }, []);

    useEffect(() => {
        if(productInfo.length !== 0) {
            const filtered = [];

            trafficStores.map(item => {
                if(item.show) {
                    filtered.push(...productInfo.filter(element => element.store === item.id));
                }

                return 0;
            });

            calcProductDaily(filtered);
        }
    }, [productInfo, lines, trafficStores]);

    useEffect(() => {
        const filtered = [];

        trafficStores.map(item => {
            if(item.show) {
                filtered.push(...otherProducts.filter(element => element[0]?.store === item.id));
            }

            return 0;
        });

        calcOtherProduct(filtered);
    }, [otherProducts, lines, trafficStores]);

    const getStores = () => {
        setTrafficStores(stores.map(item => {
            return {
                id: item.id,
                name: item.name,
                color: item.color_code_2,
                show: true
            };
        }));
    };

    const getProductTraffic = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/diadev/product", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                barcode: barcode,
                from: new Date(dates.from).toISOString("hu-HU").slice(0, 10),
                to: new Date(dates.to).toISOString("hu-HU").slice(0, 10)
            })
        });

        const fetchData = await res.json();

        if(!fetchData.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setProductInfo(fetchData.line);
        setCurrentBar(fetchData.bar.current);
        setPrevBar(fetchData.bar.prev);

        setLoading(false);
    };

    const calcProductDaily = (traffic) => {
        const dateGroup = traffic.reduce((group, x) => {
            (group[new Date(x["day"]).toISOString("hu-HU").slice(0, 10)] = group[new Date(x["day"]).toISOString("hu-HU").slice(0, 10)] || []).push(x);
            return group;
        }, {});

        let dateSum = [];

        if(lines[0].show) {
            dateSum = Object.keys(dateGroup).map(key => {
                const sum = dateGroup[key].reduce((acc, obj) => {
                    return acc + obj.sum_traffic;
                }, 0);
    
                return {
                    day: key,
                    sum: sum
                };
            });
        }
        else if(lines[1].show) {
            dateSum = Object.keys(dateGroup).map(key => {
                const sum = dateGroup[key].reduce((acc, obj) => {
                    return acc + obj.sum_profit;
                }, 0);
    
                return {
                    day: key,
                    sum: sum
                };
            });
        }
        else if(lines[2].show) {
            dateSum = Object.keys(dateGroup).map(key => {
                const sum = dateGroup[key].reduce((acc, obj) => {
                    return acc + obj.sum_quantity;
                }, 0);
    
                return {
                    day: key,
                    sum: sum
                };
            });
        }

        setShowInfo(dateSum);
    };

    const handleGetCompareInfo = async() => {
        if(otherProducts.filter(item => item[0]?.barcode === Number(newBarcode)).length !== 0) {
            return setMsg({
                color: "error",
                message: "Ez a termék már benne van a listában"
            });
        }

        setIsClickable(false);

        const res = await fetch(apiUrl+"/diadev/product", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                barcode: newBarcode,
                from: new Date(dates.from).toISOString("hu-HU").slice(0, 10),
                to: new Date(dates.to).toISOString("hu-HU").slice(0, 10)
            })
        });

        const fetchData = await res.json();

        if(!fetchData.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setOtherProducts(prevData => ([...prevData, fetchData.line]));

        setNewBarcode("");

        setIsClickable(true);
    };

    const handleRemoveProduct = (product) => {
        const newProducts = otherProducts.filter(item => item[0]?.barcode !== product[0]?.barcode);

        setOtherProducts(newProducts);
    };

    const calcOtherProduct = (products) => {
        const productsArr = [];

        products.map(traffic => {
            const dateGroup = traffic.reduce((group, x) => {
                (group[new Date(x["day"]).toISOString("hu-HU").slice(0, 10)] = group[new Date(x["day"]).toISOString("hu-HU").slice(0, 10)] || []).push(x);
                return group;
            }, {});
    
            let dateSum = [];
    
            if(lines[0].show) {
                dateSum = Object.keys(dateGroup).map(key => {
                    const sum = dateGroup[key].reduce((acc, obj) => {
                        return acc + obj.sum_traffic;
                    }, 0);
        
                    return {
                        item_name: dateGroup[key][0].item_name,
                        day: key,
                        sum: sum
                    };
                });
            }
            else if(lines[1].show) {
                dateSum = Object.keys(dateGroup).map(key => {
                    const sum = dateGroup[key].reduce((acc, obj) => {
                        return acc + obj.sum_profit;
                    }, 0);
        
                    return {
                        item_name: dateGroup[key][0].item_name,
                        day: key,
                        sum: sum
                    };
                });
            }
            else if(lines[2].show) {
                dateSum = Object.keys(dateGroup).map(key => {
                    const sum = dateGroup[key].reduce((acc, obj) => {
                        return acc + obj.sum_quantity;
                    }, 0);
        
                    return {
                        item_name: dateGroup[key][0].item_name,
                        day: key,
                        sum: sum
                    };
                });
            }
    
            return productsArr.push(dateSum);
        });

        setShowProducts(productsArr);
    };

    const handleBack = () => {
        //chartRef.current.className = HomeStyle.op_content_close;

        const timer = setTimeout(() => {
            setMsg({});
            onClose();
        }, 1000);
        return () => clearTimeout(timer);
    };

    const handleFilter = (line) => {
        const modifyArr = lines.map(item => {
            if(item.id === line.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: true
                };
            }
            
            return {
                id: item.id,
                name: item.name,
                color: item.color,
                show: false
            };
        });

        setLines(modifyArr);
    };

    const handleFilterStore = (store) => {
        const modifyArr = trafficStores.map(item => {
            if(item.id === store.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: !item.show
                };
            }
            
            return item;
        });

        setTrafficStores(modifyArr);
    };

    return (
        <div style={{overflow: "hidden"}}>
            <div
                style={{backgroundColor: "#FFFFFF", zIndex: "15"}}
                ref={chartRef}
            >
                <div>
                    {
                        Object.keys(msg).length !== 0 ?
                            <Feedback
                                color={msg.color}
                                message={msg.message}
                            />
                            :
                            <></>
                    }

                    <OpsHeader
                        operation={19}
                        opsRef={chartRef}
                        headerItem={(
                            <div className={DailyProductStyle.barcode_row}>
                                <DatePicker
                                    label="Dátum-tól"
                                    value={dates.from}
                                    onChange={(val) => setDates(prevDates => ({...prevDates, from: val}))}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: "32px"
                                        }
                                    }}
                                />

                                <DatePicker
                                    label="Dátum-ig"
                                    value={dates.to}
                                    onChange={(val) => setDates(prevDates => ({...prevDates, to: val}))}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: "32px"
                                        }
                                    }}
                                />

                                <TextField
                                    variant="outlined"
                                    label="Vonalkód"
                                    value={barcode}
                                    onChange={(e) => setBarcode(e.target.value)}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: "32px"
                                        },
                                        ".MuiFormLabel-root[data-shrink=false]": {
                                            top: "-10px"
                                        }
                                    }}
                                />

                                <button onClick={getProductTraffic}>Alkalmaz</button>
                            </div>
                        )}
                    />
                </div>

                <div>
                    <div className={DailyProductStyle.content}>
                        <div className={DailyProductStyle.title_row}>
                            <div className={DailyProductStyle.title_info}>
                                <span className={DailyProductStyle.title}>Termék forgalom / nap</span>
                                <span className={DailyProductStyle.product_name}>{productInfo[0]?.item_name}</span>
                            </div>

                            <div className={DailyProductStyle.product_compare}>
                                <TextField
                                    variant="outlined"
                                    label="Vonalkód"
                                    value={newBarcode}
                                    onChange={(e) => setNewBarcode(e.target.value)}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: "32px"
                                        },
                                        ".MuiFormLabel-root[data-shrink=false]": {
                                            top: "-10px"
                                        }
                                    }}
                                />

                                <button
                                    onClick={handleGetCompareInfo}
                                    disabled={!isClickable}
                                >
                                    Hozzáadás
                                </button>
                            </div>
                        </div>

                        <div className={DailyProductStyle.filters}>
                            <div className={DailyProductStyle.filter_bar}>
                                {trafficStores.map(item => {
                                    return (
                                        <ChartFilter
                                            key={item.id}
                                            name={item.name}
                                            colorCode={item.color}
                                            active={item.show}
                                            click={() => handleFilterStore(item)}
                                        />
                                    );
                                })}
                            </div>

                            <div className={DailyProductStyle.filter_bar}>
                                {lines.map(item => {
                                    return (
                                        <ChartFilter
                                            key={item.id}
                                            name={item.name}
                                            colorCode={item.color}
                                            active={item.show}
                                            click={() => handleFilter(item)}
                                        />
                                    );
                                })}
                            </div>
                        </div>

                        <div className={DailyProductStyle.products}>
                            {otherProducts.map(item => {
                                return (
                                    <FilterItem
                                        key={item[0]?.barcode}
                                        type="chart"
                                        text="Termék"
                                        items={item[0]?.item_name}
                                        onRemove={() => handleRemoveProduct(item)}
                                        visible={true}
                                    />
                                );
                            })}
                        </div>

                        <div className={DailyProductStyle.line_chart}>
                            {lineChartMemo}
                        </div>

                        <div className={DailyProductStyle.bar_chart}>
                            <ReactEcharts
                                option = {{
                                    tooltip: {
                                        trigger: 'item',
                                        valueFormatter: (val) => {
                                            if(lines[2].show) return val+" db";
                                            return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                                        }
                                    },
                                    yAxis: {
                                        type: 'value',
                                        axisLabel: {
                                            formatter: (val) => {
                                                if(lines[2].show) return val+" db";
                                                return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                                            }
                                        }
                                    },
                                    xAxis: {
                                        type: 'category',
                                        data: trafficStores.filter(item => item.show).sort((a, b) => {
                                            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                                        }).map(item => item.name)
                                    },
                                    grid: {
                                        containLabel: true,
                                        left: "2%",
                                        right: "2%",
                                        top: "5%",
                                        bottom: "5%"
                                    },
                                    series: [
                                        {
                                            name: "Jelenlegi időszak",
                                            type: 'bar',
                                            emphasis: {
                                                focus: 'series'
                                            },
                                            data: trafficStores.filter(item => item.show).sort((a, b) => {
                                                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                                            }).map(store => {
                                                const bar = currentBar.filter(item => item.store === store.id)[0];

                                                return {
                                                    value: lines[0].show ? bar.sum_traffic : lines[1].show ? bar.sum_profit : bar.sum_quantity,
                                                    itemStyle: {
                                                        color: "#5470C6"
                                                    }
                                                }
                                            }),
                                            markLine: {
                                                label: {
                                                    show: false
                                                },
                                                data: [
                                                    {
                                                        type: "average",
                                                        name: "Átlag",
                                                        lineStyle: {
                                                            color: "#2A4AAB"
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            name: "Múlt év időszak",
                                            type: 'bar',
                                            emphasis: {
                                                focus: 'series'
                                            },
                                            data: trafficStores.filter(item => item.show).sort((a, b) => {
                                                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                                            }).map(store => {
                                                const bar = prevBar.filter(item => item.store === store.id)[0];

                                                return {
                                                    value: lines[0].show ? bar.sum_traffic : lines[1].show ? bar.sum_profit : bar.sum_quantity,
                                                    itemStyle: {
                                                        color: "#C4C4C4"
                                                    }
                                                }
                                            }),
                                            markLine: {
                                                label: {
                                                    show: false
                                                },
                                                data: [
                                                    {
                                                        type: "average",
                                                        name: "Átlag",
                                                        lineStyle: {
                                                            color: "#686868"
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }}
                                style={{width: "100%", height: "100%"}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyProduct;