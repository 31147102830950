import { Checkbox, TableCell, TableRow } from "@mui/material";

const TransferItem = ({ invoice, onCheck }) => {
    const getInvoiceStatus = () => {
        if(invoice.status) {
            if(invoice.status === 1) {
                return "Iktatva";
            }
            else if(invoice.status === 2) {
                return "Bevételezve";
            }
        }

        return "Nem iktatott";
    };

    return (
        <TableRow
            hover
            selected={invoice.selected}
            onClick={() => onCheck(invoice)}
            sx={{ cursor: "pointer" }}
        >
            <TableCell>
                <Checkbox
                    checked={invoice.selected}
                />
            </TableCell>

            <TableCell>
                {invoice.serial_num}
            </TableCell>

            <TableCell>
                {invoice.partner_name}
            </TableCell>

            <TableCell>
                {invoice.sum} HUF
            </TableCell>

            <TableCell>
                {invoice.payment_deadline ? invoice.payment_deadline : "Nincs megadva"}
            </TableCell>

            <TableCell>
                {getInvoiceStatus()}
            </TableCell>
        </TableRow>
    );
};

export default TransferItem;