import { Avatar, Box, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import StoreIcon from "../../../layouts/stores/content/StoreIcon";

const TicketItem = ({ info, onDelete }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = info.profile_pic ? apiUrl+"/"+info.profile_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    }

    return (
        <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                    >
                        <Avatar src={imageExists(avatar) ? avatar : null} size="sm" />

                        <Stack>
                            <Typography fontSize={14} fontWeight={400}>{info.last_name+" "+info.first_name}</Typography>
                            <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Kifizető</Typography>
                        </Stack>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                    >
                        <StoreIcon
                            store={{
                                name: info.store_name,
                                color_code_2: info.color_code_2
                            }}
                            size="medium"
                        />

                        <Stack>
                            <Typography fontSize={14} fontWeight={400}>{info.store_name}</Typography>
                            <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Bevételezve</Typography>
                        </Stack>
                    </Box>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400}>{info.name}</Typography>
                        <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Típus</Typography>
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400}>{info.serial_num}</Typography>
                        <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Sorozatszám</Typography>
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400}>{info.prize_code}</Typography>
                        <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Nyereménykód</Typography>
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400}>{info.prize_value} HUF</Typography>
                        <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Nyeremény</Typography>
                    </Stack>

                    <IconButton onClick={() => onDelete(info)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Box>
            </Paper>
        </Grid>
    );
};

export default TicketItem;