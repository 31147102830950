import { styled, useTheme } from "@mui/material/styles";

import { Drawer, useMediaQuery } from "@mui/material";

import NavHeader from "./content/NavHeader";
import NavContent from "./content/NavContent";

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: "relative",
            whiteSpace: 'nowrap',
            width: 240,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                }
            })
        }
    })
);

const Nav = ({ open, onClose }) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    return isMdDown ? (
        <Drawer
            variant="temporary"
            anchor="left"
            open={open}
            onClose={onClose}
        >
            <NavHeader open={open} />
            <NavContent open={open} />
        </Drawer>
    ) : (
        <StyledDrawer
            variant="permanent"
            open={open}
        >
            <NavHeader open={open} />
            <NavContent open={open} />
        </StyledDrawer>
    );
};

export default Nav;