import { TableCell, TableRow } from "@mui/material";

const VerifyItem = ({ item }) => {
    const renderQuantity = () => {
        const from = item.from.toString().split("-");
        const to = item.to.toString().split("-");

        return (Number(to[to.length-1]) - Number(from[from.length-1])) + 1;
    };

    return (
        <TableRow>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.from}</TableCell>
            <TableCell>{item.to}</TableCell>
            <TableCell>{renderQuantity()}</TableCell>
        </TableRow>
    );
};

export default VerifyItem;