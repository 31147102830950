import { Button, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Stack, Typography } from "@mui/material";

import StoreIcon from "../../../../layouts/stores/content/StoreIcon";

const StoreItem = ({ store, onSelect }) => {
    return (
        <ListItemButton sx={{ borderRadius: 3 }}>
            <ListItemAvatar>
                <StoreIcon store={store} />
            </ListItemAvatar>

            <ListItemText
                primary={
                    <Typography variant="subtitle1" color="#262626">{store.name}</Typography>
                } 
                secondary={store.address}
            />
            
            <ListItemSecondaryAction>
                <Stack alignItems="flex-end">
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => onSelect(store)}
                    >
                        <Typography fontSize={12}>Kiválaszt</Typography>
                    </Button>
                </Stack>
            </ListItemSecondaryAction>
        </ListItemButton>
    );
};

export default StoreItem;