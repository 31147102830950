import { Chip, Tooltip, Zoom } from "@mui/material";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const StockTag = ({ label, color, click, onDelete }) => {
    return (
        <Tooltip
            arrow
            TransitionComponent={Zoom}
            onClick={click}
        >        
            <Chip
                label={label}
                icon={click && <AddCircleOutlineIcon style={{ color: "#000" }} />}
                size="small"
                clickable={true}
                variant="outlined"
                onDelete={onDelete}
                sx={click ? 
                    { 
                        borderStyle:"dashed",
                        borderColor: "#D9D9D9", 
                        borderRadius: 1,
                        '&:hover': {
                            borderStyle: 'solid',
                            transform: 'scale(1.08)',
                        }
                    }
                    :
                    {
                        bgcolor: color,
                        borderColor: color, 
                        borderRadius: 1,
                        '&:hover': {
                            transform: 'scale(1.08)',
                        }
                    }
                }
            />
        </Tooltip>
    );
};

export default StockTag;