import { useState } from "react";

import { motion } from 'framer-motion';

import { Box, Button, Grid, OutlinedInput, Paper, Stack, Typography } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";
import ResVerify from "./ResVerify";

const ResPersonal = ({ ap, dates, onClose }) => {
    const [personal, setPersonal] = useState({
        name: "",
        email: "",
        phone: "",
        comment: ""
    });

    const [verify, setVerify] = useState(false);

    const renderVerify = () => {
        if(verify) {
            return (
                <ResVerify
                    ap={ap}
                    dates={dates}
                    personal={personal}
                    onClose={() => setVerify(false)}
                />
            );
        }
    };

    return (
        <>
            {renderVerify()}

            {!verify &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={35}
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component={Paper}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            gap={2}
                            padding={2}
                        >
                            <Stack
                                spacing={1}
                                width="100%"
                                maxWidth={300}
                            >
                                <Typography fontSize={14}>Teljes név</Typography>

                                <OutlinedInput
                                    value={personal.name}
                                    onChange={(e) => setPersonal(prev => ({...prev, name: e.target.value}))}
                                    size="small"
                                />
                            </Stack>

                            <Stack
                                spacing={1}
                                width="100%"
                                maxWidth={300}
                            >
                                <Typography fontSize={14}>Email cím</Typography>

                                <OutlinedInput
                                    value={personal.email}
                                    onChange={(e) => setPersonal(prev => ({...prev, email: e.target.value}))}
                                    size="small"
                                />
                            </Stack>

                            <Stack
                                spacing={1}
                                width="100%"
                                maxWidth={300}
                            >
                                <Typography fontSize={14}>Telefonszám</Typography>

                                <OutlinedInput
                                    value={personal.phone}
                                    onChange={(e) => setPersonal(prev => ({...prev, phone: e.target.value}))}
                                    size="small"
                                />
                            </Stack>

                            <Stack
                                spacing={1}
                                width="100%"
                                maxWidth={300}
                            >
                                <Typography fontSize={14}>Megjegyzés</Typography>

                                <OutlinedInput
                                    value={personal.comment}
                                    onChange={(e) => setPersonal(prev => ({...prev, comment: e.target.value}))}
                                    size="small"
                                    multiline
                                    rows={5}
                                />
                            </Stack>

                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                style={{ alignSelf: "flex-end" }}
                            >
                                <Button
                                    variant="contained"
                                    disabled={personal.name === "" || personal.email === "" || personal.phone === ""}
                                    onClick={() => setVerify(true)}
                                >
                                    Tovább
                                </Button>
                            </motion.div>
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default ResPersonal;