import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, Grid, IconButton, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import OpHeader from "../../../components/opheader/OpHeader";
import StoreIcon from "../../../layouts/stores/content/StoreIcon";
import StatCard from "./content/StatCard";
import DailyChart from "./content/DailyChart";
import StoreChart from "./content/StoreChart";
import UserChart from "./content/UserChart";
import ItemizedTable from "./content/ItemizedTable";

const DailyDetails = ({ date, onClose, nextDate, prevDate }) => {
    const {user, setMsg} = useContext(DataContext);

    const [traffic, setTraffic] = useState({
        current: [],
        prev: []
    });
    const [itemized, setItemized] = useState([]);
    const [showItemized, setShowItemized] = useState([]);

    const [slot, setSlot] = useState("Összesítő");
    const [vat, setVat] = useState("Total");
    const [storeFilter, setStoreFilter] = useState([]);

    const [search, setSearch] = useState("");
    const [margin, setMargin] = useState([0, 0]);

    const [activeCard, setActiveCard] = useState(0);

    const days = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'];

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getItemized();
    }, [date]);

    useEffect(() => {
        try {
            const rx = new RegExp(`${search}`,'i');

            let filtered = itemized.filter(item => item.margin <= margin[1] && item.margin >= margin[0]);

            setShowItemized(filtered.filter(item => rx.test(item.receipt_num_1) || rx.test(item.barcode) || rx.test(item.item_name)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, itemized, margin]);

    const getItemized = async() => {
        const res = await fetch(apiUrl+"/traffic/itemized", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                date: date,
                company: user.company.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setItemized(data.traffic);

        let min = data.traffic[0].margin, max = data.traffic[0].margin;

        data.traffic.map(item => {
            if(item.margin > max) max = item.margin;
            if(item.margin < min) min = item.margin;
        });

        setMargin([min, max]);

        getTraffic();
    };

    const getTraffic = async() => {
        const res = await fetch(apiUrl+"/traffic/daily/itemized", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                date: date,
                company: user.company.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTraffic(data.traffic);

        setStoreFilter(data.traffic.current.filter(item => item.operation === 1).map(item => {
            return {
                id: item.store,
                name: item.name,
                color_code_2: item.color_code_2,
                active: true
            };
        }));
    };

    const calculateTraffic = () => {
        const sum = traffic.current.filter(item =>
            storeFilter.filter(item => item.active)
            .map(item => item.id)
            .includes(item.store) && item.operation === 2)
            .reduce((acc, obj) => {
                return acc + obj.daily_traffic_sum;
            },0);

        return sum;
    };

    const calculateReceipts = () => {
        const sum = traffic.current.filter(item =>
            storeFilter.filter(item => item.active)
            .map(item => item.id)
            .includes(item.store) && item.operation === 2)
            .reduce((acc, obj) => {
                return acc + obj.receipt_quantity;
            },0);

        return sum;
    };

    const calculateProfit = () => {
        const sum = traffic.current.filter(item =>
            storeFilter.filter(item => item.active)
            .map(item => item.id)
            .includes(item.store) && item.operation === 2)
            .reduce((acc, obj) => {
                return acc + obj.profit;
            },0);

        return sum;
    };

    const calculatePrevTraffic = () => {
        const sum = traffic.prev.filter(item =>
            storeFilter.filter(item => item.active)
            .map(item => item.id)
            .includes(item.store) && item.operation === 2)
            .reduce((acc, obj) => {
                return acc + obj.daily_traffic_sum;
            },0);

        return sum;
    };

    const calculatePrevReceipts = () => {
        const sum = traffic.prev.filter(item =>
            storeFilter.filter(item => item.active)
            .map(item => item.id)
            .includes(item.store) && item.operation === 2)
            .reduce((acc, obj) => {
                return acc + obj.receipt_quantity;
            },0);

        return sum;
    };

    const calculatePrevProfit = () => {
        const sum = traffic.prev.filter(item =>
            storeFilter.filter(item => item.active)
            .map(item => item.id)
            .includes(item.store) && item.operation === 2)
            .reduce((acc, obj) => {
                return acc + obj.profit;
            },0);

        return sum;
    };

    const handleChange = (event, newAlignment) => {
        if(newAlignment) setSlot(newAlignment);
    };

    const handleVat = (e) => {
        setVat(e.target.value);
    };

    const handleFilter = (store) => {
        setStoreFilter(prev => prev.map(item => {
            if(item.id === store.id) {
                return {
                    ...item,
                    active: !item.active
                };
            }

            return item;
        }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <OpHeader
                        text={date+" ("+days[new Date(date).getDay()]+")"}
                        onClose={onClose}
                    />

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                    >
                        <IconButton
                            onClick={prevDate}
                            size="small"
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>

                        <IconButton
                            onClick={nextDate}
                            size="small"
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    width="100%"
                >
                    <ToggleButtonGroup
                        value={slot}
                        onChange={handleChange}
                        exclusive
                        size="small"
                    >
                        <ToggleButton
                            value="Összesítő"
                            disabled={slot === "Összesítő"}
                        >
                            <Typography
                                fontWeight={300}
                                fontSize={10}
                                color="#000"
                            >
                                Összesítő
                            </Typography>
                        </ToggleButton>

                        <ToggleButton
                            value="Tételes forgalom"
                            disabled={slot === "Tételes forgalom"}
                        >
                            <Typography
                                fontWeight={300}
                                fontSize={10}
                                color="#000"
                            >
                                Tételes forgalom
                            </Typography>
                        </ToggleButton>

                        <ToggleButton
                            value="Profit"
                            disabled={slot === "Profit"}
                        >
                            <Typography
                                fontWeight={300}
                                fontSize={10}
                                color="#000"
                            >
                                Profit
                            </Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <Select
                        value={vat}
                        onChange={handleVat}
                        size="small"
                        sx={{ fontSize: 12, fontWeight: 300 }}
                    >
                        <MenuItem value="Total">
                            Total
                        </MenuItem>
                    </Select>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        marginLeft="auto"
                    >
                        {storeFilter.map(item => {
                            return (
                                <StoreIcon
                                    key={item.id}
                                    store={item}
                                    type="checkbox"
                                    val={item.active}
                                    onClick={handleFilter}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Grid>

            {slot === "Összesítő" &&
                <>
                    <StatCard
                        title="Forgalom"
                        count={calculateTraffic()}
                        percentage={((calculateTraffic()/calculatePrevTraffic()*100)-100).toFixed(1)}
                        extra={Math.round(calculateTraffic() - calculatePrevTraffic())}
                        isLoss={calculateTraffic() < calculatePrevTraffic()}
                        type="HUF"
                        active={activeCard === 0}
                        click={() => setActiveCard(0)}
                    />

                    <StatCard
                        title="Vásárlószám"
                        count={calculateReceipts()}
                        percentage={((calculateReceipts()/calculatePrevReceipts()*100)-100).toFixed(1)}
                        extra={Math.round(calculateReceipts() - calculatePrevReceipts())}
                        isLoss={calculateReceipts() < calculatePrevReceipts()}
                        type="DB"
                        active={activeCard === 1}
                        click={() => setActiveCard(1)}
                    />

                    <StatCard
                        title="Profit"
                        count={calculateProfit()}
                        percentage={((calculateProfit()/calculatePrevProfit()*100)-100).toFixed(1)}
                        extra={Math.round(calculateProfit() - calculatePrevProfit())}
                        isLoss={calculateProfit() < calculatePrevProfit()}
                        type="HUF"
                        active={activeCard === 2}
                        click={() => setActiveCard(2)}
                    />

                    <StatCard
                        title="Árrés"
                        count={(calculateProfit()/calculateTraffic()*100).toFixed(2)}
                        percentage={(((calculateProfit()/calculateTraffic()*100)/(calculatePrevProfit()/calculatePrevTraffic()*100)*100)-100).toFixed(1)}
                        extra={(((calculateProfit()/calculateTraffic()*100)/(calculatePrevProfit()/calculatePrevTraffic()*100)*100)-100).toFixed(1)}
                        isLoss={(calculateProfit()/calculateTraffic()) < (calculatePrevProfit()/calculatePrevTraffic())}
                        type="%"
                        active={activeCard === 3}
                        click={() => setActiveCard(3)}
                    />

                    <StatCard
                        title="Kosárérték"
                        count={Math.round(calculateTraffic()/calculateReceipts())}
                        percentage={(((calculateTraffic()/calculateReceipts())/(calculatePrevTraffic()/calculatePrevReceipts())*100)-100).toFixed(1)}
                        extra={Math.round((calculateTraffic()/calculateReceipts()) - (calculatePrevTraffic()/calculatePrevReceipts()))}
                        isLoss={(calculateTraffic()/calculateReceipts()) < (calculatePrevTraffic()/calculatePrevReceipts())}
                        type="HUF"
                        active={activeCard === 4}
                        click={() => setActiveCard(4)}
                    />

                    <DailyChart
                        date={date}
                        active={activeCard}
                        filter={storeFilter}
                    />

                    <StoreChart
                        date={date}
                        active={activeCard}
                        filter={storeFilter}
                    />

                    <UserChart
                        date={date}
                        active={activeCard}
                        filter={storeFilter}
                    />
                </>
            }

            <ItemizedTable
                itemizedTraffic={showItemized}
                storeFilter={storeFilter}
                slot={slot}
                onSearch={(val) => setSearch(val)}
                onMargin={(val) => setMargin(val)}
            />
        </Grid>
    );
};

export default DailyDetails;