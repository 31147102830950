import { Box, IconButton, Typography } from "@mui/material";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const PackLine = ({ pack, onAdd }) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={1}
            >
                <Typography
                    fontSize={12}
                    fontWeight={700}
                    color="#000"
                >
                    {pack.name}
                </Typography>

                <Typography
                    fontSize={10}
                    fontWeight={300}
                    color="#000"
                >
                    {pack.quantity} tétel
                </Typography>
            </Box>

            <IconButton
                size="small"
                onClick={() => onAdd(pack.id)}
            >
                <AddCircleOutlineIcon />
            </IconButton>
        </Box>
    );
};

export default PackLine;