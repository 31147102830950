import { useState } from "react";

import { getReturnSum, getTrafficCashOut, getTrafficOnlyCashout } from "../../services/TrafficCalc";

import { Box, Grid, Typography } from "@mui/material";

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import OpHeader from "../../components/opheader/OpHeader";
import OkButton from "./content/OkButton";
import InfoRow from "./content/InfoRow";
import ChangeContent from "./ChangeContent";
import Buttons from "./content/Buttons";

const ChangeCashout = ({ traffic, fileName, onClose}) => {
    const [checks, setChecks] = useState({
        cancel: false,
        return: false,
        other: false,
        cash: false,
        sum: false
    });
    const [checkAll, setCheckAll] = useState(false);

    const [change, setChange] = useState(false);

    const handleCheckAll = () => {
        if(!checkAll) {
            setChecks({
                cancel: true,
                return: true,
                other: true,
                cash: true,
                sum: true
            });
            return setCheckAll(true);;
        }

        setChecks({
            cancel: false,
            return: false,
            other: false,
            cash: false,
            sum: false
        });

        setCheckAll(false);
    };

    const renderChangeCashout = () => {
        if(change) {
            return (
                <ChangeContent
                    traffic={traffic}
                    fileName={fileName}
                    onClose={() => setChange(false)}
                />
            );
        }
    };

    return (
        <>
            {renderChangeCashout()}

            {!change &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={3}
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                            width="100%"
                            color="#8C8C8C"
                        >
                            <Typography
                                fontSize={12}
                                fontWeight={300}
                            >
                                {fileName}
                            </Typography>

                            <DescriptionOutlinedIcon />

                            <Typography
                                fontSize={12}
                                fontWeight={300}
                                sx={{ marginLeft: "auto" }}
                            >
                                Összes elfogadása
                            </Typography>

                            <OkButton
                                val={checkAll}
                                click={handleCheckAll}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            fontSize={12}
                            fontWeight={700}
                            color="#000"
                        >
                            Kiadások
                        </Typography>
                    </Grid>

                    <InfoRow
                        text="Sztornó"
                        val={0}
                        checked={checks.cancel}
                        click={() => setChecks(prev => ({...prev, cancel: !prev.cancel}))}
                    />

                    <InfoRow
                        text="Visszáru"
                        val={getReturnSum(traffic)}
                        checked={checks.return}
                        click={() => setChecks(prev => ({...prev, return: !prev.return}))}
                    />

                    <InfoRow
                        text="Egyéb kiadások"
                        val={0}
                        checked={checks.other}
                        click={() => setChecks(prev => ({...prev, other: !prev.other}))}
                    />

                    <InfoRow
                        text="Készpénz"
                        val={getTrafficOnlyCashout(traffic)}
                        checked={checks.cash}
                        click={() => setChecks(prev => ({...prev, cash: !prev.cash}))}
                    />

                    <InfoRow
                        text="Kiadások összesen"
                        val={getTrafficCashOut(traffic)}
                        checked={checks.sum}
                        click={() => setChecks(prev => ({...prev, sum: !prev.sum}))}
                    />

                    <Buttons
                        backText="Vissza"
                        nextText="Következő"
                        onBack={onClose}
                        onNext={() => setChange(true)}
                    />
                </Grid>
            }
        </>
    );
};

export default ChangeCashout;