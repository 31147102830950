import { useContext, useEffect, useState } from "react";

import useDebounce from "../../hooks/useDebounce";
import { DataContext } from "../../context/DataContext";

import { Grid, InputAdornment, OutlinedInput } from "@mui/material";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import OpHeader from "../../components/opheader/OpHeader";
import TrafficItem from "./content/TrafficItem";
import DailyDetails from "./content/DailyDetails";

const DailyTraffic = () => {
    const {user, setOpenOper, setMsg} = useContext(DataContext);

    const [traffics, setTraffics] = useState([]);
    const [showTraffics, setShowTraffics] = useState([]);

    const [search, setSearch] = useState("");

    const [detailDate, setDetailDate] = useState("");

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setShowTraffics([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

        getTraffics();
    }, []);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');

            setShowTraffics(traffics.filter(item => rx.test(item.date)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, traffics], 500);

    const getTraffics = async() => {
        const res = await fetch(apiUrl+"/traffic/daily/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTraffics(data.traffics);
    };

    const handlePrevDay = () => {
        let prevDay = new Date(detailDate);
        prevDay.setDate(prevDay.getDate() - 1);
        prevDay = prevDay.toISOString().split("T")[0];

        setDetailDate(prevDay);
    };

    const handleNextDay = () => {
        let nextDay = new Date(detailDate);
        nextDay.setDate(nextDay.getDate() + 1);
        nextDay = nextDay.toISOString().split("T")[0];

        setDetailDate(nextDay);
    };

    const renderDetails = () => {
        if(detailDate !== "") {
            return (
                <DailyDetails
                    date={detailDate}
                    prevDate={handlePrevDay}
                    nextDate={handleNextDay}
                    onClose={() => setDetailDate("")}
                />
            );
        }
    };

    return (
        <>
            {renderDetails()}

            {detailDate === "" &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={38}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <OutlinedInput
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Keresés"
                            fullWidth
                            size="small"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon style={{ width: 20 }} />
                                </InputAdornment>
                            }
                            sx={{
                                fontSize: 12,
                                backgroundColor: "#FFFFFF"
                            }}
                        />
                    </Grid>

                    {showTraffics.map(item => {
                        return (
                            <TrafficItem
                                key={item.id}
                                traffic={item}
                                click={() => setDetailDate(item.date)}
                            />
                        );
                    })}
                </Grid>
            }
        </>
    );
};

export default DailyTraffic;