import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, TextField, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const InfoDialog = ({ open, serialNum, setSerialNum, transitSerial, setTransitSerial, comment, setComment, onClose, onNext }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <IconButton
                    onClick={onClose}
                    size="small"
                >
                    <ArrowBackIosIcon style={{ fontSize: 16 }} />
                </IconButton>

                <IconButton
                    size="small"
                >
                    <OpenInFullIcon style={{ fontSize: 16 }} />
                </IconButton>
            </Box>

            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    <DialogContentText fontSize={14} fontWeight={500}>Értékszállítás</DialogContentText>
                    <DialogContentText fontSize={12} fontWeight={300}>Add meg az értékszállítás adatait</DialogContentText>
                
                    <TextField
                        value={serialNum}
                        onChange={(e) => setSerialNum(e.target.value)}
                        variant="filled"
                        label="Azonosító"
                        size="small"
                        sx={{ "& .MuiFilledInput-root": { bgcolor: "#FFF" } }}
                    />

                    <TextField
                        value={transitSerial}
                        onChange={(e) => setTransitSerial(e.target.value)}
                        variant="filled"
                        label="Értékszállító azonosító"
                        size="small"
                        sx={{ "& .MuiFilledInput-root": { bgcolor: "#FFF" } }}
                    />

                    <TextField
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        variant="filled"
                        label="Megjegyzés"
                        size="small"
                        sx={{ "& .MuiFilledInput-root": { bgcolor: "#FFF" } }}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={onClose}
                >
                    Vissza
                </Button>

                <Button
                    variant="contained"
                    size="small"
                    disabled={serialNum === "" || transitSerial === ""}
                    onClick={onNext}
                >
                    Tovább
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InfoDialog;