import { List } from "@mui/material";

const ApsList = ({ children }) => {
    return (
        <List>
            {children}
        </List>
    );
};

export default ApsList;