import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { io } from "socket.io-client";

import { DataContext } from "../../../context/DataContext";

import { styled } from '@mui/material/styles';
import { Avatar, Box, Button, Divider, Grid, Paper, Stack, Switch, Typography } from "@mui/material";

import { motion } from 'framer-motion';

import OpHeader from "../../../components/opheader/OpHeader";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
    width: 37,
    height: 21,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#1890FF',
                opacity: 1,
                border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff'
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 17,
        height: 17
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500
        })
    }
}));

const CashoutVerify = ({ info, onClose }) => {
    const {user, setMsg, setOpenOper} = useContext(DataContext);

    const [checked, setChecked] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = user.profile_pic ? apiUrl+"/"+user.profile_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    }

    const handleVerify = async() => {
        const res = await fetch(apiUrl+"/tickets/cashout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                item: info.ticket,
                prize: info.prize.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres művelet!"
        });
        socket.emit("cashout_tickets", {id: user.company.id});
        
        setOpenOper(0);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={12}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                    <Stack spacing={2}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={1}
                            >
                                <Avatar src={imageExists(avatar) ? avatar : null} size="sm" />

                                <Stack>
                                    <Typography fontSize={14} fontWeight={400}>{user.last_name+" "+user.first_name}</Typography>
                                    <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Kifizető</Typography>
                                </Stack>
                            </Box>

                            <Stack>
                                <Typography fontSize={14} fontWeight={400}>{info.ticket.name}</Typography>
                                <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Típus</Typography>
                            </Stack>

                            <Stack>
                                <Typography fontSize={14} fontWeight={400}>{info.ticket.serial_num}</Typography>
                                <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Sorozatszám</Typography>
                            </Stack>

                            <Stack>
                                <Typography fontSize={14} fontWeight={400}>{info.prize.val} HUF</Typography>
                                <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Nyeremény</Typography>
                            </Stack>
                        </Box>

                        <Divider />

                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={1}
                            >
                                <Typography fontSize={14} fontWeight={400}>Sérülés mentes a sorsjegy?</Typography>
                                <IOSSwitch
                                    checked={checked}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />
                            </Box>

                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={1}
                            >
                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={onClose}
                                    >
                                        Vissza
                                    </Button>
                                </motion.div>

                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handleVerify}
                                        disabled={!checked}
                                    >
                                        Kifizetés
                                    </Button>
                                </motion.div>
                            </Box>
                        </Box>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CashoutVerify;