import { useState } from "react";

import { Skeleton, TableCell, TableRow } from "@mui/material";

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import Tag from "../tag/Tag";
import CurrencyText from "../../../../../../components/currencytext/CurrencyText";
import OkButton from "../../../../../../components/okbutton/OkButton";

const TransferItem = ({ invoice, onAdd, onRemove }) => {
    const [choosen, setChoosen] = useState(false);

    const handleChoose = () => {
        if(choosen) {
            onRemove(invoice);
        }
        else {
            onAdd(invoice);
        }

        setChoosen(prev => !prev);
    };

    const calculateDifference = (startDate) => {
        const start = new Date(startDate);
        const end = new Date();

        const timeDiff = end.getTime() - start.getTime();

        const dayDiff = timeDiff / (1000 * 60 * 60 * 24);

        return dayDiff;
    };
    
    return (
        <TableRow>
            <TableCell>
                {invoice.record_date ?
                    <Tag
                        type="record"
                        invoice={invoice}
                        tagIcon={invoice.record_date ? <CalendarTodayOutlinedIcon /> : <ControlPointIcon />} 
                        tagLabel={invoice.record_date ? invoice.record_date : null}
                        tagTipLabel="Rögzítés dátuma"
                    />
                :
                    <Skeleton variant="rounded" width={80} height={20} />
                }
            </TableCell>

            <TableCell>
                {invoice.record_date ?
                    <Tag
                        type="delivery"
                        invoice={invoice}
                        tagIcon={invoice.delivery_date ? <CalendarTodayOutlinedIcon /> : <ControlPointIcon />} 
                        tagLabel={invoice.delivery_date ? invoice.delivery_date : null}
                        tagTipLabel="Teljesítés dátuma"
                    />
                :
                    <Skeleton variant="rounded" width={80} height={20} />
                }
            </TableCell>

            <TableCell>
                {invoice.record_date ?
                    <Tag
                        type="deadline"
                        invoice={invoice}
                        tagIcon={invoice.payment_deadline ? <CalendarTodayOutlinedIcon /> : <ControlPointIcon />} 
                        tagLabel={invoice.payment_deadline ? invoice.payment_deadline : null}
                        tagTipLabel="Fizetési határidő"
                    />
                :
                    <Skeleton variant="rounded" width={80} height={20} />
                }
            </TableCell>

            <TableCell
                sx={{
                    fontSize: 11,
                    fontWeight: 400
                }}
            >
                {invoice.partner_name}
            </TableCell>

            <TableCell
                sx={{
                    fontSize: 11,
                    fontWeight: 400
                }}
            >
                {invoice.partner_bank}
            </TableCell>

            <TableCell
                sx={{
                    fontSize: 14,
                    fontWeight: 400
                }}
            >
                {Math.round(calculateDifference(invoice.payment_deadline))} nap
            </TableCell>

            <TableCell
                sx={{
                    fontSize: 11,
                    fontWeight: 400
                }}
            >
                <CurrencyText val={invoice.sum} /> Ft
            </TableCell>

            <TableCell
                sx={{
                    fontSize: 11,
                    fontWeight: 400,
                    color: "#8C8C8C"
                }}
            >
                {invoice.serial_num}
            </TableCell>

            <TableCell>
                <OkButton
                    val={choosen}
                    click={handleChoose}
                />
            </TableCell>
        </TableRow>
    );
};

export default TransferItem;