import { useContext, useEffect, useState } from "react";

import { io } from "socket.io-client";

import useDebounce from "../../hooks/useDebounce";
import { DataContext } from "../../context/DataContext";

import { Box, Grid, OutlinedInput, Typography } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";
import StockTable from "./content/StockTable";
import ProductManage from "./content/ProductManage";
import StockDialog from "./content/StockDialog";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Stock = ({ defaultBarcode }) => {
    const {user, setMsg, setLoading, setOpenOper} = useContext(DataContext);

    const [search, setSearch] = useState("");

    const [stock, setStock] = useState([]);
    const [allStock, setAllStock] = useState([]);
    const [showStock, setShowStock] = useState([]);

    const [productPage, setProductPage] = useState({});

    const [dialogStock, setDialogStock] = useState(false);
    const [stockProduct, setStockProduct] = useState([])

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        socket.emit("join", ["cid:"+user.company.id]);
        
        getStock();
    }, []);

    useEffect(() => {
        socket.on("update_inbound", () => {
            getStock();
        });
    }, [socket]);

    useDebounce(() => {
        try {
            const rx = new RegExp(`${search}`,'i');
            setShowStock(allStock.filter(item => rx.test(item.product) || rx.test(item.barcode)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "error",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [search, stock, allStock], 500);

    const getStock = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/product/stock/company/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setStock(data.stock);

        const productGroup = data.stock.reduce((group, x) => {
            (group[x["product_id"]] = group[x["product_id"]] || []).push(x);
            return group;
        }, {});

        const allArr = [];

        Object.keys(productGroup).map(key => {
            const sum = productGroup[key].reduce((acc, obj) => {
                return acc + obj.quantity;
            }, 0);

            return allArr.push({
                id: productGroup[key][0].id,
                barcode: productGroup[key][0].barcode,
                articleNum: productGroup[key][0].article_num,
                priceType: productGroup[key][0].price_type,
                name: productGroup[key][0].name,
                product: productGroup[key][0].product,
                product_id: productGroup[key][0].product_id,
                vat: productGroup[key][0].vat,
                unit: productGroup[key][0].unit,
                image: productGroup[key][0].image,
                quantity: sum
            });
        });

        setAllStock(allArr);

        if(defaultBarcode) {
            getProductPage(data.stock.filter(item => item.barcode === defaultBarcode)[0]);
        }

        setLoading(false);
    };

    const getProductPage = async(product) => {
        setLoading(true);

        const purchaseRes = await fetch(apiUrl+"/product/price/last", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                product: product.product_id
            })
        });

        const purchaseData = await purchaseRes.json();

        if(!purchaseData.success) {
            return setMsg({
                color: "error",
                message: purchaseData.message
            });
        }

        const sellRes = await fetch(apiUrl+"/product/sell/price/"+product.product_id, {
            method: "GET"
        });

        const sellData = await sellRes.json();

        if(!sellData.success) {
            return setMsg({
                color: "error",
                message: sellData.message
            });
        }

        setProductPage({
            ...product,
            purchasePrice: purchaseData.income.purchase_price,
            sellPrice: sellData.sellPrice
        })

        setLoading(false);
    };

    const handleStockDialog = (barcode) => {
        setStockProduct(stock.filter(item => item.barcode === barcode));
        setDialogStock(true);
    };

    const renderProduct = () => {
        if(JSON.stringify(productPage) !== "{}") {
            return (
                <ProductManage
                    product={productPage}
                    stock={stock.filter(item => item.barcode === productPage.barcode)}
                    onRefresh={getStock}
                    onClose={() => setProductPage({})}
                />
            );
        }
    };

    return (
        <>
            {renderProduct()}

            {JSON.stringify(productPage) === "{}" &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={27}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <OutlinedInput
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Kereső"
                            size="small"
                            fullWidth
                            sx={{ bgcolor: "#FFF" }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                            color="#000"
                        >
                            <Typography fontSize={16} fontWeight={700}>Termék lista</Typography>
                            <Typography fontSize={12} fontWeight={300}>( {showStock.length} termék hozzáadva )</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <StockTable
                            stock={showStock}
                            onProduct={(product, purchasePrice, sellPrice) => setProductPage({
                                ...product,
                                purchasePrice: purchasePrice,
                                sellPrice: sellPrice
                            })}
                            onStock={handleStockDialog}
                        />

                        <StockDialog
                            open={dialogStock}
                            setOpen={setDialogStock}
                            stock={stockProduct}
                        />
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default Stock;