import ChartFilterStyle from "./ChartFilter.module.css";

const ChartFilter = ({ colorCode, name, click, item, active }) => {
    return (
        <div
            className={active ? ChartFilterStyle.filter_item_active : ChartFilterStyle.filter_item}
            onClick={() => click(item)}
        >
            <div
                className={ChartFilterStyle.filter_color}
                style={{background: colorCode}}
            ></div>

            <span className={ChartFilterStyle.filter_text}>{name}</span>
        </div>
    );
};

export default ChartFilter;