import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Autocomplete, Box, Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material";

import StoreIcon from "../../../../layouts/stores/content/StoreIcon";

const TicketItem = ({ item, onCashout }) => {
    const {setMsg} = useContext(DataContext);

    const [prizeList, setPrizeList] = useState([]);
    const [prize, setPrize] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getPrizeList();
    }, []);

    const getPrizeList = async() => {
        const res = await fetch(apiUrl+"/tickets/prize/"+item.ticket, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setMsg({
                color: "error",
                message: data.message
            });
        }

        setPrizeList(data.prize);
    };

    return (
        <Grid item xs={12}>
            <Paper sx={{ p: 1 }}>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                    >
                        <StoreIcon
                            store={{
                                name: item.store_name,
                                color_code_2: item.color_code_2
                            }}
                            size="medium"
                        />

                        <Stack>
                            <Typography fontSize={14} fontWeight={400}>{item.store_name}</Typography>
                            <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Bevételezve</Typography>
                        </Stack>
                    </Box>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400}>{item.name}</Typography>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Típus</Typography>
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400}>{item.serial_num}</Typography>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Sorozatszám</Typography>
                    </Stack>

                    <Autocomplete
                        value={prize}
                        onChange={(e, newVal) => setPrize(newVal)}
                        disablePortal
                        options={prizeList.map(p => ({id: p.id, val: p.prize_value, label: p.prize_code+" "+p.prize_value+" HUF"}))}
                        sx={{ width: 200 }}
                        renderInput={(params) => <TextField {...params} label="Nyereménykód" size="small" />}
                    />

                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => onCashout(item, prize)}
                    >
                        Tovább
                    </Button>
                </Box>
            </Paper>
        </Grid>
    );
};

export default TicketItem;