import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Grid, Grow, ListItemAvatar } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";
import ApsList from "../../../layouts/apartmans/ApsList";
import Apartman from "../../../layouts/apartmans/Apartman";
import ResDates from "./ResDates";

const ResApartman = () => {
    const {user, setOpenOper, setMsg, setLoading} = useContext(DataContext);

    const [aps, setAps] = useState([]);
    const [choosenAp, setChoosenAp] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getApartmans();
    }, []);

    const getApartmans = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/apartmans/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setAps(data.aps);
        
        setLoading(false);
    };

    const renderDates = () => {
        if(JSON.stringify(choosenAp) !== "{}") {
            return (
                <ResDates
                    ap={choosenAp}
                    onClose={() => setChoosenAp({})}
                />
            );
        }
    };

    return (
        <>
            {renderDates()}

            {JSON.stringify(choosenAp) === "{}" &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={35}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ApsList>
                            {aps.map((item, i) => {
                                return (
                                    <Grow key={i} in={true} timeout={(i+1)*500}>
                                        <ListItemAvatar>
                                            <Apartman
                                                ap={item}
                                                res={true}
                                                click={(ap) => setChoosenAp(ap)}
                                            />
                                        </ListItemAvatar>
                                    </Grow>
                                );
                            })}
                        </ApsList>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default ResApartman;