import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Avatar, AvatarGroup, Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";

import OpHeader from "../../../../components/opheader/OpHeader";
import StoreIcon from "../../../../layouts/stores/content/StoreIcon";
import DeliveryItem from "./content/DeliveryItem";

const DeliverySummary = ({ serialNum, barcode, onClose }) => {
    const {setLoading, setMsg} = useContext(DataContext);

    const [deliveryData, setDeliveryData] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getDelivery();
    }, []);

    const getDelivery = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/delivery/all/"+serialNum, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setDeliveryData(data.delivery);

        setLoading(false);
    };

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OpHeader
                    text={"Összegzés: "+serialNum}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Typography
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                    >
                        Bevételezés dátuma: {deliveryData.date}
                    </Typography>

                    <AvatarGroup>
                        <Tooltip
                            arrow
                            title={deliveryData.lastName+" "+deliveryData.firstName}
                        >
                            <Avatar
                                src={imageExists(apiUrl+"/"+deliveryData?.profilePic) ? apiUrl+"/"+deliveryData?.profilePic : null}
                            />
                        </Tooltip>

                        <Tooltip
                            arrow
                            title={deliveryData.store?.name}
                        >
                            <StoreIcon
                                store={deliveryData.store}
                            />
                        </Tooltip>
                    </AvatarGroup>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#FAFAFA" }}>
                                <TableCell sx={{ color: "#434343" }}>#</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Vonalkód</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Cikkszám</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Megnevezés</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Darabszám</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Eladási ár</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Előző eladási ár</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {deliveryData.products?.map((item, i) => {
                                return (
                                    <DeliveryItem
                                        key={i}
                                        id={i}
                                        selected={item.barcode === barcode}
                                        row={item}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default DeliverySummary;