import { useEffect, useMemo, useState } from "react";

import ReactEcharts from "echarts-for-react";

import { Box, Grid, Typography } from "@mui/material";

import ChartFilter from "../../../components/charts/chartfilter/ChartFilter";

const TotalStores = ({ traffic, onDaily }) => {
    const [currentTraffic, setCurrentTraffic] = useState([]);
    const [currentProfit, setCurrentProfit] = useState([]);
    const [currentReceipt, setCurrentReceipt] = useState([]); 

    const [prevTraffic, setPrevTraffic] = useState([]);
    const [prevProfit, setPrevProfit] = useState([]);
    const [prevReceipt, setPrevReceipt] = useState([]); 

    const [currentData, setCurrentData] = useState({
        name: "",
        color: "",
        data: []
    });

    const [prevData, setPrevData] = useState({
        name: "",
        color: "",
        data: []
    });

    const [dates, setDates] = useState([]);

    const [lines, setLines] = useState([
        {
            id: 0,
            name: "Forgalom",
            color: "#5470C6",
            show: true
        },
        {
            id: 1,
            name: "Profit",
            color: "#53FA39",
            show: false
        },
        {
            id: 2,
            name: "Vásárlószám",
            color: "#53FA39",
            show: false
        }
    ]);

    const [avg, setAvg] = useState([
        {
            id: 0,
            name: "Jelenlegi időszak átlag",
            color: "#2A4AAB",
            show: false
        },
        {
            id: 1,
            name: "Múlt évi időszak átlag",
            color: "#686868",
            show: false
        }
    ]);

    const lineChartMemo = useMemo(() => {
        return (
            <ReactEcharts
                option={{
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: dates.map(item => {
                            return item+"\n"+new Date(item).toLocaleDateString("hu-HU", { weekday: "long" });
                        })
                    },
                    yAxis: {
                        type: "value",
                        axisLabel: {
                            formatter: (val) => {
                                if(lines[2].show) {
                                    return val+" db";
                                }
                                else {
                                    return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                                }  
                            }
                        }
                    },
                    dataZoom: [
                        {
                            type: "slider",
                            show: true,
                            start: 0,
                            end: 100
                        }
                    ],
                    grid: {
                        containLabel: true,
                        left: "2%",
                        right: "2%",
                        top: "5%",
                        bottom: "18%"
                    },
                    series: [
                        {
                            type: "line",
                            name: prevData.name,
                            symbolSize: 10,
                            lineStyle: {
                                type: "dashed",
                                color: prevData.color
                            },
                            itemStyle: {
                                color: prevData.color
                            },
                            data: [
                                ...prevData.data.map((item) => ({
                                    date: item.date,
                                    current: false,
                                    value: item.sum
                                }))
                            ],
                            markLine: {
                                label: {
                                    show: false
                                },
                                symbol: avg[1].show ? "circle" : "none",
                                data: [
                                    {
                                        type: "average",
                                        name: "Átlag",
                                        lineStyle: {
                                            width: avg[1].show ? 1 : 0,
                                            color: "#686868"
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            type: "line",
                            name: currentData.name,
                            symbolSize: 10,
                            lineStyle: {
                                color: currentData.color
                            },
                            itemStyle: {
                                color: currentData.color
                            },
                            data: [
                                ...currentData.data.map((item) => ({
                                    date: item.date,
                                    current: true,
                                    value: item.sum
                                }))
                            ],
                            markLine: {
                                label: {
                                    show: false
                                },
                                symbol: avg[0].show ? "circle" : "none",
                                data: [
                                    {
                                        type: "average",
                                        name: "Átlag",
                                        lineStyle: {
                                            width: avg[0].show ? 1 : 0,
                                            color: "#2A4AAB"
                                        }
                                    }
                                ]
                            }
                        }
                    ],
                    tooltip: {
                        trigger: "axis",
                        valueFormatter: (val) => {
                            if(lines[2].show) {
                                return val+" db";
                            }
                            else {
                                return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                            }  
                        }
                    }
                }}
                onEvents={{
                    click: (line) => handleGoToLine(line)
                }}
                style={{width: "100%", height: "100%"}}
            />
        );
    }, [currentData, prevData, avg]);

    useEffect(() => {
        if(JSON.stringify(traffic) !== "[]") {
            calcCurrentTraffic();
            calcCurrentProfit();
            calcCurrentReceipt();

            calcPrevTraffic();
            calcPrevProfit();
            calcPrevReceipt();
        }
    }, [traffic?.current?.data, traffic?.prevYear?.data]);

    useEffect(() => {
        if(JSON.stringify(traffic) !== "[]") {
            if(lines[0].show) {
                setCurrentData({
                    name: lines[0].name,
                    color: lines[0].color,
                    data: [...currentTraffic]
                });

                setPrevData({
                    name: lines[0].name,
                    color: "#C4C4C4",
                    data: [...prevTraffic]
                });
            }
            else if(lines[1].show) {
                setCurrentData({
                    name: lines[1].name,
                    color: lines[1].color,
                    data: [...currentProfit]
                });

                setPrevData({
                    name: lines[1].name,
                    color: "#C4C4C4",
                    data: [...prevProfit]
                });
            }
            else if(lines[2].show) {
                setCurrentData({
                    name: lines[2].name,
                    color: lines[2].color,
                    data: [...currentReceipt]
                });

                setPrevData({
                    name: lines[2].name,
                    color: "#C4C4C4",
                    data: [...prevReceipt]
                });
            }
        }
    }, [currentTraffic, currentProfit, currentReceipt, prevTraffic, prevProfit, prevReceipt, lines]);

    const dateRange = (startDate, endDate) => {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate).toISOString("hu-HU").slice(0, 10));
            currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        }

        return dateArray;
    }

    const calcCurrentTraffic = () => {
        const unique = traffic.current.data.filter((obj, index) => {
            return index === traffic.current.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        }).reverse();

        const dateGroup = unique.reduce((group, x) => {
            (group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] = group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] || []).push(x);
            return group;
        }, {});

        const dateSum = [];
        
        Object.keys(dateGroup).map(key => {
            const sum = dateGroup[key].reduce((acc, obj) => {
                return acc + obj.daily_traffic_sum;
            }, 0);

            return dateSum.push({
                date: key,
                sum: sum
            });
        });

        const datesArr = dateRange(traffic.current.from, traffic.current.to);

        setDates(datesArr);

        let allDatesSum = [];

        dateSum.map(item => {
            const filtered = datesArr.filter(date => date === item.date);

            if(filtered.length === 0) {
                return allDatesSum.push({
                    date: item.date,
                    sum: 0
                });
            }
            else {
                return allDatesSum.push(item);
            }
        });

        setCurrentTraffic(allDatesSum);
    };

    const calcCurrentProfit = () => {
        const unique = traffic.current.data.filter((obj, index) => {
            return index === traffic.current.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        }).reverse();

        const dateGroup = unique.reduce((group, x) => {
            (group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] = group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] || []).push(x);
            return group;
        }, {});

        const dateSum = [];
        
        Object.keys(dateGroup).map(key => {
            const sum = dateGroup[key].reduce((acc, obj) => {
                return acc + obj.profit;
            }, 0);

            return dateSum.push({
                date: key,
                sum: sum
            });
        });

        const datesArr = dateRange(traffic.current.from, traffic.current.to);

        let allDatesSum = [];

        dateSum.map(item => {
            const filtered = datesArr.filter(date => date === item.date);

            if(filtered.length === 0) {
                return allDatesSum.push({
                    date: item.date,
                    sum: 0
                });
            }
            else {
                return allDatesSum.push(item);
            }
        });

        setCurrentProfit(allDatesSum);
    };

    const calcCurrentReceipt = () => {
        const unique = traffic.current.data.filter((obj, index) => {
            return index === traffic.current.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        }).reverse();

        const dateGroup = unique.reduce((group, x) => {
            (group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] = group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] || []).push(x);
            return group;
        }, {});

        const dateSum = [];
        
        Object.keys(dateGroup).map(key => {
            const sum = dateGroup[key].reduce((acc, obj) => {
                return acc + obj.receipt_quantity;
            }, 0);

            return dateSum.push({
                date: key,
                sum: sum
            });
        });

        const datesArr = dateRange(traffic.current.from, traffic.current.to);

        let allDatesSum = [];

        dateSum.map(item => {
            const filtered = datesArr.filter(date => date === item.date);

            if(filtered.length === 0) {
                return allDatesSum.push({
                    date: item.date,
                    sum: 0
                });
            }
            else {
                return allDatesSum.push(item);
            }
        });

        setCurrentReceipt(allDatesSum);
    };

    const calcPrevTraffic = () => {
        const unique = traffic.prevYear.data.filter((obj, index) => {
            return index === traffic.prevYear.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        }).reverse();

        const dateGroup = unique.reduce((group, x) => {
            (group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] = group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] || []).push(x);
            return group;
        }, {});

        const dateSum = [];
        
        Object.keys(dateGroup).map(key => {
            if(key !== traffic.prevYear.from) {
                const sum = dateGroup[key].reduce((acc, obj) => {
                    return acc + obj.daily_traffic_sum;
                }, 0);

                return dateSum.push({
                    date: key,
                    sum: sum
                });
            }
            return null;
        });

        const datesArr = dateRange(traffic.prevYear.from, traffic.prevYear.to);

        let allDatesSum = [];

        dateSum.map(item => {
            const filtered = datesArr.filter(date => date === item.date);

            if(filtered.length === 0) {
                return allDatesSum.push({
                    date: item.date,
                    sum: 0
                });
            }
            else {
                return allDatesSum.push(item);
            }
        });

        setPrevTraffic(allDatesSum);
    };

    const calcPrevProfit = () => {
        const unique = traffic.prevYear.data.filter((obj, index) => {
            return index === traffic.prevYear.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        }).reverse();

        const dateGroup = unique.reduce((group, x) => {
            (group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] = group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] || []).push(x);
            return group;
        }, {});

        const dateSum = [];
        
        Object.keys(dateGroup).map(key => {
            if(key !== traffic.prevYear.from) {
                const sum = dateGroup[key].reduce((acc, obj) => {
                    return acc + obj.profit;
                }, 0);

                return dateSum.push({
                    date: key,
                    sum: sum
                });
            }
            return null;
        });

        const datesArr = dateRange(traffic.prevYear.from, traffic.prevYear.to);

        let allDatesSum = [];

        dateSum.map(item => {
            const filtered = datesArr.filter(date => date === item.date);

            if(filtered.length === 0) {
                return allDatesSum.push({
                    date: item.date,
                    sum: 0
                });
            }
            else {
                return allDatesSum.push(item);
            }
        });

        setPrevProfit(allDatesSum);
    };

    const calcPrevReceipt = () => {
        const unique = traffic.prevYear.data.filter((obj, index) => {
            return index === traffic.prevYear.data.findIndex(o => new Date(obj.date_from).toISOString("hu-HU").slice(0, 10) === new Date(o.date_from).toISOString("hu-HU").slice(0, 10) && obj.store === o.store);
        }).reverse();

        const dateGroup = unique.reduce((group, x) => {
            (group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] = group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] || []).push(x);
            return group;
        }, {});

        const dateSum = [];
        
        Object.keys(dateGroup).map(key => {
            if(key !== traffic.prevYear.from) {
                const sum = dateGroup[key].reduce((acc, obj) => {
                    return acc + obj.receipt_quantity;
                }, 0);

                return dateSum.push({
                    date: key,
                    sum: sum
                });
            }
            return null;
        });

        const datesArr = dateRange(traffic.prevYear.from, traffic.prevYear.to);

        let allDatesSum = [];

        dateSum.map(item => {
            const filtered = datesArr.filter(date => date === item.date);

            if(filtered.length === 0) {
                return allDatesSum.push({
                    date: item.date,
                    sum: 0
                });
            }
            else {
                return allDatesSum.push(item);
            }
        });

        setPrevReceipt(allDatesSum);
    };

    const handleLineFilter = (line) => {
        const modifyArr = lines.map(item => {
            if(item.id === line.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: true
                };
            }
            
            return {
                id: item.id,
                name: item.name,
                color: item.color,
                show: false
            };
        });

        setLines(modifyArr);
    };

    const handleFilterAvg = (avgItem) => {
        const modifyArr = avg.map(item => {
            if(item.id === avgItem.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: !item.show
                };
            }

            return item;
        });

        setAvg(modifyArr);
    };

    const handleGoToLine = (line) => {
        onDaily(line.data);
    };

    return (
        <Grid item xs={12}>
            <Typography marginBottom={2}>Időszakos forgalom / nap</Typography>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {lines.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => handleLineFilter(item)}
                            />
                        );
                    })}
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {avg.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => handleFilterAvg(item)}
                            />
                        );
                    })}
                </Box>
            </Box>

            <Box
                width="100%"
                height={300}
            >
                {lineChartMemo}
            </Box>
        </Grid>
    );
};

export default TotalStores;