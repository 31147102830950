import { useContext, useState } from "react";

import { motion } from 'framer-motion';

import { DataContext } from "../../context/DataContext";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput, Paper, Stack, Typography } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";
import CurrencyInput from "../../components/currencyinput/CurrencyInput";

const CashIn = () => {
    const {user, setOpenOper, setMsg} = useContext(DataContext);

    const [cash, setCash] = useState({
        sum: "",
        comment: ""
    });

    const [open, setOpen] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleOpenVerify = () => {
        if(cash.sum === "" || cash.comment === "") {
            return setMsg({
                color: "error",
                message: "Minden mező kitöltése kötelező"
            });
        }

        setOpen(true);
    };

    const handleCashIn = async() => {const res = await fetch(apiUrl+"/cashregister/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                operation: 10,
                sum: cash.sum,
                comment: cash.comment
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres befizetés! (Összeg: "+cash.sum+" HUF)"
        });

        setOpenOper(0);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={10}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    component={Paper}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                    padding={2}
                >
                    <Stack
                        spacing={1}
                        width="100%"
                        maxWidth={300}
                    >
                        <Typography fontSize={14} fontWeight={600}>Összeg</Typography>

                        <CurrencyInput
                            val={cash.sum}
                            change={(val) => setCash(prev => ({...prev, sum: val}))}
                            size="small"
                            endAdornment={"HUF"}
                        />
                    </Stack>

                    <Stack
                        spacing={1}
                        width="100%"
                        maxWidth={300}
                    >
                        <Typography fontSize={14} fontWeight={600}>Megjegyzés</Typography>

                        <OutlinedInput
                            value={cash.comment}
                            onChange={(e) => setCash(prev => ({...prev, comment: e.target.value}))}
                            size="small"
                            multiline
                            rows={5}
                        />
                    </Stack>
                    
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        style={{ alignSelf: "flex-end" }}
                    >
                        <Button variant="contained" onClick={handleOpenVerify}>Tovább</Button>
                    </motion.div>

                    <Dialog
                        open={open}
                        onClose={() => setOpen(false)}
                        PaperProps={{
                            sx: { width: 400 }
                        }}
                    >
                        <DialogTitle>
                            Biztosan végrehajtod a műveltet?
                        </DialogTitle>

                        <DialogContent>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                width="100%"
                            >
                                <Typography fontSize={14} fontWeight={400}>Összeg: {cash.sum} HUF</Typography>
                                <Typography fontSize={12} fontWeight={400} color="#8C8C8C">Megjegyzés: {cash.comment}</Typography>
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button variant="outlined" onClick={() => setOpen(false)}>
                                Mégsem
                            </Button>

                            <Button variant="contained" onClick={handleCashIn}>
                                Véglegesítés
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CashIn;