import { useContext, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, Grid, InputAdornment, OutlinedInput, Paper, Typography } from "@mui/material";

import MailOutlineIcon from '@mui/icons-material/MailOutline';

import OpHeader from "../../../components/opheader/OpHeader";
import InviteRank from "./InviteRank";

const Invite = () => {
    const {setOpenOper} = useContext(DataContext);

    const [email, setEmail] = useState("");

    const [rankPage, setRankPage] = useState(false);

    const renderRank = () => {
        if(rankPage) {
            return (
                <InviteRank
                    email={email}
                    onBack={() => setRankPage(false)}
                />
            );
        }
    };

    return (
        <>
            {renderRank()}

            {!rankPage &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={29}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component={Paper}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            padding={2}
                        >
                            <Typography fontSize={16} fontWeight={500} color="#6489E2">
                                Felhasználó meghívása a szervezetbe
                            </Typography>

                            <Typography fontSize={12} color="#737373">
                                Add meg az email címet, ahova szeretnéd, hogy érkezzen a meghívó email
                            </Typography>

                            <OutlinedInput
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="értesítési email cím"
                                fullWidth
                                size="small"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <MailOutlineIcon />
                                    </InputAdornment>
                                }
                            />

                            <Button
                                variant="outlined"
                                disabled={!email}
                                onClick={() => setRankPage(true)}
                                sx={{
                                    alignSelf: "flex-end",
                                    borderRadius: 8,
                                    borderColor: "#6A86C4",
                                    color: "#6A86C4"
                                }}
                            >
                                Tovább
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default Invite;