import { useNavigate } from "react-router-dom";

import { Box, Button, Container, Grid, Paper, Toolbar, Typography } from "@mui/material";

import CompanySVG from "../../../assets/module/company_create.svg";

import Dashboard from "../../../layouts/dashboard/Dashboard";

const Company = () => {
    const navigate = useNavigate();

    return (
        <Dashboard>
            <Box
                component="main"
                flexGrow={1}
                height="100vh"
                overflow="auto"
                sx={{ bgcolor: "#FFF" }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                    <Paper sx={{ p: 3 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={12} md={6}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    gap={2}
                                >
                                    <Typography color="#6A86C4" fontWeight={500}>Szervezet létrehozása</Typography>
                                    
                                    <Typography color="#737373" fontSize={10} fontWeight={300}>
                                        Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor. Nam risus metus, tempor ut ante viverra, blandit vulputate augue. Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor. Nam risus metus, tempor ut ante viverra, blandit vulputate augue. 
                                    </Typography>

                                    <Button
                                        variant="outlined"
                                        sx={{
                                            alignSelf: "flex-end",
                                            borderRadius: 8,
                                            borderColor: "#6A86C4",
                                            color: "#6A86C4"
                                        }}
                                        onClick={() => navigate("/register/company/info")}
                                    >
                                        Gyerünk, kezdjük el!
                                    </Button>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <img
                                    src={CompanySVG}
                                    alt="company"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Box>
        </Dashboard>
    );
};

export default Company;