import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";

import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import StoreItem from "./content/StoreItem";

const StoreFilter = ({ stores, onClick, onRemove }) => {
    return (
        <Stack spacing={1}>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>Telephelyek</Typography>
                <IconButton
                    disabled={stores.filter(item => item.selected).length === 0}
                    onClick={onRemove}
                >
                    <FilterAltOffIcon />
                </IconButton>
            </Box>

            <Grid container spacing={1}>
                {stores.map(item => {
                    return (
                        <StoreItem
                            key={item.id}
                            store={item}
                            onClick={onClick}
                        />
                    );
                })}
            </Grid>
        </Stack>
    );
};

export default StoreFilter;