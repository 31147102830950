import { Chip } from '@mui/material';

const Option = ({ option, icon, selected, onSelect }) => {
    if(!selected) {
        return (
            <Chip 
                size="large" 
                icon={icon}
                label={option.name}
                onClick={onSelect}
                variant='outlined'
                sx={{ 
                    borderStyle: "dashed", 
                    borderRadius: 1,
                    justifyContent:"left",
                    gap: 1,
                    '&:hover': {
                        backgroundColor: '#F5F5F5',
                        borderStyle: 'solid', 
                        transform: 'scale(1.02)'
                    }
                }}
            />
        );
    }
    else {
        return (
            <Chip 
                size="large" 
                icon={icon}
                label={option.name}
                onClick={onSelect}
                variant='outlined'
                sx={{ 
                    borderStyle: "solid",
                    backgroundColor: '#F5F5F5',
                    borderRadius: 1,
                    justifyContent:"left",
                    gap: 1,
                    '&:hover': {
                        transform: 'scale(1.02)'
                    }
                }}
            />
        );
    }
};

export default Option;