import { TableCell, TableRow } from "@mui/material";

const ResItem = ({ res }) => {
    return (
        <TableRow>
            <TableCell>{res.date}</TableCell>

            <TableCell>{res.name}</TableCell>

            <TableCell>{res.email}</TableCell>

            <TableCell>{res.phone}</TableCell>

            <TableCell>{res.start_date+" - "+res.end_date}</TableCell>
        </TableRow>
    );
};

export default ResItem;