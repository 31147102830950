import { useState } from "react";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import TableItem from "./content/TableItem";

const PackModify = ({ open, setOpen, invs, onModify }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const [choosenInvs, setChoosenInvs] = useState([]);

    const handleAddInvoice = (invoice) => {
        setChoosenInvs(prev => ([...prev, invoice]));
    };

    const handleRemoveInvoice = (invoice) => {
        setChoosenInvs(prev => prev.filter(item => item.serial_num !== invoice.serial_num));
    };

    return(
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 1000
                    }
                }
            }}
        >
            <DialogTitle>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <IconButton
                        onClick={() => setOpen(false)}
                        size="small"
                    >
                        <ArrowBackIosIcon style={{ fontSize: 16 }} />
                    </IconButton>

                    <IconButton
                        size="small"
                    >
                        <OpenInFullIcon style={{ fontSize: 16 }} />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography
                        fontSize={20}
                        fontWeight={700}
                        color="#8C8C8C"
                    >
                        Utalási tételek szerkesztése
                    </Typography>

                    <Typography
                        fontSize={12}
                        fontWeight={300}
                        color="#8C8C8C"
                    >
                        Válazd ki meglévő utalási listák közül amelyhez a kiválasztott tételeket hozzá szeretnéd rendeli, Vagy hozz létre új utalási listát, a kiválasztott elemeknek.
                    </Typography>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 300,
                                            color: "#737373"
                                        }}
                                    >
                                        Számla kelte
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 300,
                                            color: "#737373"
                                        }}
                                    >
                                        Számla teljesítése
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 300,
                                            color: "#737373"
                                        }}
                                    >
                                        Fizetési határidő
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 300,
                                            color: "#737373"
                                        }}
                                    >
                                        Partner megnevezése
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 300,
                                            color: "#737373"
                                        }}
                                    >
                                        Számlaszám
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 300,
                                            color: "#737373"
                                        }}
                                    >
                                        Lejárat
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 300,
                                            color: "#737373"
                                        }}
                                    >
                                        Számla összege
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 300,
                                            color: "#737373"
                                        }}
                                    >
                                        Számla azonosító
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 300,
                                            color: "#737373"
                                        }}
                                    >
                                        Kijelölés
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {invs.map(item => {
                                    return (
                                        <TableItem
                                            key={item.id}
                                            invoice={item}
                                            onAdd={handleAddInvoice}
                                            onRemove={handleRemoveInvoice}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </DialogContent>

            <DialogActions>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="flex-end"
                    width="100%"
                >
                    <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        sx={{
                            color: "#262626",
                            borderColor: "#D9D9D9",
                            "&:hover": {
                                color: "#262626",
                                borderColor: "#D9D9D9"
                            }
                        }}
                    >
                        Vissza
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => {
                            onModify(choosenInvs);
                            setOpen(false);
                        }}
                        disabled={choosenInvs.length === 0}
                    >
                        Kijelölt elemek eltávolítása a listáról
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default PackModify;