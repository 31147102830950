import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ReactEcharts from "echarts-for-react";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import DailyVatStyle from "./DailyVat.module.css";

import OpsHeader from "../../../layouts/opsheader/OpsHeader";

import Feedback from "../../../components/feedback/Feedback";

const DailyVat = ({ data, line, onClose }) => {
    const {user, msg, setMsg, loading, setLoading} = useContext(DataContext);

    const [dailyVat, setDailyVat] = useState([]);
    const [dailyProfit, setDailyProfit] = useState([]);

    const [stores, setStores] = useState([]);

    const chartRef = useRef();

    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        calcDailyVat();
        calcDailyProfit();

        setLoading(false);
    }, []);

    const handleBack = () => {
        // chartRef.current.className = HomeStyle.op_content_close;

        const timer = setTimeout(() => {
            setMsg({});
            onClose();
        }, 1000);
        return () => clearTimeout(timer);
    };

    const calcDailyVat = () => {
        const filtered = data.current.data.filter(item => item.date === line.date);

        // const vats = Object.groupBy(filtered, ({vat}) => vat);

        const vats = filtered.reduce((group, x) => {
            (group[x["vat"]] = group[x["vat"]] || []).push(x);
            return group;
        }, {});

        let sumStoresObj = {};

        Object.keys(vats).map(key => {
            // const storesGroup = Object.groupBy(vats[key], ({store_name}) => store_name);
            
            const storesGroup = vats[key].reduce((group, x) => {
                (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
                return group;
            }, {});

            const storesSum = [];

            Object.keys(storesGroup).map(storeKey => {
                const sum = storesGroup[storeKey].reduce((acc, obj) => {
                    return acc + obj.unit_value;
                }, 0);

                storesSum.push({
                    store: storeKey,
                    sum: sum
                });
            });

            return sumStoresObj[key] = storesSum;
        });

        setStores([...sumStoresObj["0"].map(item => item.store)]);

        setDailyVat(sumStoresObj);
    };

    const calcDailyProfit = () => {
        const filtered = data.current.data.filter(item => item.date === line.date);

        //const currentStores = Object.groupBy(filtered, ({store_name}) => store_name);

        const currentStores = filtered.reduce((group, x) => {
            (group[x["store_name"]] = group[x["store_name"]] || []).push(x);
            return group;
        }, {});

        const currentStoresProfit = Object.keys(currentStores).map(key => {
            const unitSum = currentStores[key].reduce((acc, obj) => {
                return acc + obj.unit_value;
            }, 0);

            const purchaseSum = currentStores[key].reduce((acc, obj) => {
                return acc + obj.purchase_value;
            }, 0);

            return {
                store: key,
                color: currentStores[key][0].color_code_2,
                sum: ((unitSum-purchaseSum).toFixed())
            };
        });

        setDailyProfit(currentStoresProfit);
    };

    return (
        <div style={{overflow: "hidden"}}>
            <div
                style={{backgroundColor: "#FFFFFF", zIndex: "15"}}
                ref={chartRef}
            >
                <div>
                    {
                        Object.keys(msg).length !== 0 ?
                            <Feedback
                                color={msg.color}
                                message={msg.message}
                            />
                            :
                            <></>
                    }

                    <OpsHeader
                        operation={19}
                        opsRef={chartRef}
                    />
                </div>

                <div>
                    <div className={DailyVatStyle.container}>
                        <div className={DailyVatStyle.chart_container}>
                            <ReactEcharts
                                option = {{
                                    title: {
                                        text: "Forgalom ÁFA körökre lebontva",
                                        left: "center"
                                    },
                                    tooltip: {
                                        trigger: 'axis',
                                        valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                                    },
                                    grid: {
                                        containLabel: true
                                    },
                                    xAxis: {
                                        type: 'value'
                                    },
                                    yAxis: {
                                        type: 'category',
                                        data: [...stores]
                                    },
                                    series: [...Object.keys(dailyVat).map(key => ({
                                        name: key,
                                        type: 'bar',
                                        stack: 'total',
                                        label: {
                                            show: true
                                        },
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        data: [...dailyVat[key].map(item => item.sum)]
                                    }))]
                                }}
                                style={{width: "100%", height: "110%"}}
                            />
                        </div>

                        <div className={DailyVatStyle.chart_container}>
                            <ReactEcharts
                                option={{
                                    title: {
                                        text: "Profit az adott időszakban",
                                        left: "center"
                                    },
                                    series: [
                                        {
                                            name: "Profit az adott időszakban",
                                            type: "pie",
                                            radius: ["40%", "70%"],
                                            data: [...dailyProfit.map(item => ({
                                                name: item.store,
                                                value: Number(item.sum),
                                                itemStyle: {color: item.color}
                                            }))]
                                        }
                                    ],
                                    tooltip: {
                                        trigger: "item",
                                        valueFormatter: (val) => val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null})
                                    },
                                    grid: {
                                        containLabel: true
                                    }
                                }}
                                style={{width: "100%", height: "100%"}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyVat;