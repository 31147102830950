import { useContext } from "react";

import { DataContext } from "../../../../../../context/DataContext";

import { Avatar, AvatarGroup, Box, Grid, Skeleton, Stack, Tooltip, Typography } from "@mui/material";

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

import Tag from "../tag/Tag";
import CurrencyText from "../../../../../../components/currencytext/CurrencyText";
import StoreIcon from "../../../../../../layouts/stores/content/StoreIcon";

const InvoiceBody = ({ invoice, payments, costplaces, tags, onRefresh, onItemized }) => {
    const {stores} = useContext(DataContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return ( 
        <Box
            display="flex"
            flexDirection="column"
            padding={1}
            color="#000"
        >
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
            >
                {invoice.record_date ?
                    <Tag
                        type="costplace"
                        invoice={invoice}
                        tags={tags}
                        costplaces={costplaces}
                        payments={payments}
                        tagLabel={invoice.costplace_name ? invoice.costplace_name : null}
                        tagIcon={<BookmarkBorderOutlinedIcon />}
                        tagTipLabel="Számla költséghelye / tétele"
                        onRefresh={onRefresh}
                    />
                :
                    <Skeleton variant="rounded" width={90} height={20} />
                }

                {invoice.filing_user &&
                    <AvatarGroup max={2}>
                        <StoreIcon
                            store={stores.filter(item => item.id === invoice.store)[0]}
                            size="medium"
                        />

                        <Tooltip
                            title={invoice.filing_last+" "+invoice.filing_first}
                            arrow
                        >
                            <Avatar
                                src={imageExists(apiUrl+"/"+invoice.filing_pic) ? apiUrl+"/"+invoice.filing_pic : null}
                                sx={{ zIndex: 2, width: 32, height: 32 }}
                            />
                        </Tooltip>
                    </AvatarGroup>
                }
            </Box>

            <Box 
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                marginTop={2}
                marginBottom={2}
                onClick={onItemized}
                sx={{ cursor: "pointer" }}
            >
                <Box 
                    display="flex"
                    alignItems="center"
                    maxWidth={250}
                >
                    {invoice.partner_name ?
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                        >
                            {invoice.partner_name.length > 51 ? invoice.partner_name.slice(0, 50)+"..." : invoice.partner_name}
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={220} height={25} />
                    }
                </Box>

                {invoice.sum === 0 ?
                    <Tag
                        type="calculate"
                        invoice={invoice}
                        tagIcon={<ControlPointIcon />}
                        tagLabel={null}
                        tagTipLabel="Kalkuláció"
                        onRefresh={onRefresh}
                    />
                :
                    <Stack spacing={0.5}>
                        {invoice.sum ?
                            <Typography
                                fontSize={14}
                                fontWeight={400}
                            >
                                <CurrencyText
                                    val={invoice.sum}
                                /> Ft
                            </Typography>
                        :
                            <Skeleton variant="rounded" width={80} height={20} />
                        }

                        {invoice.sum ?
                            <Typography
                                fontSize={12}
                                fontWeight={400}
                                color="#8C8C8C"
                            >
                                <CurrencyText
                                    val={invoice.sum}
                                /> Ft
                            </Typography>
                        :
                            <Skeleton variant="rounded" width={70} height={15} />
                        }
                    </Stack>
                }
            </Box>

            <Box display="flex" marginBottom={2}>
                {invoice.record_date ?
                    <Tag
                        type="payment"
                        invoice={invoice}
                        tags={tags}
                        payments={payments}
                        costplaces={costplaces}
                        tagIcon={<AddCardOutlinedIcon/>}
                        tagLabel={invoice.payment_name ? invoice.payment_name : null}
                        tagTipLabel="Fizetési mód"
                        onRefresh={onRefresh}
                    />
                :
                    <Skeleton variant="rounded" width={90} height={20} />
                }
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Stack spacing={0.5}>
                    <Typography
                        fontSize={10}
                        fontWeight={300}
                        color="#737373"
                    >
                        Számla kelte
                    </Typography>

                    {invoice.record_date ?
                        <Tag
                            type="record"
                            invoice={invoice}
                            tagIcon={invoice.record_date ? <CalendarTodayOutlinedIcon /> : <ControlPointIcon />} 
                            tagLabel={invoice.record_date ? invoice.record_date : null}
                            tagTipLabel="Rögzítés dátuma"
                            onRefresh={onRefresh}
                        />
                    :
                        <Skeleton variant="rounded" width={80} height={20} />
                    }
                </Stack>

                <Stack spacing={0.5}>
                    <Typography
                        fontSize={10}
                        fontWeight={300}
                        color="#737373"
                    >
                        Számla teljesítés
                    </Typography>

                    {invoice.record_date ?
                        <Tag
                            type="delivery"
                            invoice={invoice}
                            tagIcon={invoice.delivery_date ? <CalendarTodayOutlinedIcon /> : <ControlPointIcon />} 
                            tagLabel={invoice.delivery_date ? invoice.delivery_date : null}
                            tagTipLabel="Teljesítés dátuma"
                            onRefresh={onRefresh}
                        />
                    :
                        <Skeleton variant="rounded" width={80} height={20} />
                    }
                </Stack>

                <Stack spacing={0.5}>
                    <Typography
                        fontSize={10}
                        fontWeight={300}
                        color="#737373"
                    >
                        Fizetési határidő
                    </Typography>

                    {invoice.record_date ?
                        <Tag
                            type="deadline"
                            invoice={invoice}
                            payments={payments}
                            tagIcon={invoice.payment_deadline ? <CalendarTodayOutlinedIcon /> : <ControlPointIcon />} 
                            tagLabel={invoice.payment_deadline ? invoice.payment_deadline : null}
                            tagTipLabel="Fizetési határidő"
                            onRefresh={onRefresh}
                        />
                    :
                        <Skeleton variant="rounded" width={80} height={20} />
                    }
                </Stack>
            </Box>

            <Grid
                container
                spacing={0.8}
                marginTop={2}
            >
                <Grid item>
                    <Tag
                        type="newtag"
                        invoice={invoice}
                        tags={tags}
                        tagIcon={<LocalOfferOutlinedIcon />}
                        tagLabel={null}
                        tagTipLabel="Címke / Megjegyzés"
                        onRefresh={onRefresh}
                    />
                </Grid>

                {invoice.tags?.map(item => {
                    return (
                        <Grid item key={item.id}>
                            <Tag
                                type="tag"
                                invoice={invoice}
                                tags={tags}
                                tagId={item.id}
                                tagIcon={<LocalOfferOutlinedIcon />}
                                tagLabel={item.name}
                                tagTipLabel={null}
                                onRefresh={onRefresh}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
     );
}
 
export default InvoiceBody;