import { useState } from "react";

import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";

import TransferItem from "./content/transfer/TransferItem";
import CurrencyText from "../../../../components/currencytext/CurrencyText";
import NewPackDialog from "./content/transfer/NewPackDialog";

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
    const stabilizedArray = array.map((el, index) => [el, index]);

    stabilizedArray.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedArray.map((el) => el[0]);
};

const TransferTable = ({ invoices, onRefresh }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');

    const [transferInvs, setTransferInvs] = useState([]);

    const [open, setOpen] = useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleAddInvoice = (invoice) => {
        setTransferInvs(prev => ([...prev, invoice]));
    };

    const handleRemoveInvoice = (invoice) => {
        setTransferInvs(prev => prev.filter(item => item.serial_num !== invoice.serial_num));
    };

    const calculateSum = () => {
        const sum = invoices.reduce((acc, obj) => {
            return acc += obj.sum;
        }, 0);

        return sum;
    };

    const calculateExpired = () => {
        const sum = invoices.reduce((acc, obj) => {
            if(new Date(obj.payment_deadline) < new Date()) {
                return acc += obj.sum;
            }

            return acc;
        }, 0);

        return sum;
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Stack spacing={0.5}>
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="#000"
                        >
                            Utalandó nyitott tételek: <CurrencyText val={calculateSum()} /> Ft
                        </Typography>

                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#737373"
                        >
                            Ebből lejárt számla tartozás: <CurrencyText val={calculateExpired()} /> Ft
                        </Typography>
                    </Stack>

                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        alignItems="center"
                    >
                        <Typography
                            fontSize={14}
                            fontWeight={300}
                            color="#000"
                        >
                            Műveletek:
                        </Typography>

                        <Button
                            variant="contained"
                            onClick={() => setOpen(true)}
                            disabled={transferInvs.length === 0}
                            sx={{
                                bgcolor: "#8C8C8C",
                                opacity: 0.7,
                                ":hover": {
                                    bgcolor: "#8C8C8C",
                                    opacity: 1
                                }
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={0.5}
                                alignItems="center"
                            >
                                <Typography
                                    fontSize={14}
                                    fontWeight={300}
                                >
                                    Utalási listához rendelés
                                </Typography>

                                <Typography
                                    fontSize={7}
                                    fontWeight={300}
                                >
                                    {transferInvs.length} elem kijelölve
                                </Typography>
                            </Box>
                        </Button>

                        <NewPackDialog
                            open={open}
                            setOpen={setOpen}
                            transferInvs={transferInvs}
                            onRefresh={() => {
                                setTransferInvs([]);
                                onRefresh();
                            }}
                        />
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {[
                                    { id: 'record_date', label: 'Számla kelte' },
                                    { id: 'delivery_date', label: 'Számla teljesítése' },
                                    { id: 'payment_deadline', label: 'Fizetési határidő' },
                                    { id: 'partner_name', label: 'Partner megnevezése' },
                                    { id: 'partner_bank', label: 'Számlaszám' }
                                ].map(column => {
                                    return(
                                        <TableCell
                                            key={column.id}
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: 300,
                                                color: "#737373"
                                            }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                                })}

                                <TableCell
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: 300,
                                        color: "#737373"
                                    }}
                                >
                                    Lejárat
                                </TableCell>

                                {[
                                    { id: 'sum', label: 'Számla összege' },
                                    { id: 'serial_num', label: 'Számla azonosító' }
                                ].map(column => {
                                    return(
                                        <TableCell
                                            key={column.id}
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: 300,
                                                color: "#737373"
                                            }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                                })}

                                <TableCell
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: 300,
                                        color: "#737373"
                                    }}
                                >
                                    Kijelölés
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {stableSort(invoices, getComparator(order, orderBy)).map((item) => {
                                return (
                                    <TransferItem
                                        key={item.id}
                                        invoice={item}
                                        onAdd={handleAddInvoice}
                                        onRemove={handleRemoveInvoice}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default TransferTable;