import { useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

import { DataContext } from "../../context/DataContext";

import TicketStockStyle from "./TicketStock.module.css";

import StoreBubble from "../storebubble/StoreBubble";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const TicketStock = ({ store, ticket }) => {
    const {user, stores, setMsg} = useContext(DataContext);

    const [stock, setStock] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        socket.emit("join", ["cid:"+stores.filter(s => s.id === user.store)[0].company]);
        getStore();
    }, []);

    useEffect(() => {
        socket.on("update_stock", () => {
            getStore();
        });
    }, [socket]);

    const getStock = async(s) => {
        const res = await fetch(apiUrl+"/tickets/stock/"+store.id+"/"+ticket, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        if(data.stock.length !== 0) {
            if(data.traffic) {
                const minusQuantity = data.traffic?.reduce((acc, obj) => {
                    return acc + obj.quantity;
                }, 0);

                setStock({
                    store: s.name,
                    colorCode: s.color_code_2,
                    name: data.stock[0].name,
                    value: data.stock[0].value,
                    quantity: Number(data.stock.length) - minusQuantity,
                    sum: (Number(data.stock.length) - minusQuantity) * data.stock[0].value
                });
            }
            else {
                setStock({
                    store: s.name,
                    colorCode: s.color_code_2,
                    name: data.stock[0].name,
                    value: data.stock[0].value,
                    quantity: Number(data.stock.length),
                    sum: Number(data.stock.length)* data.stock[0].value
                });
            }
        }
    };

    const getStore = async() => {
        const res = await fetch(apiUrl+"/store/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        getStock(data.store);
    };

    if(JSON.stringify(stock) !== "{}") {
        return (
            <tr className={TicketStockStyle.row}>
                <td>
                    <StoreBubble
                        text={stock.store}
                        colorCode={stock.colorCode}
                    />
                </td>
                <td>
                    {stock.name}
                </td>
                <td>
                    {stock.value} HUF
                </td>
                <td>
                    {stock.quantity} db
                </td>
                <td>
                    {stock.sum} HUF
                </td>
            </tr>
        );
    }
};

export default TicketStock;