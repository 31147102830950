import { useContext, useState } from "react";

import axios from "axios";

import { DataContext } from "../../../context/DataContext";

import { Box, Button, Grid, OutlinedInput, Paper, Step, StepButton, Stepper, Typography } from "@mui/material";

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import OpHeader from "../../../components/opheader/OpHeader";

const NewUserPw = ({ newUser, ops, onClose, onProfile, onPersonal }) => {
    const {user, setMsg, setOpenOper} = useContext(DataContext);

    const [pw, setPw] = useState({
        first: "",
        second: ""
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleNext = async() => {
        if(Object.values(pw).some(prop => prop === "") || pw.first !== pw.second) {
            return setMsg({
                color: "error",
                message: "Minden mező kitöltése kötelező és meg kell hogy egyezzenek a jelszavak"
            });
        }

        const res = await fetch(apiUrl+"/user/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                company: user.company.id,
                profile: newUser,
                stores: newUser.stores,
                ops: ops,
                pw: pw.first
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        const file = new FormData();
        file.append("file", newUser.profilePic);

        axios.post(apiUrl+"/upload/avatar/"+data.user.insertId, file);

        setOpenOper(0);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OpHeader
                    operation={15}
                    onClose={onClose}
                />
            </Grid>

            <Grid item xs={12}>
                <Paper
                    sx={{ p: 1 }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stepper activeStep={3}>
                                <Step>
                                    <StepButton
                                        icon={<PersonOutlineOutlinedIcon />}
                                        onClick={onProfile}
                                    >
                                        Profil
                                    </StepButton>
                                </Step>

                                <Step>
                                    <StepButton
                                        icon={<PersonOutlineOutlinedIcon />}
                                        onClick={onPersonal}
                                    >
                                        Személyes adatok
                                    </StepButton>
                                </Step>

                                <Step>
                                    <StepButton
                                        icon={<PersonOutlineOutlinedIcon />}
                                        onClick={onClose}
                                    >
                                        Jogosultságok
                                    </StepButton>
                                </Step>

                                <Step>
                                    <StepButton icon={<PersonOutlineOutlinedIcon style={{ color: "#1890FF" }} />}>
                                        Jelszó
                                    </StepButton>
                                </Step>
                            </Stepper>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={2}
                                width={300}
                                marginLeft="auto"
                                marginRight="auto"
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={1}

                                >
                                    <Typography fontSize={16} fontWeight={500}>Jelszavak</Typography>
                                    <HelpOutlineIcon />
                                </Box>

                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={1}

                                >
                                    <Typography fontSize={14} fontWeight={400}>Új jelszó</Typography>
                                    <OutlinedInput
                                        type="password"
                                        value={pw.first}
                                        onChange={(e) => setPw(prev => ({...prev, first: e.target.value}))}
                                        size="small"
                                    />
                                </Box>

                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={1}

                                >
                                    <Typography fontSize={14} fontWeight={400}>Új jelszó mégegyszer</Typography>
                                    <OutlinedInput
                                        type="password"
                                        value={pw.second}
                                        onChange={(e) => setPw(prev => ({...prev, second: e.target.value}))}
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                flexDirection="row"
                                gap={1}
                                justifyContent="flex-end"
                            >
                                <Button variant="outlined" onClick={onClose}>Vissza</Button>
                                <Button variant="contained" onClick={handleNext}>Véglegesítés</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default NewUserPw;