import { useContext } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Box, Chip } from "@mui/material";

import StoreIcon from "../../../../layouts/stores/content/StoreIcon";

const StockItem = ({ stockInfo }) => {
    const {stores} = useContext(DataContext);

    return (
        <Box
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
        >
            <StoreIcon
                store={stores.filter(item => item.id === stockInfo.store)[0]}
                size="medium"
            />

            <Chip
                label={stockInfo.quantity+" db"}
                size="small"
                sx={{
                    fontSize: 10,
                    fontWeight: 400,
                    borderRadius: 1
                }}
            />
        </Box>
    );
};

export default StockItem;