import { useContext, useEffect, useMemo, useState } from "react";

import ReactEcharts from "echarts-for-react";

import { DataContext } from "../../../context/DataContext";

import { Box, Grid, Typography } from "@mui/material";

import ChartFilter from "../../../components/charts/chartfilter/ChartFilter";

const TotalUsers = ({ traffic, onDaily }) => {
    const {stores} = useContext(DataContext);
    
    const [profiles, setProfiles] = useState([]);

    const [trafficStores, setTrafficStores] = useState([]);
    const [bars, setBars] = useState([
        {
            id: 0,
            name: "Forgalom",
            color: "#5470C6",
            show: true
        },
        {
            id: 1,
            name: "Ledolgozott napok",
            color: "#5470C6",
            show: false
        },
        {
            id: 2,
            name: "Ledolgozott órák",
            color: "#5470C6",
            show: false
        },
        {
            id: 3,
            name: "Kosárérték",
            color: "#5470C6",
            show: false
        }
    ]);

    const barChartMemo = useMemo(() => {
        return (
            <ReactEcharts
                option={{
                    grid: {
                        containLabel: true,
                        left: "4%",
                        right: "4%",
                        top: "0%",
                        bottom: "0%"
                    },
                    xAxis: {
                        type: "value"
                    },
                    yAxis: {
                        type: "category",
                        data: profiles.map(item => item.name)
                    },
                    series: [{
                        type: "bar",
                        smooth: true,
                        showBackground: false,
                        name: "Összes forgalom felhasználónként",
                        data: [...profiles.map(item => ({
                            value: item.sum,
                            user: item.user,
                            name: item.name,
                            from: traffic.current.from,
                            to: traffic.current.to,
                            itemStyle: {color: "#5470c6"}
                        }))],
                        markLine: {
                            label: {
                                show: false
                            },
                            symbol: "circle",
                            data: [
                                {
                                    type: "average",
                                    name: "Átlag",
                                    lineStyle: {
                                        width: 1,
                                        color: "#686868"
                                    }
                                }
                            ]
                        }
                    }],
                    tooltip: {
                        trigger: "axis",
                        valueFormatter: (val) => {
                            if(bars[1].show) {
                                return val+" nap";
                            }
                            else if(bars[2].show) {
                                return val+" óra";
                            }
                            else {
                                return val.toLocaleString("hu-HU", {style: "currency", currency: "HUF", maximumFractionDigits: null});
                            }  
                        } 
                    }
                }}
                onEvents={{
                    click: (bar) => onDaily(bar.data)
                }}
                style={{width: "100%",  height: (profiles.length*30)+"px"}}
            />
        );
    }, [profiles]);

    useEffect(() => {
        getStores();
    }, []);

    useEffect(() => {
        if(JSON.stringify(traffic) !== "[]") {
            const current = [];

            trafficStores.map(item => {
                if(item.show) {
                    current.push(...traffic.current.data.filter(element => element.store === item.id));
                }

                return 0;
            });

            const showData = {
                ...traffic,
                current: {
                    ...traffic.current,
                    data: current
                }
            };
            
            calcRealData(showData);
        }
    }, [traffic?.current?.data, trafficStores, bars]);

    const getStores = () => {
        setTrafficStores(stores.map(item => {
            return {
                id: item.id,
                name: item.name,
                color: item.color_code_2,
                show: true
            };
        }));
    };

    const calcRealData = (showData) => {
        const storeGroup = showData.current.data.reduce((group, x) => {
            (group[x["store"]] = group[x["store"]] || []).push(x);
            return group;
        }, {});

        Object.keys(storeGroup).map(key => {
            storeGroup[key].sort((a, b) => new Date(a.date_from) - new Date(b.date_from));
            storeGroup[key] = storeGroup[key].reduce((group, x) => {
                (group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] = group[new Date(x["date_from"]).toISOString("hu-HU").slice(0, 10)] || []).push(x);
                return group;
            }, {});
        });

        const realUserInfo = [];

        Object.keys(storeGroup).map(key => {
            Object.keys(storeGroup[key]).map(dateKey => {
                let prevItem = {};

                storeGroup[key][dateKey].map(item => {
                    if(JSON.stringify(prevItem) === "{}") {
                        realUserInfo.push(item);
                    }
                    else {
                        realUserInfo.push({
                            ...item,

                            a_tax: item.a_tax - prevItem.a_tax,
                            b_tax: item.b_tax - prevItem.b_tax,
                            c_tax: item.c_tax - prevItem.c_tax,
                            d_tax: item.d_tax - prevItem.d_tax,
                            e_tax: item.e_tax - prevItem.e_tax,

                            a_tax_profit: item.a_tax_profit - prevItem.a_tax_profit,
                            b_tax_profit: item.b_tax_profit - prevItem.b_tax_profit,
                            c_tax_profit: item.c_tax_profit - prevItem.c_tax_profit,
                            d_tax_profit: item.d_tax_profit - prevItem.d_tax_profit,
                            e_tax_profit: item.e_tax_profit - prevItem.e_tax_profit,

                            daily_traffic_sum: item.daily_traffic_sum - prevItem.daily_traffic_sum,
                            sum: item.sum - prevItem.sum,
                            profit: item.profit - prevItem.profit,
                            receipt_quantity: item.receipt_quantity - prevItem.receipt_quantity,
                            return_product: item.return_product - prevItem.return_product,
                            canceled: item.canceled - prevItem.canceled
                        });
                    }

                    return prevItem = item;
                });
            });
        });

        calcProfiles(realUserInfo);
    };

    const calcProfiles = (showData) => {
        const usersGroup = showData.reduce((group, x) => {
            (group[x["user"]] = group[x["user"]] || []).push(x);
            return group;
        }, {});

        const currentUsersSum = Object.keys(usersGroup).map(key => {
            let sum = 0;

            if(bars[0].show) {
                sum = usersGroup[key].reduce((acc, obj) => {
                    return acc + obj.daily_traffic_sum;
                }, 0);
            }
            else if(bars[1].show) {
                sum = usersGroup[key].length;
            }
            else if(bars[2].show) {
                sum = usersGroup[key].reduce((acc, obj) => {
                    const milliseconds = Math.abs(new Date(obj.date_from) - new Date(obj.date_to));
                    const hours = milliseconds / 36e5;

                    return acc + hours;
                }, 0).toFixed(2);
            }
            else if(bars[3].show) {
                const customers = usersGroup[key].reduce((acc, obj) => {
                    return acc + obj.receipt_quantity;
                }, 0);

                sum = Number((usersGroup[key].reduce((acc, obj) => {
                    return acc + obj.daily_traffic_sum;
                }, 0) / customers).toFixed());
            }

            return {
                user: key,
                name: usersGroup[key][0].last_name+" "+usersGroup[key][0].first_name,
                color: usersGroup[key][0].profile_color,
                sum: sum
            };
        });

        setProfiles(currentUsersSum.sort((a, b) => a.sum - b.sum));
    };

    const handleFilterStore = (store) => {
        const modifyArr = trafficStores.map(item => {
            if(item.id === store.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: !item.show
                };
            }
            
            return item;
        });

        setTrafficStores(modifyArr);
    };

    const handleFilterBar = (bar) => {
        const modifyArr = bars.map(item => {
            if(item.id === bar.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: true
                };
            }
            
            return {
                id: item.id,
                name: item.name,
                color: item.color,
                show: false
            };
        });

        setBars(modifyArr);
    };

    return (
        <Grid item xs={12}>
            <Typography marginBottom={2}>Időszakos forgalom / felhasználók</Typography>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {trafficStores.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => handleFilterStore(item)}
                            />
                        );
                    })}
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    {bars.map(item => {
                        return (
                            <ChartFilter
                                key={item.id}
                                name={item.name}
                                colorCode={item.color}
                                active={item.show}
                                click={() => handleFilterBar(item)}
                            />
                        );
                    })}
                </Box>
            </Box>

            <Box width="100%">
                {barChartMemo}
            </Box>
        </Grid>
    );
};

export default TotalUsers;