import { Box, Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";

import TableItem from "./TableItem";
import SearchInput from "./content/SearchInput";
import MarginInput from "./content/MarginInput";
import { useEffect, useState } from "react";

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
    const stabilizedArray = array.map((el, index) => [el, index]);

    stabilizedArray.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedArray.map((el) => el[0]);
};

const ItemizedTable = ({ itemizedTraffic, storeFilter, slot, onSearch, onMargin }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');

    const [filteredItems, setFilteredItems] = useState(itemizedTraffic.filter(item =>
        storeFilter.filter(s => s.active).map(s => s.id).includes(item.store)
    ));

    const [vatFilter, setVatFilter] = useState([true, true, true, true]);

    useEffect(() => {
        let filtered = itemizedTraffic;

        if(!vatFilter[0]) {
            filtered = filtered.filter(item => item.vat !== 5)
        }

        if(!vatFilter[1]) {
            filtered = filtered.filter(item => item.vat !== 18)
        }

        if(!vatFilter[2]) {
            filtered = filtered.filter(item => Number(item.vat) !== 27)
        }

        if(!vatFilter[3]) {
            filtered = filtered.filter(item => item.vat !== 0)
        }

        setFilteredItems(filtered.filter(item =>
            storeFilter.filter(s => s.active).map(s => s.id).includes(item.store)
        ));
    }, [itemizedTraffic, vatFilter, storeFilter]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    if(slot === "Tételes forgalom") {
        return (
            <>
                <Grid item xs={12} md={6} marginTop={0.5}>
                    <SearchInput onSearch={onSearch} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <MarginInput traffic={itemizedTraffic} onMargin={onMargin} />
                </Grid>

                <Grid item xs={12}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Checkbox
                                checked={vatFilter[0]}
                                onChange={(e) => setVatFilter(prev => [e.target.checked, prev[1], prev[2], prev[3]])}
                            />

                            <Typography color="#000">5%</Typography>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Checkbox
                                checked={vatFilter[1]}
                                onChange={(e) => setVatFilter(prev => [prev[0], e.target.checked, prev[2], prev[3]])}
                            />

                            <Typography color="#000">18%</Typography>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Checkbox
                                checked={vatFilter[2]}
                                onChange={(e) => setVatFilter(prev => [prev[0], prev[1], e.target.checked, prev[3]])}
                            />

                            <Typography color="#000">27%</Typography>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Checkbox
                                checked={vatFilter[3]}
                                onChange={(e) => setVatFilter(prev => [prev[0], prev[1], prev[2], e.target.checked])}
                            />

                            <Typography color="#000">AJT</Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ bgcolor: "#FAFAFA" }}>
                                    {[
                                        { id: 'user', label: 'Felhasználó' },
                                        { id: 'date', label: 'Dátum / Idő' },
                                        { id: 'receipt_num_1', label: 'Nyugtaszám' },
                                        { id: 'barcode', label: 'Vonalkód' },
                                        { id: 'item_name', label: 'Megnevezés' },
                                        { id: 'quantity', label: 'Mennyiség' },
                                        { id: 'unit_price', label: 'Egységár' },
                                        { id: 'purchase_price', label: 'Beszerár' },
                                        { id: 'unit_value', label: 'Eladási érték' },
                                        { id: 'purchase_value', label: 'Beszerzési érték' },
                                        { id: 'vat', label: 'Áfa' },
                                        { id: 'margin', label: 'Árrés' }
                                    ].map((column) => (
                                        <TableCell key={column.id} sx={{ color: "#434343" }}>
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {stableSort(filteredItems, getComparator(order, orderBy)).map((item) => (
                                    <TableItem
                                        key={item.id}
                                        line={item}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </>
        );
    }
};

export default ItemizedTable;