import { Box, Button, Typography } from "@mui/material";

const RankItem = ({ rank, onChoose }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={1}
            width="100%"
        >
            <Typography fontSize={16} fontWeight={500} color="#6489E2">
                {rank.name}
            </Typography>

            <Typography fontSize={12} color="#737373">
                {rank.description}
            </Typography>

            <Button
                variant="outlined"
                onClick={onChoose}
                sx={{
                    color: "#6489E2",
                    alignSelf: "flex-end",
                    borderRadius: 6
                }}
            >
                Kiválaszt
            </Button>
        </Box>
    );
};

export default RankItem;