import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Divider, List } from "@mui/material";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import NavGroup from "./NavGroup";
import NavItem from "./NavItem";

import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import NavAccordion from "./NavAccordion";

const NavContent = ({ open }) => {
    const {user, stores, setMsg, setOpenOper} = useContext(DataContext);

    const navigate = useNavigate();

    const handleGoToShop = () => {
        user.stores.map(store => {
            const storeName = stores.filter(s => s.id === store.id)[0]?.name;

            if(storeName){
                getStoreStatus(storeName).then(data => {
                    if(Number(data.uid) === user.id && Number(data.operation) !== 2){
                        setMsg({});
                        setOpenOper(0);
                        navigate("/shoparea/"+storeName);
                    }
                });
            }
        });
    };

    return (
        <List>
            <NavGroup
                group="Menu"
                open={open}
            >
                <NavItem
                    name="Home"
                    icon={<HomeIcon/>}
                    open={open}
                    click={handleGoToShop}
                />
            </NavGroup>

                {/*<NavItem
                    name="Eladótér"
                    icon={<ShoppingCartOutlinedIcon/>}
                    open={open}
                    click={handleGoToShop}
                />

                <NavAccordion
                    name="Accordion"
                    icon={<SignalCellularAltOutlinedIcon/>}
                    open={open}
                >
                    <NavItem
                        name="Elem1"
                        icon={<ShoppingCartOutlinedIcon/>}
                        open={open}
                    />

                    <NavItem
                        name="Elem2"
                        icon={<ShoppingCartOutlinedIcon/>}
                        open={open}
                    />
                </NavAccordion>
            </NavGroup>

            {open ? <Divider sx={{ marginTop: 2, marginBottom: 2 }} /> : null}

            <NavGroup
                group="Dashboard"
                open={open}
            >
                <NavItem
                    name="Riportok"
                    icon={<SignalCellularAltOutlinedIcon/>}
                    open={open}
                />
            </NavGroup>

            {open ? <Divider sx={{ marginTop: 2, marginBottom: 2 }} /> : null}

            <NavGroup
                group="Vezérlőpult"
                open={open}
            >
                <NavItem
                    name="Mail"
                    icon={<MailOutlineOutlinedIcon/>}
                    open={open}
                />

                <NavItem
                    name="Chat"
                    icon={<SmsOutlinedIcon/>}
                    open={open}
                />

                <NavItem
                    name="Calendar"
                    icon={<CalendarTodayOutlinedIcon/>}
                    open={open}
                />

                <NavItem
                    name="E-commerce"
                    icon={<ShoppingCartOutlinedIcon/>}
                    open={open}
                />
            </NavGroup>*/}
        </List>
    );
};

export default NavContent;