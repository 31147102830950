import { Box, Button, Dialog, DialogActions, DialogContentText, IconButton, InputAdornment, OutlinedInput, Typography, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SumDialog = ({ open, setOpen, choosen, barcode, quantity, boxQuantity, comment, unit, onNext }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <IconButton
                    onClick={() => setOpen(false)}
                    size="small"
                >
                    <ArrowBackIosIcon style={{ fontSize: 16 }} />
                </IconButton>

                <IconButton
                    size="small"
                >
                    <OpenInFullIcon style={{ fontSize: 16 }} />
                </IconButton>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                alignItems="center"
            >
                <DialogContentText fontSize={20}>
                    {choosen.itemName}
                </DialogContentText>

                <DialogContentText fontSize={12}>{barcode}</DialogContentText>

                <DialogContentText
                    fontSize={16}
                    alignSelf="flex-start"
                    paddingLeft={1}
                >
                    ÖSSZEGZÉS
                </DialogContentText>

                <DialogContentText
                    fontSize={12}
                    alignSelf="flex-start"
                    paddingLeft={1}
                >
                    Győződj meg róla, hogy a számlán szereplő mennyiség megérkezett. Amennyiben szükséges úgy bontsd tételekre a csomagolást.
                </DialogContentText>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={12}>Vonalkód</DialogContentText>

                    <OutlinedInput
                        value={barcode}
                        readOnly
                        size="small"
                        fullWidth
                        endAdornment={choosen.priceChange === 1 && 
                            <InputAdornment position="end">
                                <Box
                                    bgcolor="#61D890"
                                    color="#FFF"
                                    borderRadius={2}
                                    paddingX={1}
                                    fontSize={12}
                                >
                                    új
                                </Box>
                            </InputAdornment>
                        }
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={12}>Megnevezés</DialogContentText>

                    <OutlinedInput
                        value={choosen.itemName}
                        readOnly
                        size="small"
                        fullWidth
                        endAdornment={choosen.priceChange === 1 && 
                            <InputAdornment position="end">
                                <Box
                                    bgcolor="#61D890"
                                    color="#FFF"
                                    borderRadius={2}
                                    paddingX={1}
                                    fontSize={12}
                                >
                                    új
                                </Box>
                            </InputAdornment>
                        }
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="95%"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        width="47%"
                    >
                        <DialogContentText fontSize={12}>Érkezett</DialogContentText>

                        <OutlinedInput
                            value={Number(choosen.quantity)*Number(boxQuantity)}
                            readOnly
                            size="small"
                            fullWidth
                            endAdornment={
                                <Box
                                    p={0.5}
                                    sx={{
                                        backgroundColor: "#8C8C8C",
                                        marginRight: -1.7,
                                        borderTopRightRadius: 2,
                                        borderBottomRightRadius: 2,
                                        height: 30
                                    }}
                                >
                                    <Typography color="#FFF" fontSize={14} marginTop={0.5}>
                                        {unit}
                                    </Typography>
                                </Box>
                            }
                        />
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        width="47%"
                    >
                        <DialogContentText fontSize={12}>Számolt</DialogContentText>

                        <OutlinedInput
                            value={quantity}
                            readOnly
                            size="small"
                            fullWidth
                            endAdornment={
                                <Box
                                    p={0.5}
                                    sx={{
                                        backgroundColor: "#8C8C8C",
                                        marginRight: -1.7,
                                        borderTopRightRadius: 2,
                                        borderBottomRightRadius: 2,
                                        height: 30
                                    }}
                                >
                                    <Typography color="#FFF" fontSize={14} marginTop={0.5}>
                                        {unit}
                                    </Typography>
                                </Box>
                            }
                        />
                    </Box>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={12}>Eltérés</DialogContentText>

                    <OutlinedInput
                        value={(-Number(choosen.quantity)*Number(boxQuantity))+Number(quantity)}
                        readOnly
                        size="small"
                        fullWidth
                        endAdornment={
                            (-Number(choosen.quantity)*Number(boxQuantity))+Number(quantity) === 0 ?
                                <CheckCircleOutlineIcon style={{ color: "#13C2C2" }} />
                            :
                                <InfoIcon style={{ color: "#FA541C" }} />
                        }
                        sx={(-Number(choosen.quantity)*Number(boxQuantity))+Number(quantity) === 0 && {
                            color: "#13C2C2",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#13C2C2"
                            },
                            "&:hover": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#13C2C2"
                                }
                            },
                            "&:focused": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#13C2C2"
                                }
                            }
                        }}
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={12}>Egységár nettó</DialogContentText>

                    <OutlinedInput
                        value={(Number(choosen.unitPrice)/Number(boxQuantity))+" HUF"}
                        readOnly
                        size="small"
                        fullWidth
                        endAdornment={
                            choosen.priceChange === 0 ?
                                <HorizontalRuleIcon style={{ color: "orange" }} />
                            :
                            choosen.priceChange === 1 ?
                                <AddIcon style={{ color: "green" }} />
                            :
                            choosen.priceChange === 2 ?
                                <KeyboardArrowUpIcon style={{ color: "red" }} />
                            :
                            choosen.priceChange === 3 &&
                                <KeyboardArrowDownIcon style={{ color: "green" }} />
                        }
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={12}>Áfakör</DialogContentText>

                    <OutlinedInput
                        value={(choosen.vat*100)+" %"}
                        readOnly
                        size="small"
                        fullWidth
                        endAdornment={
                            <KeyboardArrowDownIcon />
                        }
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={12}>Megjegyzés</DialogContentText>

                    <OutlinedInput
                        value={comment}
                        readOnly
                        size="small"
                        fullWidth
                    />
                </Box>
            </Box>

            <DialogActions>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        sx={{
                            color: "#262626",
                            borderColor: "#D9D9D9",
                            "&:hover": {
                                color: "#262626",
                                borderColor: "#D9D9D9"
                            }
                        }}
                    >
                        Vissza
                    </Button>

                    <Button
                        variant="contained"
                        onClick={onNext}
                        sx={{
                            bgcolor: "#8C8C8C",
                            "&:hover": {
                                bgcolor: "#8C8C8C"
                            }
                        }}
                    >
                        Bevételezési listához ad
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default SumDialog;