import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, OutlinedInput, Stack, useMediaQuery, useTheme } from "@mui/material";
import { DateCalendar } from '@mui/x-date-pickers';

import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

import DialogHeader from "./DialogHeader";
import Option from "./content/Option";

const InputDialog = ({ type, invoice, payments, costplaces, open, newTag, setNewTag, onVerify, onClose }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    if(type === "deadline") {
        return (
            <Dialog fullScreen={matches} open={open} onClose={onClose} >
                <DialogTitle>
                    <DialogHeader invoice={invoice} />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Kérlek add meg fizetési határidőt.
                    </DialogContentText>

                    <DateCalendar
                        value={newTag}
                        onChange={(newValue) => {
                            setNewTag(newValue);
                        }}
                        sx={{ color: "#000" }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Mégse</Button>
                    <Button onClick={onVerify} autoFocus>Megerősít</Button>
                </DialogActions>
            </Dialog>
        );
    }
    else if(type === "payment") {
        return (
            <Dialog fullScreen={matches} open={open} onClose={onClose} >
                <DialogTitle>
                    <DialogHeader invoice={invoice} />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Kérlek add meg a fizetési módot.
                    </DialogContentText>

                    <Stack margin={4} gap={1}>
                        {payments?.map(item => {
                            return (
                                <Option
                                    key={item.id}
                                    option={item}
                                    icon={<CreditCardOutlinedIcon />}
                                    selected={item.id === newTag}
                                    onSelect={() => setNewTag(item.id)}
                                />
                            );
                        })}
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Mégse</Button>
                    <Button onClick={onVerify} disabled={!newTag} autoFocus>Megerősít</Button>
                </DialogActions>
            </Dialog>
        );
    }
    else if(type === "costplace") {
        return (
            <Dialog fullScreen={matches} open={open} onClose={onClose} >
                <DialogTitle>
                    <DialogHeader invoice={invoice} />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Kérlek add meg a költséghelyet.
                    </DialogContentText>

                    <Stack margin={4} gap={1}>
                        {costplaces?.map(item => {
                            return (
                                <Option
                                    key={item.id}
                                    option={item}
                                    icon={<PushPinOutlinedIcon />}
                                    selected={item.id === newTag}
                                    onSelect={() => setNewTag(item.id)}
                                />
                            );
                        })}
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Mégse</Button>
                    <Button onClick={onVerify} disabled={!newTag} autoFocus>Megerősít</Button>
                </DialogActions>
            </Dialog>
        );
    }
    else if(type === "newtag") {
        return (
            <Dialog fullScreen={matches} open={open} onClose={onClose} >
                <DialogTitle>
                    <DialogHeader invoice={invoice} />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Kérlek add meg a cimke nevét.
                    </DialogContentText>

                    <OutlinedInput
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        placeholder="Cimke"
                        size="small"
                        fullWidth
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Mégse</Button>
                    <Button onClick={onVerify} disabled={!newTag} autoFocus>Megerősít</Button>
                </DialogActions>
            </Dialog>
        );
    }
    else if(type === "tag") {
        return (
            <Dialog fullScreen={matches} open={open} onClose={onClose} >
                <DialogTitle>
                    <DialogHeader invoice={invoice} />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Módosítsd a cimke nevét.
                    </DialogContentText>

                    <OutlinedInput
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        placeholder="Cimke"
                        size="small"
                        fullWidth
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Mégse</Button>
                    <Button onClick={onVerify} disabled={!newTag} autoFocus>Megerősít</Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default InputDialog;