import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Box, Grid, Paper, Skeleton, Typography } from "@mui/material";

import ItemizedTag from "./content/ItemizedTag";

const ItemizedHeader = ({ invoice }) => {
    const {setMsg} = useContext(DataContext);

    const [companyInfo, setCompanyInfo] = useState({});
    const [partnerInfo, setPartnerInfo] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getCompanyInfo();
    }, []);

    const getCompanyInfo = async() => {
        const res = await fetch(apiUrl+"/company/"+invoice.company, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setCompanyInfo(data.company);

        getPartnerInfo();
    };

    const getPartnerInfo = async() => {
        const res = await fetch(apiUrl+"/partner/"+invoice.partner, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPartnerInfo(data.partner);
    };

    return (
        <>
            <Grid item xs={12} md={6}>
                <Box
                    component={Paper}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    padding={1}
                    gap={0.5}
                    boxSizing="border-box"
                    width="100%"
                    height="100%"
                >
                    <Typography
                        fontSize={14}
                        fontWeight={300}
                        color="#747474"
                        marginBottom={1}
                    >
                        Szállító
                    </Typography>

                    {partnerInfo.name ?
                        <Typography fontSize={14} fontWeight={500} color="#747474">{partnerInfo?.name}</Typography>
                    :
                        <Skeleton variant="rounded" width={180} height={20} />
                    }

                    {partnerInfo.name ?
                        <Typography fontSize={12} fontWeight={300} color="#747474">{partnerInfo?.tax_number}</Typography>
                    :
                        <Skeleton variant="rounded" width={140} height={15} />
                    }
                    
                    {partnerInfo.address ?
                        <Typography fontSize={12} fontWeight={300} color="#747474">
                            {partnerInfo.address}
                        </Typography>
                    :
                        <ItemizedTag
                            type="address"
                            partner={true}
                            companyData={partnerInfo}
                            tagLabel="+ cím hozzáadása"
                            onRefresh={getCompanyInfo}
                        />
                    }

                    {partnerInfo.bank_account ?
                        <Typography fontSize={12} fontWeight={300} color="#747474">
                            {partnerInfo.bank_account}
                        </Typography>
                    :
                        <ItemizedTag
                            type="bank"
                            partner={true}
                            companyData={partnerInfo}
                            tagLabel="+ bankszámla hozzáadása"
                            onRefresh={getCompanyInfo}
                        />
                    }
                </Box>
            </Grid>

            <Grid item xs={12} md={6}>
                <Box
                    component={Paper}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    padding={1}
                    boxSizing="border-box"
                    width="100%"
                    height="100%"
                >
                    <Typography
                        fontSize={14}
                        fontWeight={300}
                        color="#747474"
                        marginBottom={1}
                    >
                        Vevő
                    </Typography>

                    {companyInfo.name ?
                        <Typography fontSize={14} fontWeight={500} color="#747474">{companyInfo?.name}</Typography>
                    :
                        <Skeleton variant="rounded" width={180} height={20} />
                    }

                    {companyInfo.tax_number ?
                        <Typography fontSize={12} fontWeight={300} color="#747474">
                            {companyInfo.tax_number}
                        </Typography>
                    :
                        <ItemizedTag
                            type="tax"
                            partner={false}
                            companyData={companyInfo}
                            tagLabel="+ adószám hozzáadása"
                            onRefresh={getCompanyInfo}
                        />
                    }

                    {companyInfo.address ?
                        <Typography fontSize={12} fontWeight={300} color="#747474">
                            {companyInfo.address}
                        </Typography>
                    :
                        <ItemizedTag
                            type="address"
                            partner={false}
                            companyData={companyInfo}
                            tagLabel="+ cím hozzáadása"
                            onRefresh={getCompanyInfo}
                        />
                    }

                    {companyInfo.bank_account ?
                        <Typography fontSize={12} fontWeight={300} color="#747474">
                            {companyInfo.bank_account}
                        </Typography>
                    :
                        <ItemizedTag
                            type="bank"
                            partner={false}
                            companyData={companyInfo}
                            tagLabel="+ bankszámla hozzáadása"
                            onRefresh={getCompanyInfo}
                        />
                    }
                </Box>
            </Grid>
        </>
    );
};

export default ItemizedHeader;