import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";
import { getUserOperations } from "../../services/Api";

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, OutlinedInput, Paper, Typography } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import OpHeader from "../../components/opheader/OpHeader";
import RankItem from "./content/RankItem";

const CreateRank = () => {
    const {user, setOpenOper, setMsg} = useContext(DataContext);

    const [ops, setOps] = useState([]);
    const [checkedOps, setCheckedOps] = useState({});

    const [newData, setNewData] = useState({
        name: "",
        desc: ""
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getUserOperations(user.id).then(data => {
            const grouped = data.filter(item => Number(item.company) === Number(user.company.id)).reduce((group, x) => {
                (group[x["group_id"]] = group[x["group_id"]] || []).push(x);
                return group;
            }, {});

            setOps(grouped);

            setCheckedOps(() => {
                return data.reduce((acc, obj) => ({ ...acc, [obj.operation]: false}), {});
            });
        });
    }, []);

    const handleCheckboxChange = (itemId) => {
        setCheckedOps(prev => ({ ...prev, [itemId]: !prev[itemId] }));
    };

    const handleSaveRank = async() => {
        const opsArr = [];

        Object.keys(checkedOps).map(itemId => {
            if(checkedOps[itemId]) return opsArr.push({operation: Number(itemId)});
            return;
        });

        const res = await fetch(apiUrl+"/rank/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                newData: newData,
                company: user.company.id,
                ops: opsArr
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeresen létrehoztad a rangot!"
        });

        setOpenOper(0);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={30}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Grid item xs={12}>
                <Box
                    component={Paper}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    padding={2}
                >
                    <Button
                        variant="outlined"
                        onClick={handleSaveRank}
                        disabled={Object.values(newData).some(item => item === "") || !Object.values(checkedOps).some(item => item === true)}
                        sx={{
                            color: "#6489E2",
                            alignSelf: "flex-start",
                            borderRadius: 6
                        }}
                    >
                        Létrehozás
                    </Button>

                    <OutlinedInput
                        value={newData.name}
                        onChange={(e) => setNewData(prev => ({...prev, name: e.target.value}))}
                        placeholder="megnevezés"
                        fullWidth
                        size="small"
                    />

                    <OutlinedInput
                        value={newData.desc}
                        onChange={(e) => setNewData(prev => ({...prev, desc: e.target.value}))}
                        placeholder="leírás"
                        fullWidth
                        size="small"
                    />

                    <Grid container spacing={3} sx={{ marginTop: 2 }}>
                        {Object.keys(ops).map(groupId => {
                            return (
                                <Grid key={groupId} item xs={12}>
                                    <Accordion defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography fontSize={16} fontWeight={500}>{ops[groupId][0].group_name}</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            {ops[groupId].map((item) => {
                                                return (
                                                    <RankItem
                                                        key={item.operation}
                                                        op={item}
                                                        checked={checkedOps[item.operation]}
                                                        onSwitch={() => handleCheckboxChange(item.operation)}
                                                    />
                                                );
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CreateRank;