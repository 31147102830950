import { useContext, useState } from "react";

import { DataContext } from "../../context/DataContext";

import { Box, Button, Grid, Grow, OutlinedInput, Paper, Typography } from "@mui/material";

import TechUserJPEG from "../../assets/home/modules/tech_user.jpeg";

import OpHeader from "../../components/opheader/OpHeader";

const AddProduct = () => {
    const {user, setOpenOper, setMsg, setLoading} = useContext(DataContext);

    const [inputs, setInputs] = useState({
        name: "",
        barcode: "",
        fastcode: "",
        vat: ""
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleAddProduct = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/product/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                product: inputs
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "A termék sikeresen felvezetve"
        });

        setOpenOper(0);

        setLoading(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={32}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Paper sx={{ p: 3, marginTop: 2 }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} md={6}>
                        <img
                            src={TechUserJPEG}
                            alt="techuser"
                            style={{ width: "100%" }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            gap={2}
                        >
                            <Typography color="#6A86C4" fontWeight={500}>Új termék hozzáadása</Typography>
                            
                            <Typography color="#737373" fontSize={10} fontWeight={300}>
                                Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor. Nam risus metus, tempor ut ante viverra, blandit vulputate augue. Duis tempor ultrices eros, eu rutrum metus dignissim id. Nullam iaculis eros in odio tincidunt imperdiet. Vivamus sit amet dui ex. Suspendisse in ipsum vitae lorem semper tempor. Nam risus metus, tempor ut ante viverra, blandit vulputate augue. 
                            </Typography>

                            <Grow in={true} timeout={1000}>
                                <OutlinedInput
                                    placeholder="megnevezés"
                                    fullWidth
                                    value={inputs.name}
                                    onChange={(e) => setInputs(prev => ({...prev, name: e.target.value}))}
                                    size="small"
                                    sx={{ bgcolor: "#FFF" }}
                                />
                            </Grow>

                            <Grow in={true} timeout={1500}>
                                <OutlinedInput
                                    placeholder="vonalkód"
                                    fullWidth
                                    value={inputs.barcode}
                                    onChange={(e) => setInputs(prev => ({...prev, barcode: e.target.value}))}
                                    size="small"
                                    sx={{ bgcolor: "#FFF" }}
                                />
                            </Grow>

                            <Grow in={true} timeout={2000}>
                                <OutlinedInput
                                    placeholder="gyorskód"
                                    fullWidth
                                    value={inputs.fastcode}
                                    onChange={(e) => setInputs(prev => ({...prev, fastcode: e.target.value}))}
                                    size="small"
                                    sx={{ bgcolor: "#FFF" }}
                                />
                            </Grow>

                            <Grow in={true} timeout={2500}>
                                <OutlinedInput
                                    placeholder="ÁFA kulcs"
                                    fullWidth
                                    value={inputs.vat}
                                    endAdornment={"%"}
                                    onChange={(e) => setInputs(prev => ({...prev, vat: e.target.value}))}
                                    size="small"
                                    sx={{ bgcolor: "#FFF" }}
                                />
                            </Grow>

                            <Box
                                display="flex"
                                flexDirection="row"
                                gap={1}
                                alignSelf="flex-end"
                            >
                                <Button
                                    variant="outlined"
                                    disabled={inputs.name === "" || inputs.vat === "" || (inputs.barcode === "" && inputs.fastcode === "")}
                                    onClick={handleAddProduct}
                                    sx={{
                                        borderRadius: 8,
                                        borderColor: "#6A86C4",
                                        color: "#6A86C4"
                                    }}
                                >
                                    Hozzáadása
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default AddProduct;