import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";

import { Box, Button, Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";
import IncomeItem from "./content/IncomeItem";
import CurrencyText from "../../components/currencytext/CurrencyText";
import StoreDialog from "./content/StoreDialog";
import SummaryTable from "./SummaryTable";

const IncomeTable = ({ income, onClose, onCheck, onCheckAll }) => {
    const {user, setLoading, setMsg, setOpenOper} = useContext(DataContext);

    const [compStores, setCompStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState({});

    const [open, setOpen] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getCompanyStores();
    }, []);

    const getCompanyStores = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/company/stores/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setCompStores(data.stores);

        setLoading(false);
    };

    const handleNote = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/income/note", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                serialNum: income.packNumber,
                user: user.id,
                store: selectedStore.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        handleProducts(data.note)
    };

    const handleProducts = async(note) => {
        const res = await fetch(apiUrl+"/income/products", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                products: income.lines
            })
        });

        const data = await res.json();

        if(!data.success) {
            console.log("1: "+data.message);
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        handleIncome(note);
    };

    const handleIncome = async(note) => {
        const res = await fetch(apiUrl+"/income/income", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                store: selectedStore.id,
                user: user.id,
                products: income.lines,
                noteId: note
            })
        });

        const data = await res.json();

        if(!data.success) {
            console.log("2: "+data.message);
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        handlePrice();
    };

    const handlePrice = async() => {
        const res = await fetch(apiUrl+"/income/price", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                products: income.lines
            })
        });

        const data = await res.json();

        if(!data.success) {
            console.log("3: "+data.message);
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        handleStock();
    };

    const handleStock = async() => {
        const res = await fetch(apiUrl+"/income/stock", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                store: selectedStore.id,
                products: income.lines
            })
        });

        const data = await res.json();

        if(!data.success) {
            console.log("4: "+data.message);
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres bevételezés"
        });
        setLoading(false);
        setOpenOper(0);
    };

    const renderSummary = () => {
        if(JSON.stringify(selectedStore) !== "{}") {
            return (
                <SummaryTable
                    income={income}
                    store={selectedStore}
                    onVerify={handleNote}
                    onClose={() => setSelectedStore({})}
                />
            );
        }
    };

    return (
        <>
            {renderSummary()}

            {JSON.stringify(selectedStore) === "{}" &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            text={income.packNumber}
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            width="100%"
                        >
                            <Button
                                size="large"
                                variant="contained"
                                disabled={income.lines.filter(item => !item.ok).length !== 0}
                                onClick={() => setOpen(true)}
                            >
                                Tovább
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <caption>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        gap={2}
                                        color="#000"
                                    >
                                        <Typography fontSize={14} fontWeight={400}>
                                            Total: <Typography fontWeight={600}><CurrencyText val={income.totals.grandTotal} /> HUF</Typography>
                                        </Typography>
                                    </Box>
                                </caption>

                                <TableHead>
                                    <TableRow sx={{ bgcolor: "#FAFAFA" }}>
                                        <TableCell sx={{ color: "#434343" }}>#</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Vonalkód</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Cikkszám</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Megnevezés</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Darabszám</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Eladási ár</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Vétel ár</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Eladási ár összesen</TableCell>
                                        <TableCell sx={{ color: "#434343" }}>Vétel ár összesen</TableCell>
                                        <TableCell sx={{ color: "#434343", textAlign: "center" }}>
                                            <Checkbox
                                                checked={income.lines.length > 0 && income.lines.filter(item => item.ok).length === income.lines.length}
                                                indeterminate={income.lines.filter(item => item.ok).length > 0 && income.lines.filter(item => item.ok).length < income.lines.length}
                                                onChange={onCheckAll}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {income.lines.map((item, i) => {
                                        return (
                                            <IncomeItem
                                                key={i}
                                                id={i}
                                                row={item}
                                                onCheck={onCheck}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <StoreDialog
                        open={open}
                        setOpen={setOpen}
                        stores={compStores}
                        onChoose={(store) => {
                            setSelectedStore(store);
                            setOpen(false);
                        }}
                    />
                </Grid>
            }
        </>
    );
};

export default IncomeTable;