import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { motion } from 'framer-motion';
import { Box, Button, Grid, OutlinedInput, Paper } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";
import InvoiceItemized from "./InvoiceItemized";
import InvoiceItem from "./content/InvoiceItem";
import IncomeTable from "./content/content/income/IncomeTable";

const NewInvoice = () => {
    const {user, setMsg, setLoading, setOpenOper, stores} = useContext(DataContext);

    const [serialNum, setSerialNum] = useState("");
    const [invoice, setInvoice] = useState({});

    const [tags, setTags] = useState([]);
    const [payments, setPayments] = useState([]);
    const [costplaces, setCostplaces] = useState([]);
    const [locations, setLocations] = useState([]);

    const [open, setOpen] = useState({});
    const [summary, setSummary] = useState([]);
    const [income, setIncome] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(!user.company.nav_token) {
            setMsg({
                color: "error",
                message: "Nincs megadva technikai felhasználó a szervezethez"
            });
            return setOpenOper(0);
        }

        getCompanyTags();
        getPayments();
        getCostplaces();
        getLocations();

        getNewInvoices();
    }, [user]);

    const getCompanyTags = async() => {
        const res = await fetch(apiUrl+"/tags/company/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTags(data.tags);
    };

    const getPayments = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/payments", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setPayments(data.payments);
    };

    const getCostplaces = async() => {
        const res = await fetch(apiUrl+"/inbound/costplaces", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setCostplaces(data.costplaces);
    };

    const getLocations = async() => {
        const res = await fetch(apiUrl+"/inbound/locations", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setLocations(data.locations);
    };

    const getNewInvoices = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/newinvoices", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ token: user.company.nav_token, company: user.company.id })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: data.noNew ? "success" : "error",
                message: data.message
            });
        }

        if(data.invoices.length !== 0) {
            return syncPartners(data.invoices);
        }

        setLoading(false);
    };

    const syncPartners = async(invoices) => {
        const res = await fetch(apiUrl+"/inbound/sync/partners", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(invoices)
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        syncInvoices(invoices);
    };

    const syncInvoices = async(invoices) => {
        const res = await fetch(apiUrl+"/inbound/sync/invoices", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company: user.company.id,
                invoices: invoices
            })
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setLoading(false);
    };

    const handleGetInvoice = async() => {
        const res = await fetch(apiUrl+"/inbound/get/serial", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ serialNum: serialNum })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setInvoice(data.invoice);
    };

    const renderItemized = () => {
        if(JSON.stringify(open) !== "{}") {
            return (
                <InvoiceItemized
                    invoice={open}
                    payments={payments}
                    costplaces={costplaces}
                    locations={locations}
                    tags={tags}
                    onRefresh={() => handleGetInvoice(open.serial_num)}
                    onClose={() => setOpen({})}
                />
            );
        }
    };

    const renderIncome = () => {
        if(JSON.stringify(income) !== "{}") {
            return (
                <IncomeTable
                    invoice={income}
                    store={stores.filter(item => item.id === user.store)[0]}
                    onClose={() => setIncome({})}
                />
            );
        }
    };

    return (
        <>
            {renderItemized()}
            {renderIncome()}

            {(JSON.stringify(open) === "{}" && JSON.stringify(income) === "{}") &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={4}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            component={Paper}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                            width="100%"
                            padding={2}
                            boxSizing="border-box"
                        >
                            <OutlinedInput
                                value={serialNum}
                                onChange={(e) => setSerialNum(e.target.value)}
                                size="small"
                                placeholder="Számlaszám"
                            />

                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <Button variant="contained" onClick={handleGetInvoice}>Lekérdezés</Button>
                            </motion.div>
                        </Box>
                    </Grid>

                    {JSON.stringify(invoice) !== "{}" &&
                        <InvoiceItem
                            invoice={invoice}
                            tags={tags}
                            payments={payments}
                            costplaces={costplaces}
                            locations={locations}
                            store={user.store}
                            onItemized={() => setOpen(invoice)}
                            onRefresh={() => {
                                getCompanyTags();
                                handleGetInvoice(invoice.serial_num);
                            }}
                            onReceived={(invoiceInfo) => setSummary(invoiceInfo)}
                            onIncome={() => setIncome(invoice)}
                        />
                    }
                </Grid>
            }
        </>
    );
};

export default NewInvoice;