import { useContext, useState } from "react";

import { DataContext } from "../../../../../context/DataContext";

import { Avatar, AvatarGroup, Box, IconButton, Tooltip, Typography } from "@mui/material";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import StoreIcon from "../../../../../layouts/stores/content/StoreIcon";
import PackageItem from "../../transit/content/PackageItem";

const Package = ({ pack, onChoose, onRemove }) => {
    const {stores} = useContext(DataContext);

    const [open, setOpen] = useState(true);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = pack[0].transit_pic ? apiUrl+"/"+pack[0].transit_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={1}
        >
            <Box
                display="flex"
                flexDirection="row"
                gap={1}
                alignItems="center"
            >
                <AvatarGroup max={2}>
                    <StoreIcon
                        store={stores.filter(item => item.id === pack[0].store)[0]}
                        size="medium"
                    />

                    <Tooltip
                        title={pack[0].transit_last+" "+pack[0].transit_first}
                        arrow
                    >
                        <Avatar
                            src={imageExists(avatar) ? avatar : null}
                            sx={{ zIndex: 2, width: 32, height: 32 }}
                        />
                    </Tooltip>
                </AvatarGroup>

                <Box
                    display="flex"
                    flexDirection="column"
                    color="#000"
                >
                    <Typography fontSize={14} fontWeight={500}>{pack[0].transit_num}</Typography>
                    <Typography fontSize={12} fontWeight={300}>{pack[0].date_of_transit+" ("+pack.length+" tétel)"}</Typography>
                </Box>

                {open ?
                    <IconButton
                        onClick={() => setOpen(prev => !prev)}
                        size="small"
                        sx={{ marginLeft: "auto" }}
                    >
                        <KeyboardArrowUpIcon />
                    </IconButton>
                :
                    <IconButton
                        onClick={() => setOpen(prev => !prev)}
                        size="small"
                        sx={{ marginLeft: "auto" }}
                    >
                        <KeyboardArrowDownIcon />
                    </IconButton>
                }
            </Box>

            {open && pack.map(item => {
                return (
                    <PackageItem
                        key={item.id}
                        pack={item}
                        onChoose={() => onChoose(item)}
                        onRemove={() => onRemove(item)}
                    />
                );
            })}
        </Box>
    );
};

export default Package;