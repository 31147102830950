import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../../../context/DataContext";

import { Button, Grid, Grow, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Stack, Typography } from "@mui/material";

import IncomeTable from "./IncomeTable";
import OpHeader from "../../../../../../components/opheader/OpHeader";
import Stores from "../../../../../../layouts/stores/Stores";
import StoreIcon from "../../../../../../layouts/stores/content/StoreIcon";

const IncomeStore = ({ invoice, onClose }) => {
    const {user, setMsg, setLoading} = useContext(DataContext);

    const [compStores, setCompStores] = useState([]);
    const [choosenStore, setChoosenStore] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getCompanyStores();
    }, []);

    const getCompanyStores = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/company/stores/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setCompStores(data.stores);

        setLoading(false);
    };

    const renderTable = () => {
        if(JSON.stringify(choosenStore) !== "{}") {
            return (
                <IncomeTable
                    invoice={invoice}
                    store={choosenStore}
                    onClose={() => setChoosenStore({})}
                />
            );
        }
    };

    return (
        <>
            {renderTable()}

            {JSON.stringify(choosenStore) === "{}" &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OpHeader
                            text="Bevételezés"
                            onClose={onClose}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Stores>
                            {compStores.map((item, i) => {
                                return (
                                    <Grow key={i} in={true} timeout={(i+1)*500}>
                                        <ListItemButton sx={{ borderRadius: 3 }}>
                                            <ListItemAvatar>
                                                <StoreIcon store={item} />
                                            </ListItemAvatar>
                                
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle1" color="#262626">{item.name}</Typography>
                                                } 
                                                secondary={item.address}
                                            />
                                            
                                            <ListItemSecondaryAction>
                                                <Stack alignItems="flex-end">
                                                    <Button
                                                        variant="contained"
                                                        size="medium"
                                                        onClick={() => setChoosenStore(item)}
                                                    >
                                                        <Typography fontSize={12}>Kiválaszt</Typography>
                                                    </Button>
                                                </Stack>
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                    </Grow>
                                );
                            })}
                        </Stores>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default IncomeStore;