import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import { AvatarGroup, Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

import CurrencyText from "../../../components/currencytext/CurrencyText";
import StoreIcon from "../../../layouts/stores/content/StoreIcon";

const TrafficItem = ({ traffic, click }) => {
    const {user, setMsg} = useContext(DataContext);

    const [itemized, setItemized] = useState({
        current: [],
        prev: []
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(JSON.stringify(traffic) !== "{}") {
            getItemized();
        }
    }, []);

    const getItemized = async() => {
        const res = await fetch(apiUrl+"/traffic/daily/itemized", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                date: traffic.date,
                company: user.company.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setItemized(data.traffic);
    };

    return (
        <Grid item xs={12}>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                border="1px solid #DCDCDC"
                borderRadius={1}
                color="#262626"
                padding={1}
                boxSizing="border-box"
            >
                <IconButton
                    onClick={() => click(itemized)}
                    size="small"
                >
                    <MoreHorizIcon />
                </IconButton>

                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={1}
                >
                    <Typography fontSize={12} fontWeight={300}>Státusz</Typography>

                    {itemized.current.filter(item => item.operation === 2).length !== 0 ?
                        <LockIcon />
                    :
                        <LockOpenIcon />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    <Typography fontSize={12} fontWeight={300}>Dátum</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            {traffic.date}
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={80} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>Napnyitás</Typography>

                    {itemized.current.length !== 0 ?
                        <AvatarGroup>
                            {itemized.current.filter(item => item.operation === 1).map(item => {
                                return (
                                    <StoreIcon
                                        key={item.id}
                                        store={item}
                                        size="small"
                                    />
                                );
                            })}
                        </AvatarGroup>
                    :
                        <Skeleton variant="circular" width={20} height={20} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>Műszakváltás</Typography>

                    {itemized.current.length !== 0 ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            {itemized.current.filter(item => item.operation === 3).length}
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={20} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>Nyugtaszám</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            {traffic.receipt_quantity ? traffic.receipt_quantity : 0} DB
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={40} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>Kosárérték</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            <CurrencyText val={Math.round(traffic.basket_value)} /> HUF
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={60} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>5%-os</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            <CurrencyText val={traffic.a_tax} /> HUF
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={80} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>18%-os</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            <CurrencyText val={traffic.b_tax} /> HUF
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={80} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>27%-os</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            <CurrencyText val={traffic.c_tax} /> HUF
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={80} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>AJT</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            <CurrencyText val={traffic.d_tax} /> HUF
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={80} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>AM</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            <CurrencyText val={traffic.e_tax} /> HUF
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={80} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>Forgalom</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            <CurrencyText val={traffic.daily_traffic_sum} /> HUF
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={80} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>Árrés</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            {Number(traffic.margin).toFixed(2)} %
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={30} height={15} />
                    }
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems="center"
                >
                    <Typography fontSize={12} fontWeight={300}>Bankkártya</Typography>

                    {traffic.date ?
                        <Typography
                            fontSize={12}
                            fontWeight={400}
                            color="#000"
                        >
                            <CurrencyText val={traffic.card} /> HUF
                        </Typography>
                    :
                        <Skeleton variant="rounded" width={80} height={15} />
                    }
                </Box>
            </Box>
        </Grid>
    );
};

export default TrafficItem;