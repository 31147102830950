import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

const SettingsTab = () => {
    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
            <ListItemButton>
                <ListItemIcon>
                    <HelpOutlineOutlinedIcon />
                </ListItemIcon>

                <ListItemText secondary="Segítség" />
            </ListItemButton>

            <ListItemButton>
                <ListItemIcon>
                    <RestoreOutlinedIcon />
                </ListItemIcon>

                <ListItemText secondary="Előzmények" />
            </ListItemButton>

            <ListItemButton>
                <ListItemIcon>
                    <PermIdentityOutlinedIcon />
                </ListItemIcon>

                <ListItemText secondary="Profil beállítások" />
            </ListItemButton>
        </List>
    );
};

export default SettingsTab;