import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import OpHeader from "../../components/opheader/OpHeader";
import ProductRow from "./content/ProductRow";
import StoreIcon from "../../layouts/stores/content/StoreIcon";

const IncomingProducts = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {user, setOpenOper, setLoading, setMsg} = useContext(DataContext);

    const [compStores, setCompStores] = useState([]);

    const [rowInfo, setRowInfo] = useState({
        itemName: "",
        unitPrice: "",
        vat: "",
        quantity: ""
    });
    const [rows, setRows] = useState([]);
    const [currentRow, setCurrentRow] = useState(0);

    const [barcode, setBarcode] = useState("");

    const [storeDialog, setStoreDialog] = useState(false);
    const [barcodeDialog, setBarcodeDialog] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        getCompanyStores();

        setLoading(false);
    }, []);

    const getCompanyStores = async() => {
        const res = await fetch(apiUrl+"/company/stores/"+user.company.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setCompStores(data.stores.map(item => ({...item, active: false})));
    };

    const handleAddRow = () => {
        setRowInfo({
            itemName: "",
            unitPrice: "",
            vat: "",
            quantity: ""
        });
        setRows(prev => [...prev, rowInfo]);
    };

    const handleClick = (store) => {
        setCompStores(prev => prev.map(item => {
            if(item.id === store.id) {
                return {...item, active: !item.active};
            }
            return {...item, active: false};
        }));
    };

    const handleIncomeProducts = () => {
        setStoreDialog(false);
        setBarcodeDialog(true);
    };

    const handleGetProduct = async() => {
        const res = await fetch(apiUrl+"/product/barcode", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                barcode: barcode,
                product: rows[currentRow]
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        saveIncome();
    };

    const saveIncome = async() => {
        const res = await fetch(apiUrl+"/product/income/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                barcode: barcode,
                store: compStores.filter(item => item.active)[0].id,
                product: rows[currentRow]
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        saveStock();
    };

    const saveStock = async() => {
        const res = await fetch(apiUrl+"/product/stock", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                barcode: barcode,
                store: compStores.filter(item => item.active)[0].id,
                quantity: rows[currentRow].quantity
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        if(currentRow === rows.length-1) {
            setMsg({
                color: "success",
                message: "Sikeres bevételezés"
            });
            setRows([]);
            setCurrentRow(0);
            setBarcode("");
            return setBarcodeDialog(false);
        }
        else {
            setBarcode("");
            return setCurrentRow(prev => prev+1);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OpHeader
                    operation={26}
                    onClose={() => setOpenOper(0)}
                />
            </Grid>

            <Paper
                sx={{
                    width: "100%",
                    p: 2,
                    marginTop: 1
                }}
            >
                <Grid
                    container
                    spacing={4}
                >
                    <Grid item xs={12} md={3}>
                        <OutlinedInput
                            value={rowInfo.itemName}
                            onChange={(e) => setRowInfo(prev => ({...prev, itemName: e.target.value}))}
                            placeholder="Megnevezés"
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <OutlinedInput
                            value={rowInfo.unitPrice}
                            onChange={(e) => setRowInfo(prev => ({...prev, unitPrice: e.target.value}))}
                            placeholder="Beszerzési ár"
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <OutlinedInput
                            value={rowInfo.quantity}
                            onChange={(e) => setRowInfo(prev => ({...prev, quantity: e.target.value}))}
                            placeholder="Darabszám"
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <OutlinedInput
                            value={rowInfo.vat}
                            onChange={(e) => setRowInfo(prev => ({...prev, vat: e.target.value}))}
                            placeholder="ÁFA kulcs"
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleAddRow}
                            disabled={Object.values(rowInfo).some(item => item === "")}
                        >
                            Hozzáadás
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#FAFAFA" }}>
                                <TableCell sx={{ color: "#434343" }}>Megnevezés</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Darabszám</TableCell>
                                <TableCell sx={{ color: "#434343" }}>Beszerzési ár</TableCell>
                                <TableCell sx={{ color: "#434343" }}>ÁFA kulcs</TableCell>
                                <TableCell sx={{ color: "#434343", textAlign: "right" }}>Műveletek</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows.map((item, index) => {
                                return (
                                    <ProductRow
                                        key={index}
                                        row={item}
                                        onDelete={() => setRows(prev => prev.filter((_, i) => i !== index))}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Box
                display="flex"
                justifyContent="flex-end"
                component={Paper}
                width="100%"
                marginTop={1}
                padding={2}
            >
                <Button
                    variant="contained"
                    disabled={rows.length === 0}
                    onClick={() => setStoreDialog(true)}
                >
                    Bevételezés
                </Button>
            </Box>

            <Dialog
                fullScreen={matches}
                open={storeDialog}
                onClose={() => setStoreDialog(false)}
            >
                <DialogTitle>
                    Telephely választó
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Add meg a telephelyet
                    </DialogContentText>

                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        gap={2}
                        width="100%"
                        height={40}
                    >
                        {compStores.map(item => {
                            return (
                                <StoreIcon
                                    key={item.id}
                                    store={item}
                                    type="checkbox"
                                    val={item.active}
                                    onClick={handleClick}
                                />
                            );
                        })}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button
                        size="small"
                        onClick={() => setStoreDialog(false)}
                    >
                        Vissza
                    </Button>

                    <Button
                        variant="contained"
                        size="small"
                        disabled={compStores.filter(item => item.active).length === 0}
                        onClick={handleIncomeProducts}
                    >
                        Tovább
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullScreen={matches}
                open={barcodeDialog}
                onClose={() => setBarcodeDialog(false)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            minWidth: 400
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography fontSize={20} fontWeight={600}>{rows[currentRow]?.itemName}</Typography>

                        <IconButton
                            onClick={() => setBarcodeDialog(false)}
                            sx={{ marginLeft: "auto" }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText margin={2} align='center' fontSize={14}>
                        Add meg a termék vonalkódját
                    </DialogContentText>

                    <OutlinedInput
                        value={barcode}
                        onChange={(e) => setBarcode(e.target.value)}
                        placeholder="Vonalkód"
                        size="small"
                        fullWidth
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleGetProduct}
                        disabled={barcode === ""}
                    >
                        Tovább
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default IncomingProducts;