import { useState } from "react";

import { Box } from "@mui/material";

import Header from "./header/Header";
import Nav from "./nav/Nav";

const Dashboard = ({ children }) => {
    const [navOpen, setNavOpen] = useState(false);

    return (
        <Box display="flex">
            <Header
                open={navOpen}
                onNav={() => setNavOpen(prev => !prev)}
            />

            <Nav
                open={navOpen}
                onClose={() => setNavOpen(false)}
            />

            {children}
        </Box>
    );
};

export default Dashboard;