import { useContext, useState } from "react";

import { DataContext } from "../../../../../context/DataContext";

import { Avatar, AvatarGroup, Box, Tooltip, Typography } from "@mui/material";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import StoreIcon from "../../../../../layouts/stores/content/StoreIcon";

const PackageItem = ({ pack, summary, onChoose, onRemove }) => {
    const {stores} = useContext(DataContext);

    const [checked, setChecked] = useState(summary ? true : false);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = pack.profile_pic ? apiUrl+"/"+pack.profile_pic : null;

    const handleSelect = () => {
        if(!summary) {
            setChecked(prevCheck => !prevCheck);
            onChoose();
        }
    };

    const handleRemove = () => {
        if(!summary) {
            setChecked(prevCheck => !prevCheck);
            onRemove();
        }
    };

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    if(checked) {
        return (
            <Tooltip
                onClick={handleRemove}
                sx={{ cursor: "pointer"}}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    border="1px solid #13C2C2"
                    borderRadius={1}
                    padding={1}
                    boxSizing="border-box"
                    width="100%"
                >
                    <AvatarGroup max={2}>
                        <StoreIcon
                            store={stores.filter(item => item.id === pack.store)[0]}
                            size="medium"
                        />

                        <Tooltip
                            title={pack.last_name+" "+pack.first_name}
                            arrow
                        >
                            <Avatar
                                src={imageExists(avatar) ? avatar : null}
                                sx={{ zIndex: 2, width: 32, height: 32 }}
                            />
                        </Tooltip>
                    </AvatarGroup>

                    <Box
                        display="flex"
                        flexDirection="column"
                        color="#000"
                    >
                        <Typography fontSize={12} fontWeight={700}>{pack.serial_num}</Typography>
                        <Typography fontSize={10} fontWeight={300}>{pack.date_of_record}</Typography>
                    </Box>

                    <CheckCircleOutlineIcon
                        style={{
                            color: "#13C2C2",
                            fontSize: 32,
                            marginLeft: "auto"
                        }}
                    />
                </Box>
            </Tooltip>
        );
    }
    else {
        return (
            <Tooltip
                onClick={handleSelect}
                sx={{ cursor: "pointer", opacity: 0.5 }}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    border="1px solid #FFF"
                    borderRadius={1}
                    padding={1}
                    boxSizing="border-box"
                    width="100%"
                >
                    <AvatarGroup max={2}>
                        <StoreIcon
                            store={stores.filter(item => item.id === pack.store)[0]}
                            size="medium"
                        />

                        <Tooltip
                            title={pack.last_name+" "+pack.first_name}
                            arrow
                        >
                            <Avatar
                                src={imageExists(avatar) ? avatar : null}
                                sx={{ zIndex: 2, width: 32, height: 32 }}
                            />
                        </Tooltip>
                    </AvatarGroup>

                    <Box
                        display="flex"
                        flexDirection="column"
                    >
                        <Typography fontSize={12} fontWeight={700}>{pack.serial_num}</Typography>
                        <Typography fontSize={10} fontWeight={300}>{pack.date_of_record}</Typography>
                    </Box>
                </Box>
            </Tooltip>
        );
    }
};

export default PackageItem;