import { useContext, useEffect, useRef, useState } from "react";

import { DataContext } from "../../../../context/DataContext";

import { Box, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const NavGroup = ({ open, group, company, children }) => {
    const {user, setUser} = useContext(DataContext);

    const anchorRef = useRef(null);

    const [companyOpen, setCompanyOpen] = useState(false);

    useEffect(() => {
        if(!open) setCompanyOpen(false);
    }, [open]);

    const handleToggle = () => {
        setCompanyOpen((prevOpen) => !prevOpen);
    };

    const handleChooseCompany = (e, newCompany) => {
        setUser(prev => ({...prev, company: newCompany}))
        handleClose(e);
    };

    const handleClose = (e) => {
        if(anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }

        setCompanyOpen(false);
    };

    return (
        <Box>
            {open &&
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={2}
                >
                    <Typography
                        color="#262626"
                        fontSize={12}
                        marginLeft={2}
                    >
                        {group.toUpperCase()}
                    </Typography>

                    {company &&
                        <>
                            <IconButton
                                ref={anchorRef}
                                aria-controls={companyOpen ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                            >
                                {companyOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>

                            <Popper
                                open={companyOpen}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                                sx={{ zIndex: 100000 }}
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={companyOpen} id="menu-list-grow">
                                                    {user.companies.map(item => {
                                                        return (
                                                            <MenuItem
                                                                key={item.id}
                                                                onClick={(e) => handleChooseCompany(e, item)}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </>
                    }
                </Box>
            }  

            {children}
        </Box>
    );
};

export default NavGroup;