import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useDebounce from "../../../../hooks/useDebounce";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import TicketManageStyle from "./TicketManage.module.css";

import OpsHeader from "../../../../layouts/opsheader/OpsHeader";
import TicketInfoNav from "../../../../layouts/ticketinfonav/TicketInfoNav";

import Feedback from "../../../../components/feedback/Feedback";
import TicketCard from "../../../../components/ticketcard/TicketCard";
import SearchBar from "../../../../components/searchbar/SearchBar";

const TicketManage = () => {
    const {
        user,
        msg, setMsg,
        loading, setLoading,
        slideRef,
        setOpenOper,
        searchRef,
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [showTickets, setShowTickets] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setSearch("");
        setShowSearch("");

        getTickets();

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowTickets(tickets.filter(item => rx.test(item.name)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [tickets, showSearch]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    const getTickets = async() => {
        const res = await fetch(apiUrl+"/tickets", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setTickets(data.tickets);
    };

    const handleBack = () => {
        // slideRef.current.className = HomeStyle.op_content_close;

        const timer = setTimeout(() => {
            setMsg({});
            setOpenOper(0);
        }, 1000);
        return () => clearTimeout(timer);
    };

    const handleNavSearch = () => {
        searchRef.current.focus();
    };

    return (
        <div style={{overflow: "hidden"}}>
            <div
                style={{backgroundColor: "#FFFFFF"}}
                ref={slideRef}
            >
                <div>
                    {
                        Object.keys(msg).length !== 0 ?
                            <Feedback
                                color={msg.color}
                                message={msg.message}
                            />
                            :
                            <></>
                    }

                    <OpsHeader
                        operation={17}
                        opsRef={slideRef}
                    />

                    <SearchBar
                        ph="Keresés"
                        val={search}
                        change={(e) => setSearch(e.target.value)}
                    />
                </div>

                <div>
                    <div className={TicketManageStyle.ops_tickets_header}>
                        <TicketInfoNav
                            active={1}
                        />
                    </div>

                    <div className={TicketManageStyle.ops_tickets_body}>
                        {showTickets.length === 0 ?
                            <span className={TicketManageStyle.ops_tickets_none}>
                                A keresésnek nincs eredménye.
                            </span>
                        :
                            showTickets.map(item => {
                                return (
                                    <TicketCard
                                        key={item.id}
                                        ticket={item}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketManage;