import { Box, ClickAwayListener, MenuItem, MenuList, Paper, Typography } from "@mui/material";

import HistoryIcon from '@mui/icons-material/History';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const OpsPopper = ({ open, onClose }) => {
    if(open) {
        return (
            <ClickAwayListener onClickAway={onClose}>
                <Paper
                    sx={{
                        position: "fixed",
                        zIndex: 99999
                    }}
                >
                    <MenuList id="menu-list-grow">
                        <MenuItem>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={0.5}
                            >
                                <HistoryIcon style={{ fontSize: 20, color: "#8C8C8C"}} />

                                <Typography
                                    fontSize={12}
                                    fontWeight={300}
                                    color="#8C8C8C"
                                >
                                    Számla történet
                                </Typography>
                            </Box>
                        </MenuItem>

                        <MenuItem>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={0.5}
                            >
                                <PrintOutlinedIcon style={{ fontSize: 20, color: "#8C8C8C"}} />

                                <Typography
                                    fontSize={12}
                                    fontWeight={300}
                                    color="#8C8C8C"
                                >
                                    Nyomtatás
                                </Typography>
                            </Box>
                        </MenuItem>

                        <MenuItem>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={0.5}
                            >
                                <FileDownloadOutlinedIcon style={{ fontSize: 20, color: "#8C8C8C"}} />

                                <Typography
                                    fontSize={12}
                                    fontWeight={300}
                                    color="#8C8C8C"
                                >
                                    Letöltés PDF
                                </Typography>
                            </Box>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </ClickAwayListener>
        );
    }
};

export default OpsPopper;