import { useState } from "react";

import { Box, Button, Dialog, DialogActions, DialogContentText, IconButton, OutlinedInput, Tooltip, useMediaQuery, useTheme } from "@mui/material";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const NewNameDialog = ({ open, setOpen, choosen, barcode, nameInvoice, onNext }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const [choosenName, setChoosenName] = useState([false, false]);

    const [newName, setNewName] = useState("");

    const handleChoose = (id) => {
        setChoosenName(prev => prev.map((_, index) => {
            if(id === index) {
                return true;
            }

            return false;
        }));
    };

    const handleNext = () => {
        const id = choosenName.indexOf(true);
        
        if(id === 0) return onNext(nameInvoice);
        else if(id === 1) return onNext(newName);
    };

    return (
        <Dialog
            fullScreen={matches}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: 400
                    }
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <IconButton
                    onClick={() => setOpen(false)}
                    size="small"
                >
                    <ArrowBackIosIcon style={{ fontSize: 16 }} />
                </IconButton>

                <IconButton
                    size="small"
                >
                    <OpenInFullIcon style={{ fontSize: 16 }} />
                </IconButton>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                alignItems="center"
            >
                <DialogContentText fontSize={20}>
                    {choosen.itemName}
                </DialogContentText>

                <DialogContentText fontSize={12}>{barcode}</DialogContentText>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        alignSelf="flex-start"
                    >
                        <ErrorIcon style={{ color: "#73D13D" }} />

                        <DialogContentText
                            fontSize={16}
                            paddingLeft={1}
                        >
                            Új termék adja meg a megnevezést
                        </DialogContentText>
                    </Box>

                    <DialogContentText fontSize={12}>
                        A termék már létezik a rendszerben viszont eltér a megnevezése. Győzödj meg róla, hogy jó terméket olvastál be, valamint válazd ki amennyiben szeretnéd módosítani a megnevezését.
                    </DialogContentText>

                    <DialogContentText fontSize={12}>Számlán szereplő megnevezés</DialogContentText>

                    <Tooltip onClick={() => handleChoose(0)}>
                        <OutlinedInput
                            value={nameInvoice}
                            readOnly
                            size="small"
                            fullWidth
                            endAdornment={
                                choosenName[0] && <CheckCircleOutlineIcon style={{ color: "#13C2C2" }} />
                            }
                            sx={choosenName[0] && {
                                color: "#13C2C2",
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#13C2C2"
                                },
                                "&:hover": {
                                    ".MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#13C2C2"
                                    }
                                },
                                "&:focused": {
                                    ".MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#13C2C2"
                                    }
                                }
                            }}
                        />
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    width="95%"
                >
                    <DialogContentText fontSize={12}>Egyéni</DialogContentText>

                    <Tooltip onClick={() => handleChoose(1)}>
                        <OutlinedInput
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            size="small"
                            fullWidth
                            endAdornment={
                                choosenName[1] && <CheckCircleOutlineIcon style={{ color: "#13C2C2" }} />
                            }
                            sx={choosenName[1] && {
                                color: "#13C2C2",
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#13C2C2"
                                },
                                "&:hover": {
                                    ".MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#13C2C2"
                                    }
                                },
                                "&:focused": {
                                    ".MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#13C2C2"
                                    }
                                }
                            }}
                        />
                    </Tooltip>
                </Box>
            </Box>

            <DialogActions>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        sx={{
                            color: "#262626",
                            borderColor: "#D9D9D9",
                            "&:hover": {
                                color: "#262626",
                                borderColor: "#D9D9D9"
                            }
                        }}
                    >
                        Vissza
                    </Button>

                    <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={choosenName.filter(item => item).length === 0}
                        sx={{
                            bgcolor: "#8C8C8C",
                            "&:hover": {
                                bgcolor: "#8C8C8C"
                            }
                        }}
                    >
                        Tovább
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default NewNameDialog;