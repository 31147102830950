import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";

import { DataContext } from "../../context/DataContext";
import { getStoreStatus } from "../../services/Api";
import { calculateNet, getVatSum } from "../../services/TrafficCalc";

import { motion } from 'framer-motion';

import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import { Box, Button, Grid, Grow, Paper } from "@mui/material";

import OpHeader from "../../components/opheader/OpHeader";
import ChartFilter from "../../components/charts/chartfilter/ChartFilter";

import TotalYears from "./content/TotalYears";
import TotalStores from "./content/TotalStores";
import StoresTraffic from "./content/StoresTraffic";
import DayAvg from "./content/DayAvg";
import TotalUsers from "./content/TotalUsers";
import ProductTraffic from "./content/ProductTraffic";

import DailyStores from "./content/dailystores/DailyStores";
import DailyUser from "./content/dailyuser/DailyUser";
import DailyProduct from "./content/dailyproduct/DailyProduct";

const DiagramsDev = () => {
    const {
        user,
        setMsg,
        setOpenOper,
        setLoading
    } = useContext(DataContext);

    const [info, setInfo] = useState({
        from: dayjs(Date.now()),
        to: dayjs(Date.now())
    });
    const [traffic, setTraffic] = useState([]);
    const [netTraffic, setNetTraffic] = useState([]);

    const [daily, setDaily] = useState(false);
    const [dailyInfo, setDailyInfo] = useState({});

    const [product, setProduct] = useState(false);
    const [productInfo, setProductInfo] = useState({});

    const [userPage, setUserPage] = useState(false);
    const [userInfo, setUserInfo] = useState({});

    const [trafficTypes, setTrafficTypes] = useState([
        {
            id: 0,
            name: "Nettó",
            color: "#5470C6",
            show: true
        },
        {
            id: 1,
            name: "Bruttó",
            color: "#5470C6",
            show: false
        },
    ]);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const getTraffic = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/diadev/traffic", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(info)
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTraffic(data.traffic);

        setNetTraffic(calculateNet(data.traffic));

        setLoading(false);
    };

    const handleGoToDaily = (data) => {
        setDailyInfo(data);

        setDaily(true);
    };

    const renderDaily = () => {
        if(daily) {
            return (
                <DailyStores
                    data={dailyInfo}
                    onClose={() => setDaily(false)}
                />
            );
        }
    };

    const handleGoToProduct = (data) => {
        setProductInfo(data);

        setProduct(true);
    };

    const renderProduct = () => {
        if(product) {
            return (
                <DailyProduct
                    data={productInfo}
                    onClose={() => setProduct(false)}
                />
            );
        }
    };

    const handleGoToUser = (data) => {
        setUserInfo(data);

        setUserPage(true);
    };

    const renderUser = () => {
        if(userPage) {
            return (
                <DailyUser
                    data={userInfo}
                    onClose={() => setUserPage(false)}
                />
            );
        }
    };

    const handleTypeFilter = (type) => {
        const modifyArr = trafficTypes.map(item => {
            if(item.id === type.id) {
                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    show: true
                };
            }
            
            return {
                id: item.id,
                name: item.name,
                color: item.color,
                show: false
            };
        });

        setTrafficTypes(modifyArr);
    };

    return (
        <>
            {renderDaily()}
            {renderProduct()}
            {renderUser()}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <OpHeader
                        operation={20}
                        onClose={() => setOpenOper(0)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box
                        component={Paper}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        padding={2}
                    >
                        {traffic.length === 0 ?
                            <>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={3}
                                >
                                    <Grow in={true} timeout={400}>
                                        <DateCalendar
                                            value={info.from}
                                            onChange={(val) => setInfo(prevData => ({...prevData, from: val}))}
                                            sx={{ color: "#000" }}
                                        />
                                    </Grow>

                                    <Grow in={true} timeout={800}>
                                        <DateCalendar
                                            value={info.to}
                                            onChange={(val) => setInfo(prevData => ({...prevData, to: val}))}
                                            sx={{ color: "#000" }}
                                        />
                                    </Grow>
                                </Box>

                                <motion.div
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <Grow in={true} timeout={1200}>
                                        <Button variant="contained" onClick={getTraffic}>
                                            Lekérdezés
                                        </Button>
                                    </Grow>
                                </motion.div>
                            </>
                            :
                            <>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                    marginBottom={3}
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        gap={2}
                                    >
                                        <Grow in={true} timeout={400}>
                                            <DatePicker
                                                label="Dátum-tól"
                                                value={info.from}
                                                onChange={(val) => setInfo(prevData => ({...prevData, from: val}))}
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        height: "32px"
                                                    }
                                                }}
                                            />
                                        </Grow>

                                        <Grow in={true} timeout={800}>
                                            <DatePicker
                                                label="Dátum-ig"
                                                value={info.to}
                                                onChange={(val) => setInfo(prevData => ({...prevData, to: val}))}
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        height: "32px"
                                                    }
                                                }}
                                            />
                                        </Grow>

                                        <motion.div
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                        >
                                            <Grow in={true} timeout={1200}>
                                                <Button variant="contained" onClick={getTraffic}>
                                                    Lekérdezés
                                                </Button>
                                            </Grow>
                                        </motion.div>
                                    </Box>

                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        gap={1}
                                    >
                                        {trafficTypes.map(item => {
                                            return (
                                                <ChartFilter
                                                    key={item.id}
                                                    name={item.name}
                                                    colorCode={item.color}
                                                    active={item.show}
                                                    click={() => handleTypeFilter(item)}
                                                />
                                            );
                                        })}
                                    </Box>
                                </Box>

                                <Grid container spacing={3}>
                                    <TotalYears 
                                        traffic={trafficTypes[1].show ? traffic : netTraffic}
                                        vatValues={getVatSum(traffic)}
                                    />

                                    <TotalStores
                                        traffic={trafficTypes[1].show ? traffic : netTraffic}
                                        onDaily={handleGoToDaily}
                                    />

                                    <DayAvg
                                        traffic={trafficTypes[1].show ? traffic : netTraffic}
                                    />

                                    <StoresTraffic
                                        traffic={trafficTypes[1].show ? traffic : netTraffic}
                                    />

                                    <TotalUsers
                                        traffic={trafficTypes[1].show ? traffic : netTraffic}
                                        onDaily={handleGoToUser}
                                    />

                                    <ProductTraffic
                                        traffic={traffic}
                                        gross={trafficTypes[1].show}
                                        onDaily={handleGoToProduct}
                                    />
                                </Grid>
                            </>
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default DiagramsDev;