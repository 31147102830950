import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../context/DataContext";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import OpHeader from "../../../components/opheader/OpHeader";
import TicketItem from "./content/TicketItem";
import IncomingVerify from "./IncomingVerify";

const IncomingTickets = () => {
    const {user, setOpenOper, setLoading, setMsg} = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [newTicket, setNewTicket] = useState({
        type: "",
        from: "",
        to: ""
    });
    const [incoming, setIncoming] = useState([]);

    const [open, setOpen] = useState({});
    const [summary, setSummary] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getDraftTickets();
    }, []);

    const getDraftTickets = async() => {
        setLoading(true);

        const res = await fetch(apiUrl+"/tickets/incoming/"+user.store, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setIncoming(data.tickets.map(item => ({
            id: item.id,
            type: item.ticket,
            name: item.name,
            from: item.serial_from,
            to: item.serial_to
        })));

        getTicketTypes();
    };

    const getTicketTypes = async() => {
        const res = await fetch(apiUrl+"/tickets", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setTickets(data.tickets);

        setLoading(false);
    };

    const handleAddRow = async() => {
        if(newTicket.type === "" || newTicket.from === "" || newTicket.to === "") {
            return setMsg({
                color: "error",
                message: "Minden mező kitöltése kötelező!"
            });
        }

        const res = await fetch(apiUrl+"/tickets/incoming/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: user.store,
                row: newTicket
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getDraftTickets();

        setNewTicket({
            id: 0,
            type: 0,
            from: "",
            to: "",
            quantity: ""
        });

        setMsg({
            color: "success",
            message: "Sor hozzáadva!"
        });
    };

    const handleDeleteItem = async() => {
        const res = await fetch(apiUrl+"/tickets/incoming/delete", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: open.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getDraftTickets();

        setOpen({});

        setMsg({
            color: "error",
            message: "Sor törölve"
        })
    };

    const handleEditItem = async(newItem) => {
        const res = await fetch(apiUrl+"/tickets/incoming/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                row: newItem
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        getDraftTickets();

        setMsg({
            color: "success",
            message: "Sor módosítva"
        })
    };

    const handleNext = async() => {
        if(incoming.length === 0) {
            return setMsg({
                color: "error",
                message: "Először add hozzá a sorsjegyeket"
            });
        }
        
        setMsg({});
        setSummary(true);
    };

    const renderVerify = () => {
        if(summary) {
            return (
                <IncomingVerify
                    incoming={incoming}
                    onClose={() => setSummary(false)}
                />
            );
        }
    };

    return (
        <>
            {renderVerify()}

            {!summary &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OpHeader
                            operation={11}
                            onClose={() => setOpenOper(0)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead sx={{ bgcolor: "#F5F5F5" }}>
                                    <TableRow>
                                        <TableCell>Sorsjegyek</TableCell>
                                        <TableCell>Sorozatszám-tól</TableCell>
                                        <TableCell>Sorozatszám-ig</TableCell>
                                        <TableCell>Mennyiség</TableCell>
                                        <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow>
                                        <TableCell width={220}>
                                            <FormControl size="small" sx={{ width: 220 }}>
                                                <InputLabel id="select_label">Sorsjegy típus</InputLabel>

                                                <Select
                                                    value={newTicket.type}
                                                    onChange={(e) => setNewTicket(prevTick => ({...prevTick, type: e.target.value}))}
                                                    label="Sorsjegy típus"
                                                    labelId="select_label"
                                                >
                                                    {tickets.map(item => {
                                                        return (
                                                            <MenuItem
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </TableCell>

                                        <TableCell>
                                            <OutlinedInput
                                                value={newTicket.from}
                                                onChange={(e) => setNewTicket(prev => ({...prev, from: e.target.value}))}
                                                size="small"
                                                fullWidth
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <OutlinedInput
                                                value={newTicket.to}
                                                onChange={(e) => setNewTicket(prev => ({...prev, to: e.target.value}))}
                                                size="small"
                                                fullWidth
                                            />
                                        </TableCell>

                                        <TableCell width={80}></TableCell>

                                        <TableCell align="right">
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={handleAddRow}
                                            >
                                                + Hozzáadás
                                            </Button>
                                        </TableCell>
                                    </TableRow>

                                    {incoming.map(item => {
                                        return (
                                            <TicketItem
                                                key={item.id}
                                                item={item}
                                                tickets={tickets}
                                                onDelete={(t) => setOpen(t)}
                                                onEdit={handleEditItem}
                                            />
                                        );
                                    })}

                                    <Dialog
                                        open={Object.keys(open).length !== 0}
                                        onClose={() => setOpen({})}
                                        PaperProps={{
                                            sx: { width: 400 }
                                        }}
                                    >
                                        <DialogTitle>
                                            Biztosan törlöd a sort?
                                        </DialogTitle>

                                        <DialogContent>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Typography fontSize={14} fontWeight={400}>{open.name}</Typography>
                                                <Typography fontSize={12} fontWeight={400} color="#8C8C8C">{open.from} - {open.to}</Typography>
                                            </Box>
                                        </DialogContent>

                                        <DialogActions>
                                            <Button variant="outlined" onClick={() => setOpen({})}>
                                                Mégsem
                                            </Button>

                                            <Button variant="contained" onClick={handleDeleteItem}>
                                                Törlés
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </TableBody>
                            </Table>

                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                padding={1}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                >
                                    Bevételezés
                                </Button>
                            </Box>
                            
                        </TableContainer>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default IncomingTickets;