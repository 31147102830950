import { Box, IconButton, Stack, Typography } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";

import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const DateFilter = ({ dates, onFrom, onTo, onRemove }) => {
    return (
        <Stack spacing={1}>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>Dátum</Typography>
                <IconButton
                    disabled={dates.from === null && dates.to === null}
                    onClick={onRemove}
                >
                    <FilterAltOffIcon />
                </IconButton>
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                gap={1}
            >
                <DatePicker
                    value={dates.from}
                    onChange={onFrom}
                    slotProps={{ textField: { size: 'small' } }}
                />

                <DatePicker
                    value={dates.to}
                    onChange={onTo}
                    slotProps={{ textField: { size: 'small' } }}
                />
            </Box>
        </Stack>
    );
};

export default DateFilter;