import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

import { DataContext } from "../../../../context/DataContext";

import { Box, Button, Divider, Grid, OutlinedInput, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

const Company = ({ userInfo, onClose, onSave }) => {
    const {user, setMsg} = useContext(DataContext);

    const [info, setInfo] = useState({
        id: userInfo.id,
        social: userInfo.social_sec_num,
        tax: userInfo.personal_tax_id,
        companyIn: dayjs(userInfo.company_in_date),
        companyOut: dayjs(userInfo.company_out_date),
        medical: dayjs(userInfo.medical),
        lung: dayjs(userInfo.lung_screen)
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSave = async() => {
        const res = await fetch(apiUrl+"/user/modify/company", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                profile: info
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres művelet!"
        });

        onSave();
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography fontSize={16} fontWeight={500}>Vállalati adatok</Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    marginLeft="auto"
                    marginRight="auto"
                    justifyContent="space-between"
                    width={540}
                >
                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">TAJ szám</Typography>
                        <OutlinedInput
                            value={info.social}
                            onChange={(e) => setInfo(prev => ({...prev, social: e.target.value}))}
                            size="small"
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Adószám</Typography>
                        <OutlinedInput
                            value={info.tax}
                            onChange={(e) => setInfo(prev => ({...prev, tax: e.target.value}))}
                            size="small"
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    marginLeft="auto"
                    marginRight="auto"
                    justifyContent="space-between"
                    width={540}
                >
                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Munkaszerződés kezdete</Typography>
                        <DatePicker
                            value={info.companyIn}
                            onChange={(newVal) => setInfo(prev => ({...prev, companyIn: newVal}))}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Munkaszerződés vége</Typography>
                        <DatePicker
                            value={info.companyOut}
                            onChange={(newVal) => setInfo(prev => ({...prev, companyOut: newVal}))}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    marginLeft="auto"
                    marginRight="auto"
                    justifyContent="space-between"
                    width={540}
                >
                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Orvosi alkalmassági érvényessége</Typography>
                        <DatePicker
                            value={info.medical}
                            onChange={(newVal) => setInfo(prev => ({...prev, medical: newVal}))}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>

                    <Stack>
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">Tüdőszűrés érvényessége</Typography>
                        <DatePicker
                            value={info.lung}
                            onChange={(newVal) => setInfo(prev => ({...prev, lung: newVal}))}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{
                                width: 265
                            }}
                        />
                    </Stack>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    justifyContent="flex-end"
                >
                    <Button variant="outlined" onClick={onClose}>Mégse</Button>
                    <Button variant="contained" onClick={handleSave}>Mentés</Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Company;