import { useState } from "react";

import { motion } from 'framer-motion';

import { Avatar, Box, Button, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Buttons from "./Buttons";

const UserItem = ({ user, onChoose, onLogin }) => {
    const [pw, setPw] = useState("");
    const [showPw, setShowPw] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = user.profile_pic ? apiUrl+"/"+user.profile_pic : null;

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    if(user.active) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                width="100%"
            >
                <Tooltip onClick={onChoose}>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        style={{ cursor: "pointer", width: "100%" }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                            border="1px solid #13C2C2"
                            borderRadius={1}
                            padding={2}
                            boxSizing="border-box"
                            width="100%"
                        >
                            <Tooltip
                                title={user.last_name+" "+user.first_name}
                                arrow
                            >
                                <Avatar
                                    src={imageExists(avatar) ? avatar : null}
                                    sx={{ zIndex: 2, width: 32, height: 32 }}
                                />
                            </Tooltip>

                            <Typography
                                fontSize={12}
                                fontWeight={700}
                                color="#000"
                            >
                                {user.last_name+" "+user.first_name}
                            </Typography>

                            <CheckCircleOutlineIcon
                                style={{
                                    color: "#13C2C2",
                                    fontSize: 32,
                                    marginLeft: "auto"
                                }}
                            />
                        </Box>
                    </motion.div>
                </Tooltip>

                <Typography
                    fontSize={12}
                    fontWeight={300}
                    color="#000"
                >
                    <span style={{ fontWeight: 700 }}>{user.first_name}</span> add meg a jelszavadat a műszakváltáshoz:
                </Typography>

                <TextField
                    type={showPw ? "text" : "password"}
                    value={pw}
                    onChange={(e) => setPw(e.target.value)}
                    variant="filled"
                    label="Jelszó"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPw(prev => !prev)}
                                    size="small"
                                >
                                    {showPw ? <VisibilityIcon style={{ fontSize: 14 }} /> : <VisibilityOffIcon style={{ fontSize: 14 }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{ "& .MuiFilledInput-root": {bgcolor: "#FFF"} }}
                />

                <Buttons
                    backText="Mégsem"
                    nextText="Véglegesítés"
                    onBack={onChoose}
                    onNext={() => onLogin(pw)}
                />
            </Box>
        );
    }
    else {
        return (
            <Tooltip onClick={onChoose}>
                <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    style={{
                        width: "100%",
                        cursor: "pointer"
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        alignItems="center"
                        border="1px solid #8C8C8C"
                        borderRadius={1}
                        padding={2}
                        boxSizing="border-box"
                        width="100%"
                        sx={{
                            opacity: 0.5,
                            "&:hover": {
                                opacity: 1,
                                border: "1px solid #13C2C2"
                            }
                        }}
                    >
                        <Tooltip
                            title={user.last_name+" "+user.first_name}
                            arrow
                        >
                            <Avatar
                                src={imageExists(avatar) ? avatar : null}
                                sx={{ zIndex: 2, width: 32, height: 32 }}
                            />
                        </Tooltip>

                        <Typography
                            fontSize={12}
                            fontWeight={700}
                            color="#000"
                        >
                            {user.last_name+" "+user.first_name}
                        </Typography>
                    
                        <CheckCircleOutlineIcon
                            style={{
                                color: "#8C8C8C",
                                fontSize: 32,
                                marginLeft: "auto"
                            }}
                        />
                    </Box>
                </motion.div>
            </Tooltip>
        );
    }
};

export default UserItem;