import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import ArrangedItem from "./content/transfer/ArrangedItem";

const ArrangedList = ({ invoices }) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                fontSize: 10,
                                fontWeight: 300,
                                color: "#737373"
                            }}
                        >
                            Utalási dátum
                        </TableCell>

                        <TableCell
                            sx={{
                                fontSize: 10,
                                fontWeight: 300,
                                color: "#737373"
                            }}
                        >
                            Utalási csomag
                        </TableCell>

                        <TableCell
                            sx={{
                                fontSize: 10,
                                fontWeight: 300,
                                color: "#737373"
                            }}
                        >
                            Utalta
                        </TableCell>

                        <TableCell
                            sx={{
                                fontSize: 10,
                                fontWeight: 300,
                                color: "#737373"
                            }}
                        >
                            Partner megnevezése
                        </TableCell>

                        <TableCell
                            sx={{
                                fontSize: 10,
                                fontWeight: 300,
                                color: "#737373"
                            }}
                        >
                            Számlaszám
                        </TableCell>

                        <TableCell
                            sx={{
                                fontSize: 10,
                                fontWeight: 300,
                                color: "#737373"
                            }}
                        >
                            Számla összege
                        </TableCell>

                        <TableCell
                            sx={{
                                fontSize: 10,
                                fontWeight: 300,
                                color: "#737373"
                            }}
                        >
                            Számla azonosító
                        </TableCell>

                        <TableCell
                            sx={{
                                fontSize: 10,
                                fontWeight: 300,
                                color: "#737373"
                            }}
                        >
                            Műveletek
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {invoices.map(item => {
                        return (
                            <ArrangedItem
                                key={item.id}
                                row={item}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ArrangedList;