import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";

import { Box, IconButton, Typography } from "@mui/material";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const OpHeader = ({ operation, onClose, text }) => {
    const {setMsg} = useContext(DataContext);

    const [op, setOp] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(!text) {
            getOperationData();
        }
    }, []);

    const getOperationData = async() => {
        const res = await fetch(apiUrl+"/operation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: operation
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setOp(data.op);
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={2}
            color="black"
        >
            <IconButton
                size="small"
                onClick={onClose}
            >
                <ArrowBackIcon />
            </IconButton>
            
            <Typography fontSize={18} fontWeight={500}>{text ? text : op.name}</Typography>
        </Box>
    );
};

export default OpHeader;