import { Box, Divider, Grid, Typography } from "@mui/material";

import OkButton from "./OkButton";
import CurrencyText from "../../../components/currencytext/CurrencyText";

const InfoRow = ({ text, val, checked, click, noIcon }) => {
    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Typography
                    fontSize={12}
                    fontWeight={400}
                    color="#8C8C8C"
                >
                    {text}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    justifyContent="flex-end"
                    alignItems="center"
                    width="100%"
                >
                    <Typography
                        fontSize={16}
                        fontWeight={500}
                        color="#262626"
                    >
                        <CurrencyText val={val} /> HUF
                    </Typography>

                    {!noIcon &&
                        <OkButton
                            val={checked}
                            click={click}
                        />
                    }
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );
};

export default InfoRow;