import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, Box, ButtonBase, ClickAwayListener, Fade, Grid, Paper, Popper, Stack, Tab, Tabs, Tooltip, Typography } from "@mui/material";

import { DataContext } from "../../../../context/DataContext";

import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import ProfileTab from "./ProfileTab";
import SettingsTab from "./SettingsTab";

const TabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && children}
        </div>
    );
};

const Profile = ({ name }) => {
    const {user, setUser, setMsg, setOpenOper} = useContext(DataContext);

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [tab, setTab] = useState(0);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const apiUrl = process.env.REACT_APP_API_URL;

    const avatar = user.profile_pic ? apiUrl+"/"+user.profile_pic : null;

    const handleLogout = () => {
        setOpenOper(0);
        setUser();
        setMsg({});
        navigate("/");
    };

    const imageExists = (url) => {
        const http = new XMLHttpRequest();
    
        http.open("HEAD", url, false);
        http.send();
    
        return http.status !== 404;
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75, marginLeft: "auto" }}>
            <Tooltip
                title={name}
                arrow
            >
                <ButtonBase
                    aria-describedby={id}
                    type="button"
                    onClick={handleClick}
                    sx={{
                        p: 0.25
                    }}
                >
                    <Avatar src={imageExists(avatar) ? avatar : null} size="small" />
                </ButtonBase>
            </Tooltip>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                transition
                placement="bottom-end"
                disablePortal
                popperOptions={{
                    modifiers: [{
                        name: 'offset',
                        options: {
                            offset: [0, 7]
                        }
                    }]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <Box sx={{ px: 2.5, pt: 3 }}>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item>
                                            <Stack direction="row" spacing={1.25} alignItems="center">
                                                <Avatar src={imageExists(avatar) ? avatar : null} />

                                                <Stack>
                                                    <Typography variant="subtitle1">{name}</Typography>
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {user.rank_name}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs variant="fullWidth" value={tab} onChange={handleChange}>
                                        <Tab icon={<PermIdentityOutlinedIcon />} />

                                        <Tab disabled icon={<SettingsOutlinedIcon />} />
                                    </Tabs>
                                </Box>

                                <TabPanel value={tab} index={0}>
                                    <ProfileTab onLogout={handleLogout} />
                                </TabPanel>

                                <TabPanel value={tab} index={1}>
                                    <SettingsTab />
                                </TabPanel>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </Box>
    );
};

export default Profile;