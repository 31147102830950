import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../../../context/DataContext";

import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import CurrencyText from "../../../../../../components/currencytext/CurrencyText";
import PackItem from "./content/PackItem";

const TransferPack = ({ pack, onRefresh }) => {
    const {user, setLoading, setMsg} = useContext(DataContext);

    const [open, setOpen] = useState(false);

    const [packGroup, setPackGroup] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setPackGroup(pack.invoices.reduce((group, x) => {
            (group[x["partnerId"]] = group[x["partnerId"]] || []).push(x);
            return group;
        }, {}));
    }, []);

    const calculateArranged = () => {
        const sum = pack.invoices.reduce((acc, obj) => {
            if(obj.arranged === 1) {
                return acc += obj.sum;
            }

            return acc;
        }, 0);

        return sum;
    };

    const handleTransfer = async(invs) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/transfer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                invoices: invs
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres utalás!"
        });

        setLoading(false);

        onRefresh();
    };

    const handleModify = async(invs) => {
        setLoading(true);

        const res = await fetch(apiUrl+"/inbound/transfer/remove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                invoices: invs
            })
        });

        const data = await res.json();

        if(!data.success) {
            setLoading(false);
            return setMsg({
                color: "error",
                message: data.message
            });
        }

        setMsg({
            color: "success",
            message: "Sikeres módosítás!"
        });

        setLoading(false);

        onRefresh();
    };

    return (
        <Grid item xs={12}>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                marginBottom={2}
            >
                <Stack>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                        color="#000"
                    >
                        <Typography
                            fontSize={12}
                            fontWeight={700}
                        >
                            {pack.name}
                        </Typography>

                        <IconButton
                            size="small"
                        >
                            <BorderColorOutlinedIcon style={{ fontSize: 16 }} />
                        </IconButton>
                    </Box>

                    <Typography
                        fontSize={10}
                        fontWeight={300}
                        color="#000"
                    >
                        {pack.invoices.length} tétel
                    </Typography>
                </Stack>

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    <Stack>
                        <Typography
                            fontSize={12}
                            fontWeight={700}
                            color="#000"
                        >
                            <CurrencyText val={pack.sum} /> Ft
                        </Typography>

                        <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="#000"
                        >
                            Utalandó összesen
                        </Typography>
                    </Stack>

                    <Stack>
                        <Typography
                            fontSize={12}
                            fontWeight={700}
                            color="#000"
                        >
                            <CurrencyText val={calculateArranged()} /> Ft
                        </Typography>

                        <Typography
                            fontSize={10}
                            fontWeight={300}
                            color="#000"
                        >
                            Ebből utalva
                        </Typography>
                    </Stack>

                    <IconButton
                        size="small"
                        onClick={() => setOpen(prev => !prev)}
                    >
                        {open ?
                            <KeyboardArrowUpOutlinedIcon />
                        :
                            <KeyboardArrowDownOutlinedIcon />
                        }
                    </IconButton>
                </Box>
            </Box>

            {open &&
                <Grid container spacing={1}>
                    {Object.keys(packGroup).map(key => {
                        return (
                            <PackItem
                                key={packGroup[key].id}
                                invs={packGroup[key]}
                                onTransfer={handleTransfer}
                                onModify={handleModify}
                            />
                        );
                    })}
                </Grid>
            }
        </Grid>
    );
};

export default TransferPack;